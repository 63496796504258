import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import classNames from "classnames"

import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { Checkbox } from "../Checkbox"
import { ConfirmationModal } from "../ConfirmationModal"
import Loader from "../Loader"
import { useModals } from "@mattjennings/react-modal-stack"

import MoreHorizontalSVG from "../../../assets/images/icons/MoreHorizontal.svg"

import "./styles.sass"

export type HasIdOrKey = { id: string; key: string }

export type RowDataWithId<T> = T & { id: string | number }
export type RowDataWithKey<T> = T & { key: string }

const isRowDataWithId = <T,>(row: ModifyRow<T>): row is RowDataWithId<T> =>
  "id" in row

const isRowDataWithKey = <T,>(row: ModifyRow<T>): row is RowDataWithKey<T> =>
  "key" in row

export type ModifyRow<T> = RowDataWithId<T> | RowDataWithKey<T>

export const DEFAULT_EMPTY_TABLE_CELL = <span className="no-data-cell">—</span>

export type Action<RowData> = {
  label:
    | string
    | JSX.Element
    | ((d: ModifyRow<RowData>) => string | JSX.Element)
  onClick: (d: ModifyRow<RowData>) => void
  requiresConfirmation?: boolean
}

type RowActionProps<RowData> = {
  row: ModifyRow<RowData>
  actions: Action<ModifyRow<RowData>>[]
}

function RowAction<RowData>({ row, actions }: RowActionProps<RowData>) {
  const { openModal, closeModal } = useModals()
  const ref = useRef<HTMLDivElement>(null)

  const [showDropdown, setShowDropdown] = useState(false)

  const closeDropdown = useCallback(() => {
    setShowDropdown(false)
  }, [])

  useOnClickOutside([ref], closeDropdown)

  const toggleDropdown = useCallback((e: MouseEvent) => {
    e.stopPropagation()
    setShowDropdown((p) => !p)
  }, [])

  const handleConfirmation = (
    row: ModifyRow<RowData>,
    onClick: (d: ModifyRow<RowData>) => void,
  ) => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await onClick(row)
        closeModal()
      },
      onCancel: closeModal,
    })
  }

  return (
    <div ref={ref} onClick={toggleDropdown} className="RowAction">
      <MoreHorizontalSVG />
      {showDropdown && (
        <div className="dropdown">
          {actions.map(({ label, onClick, requiresConfirmation }, index) => (
            <div
              className="action"
              key={index}
              onClick={() =>
                requiresConfirmation
                  ? handleConfirmation(row, onClick)
                  : onClick(row)
              }
            >
              {typeof label === "function" ? label(row) : label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export type Column<RowData> = {
  field: Extract<keyof ModifyRow<RowData>, string>
  label: string | JSX.Element | (() => string | JSX.Element)
  headClassName?: string
  cellClassName?: string
  renderCell?: (d: ModifyRow<RowData>) => string | JSX.Element
}

export type TableProps<RowData> = {
  className?: string
  rows: ModifyRow<RowData>[]
  columns: Column<ModifyRow<RowData>>[]
  emptyTableCell?: string | JSX.Element
  footerCells?: (string | JSX.Element)[]
  rowActions?: Action<ModifyRow<RowData>>[]
  loading?: boolean
  showRowNumber?: boolean
  calcRowNumber?: (index: number) => number
  isSelectable?: boolean
  initialSelectedRows?: ModifyRow<RowData>[]
  onSelectedRows?: (r: ModifyRow<RowData>[]) => void
  onRowClick?: (r: ModifyRow<RowData>) => void
  pagination?: ReactNode
  notFoundText?: string | JSX.Element
  footerInfo?: ReactNode
}

function Table<RowData>({
  className,
  rows,
  columns,
  footerCells,
  emptyTableCell,
  rowActions,
  loading = false,
  showRowNumber = false,
  calcRowNumber,
  isSelectable = false,
  initialSelectedRows,
  onSelectedRows,
  onRowClick,
  pagination,
  footerInfo,
  notFoundText = "Not found",
}: TableProps<RowData>) {
  const tableClassNames = classNames("Table", className, {
    loading: !!loading,
    isRowClickable: !!onRowClick,
  })
  const tableWrapperClassNames = classNames("TableWrapper", className)

  const [selectedRows, setSelectedRows] = useState<ModifyRow<RowData>[]>([])

  useEffect(() => {
    if (onSelectedRows) {
      onSelectedRows(selectedRows)
    }
  }, [selectedRows, onSelectedRows])

  useEffect(() => {
    setSelectedRows(initialSelectedRows ?? [])
  }, [initialSelectedRows, rows])

  // Returns a function that handles the selection of a row
  const handleRowCheck = (row: ModifyRow<RowData>) => {
    if (!isCheckboxChecked(row)) {
      setSelectedRows((r) => [...r, row])
      return
    }

    setSelectedRows((r) =>
      r.filter(
        (r) =>
          (isRowDataWithId(row) && isRowDataWithId(r) && r.id !== row.id) ||
          (isRowDataWithKey(row) && isRowDataWithKey(r) && r.key !== row.key),
      ),
    )
  }

  const isAllCheckboxChecked =
    selectedRows.length > 0 && rows.length === selectedRows.length

  const handleCheckAll = () => {
    if (isAllCheckboxChecked) {
      setSelectedRows([])
      return
    }
    setSelectedRows(rows)
  }

  const isCheckboxChecked = (row: ModifyRow<RowData>) => {
    return !!selectedRows.find(
      (r) =>
        (isRowDataWithId(row) && isRowDataWithId(r) && r.id === row.id) ||
        (isRowDataWithKey(row) && isRowDataWithKey(r) && r.key === row.key),
    )
  }

  const collSpanNo = useMemo(() => {
    let number = columns.length
    if (isSelectable) {
      number++
    }
    if (showRowNumber) {
      number++
    }
    if (rowActions) {
      number++
    }
    return number
  }, [columns, isSelectable, showRowNumber, rowActions])

  const rowClassNames = classNames({ "clickable-row": !!onRowClick })

  const tableHeader = (
    <thead>
      <tr>
        {isSelectable && (
          <th className="checkbox-cell">
            <Checkbox value={isAllCheckboxChecked} onChange={handleCheckAll} />
          </th>
        )}
        {showRowNumber && <th className="number-cell" />}
        {columns.map(({ field, label, headClassName }) => (
          <th key={field} className={headClassName}>
            {typeof label === "function" ? label() : label}
          </th>
        ))}
        {rowActions && <th className="action-cell" />}
      </tr>
    </thead>
  )

  if (loading) {
    return (
      <div className={tableWrapperClassNames}>
        <table className={tableClassNames}>
          {tableHeader}
          <tbody>
            <tr className="loader-container">
              <td colSpan={collSpanNo}>
                <Loader className="loader" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className={tableWrapperClassNames}>
      <table className={tableClassNames}>
        {tableHeader}
        <tbody>
          {!loading && rows.length === 0 ? (
            <tr>
              <td colSpan={collSpanNo} className="empty-cell">
                {emptyTableCell ? (
                  emptyTableCell
                ) : (
                  <div className="empty-div">{notFoundText}</div>
                )}
              </td>
            </tr>
          ) : (
            rows.map((row, i) => (
              <tr
                key={
                  isRowDataWithId(row)
                    ? row.id
                    : isRowDataWithKey(row)
                      ? row.key
                      : i
                }
                className={rowClassNames}
                onClick={() => onRowClick?.(row)}
              >
                {isSelectable && (
                  <td
                    className="checkbox-cell"
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation()
                      handleRowCheck(row)
                    }}
                  >
                    <Checkbox value={isCheckboxChecked(row)} />
                  </td>
                )}
                {showRowNumber && (
                  <td className="number-cell">{calcRowNumber?.(i) ?? i + 1}</td>
                )}
                {columns.map(({ field, cellClassName, renderCell }, j) => {
                  return (
                    <td key={`${i}-${field}`} className={cellClassName}>
                      {renderCell ? (
                        renderCell(row)
                      ) : typeof row[field] === "string" ? (
                        <>{row[field]}</>
                      ) : (
                        JSON.stringify(row[field])
                      )}
                    </td>
                  )
                })}
                {rowActions && (
                  <td
                    className="action-cell"
                    onClick={(e: MouseEvent) => e.stopPropagation()}
                  >
                    <RowAction row={row} actions={rowActions} />
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
        {!loading && rows.length > 0 && (footerCells || pagination) && (
          <tfoot>
            {!!footerCells && (
              <tr>
                {isSelectable && <td className="checkbox-cell" />}
                {showRowNumber && <td className="number-cell" />}
                {footerCells.map((cell, i) => (
                  <td key={`foot-${i}`}>{cell}</td>
                ))}
                {rowActions && <td className="action-cell" />}
              </tr>
            )}
            {!!pagination && (
              <tr className="pagination-row">
                <td colSpan={100}>{pagination}</td>
              </tr>
            )}
          </tfoot>
        )}
      </table>
      {!loading && !!footerInfo && (
        <div className="footer-info">{footerInfo}</div>
      )}
    </div>
  )
}

export default Table
