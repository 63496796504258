import { useEffect } from "react"

import { isNative } from "../utils"
import {
  OrientationLockType,
  ScreenOrientation,
} from "@capacitor/screen-orientation"

export const useLockOrientation = (
  orientation: OrientationLockType = "portrait",
) => {
  useEffect(() => {
    if (isNative()) {
      ScreenOrientation.lock({ orientation })
    }
  }, [orientation])
}
