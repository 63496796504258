import { api } from "../.."
import {
  paymentsCreditCardsURL,
  paymentsCreditCardURL,
  paymentsInvoiceURL,
  paymentsPrepaidCodeURL,
  paymentsResellerURL,
  paymentsURL,
} from "../../../../api"
import { PaginatedResponse } from "../../../types"
import { CreditCard, Payments, Reseller } from "./types"

export const payments = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPayments: builder.query<Payments, void>({
      query: () => paymentsURL(),
      providesTags: ["Payments"],
    }),

    fetchPaymentsCreditCards: builder.query<
      PaginatedResponse<CreditCard>,
      void
    >({
      query: () => paymentsCreditCardsURL(),
    }),

    savePaymentReseller: builder.mutation<void, Reseller>({
      query: (body) => ({
        url: paymentsResellerURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: ["Payments"],
    }),

    savePaymentCreditCard: builder.mutation<void, void>({
      query: () => ({
        url: paymentsCreditCardURL(),
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Payments"],
    }),

    savePaymentInvoice: builder.mutation<void, void>({
      query: () => ({
        url: paymentsInvoiceURL(),
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Payments"],
    }),

    savePaymentPrepaidCode: builder.mutation<void, void>({
      query: () => ({
        url: paymentsPrepaidCodeURL(),
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Payments"],
    }),
  }),
})

export const {
  useFetchPaymentsQuery,
  useLazyFetchPaymentsCreditCardsQuery,
  useSavePaymentResellerMutation,
  useSavePaymentCreditCardMutation,
  useSavePaymentInvoiceMutation,
  useSavePaymentPrepaidCodeMutation,
} = payments
