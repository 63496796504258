import React, { useMemo } from "react"

import classNames from "classnames"

import ContentGridSection from "./ContentGridSection"
import { useCustomContentContext } from "./CustomContentContext"
import { getClassName, getSectionCount } from "./utils"

import "./ContentGrid.sass"

const ContentGrid = () => {
  const {
    content: { type, sections },
  } = useCustomContentContext()

  const numberOfSections = useMemo(() => getSectionCount(type), [type])

  return (
    <div className={classNames("ContentGrid", getClassName(type))}>
      {sections.slice(0, numberOfSections).map((section, index) => (
        <ContentGridSection key={index} index={index} section={section} />
      ))}
    </div>
  )
}

export default ContentGrid
