import { PropsWithChildren } from "react"

import ServiceWorkerUpdateNotification from "../modals/ServiceWorkerUpdateNotification"
import { isNative } from "../utils"
import { useRegisterSW } from "virtual:pwa-register/react"

const AppUpdateProvider = ({ children }: PropsWithChildren<unknown>) => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW()

  return (
    <>
      {needRefresh && !isNative() && (
        <ServiceWorkerUpdateNotification
          updateServiceWorker={updateServiceWorker}
        />
      )}
      {children}
    </>
  )
}

export default AppUpdateProvider
