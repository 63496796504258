const root = "/settings/buildings"
const overview = "/settings/buildings/overview"
const add = `${root}/add`
const edit = `${root}/edit/:building_id`
const add_floor = `${root}/edit/:building_id/floors/add`
const edit_floor = `${root}/edit/:building_id/floors/:floor_id`

export const BUILDINGS_PATHS = {
  root,
  overview,
  add,
  edit,
  add_floor,
  edit_floor,
} as const
