import { PropsWithChildren } from "react"

import {
  ITooltip,
  Tooltip as ReactTooltip,
  TooltipWrapper,
} from "react-tooltip"

import "./Tooltip.sass"

interface Props extends ITooltip {
  uniqueId: string
}

export const Tooltip = ({
  children,
  uniqueId,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <TooltipWrapper tooltipId={uniqueId}>
      <ReactTooltip {...props} anchorId={uniqueId} />
      <div className="tp-wrapper" id={uniqueId}>
        {children}
      </div>
    </TooltipWrapper>
  )
}
