import React from "react"

import { useTranslation } from "react-i18next"

import { Children } from "../../../../../types/sharedTypes"

import Card from "../../../../../components/basic/Card"

import "./QuantityCard.sass"

type QuantityCardProps = {
  planLabel: Children
  changeButton: Children
  children: Children
  unit: string
}

const QuantityCard = ({
  changeButton,
  children,
  planLabel,
  unit,
}: QuantityCardProps) => {
  const { t } = useTranslation()

  return (
    <Card className="QuantityCard">
      <div className="QuantityCard__body">
        <div className="QuantityCard__body__row">
          <div className="row-label">
            {t(
              "desktop.settings.billing.subscription_quantity.subscription_plan",
            )}
          </div>
          <div className="row-value">
            <div className="plan-label">{planLabel}</div>
            {changeButton}
          </div>
        </div>
        <div className="QuantityCard__body__row">
          <div className="row-label">
            {t(
              "desktop.settings.billing.subscription_quantity.no_of_unit_licenses",
              { unit },
            )}
          </div>
          <div className="row-value">{children}</div>
        </div>
      </div>
    </Card>
  )
}

export default QuantityCard
