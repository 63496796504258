import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { SETTINGS_GROUPS, SettingsGroups } from "../constants"
import { EffectiveSettingsLocation } from "../types"
import SettingsGroup from "./index"

import { Input } from "../../../../../components/basic/Input"
import Field from "../../../../../components/Field"

type Props = {
  checked: boolean
  disabled: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
}

const MaintenanceGroup = ({
  checked,
  disabled,
  onToggleSettingsGroup,
  effectiveSettingsLocation,
  onLocationClick,
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <>
      <hr />

      <SettingsGroup
        label={t("desktop.settings.buildings.building_form.maintenance_email")}
        checked={checked}
        disabled={disabled}
        onChange={(checked) =>
          onToggleSettingsGroup(SETTINGS_GROUPS.MAINTENANCE_GROUP, checked)
        }
        effectiveSettingsLocation={effectiveSettingsLocation}
        onLocationClick={onLocationClick}
        id={SETTINGS_GROUPS.MAINTENANCE_GROUP}
      >
        <div className="FieldWrapper Subtext">
          {t(
            "desktop.settings.rooms.device_settings.form.maintenance_contact.intro",
          )}
        </div>
        <Field
          control={control}
          name="maintenance_email"
          label={t(
            "desktop.settings.rooms.device_settings.form.maintenance_contact.contact_email_label",
          )}
          className="field-width-50"
        >
          {(props) => (
            <Input
              {...props}
              disabled={disabled}
              placeholder={t(
                "desktop.settings.rooms.device_settings.form.maintenance_contact.contact_email_placeholder",
              )}
            />
          )}
        </Field>
      </SettingsGroup>
    </>
  )
}

export default MaintenanceGroup
