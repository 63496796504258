import { isBlank } from "../utils"

import "./BranchName.sass"

/**
 * Will display the branch name in the upper right corner if it exists in the env.
 */
export const BranchName = () => {
  if (isBlank(import.meta.env.VITE_APP_BRANCH_NAME)) return null

  return (
    <span className="BranchName">{import.meta.env.VITE_APP_BRANCH_NAME}</span>
  )
}
