import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { toFriendlyName, toFriendlyTitle } from "../utils"

import { Subscription } from "../../../../redux/api/billing/subscriptions/types"

import "./PrepaidCodeInfo.sass"

type PrepaidCodeInfoProps = {
  subscription: Subscription
  index: number
}
const PrepaidCodeInfo = ({
  subscription: { plan_type, prepaid_code, unit, next_billing_date },
  index,
}: PrepaidCodeInfoProps) => {
  const { t } = useTranslation()

  const planTitle = `${toFriendlyTitle(plan_type)} ${toFriendlyName(plan_type)}`
  const dates = `${dayjs(prepaid_code?.activated_at).format("l")} - ${dayjs(
    next_billing_date,
  ).format("l")}`

  return (
    <div className="PrepaidCodeInfo">
      <div className="PrepaidCodeInfo__index">{index}</div>
      <div className="PrepaidCodeInfo__details">
        <div>{planTitle}</div>
        <p>
          {t("desktop.components.subscription_card.licenses", {
            count: unit[0].quantity,
            unit: unit[0].type,
          })}
        </p>
        <p>
          {t("desktop.settings.billing.pre_paid_code_info.validity")}: {dates}
        </p>
        <p>
          {t("desktop.settings.billing.pre_paid_code_info.coupon_code")}:{" "}
          {prepaid_code?.code}
        </p>
      </div>
    </div>
  )
}

export default PrepaidCodeInfo
