import React from "react"
import { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"

import LogoVariant from "../../screens/LogoVariant"

import {
  BrandingOption,
  CheckInOption,
} from "../../redux/printer_settings/types"

import "./VisitorBadge.sass"

const DEFAULT_FORMATTER = "D MMM, YYYY @ HH.mm"

type Props = {
  name: string
  brandingType: BrandingOption
  companyName?: string
  companyLogo?: string
  checkInType: CheckInOption
  checkInTime?: Dayjs | Date
  message?: string
  signInField1?: string
  signInField2?: string
} & { ref?: ForwardedRef<HTMLDivElement> }

const VisitorBadge = (
  {
    name,
    brandingType,
    companyName,
    companyLogo,
    checkInType,
    checkInTime,
    message,
    signInField1,
    signInField2,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const formattedDateTime = checkInTime
    ? dayjs(checkInTime).format(DEFAULT_FORMATTER)
    : dayjs().format(DEFAULT_FORMATTER)

  const isBrandingLogo = brandingType === BrandingOption.LOGO
  const isBrandingCompanyName = brandingType === BrandingOption.COMPANY_NAME

  const brandingClassName = classNames({
    VisitorBadgeBranding: true,
    BrandingLogo: isBrandingLogo,
    BrandingCompanyName: isBrandingCompanyName,
  })

  return (
    <div ref={ref} className="VisitorBadge" id="preview">
      {/*TODO: Omitted until we can manage taking & displaying visitor photos*/}
      {/*<div className="VisitorBadgeHeader"></div>*/}

      <div className="VisitorBadgeBody">
        <div className="VisitorBadgeContent">
          <div className="VisitorBadgeName">{name}</div>
          {signInField1 && <div className="UserInfo">{signInField1}</div>}
          {signInField2 && <div className="UserInfo">{signInField2}</div>}
          {checkInType === CheckInOption.DATE_AND_TIME && (
            <div className="UserInfo">{formattedDateTime}</div>
          )}
          <div className="UserInfoSubtext">{message}</div>
        </div>
      </div>

      <div className="VisitorBadgeFooter">
        <div className={brandingClassName}>
          {isBrandingLogo &&
            (companyLogo ? (
              <img alt="logo" src={companyLogo} />
            ) : (
              <LogoVariant variant="landscape" />
            ))}
          {isBrandingCompanyName && <h1>{companyName}</h1>}
        </div>
      </div>
    </div>
  )
}

export default forwardRef(VisitorBadge)
