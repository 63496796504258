import React, { PropsWithChildren } from "react"

import { useScrollToTop } from "../../hooks/useScrollToLocation"
import "react-quill/dist/quill.snow.css"

import { DocumentViewType } from "../../redux/documents/types"

import "./DocumentView.sass"

type Props = {
  document: DocumentViewType
}

const DocumentView = ({ document }: PropsWithChildren<Props>) => {
  useScrollToTop()

  return (
    <div className="DocumentView">
      <div className="document-title">{document.title}</div>
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: document.description }}
      ></div>
    </div>
  )
}

export default DocumentView
