import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import SettingsSection from "./SettingsSection"

import { Input } from "../../../../../components/basic/Input"
import Field from "../../../../../components/Field"

const TitleSection = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <SettingsSection
      title={t(
        "desktop.settings.rooms.custom_content.forms.general.slideshow_label",
      )}
      className="TitleSection"
    >
      <Field control={control} name="name">
        {(props) => (
          <Input
            {...props}
            maxLength={60}
            placeholder={t(
              "desktop.settings.rooms.custom_content.forms.general.slideshow_placeholder",
            )}
          />
        )}
      </Field>
    </SettingsSection>
  )
}

export default TitleSection
