import { useEffect } from "react"

import { removePluginListeners } from "../../utils"
import { AppState, App as CapApp } from "@capacitor/app"

export const useRefresh = (callBack: Function): void => {
  useEffect(() => {
    const listener = CapApp.addListener("appStateChange", (state: AppState) => {
      if (state.isActive) {
        callBack()
      }
    })

    return () => {
      removePluginListeners(listener)
    }
  }, [callBack])

  return
}
