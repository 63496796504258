import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { experimentsCompanyURL } from "../../api/urls"
import { AsyncCheckbox } from "../advanced/AsyncCheckbox"
import { RedirectLink } from "../advanced/RedirectLink"
import { UncontrolledField } from "../Field"
import PageForm from "./PageFormHook"

import { fetchExperiments } from "../../redux/experiments/experimentsSlice"
import { selectRoomsSettings } from "../../redux/experiments/selectors"
import { useAppSelector } from "../../redux/reducers"
import { useActions } from "../../redux/utils"

import "./RoomsForm.sass"

type FormValues = {
  enabled: boolean
}

function RoomsForm() {
  const { t } = useTranslation()

  const actions = useActions({
    fetchExperiments: () => fetchExperiments(),
  })

  const { roomsSettings } = useAppSelector(selectRoomsSettings)
  const { data } = roomsSettings || {}

  const methods = useForm<FormValues>()

  return (
    <FormProvider {...methods}>
      <PageForm className="RoomsForm" updateMode={true}>
        <UncontrolledField>
          <AsyncCheckbox
            updateMode={!!roomsSettings?.id}
            value={data?.values.enabled}
            label={t("desktop.settings.rooms.form.in_app_room_booking")}
            urlGenerator={() => experimentsCompanyURL(roomsSettings?.id)}
            bodyGenerator={(enabled: boolean) => ({
              data: {
                type: "ROOMS_SETTINGS",
                values: {
                  enabled,
                },
              },
            })}
            refresh={actions.fetchExperiments}
            isSecondary
            successToastMessage={t(
              "desktop.settings.rooms.form.room_booking_settings_updated_toast",
            )}
            description={
              <Trans
                i18nKey="desktop.settings.rooms.form.in_app_room_booking_description"
                components={{
                  a1: (
                    <RedirectLink to="book-a-meeting-room-in-the-mobile-app">
                      this article
                    </RedirectLink>
                  ),
                  a2: (
                    <RedirectLink to="android-app-link">Android</RedirectLink>
                  ),
                  a3: <RedirectLink to="ios-app-link">iOS</RedirectLink>,
                }}
              />
            }
          />
        </UncontrolledField>
      </PageForm>
    </FormProvider>
  )
}

export default RoomsForm
