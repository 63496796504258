import React, { ForwardedRef, forwardRef } from "react"

import { Field } from "./Field"
import { Label } from "./Label"

type Props = {
  value?: string | number | readonly string[]
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  accept?: string
  failedAttempts?: React.Key | null
  [x: string]: any
}

export const FileField = forwardRef(
  (
    { value, onChange, accept, failedAttempts, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Field className="FileField" {...rest}>
        <Label>File</Label>
        <input
          type="file"
          value={value}
          accept={accept}
          onChange={onChange}
          ref={ref}
          key={`input-attempt-${failedAttempts}`}
        />
      </Field>
    )
  },
)
