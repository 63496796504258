import dayjs, { Dayjs } from "dayjs"
import { ThunkApiConfig } from "RootType"

import {
  configScreeningEmployeeURL,
  configScreeningsURL,
  configScreeningURL,
  configScreeningVisitorURL,
  employeeScreeningURLOLD,
  exportScreeningURL,
  get,
  postJSON,
  putJSON,
  visitorScreeningURL,
} from "../../api"
import { timeZone } from "../../dayjs"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  EmployeeScreeningPayload,
  ScreeningsConfig,
  ScreeningsConfigData,
  VisitorScreeningPayload,
} from "./typesOld"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

const formatDate = (date: Dayjs | string) => {
  return dayjs(date).format("YYYY-MM-DD")
}

/**
 *  Thunks
 */
export const fetchEmployeeScreeningsConfigData = createAsyncThunk<
  ScreeningsConfigData,
  void,
  ThunkApiConfig
>("screeningsOld/fetchEmployeeConfigData", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(configScreeningEmployeeURL(), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchVisitorScreeningsConfigData = createAsyncThunk<
  ScreeningsConfigData,
  string,
  ThunkApiConfig
>("screeningsOld/fetchVisitorConfigData", async (company_id, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(
    configScreeningVisitorURL(company_id),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

type CreateEmployeeScreeningProps = {
  userId: string
  survey: JSON
}

export const createEmployeeScreening = createAsyncThunk<
  EmployeeScreeningPayload,
  CreateEmployeeScreeningProps,
  ThunkApiConfig
>("screeningsOld/createEmployee", async ({ userId, survey }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    employeeScreeningURLOLD(),
    {
      body: {
        employee: { id: userId },
        survey,
      },
    },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

type CreateVisitorScreening = {
  companyId: string
  visitorId: string
  survey: JSON
}

export const createVisitorScreening = createAsyncThunk<
  VisitorScreeningPayload,
  CreateVisitorScreening,
  ThunkApiConfig
>(
  "screeningsOld/createVisitor",
  async ({ companyId, visitorId, survey }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await postJSON(
      visitorScreeningURL(companyId),
      {
        body: {
          visitor: { id: visitorId },
          survey: survey,
        },
      },
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    throw new Error(await getErrorMessage(response))
  },
)

// Config screenings

export const fetchScreeningsConfig = createAsyncThunk<
  ScreeningsConfig,
  void,
  ThunkApiConfig
>("screeningsOld/fetchScreeningsConfig", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(configScreeningsURL(), {}, access_token)

  if (response.ok) {
    const json = await response.json()
    const config: ScreeningsConfig = json.results[0] || {
      enabled: false,
      auto_cancel_desk_reservation: false,
      send_survey_to: "visitors&employees",
      questions: [],
      notify_emails: [],
      instructions: "",
      success_msg: "",
      failure_msg: "",
    }

    return config
  }

  throw new Error(await getErrorMessage(response))
})

export const createScreeningsConfig = createAsyncThunk<
  ScreeningsConfig,
  ScreeningsConfig,
  ThunkApiConfig
>("screeningsOld/createScreeningsConfig", async (payload, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    configScreeningsURL(),
    { body: payload },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

type ScreeningsConfigWithId = {
  id: string
  config: ScreeningsConfig
}

export const updateScreeningsConfig = createAsyncThunk<
  ScreeningsConfig,
  ScreeningsConfigWithId,
  ThunkApiConfig
>(
  "screeningsOld/updateScreeningsConfig",
  async ({ id, config }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await putJSON(
      configScreeningURL(id),
      { body: config },
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    throw new Error(await getErrorMessage(response))
  },
)

type FetchScreeningsCSVProps = {
  start: string
  end: string
  exportFor: "visitors" | "employees" | "visitors&employees"
}

export const fetchScreeningsCSV = createAsyncThunk<
  string,
  FetchScreeningsCSVProps,
  ThunkApiConfig
>(
  "screeningsOld/fetchScreeningsCSV",
  async ({ start, end, exportFor }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await get(
      exportScreeningURL({
        from: formatDate(start),
        to: formatDate(end),
        export_for: exportFor,
        tz: timeZone,
      }),
      {},
      access_token,
    )

    if (response.ok) {
      return await response.text()
    }

    throw new Error(await getErrorMessage(response))
  },
)

/**
 *  Slice
 */
export type ScreeningsState = SliceState & {
  entry: ScreeningsConfig | null
  data: ScreeningsConfigData | null
}

const initialState: ScreeningsState = {
  entry: null,
  data: null,
  ...sliceInitialState,
}

const screeningsSliceOld = createSlice({
  name: "screenings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchEmployeeScreeningsConfigData.fulfilled,
      (state, { payload }) => {
        setFetchSuccessState(state)
        state.data = payload
      },
    )

    builder.addCase(
      fetchVisitorScreeningsConfigData.fulfilled,
      (state, { payload }) => {
        setFetchSuccessState(state)
        state.data = payload
      },
    )

    builder.addCase(fetchScreeningsConfig.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })

    builder.addCase(updateScreeningsConfig.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })

    builder.addCase(createScreeningsConfig.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })

    builder.addMatcher(
      isAnyOf(
        fetchEmployeeScreeningsConfigData.pending,
        fetchVisitorScreeningsConfigData.pending,
        fetchScreeningsConfig.pending,
      ),
      (state) => {
        state.isLoading = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        fetchEmployeeScreeningsConfigData.rejected,
        fetchVisitorScreeningsConfigData.rejected,
        fetchScreeningsConfig.rejected,
      ),
      (state, action) => {
        setFetchErrorState(state, action)
      },
    )

    builder.addMatcher(
      isAnyOf(
        createEmployeeScreening.pending,
        createVisitorScreening.pending,
        createScreeningsConfig.pending,
        updateScreeningsConfig.pending,
      ),
      (state) => {
        state.isSubmitting = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        createEmployeeScreening.rejected,
        createVisitorScreening.rejected,
        createScreeningsConfig.rejected,
        updateScreeningsConfig.rejected,
      ),
      (state, action) => {
        setSubmitErrorState(state, action)
      },
    )
  },
})

export const screeningsReducerOld = screeningsSliceOld.reducer
