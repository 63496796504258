import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchUserQuery } from "../../../redux/api/users"
import { UserResponse } from "../../../redux/api/users/types"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import UserDirectoryForm from "../../../components/Form/UserDirectoryForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./EditUserDirectory.sass"

type ParamsType = {
  user_email: string
}

const DEFAULT_USER = {
  first_name: "",
  last_name: "",
  type: "portal_user",
  default_building: "",
  phone_number: "",
  departments: [],
} as Partial<UserResponse>

const EditUserDirectory = () => {
  const { t } = useTranslation()

  const { user_email } = useParams<ParamsType>()

  const {
    data: user = DEFAULT_USER,
    isLoading,
    error,
  } = useFetchUserQuery(user_email ?? skipToken)

  const userNotFound =
    (!!user_email && !user) ||
    (error && "status" in error && error.status === 404)

  return (
    <View className="EditUserDirectory">
      <>
        <Breadcrumbs
          depth={2}
          values={[
            t("desktop.settings.people.title"),
            user?.email
              ? t("desktop.settings.people.edit_breadcrumb")
              : t("desktop.settings.people.add_breadcrumb"),
          ]}
        />
        <Intro isConstrained>
          {user?.email
            ? t("desktop.settings.people.edit_title")
            : t("desktop.settings.people.add_title")}
        </Intro>

        <Space size={0.75} />
      </>

      {isLoading && <Loader className="loader" />}
      {!isLoading && userNotFound && (
        <p>{t("desktop.settings.people.not_found")}</p>
      )}
      {!isLoading && !userNotFound && <UserDirectoryForm person={user} />}
    </View>
  )
}

export default EditUserDirectory
