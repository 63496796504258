import { useState } from "react"

import { useTranslation } from "react-i18next"

import { useIsOnDeskSpotCheckIn } from "../hooks/useIsOnSpotDeskCheckIn"
import { toInternalTime } from "../utils"
import Button from "./advanced/Button"
import { IconMapper } from "./CorrectIconMapper"

import { selectCheckIns } from "../redux/check_ins/selectors"
import { CHECK_INS_TYPES, CheckInResponse } from "../redux/check_ins/types"
import { useAppSelector } from "../redux/reducers"

import "./CheckIns.sass"

type Props = {
  handleCheckIn: (checkIn: CheckInResponse) => void
}

export const CheckIns = ({ handleCheckIn }: Props) => {
  const { entries: checkIns } = useAppSelector(selectCheckIns)
  const isOnSpotCheckIn = useIsOnDeskSpotCheckIn()

  const filteredCheckIns = isOnSpotCheckIn
    ? checkIns.filter((checkIn) => checkIn.type !== CHECK_INS_TYPES.DESK)
    : checkIns

  return (
    <div className="CheckIns">
      {filteredCheckIns.map((checkIn) => {
        return (
          <CheckIn
            checkIn={checkIn}
            handleCheckIn={handleCheckIn}
            key={checkIn.id}
          />
        )
      })}
    </div>
  )
}

type CheckInProps = {
  checkIn: CheckInResponse
  handleCheckIn: (checkIn: CheckInResponse) => void
}

const CheckIn = ({ checkIn, handleCheckIn }: CheckInProps) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const onClick = async () => {
    setLoading(true)
    await handleCheckIn(checkIn)
    setLoading(false)
  }

  return (
    <div className="CheckIn" key={checkIn.id}>
      <div className="Name">
        {checkIn.name}{" "}
        <div className="ResourceIcon">
          <IconMapper iconType={checkIn.type} />
        </div>
      </div>
      <div className="Description">
        {toInternalTime(checkIn.start)} - {toInternalTime(checkIn.end)}
        {checkIn.building && <span> &middot; {checkIn.building.name}</span>}
        {checkIn.floor && <span> &middot; {checkIn.floor.name}</span>}
      </div>
      <div className="Confirm">
        <Button isLoading={loading} onClick={onClick}>
          {t("mobile.general.check_in")}
        </Button>
      </div>
    </div>
  )
}
