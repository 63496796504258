import React from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { USER_DATE_FORMAT } from "../../../../constants"
import { createLocationString } from "../../../../hooks/useNavigation"
import { ISODate } from "../../../../types/sharedTypes"
import { getPlanGroupPath } from "../constants"
import { toFriendlyName, toFriendlyTitle } from "../utils"

import {
  NOTIFICATION_SCHEDULED_TYPE,
  SUBSCRIPTION_STATUSES,
} from "../../../../redux/api/billing/constants"
import {
  NotificationPrepaidExpired,
  NotificationScheduleChange,
  Subscription,
} from "../../../../redux/api/billing/subscriptions/types"

import WarningCard from "../../../../components/basic/WarningCard"

import "./SubscriptionNotifications.sass"

const getHumanizedDuration = (until: ISODate) => {
  return dayjs.duration(dayjs(until).diff(dayjs())).humanize(true)
}

type NotificationPrepaidExpiredSub = NotificationPrepaidExpired & {
  subscription: Subscription
}
type NotificationScheduleChangeSub = NotificationScheduleChange & {
  subscription: Subscription
}

type NotificationWithSubscription =
  | NotificationPrepaidExpiredSub
  | NotificationScheduleChangeSub

type SubscriptionNotificationsProps = {
  notifications: NotificationWithSubscription[]
  unit: string
}

const SubscriptionNotifications = ({
  notifications,
  unit,
}: SubscriptionNotificationsProps) => {
  const { t } = useTranslation()
  const unitTrans = t(
    `desktop.components.subscription_card.${unit}` as ParseKeys,
    {
      count: 1,
    },
  )

  const getNotificationPrepaidExpired = ({
    code,
    quantity,
    expires_at,
    reseller,
    subscription,
  }: NotificationPrepaidExpiredSub) => {
    const expiresAt = dayjs(expires_at).format(USER_DATE_FORMAT)
    const quantityUsed = subscription.unit[0].quantity_used

    return (
      <div key={`${subscription.id}-${code}`}>
        <div>
          <Trans
            i18nKey="desktop.settings.billing.plans.notifications.prepaid_code_expires"
            values={{
              code: code,
              quantity: quantity,
              duration: getHumanizedDuration(expires_at),
              expiresAt,
              unit: unitTrans,
            }}
          />
        </div>
        {reseller && (
          <div>
            <Trans
              i18nKey="desktop.settings.billing.plans.notifications.reach_out_reseller"
              values={{
                email: reseller.email,
                name: reseller.name,
              }}
            />
            <a href={`mailto:${reseller.email}`}>{reseller.email}</a>
          </div>
        )}
        {quantityUsed > 0 && (
          <div className="notification-warning">
            <Trans
              i18nKey="desktop.settings.billing.plans.notifications.expired_licenses"
              values={{
                count: quantityUsed,
                unit: unitTrans,
                expiresAt,
              }}
            />
          </div>
        )}
      </div>
    )
  }

  const getScheduleChange = ({
    next_billing_at,
    type,
    subscription,
  }: NotificationScheduleChangeSub) => {
    const expiresAt = dayjs(next_billing_at).format(USER_DATE_FORMAT)
    const quantityUsed = subscription.unit[0].quantity_used

    return (
      <div key={`${subscription.id}-${type}`}>
        <div>
          <Trans
            i18nKey={
              "desktop.settings.billing.plans.notifications.scheduled_subscription_change"
            }
            values={{
              planTitle: toFriendlyTitle(subscription.plan_type),
              expiresAt: expiresAt,
            }}
          />
        </div>
        <div>
          <Trans
            i18nKey={
              "desktop.settings.billing.plans.notifications.features_working_until"
            }
          />
        </div>
        {quantityUsed > 0 && (
          <div className="notification-warning">
            <Trans
              i18nKey="desktop.settings.billing.plans.notifications.expired_licenses"
              values={{
                count: quantityUsed,
                unit: unitTrans,
                expiresAt: expiresAt,
              }}
            />
          </div>
        )}
      </div>
    )
  }

  const getScheduleCancelation = ({
    next_billing_at,
    type,
    subscription,
  }: NotificationScheduleChangeSub) => {
    const isTrial = subscription.status === SUBSCRIPTION_STATUSES.IN_TRIAL

    const expiresAt = dayjs(next_billing_at).format(USER_DATE_FORMAT)
    const modifyUrl = createLocationString(
      getPlanGroupPath(subscription.planGroup).root,
      {
        id: subscription.id,
        planType: subscription.plan_type,
        period: subscription.plan_variation.period_unit,
        currency: subscription.plan_variation.currency,
      },
    )
    const quantityUsed = subscription.unit[0].quantity_used

    return (
      <div key={`${subscription.id}-${type}`}>
        <div>
          <Trans
            i18nKey={
              isTrial
                ? "desktop.settings.billing.plans.notifications.scheduled_cancelation_trial"
                : "desktop.settings.billing.plans.notifications.scheduled_cancelation"
            }
            values={{
              planName: `${toFriendlyTitle(
                subscription.plan_type,
              )} ${toFriendlyName(subscription.plan_type)}`,
              duration: getHumanizedDuration(next_billing_at),
              expiresAt: expiresAt,
              unit: unitTrans,
              count: subscription.unit[0].quantity,
            }}
          />
        </div>
        <div>
          <Trans
            i18nKey={"desktop.settings.billing.plans.notifications.modify_plan"}
          >
            <Link to={modifyUrl}>link</Link>
          </Trans>
        </div>
        {quantityUsed > 0 && (
          <div className="notification-warning">
            <Trans
              i18nKey="desktop.settings.billing.plans.notifications.expired_licenses"
              values={{
                count: quantityUsed,
                unit: unitTrans,
                expiresAt: expiresAt,
              }}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <WarningCard className="SubscriptionNotifications">
      {notifications.map((notification) => {
        switch (notification.type) {
          case NOTIFICATION_SCHEDULED_TYPE.PREPAID_EXPIRES:
            return getNotificationPrepaidExpired(notification)
          case NOTIFICATION_SCHEDULED_TYPE.SCHEDULED_CANCELATION:
            return getScheduleCancelation(notification)
          case NOTIFICATION_SCHEDULED_TYPE.SCHEDULED_PLAN_CHANGE:
            return getScheduleChange(notification)
          default:
            return null
        }
      })}
    </WarningCard>
  )
}

export default SubscriptionNotifications
