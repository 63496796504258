/**
 * Parses a given string to extract a numerical duration and a single alphabetical unit character at the end.
 * @param {string} input - The input string to be parsed.
 * @returns {(Array<string>)} An array containing the matched results or an empty array if no match is found.
 *                  The array structure is as follows:
 *                  - match[0] {string}: The entire matched string.
 *                  - match[1] {string}: The numerical duration part of the match.
 *                  - match[2] {string}: The alphabetical unit character at the end of the string.
 */
export const parseInterval = (input: string): Array<string> => {
  const pattern = /(\d+)[^0-9]*([A-Za-z])$/

  const match = input.match(pattern)

  return match ?? []
}
