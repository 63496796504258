import React from "react"

import ManageFloorPlan from "./"
import FloorPlanProvider from "./FloorPlanProvider"

type Props = {
  add?: boolean
}

const FloorPlanWrapper = ({ add }: Props) => {
  return (
    <FloorPlanProvider>
      <ManageFloorPlan add={add} />
    </FloorPlanProvider>
  )
}

export default FloorPlanWrapper
