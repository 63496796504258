import { useTranslation } from "react-i18next"
import { Redirect, useHistory } from "react-router-dom"

import { useNavigation } from "../../../hooks/useNavigation"
import DeleteCompanySurveyModal from "./DeleteCompanySurveyModal"
import { useModals } from "@mattjennings/react-modal-stack"

import Button from "../../../components/advanced/Button"
import Heading from "../../../components/Heading"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./DeleteCompanySurvey.sass"

export type SurveyData = {
  email: string
  subscriptions: Record<string, { name: string; id: string }>
}

type LocationState = {
  surveyData: SurveyData
}

const DeleteCompanySurvey = () => {
  const history = useHistory()
  const { push } = useNavigation()
  const { t } = useTranslation()
  const { openModal, closeAllModals } = useModals()

  const locationState = history.location.state as LocationState

  if (!locationState?.surveyData) {
    return <Redirect to="/" />
  }

  const { surveyData } = locationState

  return (
    <View className="DeleteCompanySurvey">
      <Space size={4} />
      <Heading>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.heading",
        )}
      </Heading>

      <p>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.deletion",
        )}
      </p>
      <p>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.improve",
        )}
      </p>

      <Space size={0.75} />

      <Button
        onClick={() => {
          openModal(DeleteCompanySurveyModal, {
            surveyData,
            title: t(
              "desktop.settings.organization.company_details.delete_company.survey.take_survey",
            ),
            onCancel: () => {
              closeAllModals()
            },
          })
        }}
      >
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.take_survey",
        )}
      </Button>

      <Space size={1.5} />

      <p>
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.change_mind",
        )}
      </p>

      <Space size={0.75} />

      <Button
        variant="link"
        onClick={() => {
          push("/")
        }}
      >
        {t(
          "desktop.settings.organization.company_details.delete_company.survey.start_over",
        )}
      </Button>
    </View>
  )
}

export default DeleteCompanySurvey
