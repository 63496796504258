import { registerLocale, setDefaultLocale } from "react-datepicker"

import { de, enGB, enUS, es, fr, nl, pl, sl } from "date-fns/locale"

type LocaleMap = {
  [key: string]: Locale
}

/**
 * A map of supported locales and their corresponding locale data.
 */
const locales: LocaleMap = {
  en: enUS,
  "en-GB": enGB,
  "en-US": enUS,
  de,
  es,
  fr,
  nl,
  pl,
  sl,
}

/**
 * Imports and registers locales dynamically.
 * @param locale - The locale to load.
 * @returns A promise that resolves when the locale data has been loaded.
 */
export const loadLocaleData = async (locale: string) => {
  try {
    const localeData = locales[locale]

    // Register the locale data
    registerLocale(locale, localeData)
  } catch (error) {
    console.error(`Error loading locale data for ${locale}:`, error)
  }

  // Set the default locale
  setDefaultLocale("en-US")
}
