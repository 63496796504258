import React, { PropsWithChildren } from "react"

import classNames from "classnames"
import { FieldValues, useFormContext } from "react-hook-form"

import "./VisitorForm.sass"

type Props<T extends FieldValues> = {
  className?: string
  onSubmit: (Values: T) => void
}

const VisitorForm = <T extends FieldValues>({
  className,
  onSubmit,
  children,
}: PropsWithChildren<Props<T>>) => {
  const { handleSubmit } = useFormContext()

  return (
    <form
      className={classNames(["VisitorForm", className])}
      onSubmit={handleSubmit && handleSubmit(onSubmit as any)}
    >
      {children}
    </form>
  )
}

export default VisitorForm
