import { PropsWithChildren } from "react"

import { Children } from "../types/sharedTypes"
import Avatar from "./advanced/Avatar"
import { Tooltip } from "./basic/Tooltip"

import { UserData } from "../redux/user/types"
import { formatUser } from "../redux/user/utils"

import "./UserTooltip.sass"

type PlaceTooltipProps = {
  user: UserData
  children: Children
  uniqueId: string
}

const UserTooltip = ({
  user,
  children,
  uniqueId,
}: PropsWithChildren<PlaceTooltipProps>) => {
  return (
    <div className="UserTooltip">
      <Tooltip
        content={
          <div className="UserContentTooltip">
            <Avatar user={user} size="medium" hasDefaultAvatar />
            <div className="fullName">{formatUser(user)}</div>
          </div>
        }
        uniqueId={uniqueId}
        place="bottom"
      >
        {children}
      </Tooltip>
    </div>
  )
}

export default UserTooltip
