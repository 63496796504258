import React from "react"

import { useTranslation } from "react-i18next"

import Loader from "../basic/Loader"
import SafeViewArea from "./SafeViewArea"
import { TopNav } from "./TopNav"

import { useFetchDepartmentsQuery } from "../../redux/api/departments"
import { DepartmentResponse } from "../../redux/api/departments/types"

import PeopleSVG from "../../assets/images/icons/People.svg"

import "./DepartmentPicker.sass"

type DepartmentPickerProps = {
  asPage?: boolean
  showAll?: boolean
  onPick: (department: DepartmentResponse | null) => void
}

export const DepartmentPicker: React.FC<DepartmentPickerProps> = ({
  asPage = false,
  showAll = false,
  onPick,
}) => {
  const { t } = useTranslation()

  const {
    data: { results: departments = [] } = {},
    isLoading: areDepartmentsLoading,
  } = useFetchDepartmentsQuery()

  return (
    <SafeViewArea className="DepartmentPicker">
      <TopNav
        backArrow={asPage}
        title={!asPage ? t("mobile.general.choose_department") : ""}
        onClose={() => {
          onPick(null)
        }}
      />
      <div className="picker-content">
        {asPage && <h2>{t("mobile.general.choose_department")}</h2>}
        {!areDepartmentsLoading && (
          <div>
            {showAll && (
              <div
                className="department-item"
                onClick={() => {
                  onPick(null)
                }}
              >
                <div className="department-info">
                  <div className="name">
                    {t("mobile.general.all_departments")}
                  </div>
                </div>
              </div>
            )}
            {departments.map((department: DepartmentResponse) => {
              return (
                <div
                  className="department-item"
                  key={department.id}
                  onClick={() => {
                    onPick(department)
                  }}
                >
                  <div className="department-info">
                    <div className="name">{department.name}</div>
                  </div>

                  <span className="users-count">
                    {department.users?.length} <PeopleSVG />
                  </span>
                </div>
              )
            })}
          </div>
        )}

        {areDepartmentsLoading && <Loader />}
      </div>
    </SafeViewArea>
  )
}
