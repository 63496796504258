import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { FloorResponse } from "../redux/floors/types"

import FloorForm from "../components/Form/FloorForm"

type Props = {
  open: boolean
  buildingId: string
  floor: FloorResponse | null
}

export default function FloorModal({ open, buildingId, floor }: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <FloorForm buildingId={buildingId} floor={floor} />
    </ReactModal>
  )
}
