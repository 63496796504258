import dayjs from "dayjs"

import { InternalTime, ScheduleReservation } from "../types/sharedTypes"
import { toInternalTime } from "../utils"

import { useFetchTimeslotsQuery } from "../redux/api/timeslots"
import { ReservationResponse } from "../redux/reservations/types"
import { timeslotComparator } from "../redux/timeslots/utils"

const DEFAULT_FROM: InternalTime = "09:00"
const DEFAULT_TO: InternalTime = "17:00"
const DEFAULT_ROOM_BOOKING_TIME = 60

/**
 * Type guard to check if its an asset or a normal reservation.
 */
export function isNormalReservation(
  tbd: ReservationResponse | ScheduleReservation,
): tbd is ReservationResponse {
  if ((tbd as ReservationResponse).timeslot) {
    return true
  }
  return false
}

/**
 * This hook takes care of selecting the appropriate from
 * and to time according to different parameters gathered
 * from the state.
 */
export const useFromToCalculator = (
  reservation?: ReservationResponse | ScheduleReservation,
  isRoomBooking: boolean = false,
) => {
  const { data: timeslots = [] } = useFetchTimeslotsQuery()

  /**
   * for room booking, the default time period is from now to 1 hour from now
   */

  if (!reservation && isRoomBooking) {
    const currentDate = dayjs()
    return {
      defaultFrom: toInternalTime(currentDate),
      defaultTo: toInternalTime(
        currentDate.add(DEFAULT_ROOM_BOOKING_TIME, "minute"),
      ),
    }
  }

  /**
   * If a reservation was provided, we already know the from and to.
   * Find out which reservation type we have and fill in the return.
   */
  if (reservation) {
    if (isNormalReservation(reservation)) {
      const { from, to } = reservation

      return {
        defaultFrom: from || DEFAULT_FROM,
        defaultTo: to || DEFAULT_TO,
      }
    } else {
      /**
       * Newer endpoints give us start, end and timezone,
       * we need to do the conversion ourselves.
       */
      const { start, end, tz } = reservation

      /**
       * NEW_ENDPOINTS
       * Use the bellow instead, once we convert from old to new endpoints
       *
       * const localStart = dayjs(start)
       * const localEnd = dayjs(end)
       * */
      const localStart = tz ? dayjs(start).tz(tz) : dayjs(start)
      const localEnd = tz ? dayjs(end).tz(tz) : dayjs(end)
      /***/

      if (start && end) {
        return {
          defaultFrom: toInternalTime(localStart),
          defaultTo: toInternalTime(localEnd),
        }
      }
    }
  }

  /**
   * Otherwise choose the default timeslot, and if none exist,
   * the first available timeslot.
   */
  if (timeslots && timeslots.length > 0) {
    // Searching for default timeslot
    const defaultTimeslot = timeslots.find((timeslot) => timeslot.is_default)

    if (defaultTimeslot) {
      return {
        defaultFrom: defaultTimeslot.from,
        defaultTo: defaultTimeslot.to,
        timeslot: defaultTimeslot,
      }
    }

    // Default timeslot wasn't found, will select the first available one.
    const firstTimeslot = [...timeslots].sort(timeslotComparator)[0]

    return {
      defaultFrom: firstTimeslot.from,
      defaultTo: firstTimeslot.to,
      timeslot: firstTimeslot,
    }
  }

  /**
   * Found nothing - return a sane default.
   */
  return { defaultFrom: DEFAULT_FROM, defaultTo: DEFAULT_TO }
}
