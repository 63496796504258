import classNames from "classnames"
import ReactModal from "react-modal"

import { TYPEFORM_DELETION_FORM_ID } from "../../../constants"
import { contentStyle, overlayStyle } from "../../../modals/modalStyles"
import { SurveyData } from "./DeleteCompanySurvey"
import { Widget } from "@typeform/embed-react"

import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./DeleteCompanySurveyModal.sass"

type DeletionFormProps = {
  surveyData: SurveyData
}

export const DeletionForm = ({ surveyData }: DeletionFormProps) => {
  return (
    <Widget
      className="deletion-form"
      id={TYPEFORM_DELETION_FORM_ID}
      style={{
        height: "32rem",
      }}
      hidden={{
        email: surveyData.email,
        solutions: Object.keys(surveyData.subscriptions).join(","),
        subscription_id: Object.values(surveyData.subscriptions)
          .map((sub) => sub.id)
          .join(","),
        plan_name: Object.values(surveyData.subscriptions)
          .map((sub) => sub.name)
          .join(","),
      }}
    />
  )
}

type Props = {
  open: boolean
  onCancel: () => void
  className?: string
  surveyData: SurveyData
  title: string
}

const DeleteCompanySurveyModal = ({
  open,
  onCancel,
  className,
  surveyData,
  title,
}: Props) => {
  const cn = classNames("DeleteCompanySurveyModal ModalForm", className)
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => onCancel()}
      ariaHideApp={false}
      style={{
        content: contentStyle,
        overlay: overlayStyle,
      }}
    >
      <div className={cn}>
        <div className="top-bar">
          <h1>{title}</h1>
          <button className="close" onClick={() => onCancel()}>
            <CrossSVG />
          </button>
        </div>

        <div className="content">
          <DeletionForm surveyData={surveyData} />
        </div>
      </div>
    </ReactModal>
  )
}
export default DeleteCompanySurveyModal
