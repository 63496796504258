import { useRef } from "react"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

export type Navigation = {
  searchParams: Record<string, unknown> | null
  next: string | null
}
const { stringify, parse } = queryString

/**
 * Creates a location string with optional search parameters.
 *
 * @param location - The base location string.
 * @param searchParams - Optional search parameters to be appended to the location string.
 * @returns The location string with optional search parameters.
 *
 * @example
 * // Without search parameters
 * const locationString = createLocationString("/home");
 * // Output: "/home"
 *
 * // With search parameters
 * const searchParams = { id: 123, category: "books" };
 * const locationString = createLocationString("/products", searchParams);
 * // Output: "/products?id=123&category=books"
 */
export const createLocationString = (
  location: string,
  searchParams?: Record<string, unknown>,
) =>
  searchParams
    ? `${location}${location.includes("?") ? "&" : "?"}${stringify(
        searchParams,
        { skipNull: true },
      )}`
    : location

/**
 * Custom hook for managing navigation in the application.
 * It provides methods for setting and getting search parameters,
 * pushing and replacing history, and parsing query parameters from the URL.
 * It should be used as a replacement for the useHistory hook.
 *
 * @returns An object containing navigation methods and properties.
 */
export const useNavigation = () => {
  const history = useHistory()
  const navigation = useRef<Navigation>({
    searchParams: null,
    next: null,
  })

  // Parse the query parameters from the URL.
  if (
    history.location.search &&
    !navigation.current.searchParams &&
    !navigation.current.next
  ) {
    const { next, ...params } = parse(history.location.search)
    if (!!next && typeof next === "string") {
      navigation.current.next = next
    }
    navigation.current.searchParams = params
  }

  /**
   * Gets the URL of the next form in the navigation stack.
   *
   * @returns The URL of the next form in the navigation stack.
   */
  const getNextFromCurrentUrl = () => {
    return `${history.location.pathname}${
      history.location.search ? `${history.location.search}` : ""
    }`
  }

  /**
   * Navigates to a new location and pushes it onto the history stack with the next form URL.
   *
   * @param location - The path of the new location.
   * @param searchParams - Optional search parameters to append to the URL.
   * @param state - Optional state object to associate with the new location.
   */
  const pushWithNext = (
    location: string,
    searchParams?: Record<string, unknown>,
    state?: unknown,
  ) => {
    push(location, { ...searchParams, next: navigation.current.next }, state)
  }

  /**
   * Navigates to the next form in the navigation stack or to a specified location.
   *
   * @param location - The path of the new location.
   * @param searchParams - Optional search parameters to append to the URL.
   * @param state - Optional state object to associate with the new location.
   */
  const pushToNextOr = (
    location: string,
    searchParams?: Record<string, unknown>,
    state?: unknown,
  ) => {
    navigation.current.next
      ? history.push(navigation.current.next)
      : push(location, searchParams, state)
  }

  /**
   * Navigates to a new location and pushes it onto the history stack.
   * This function has breaking changes, to the original push function.
   * The state is moved to the 3rd argument.
   * It can be used as replacement for the history push function in the web app without any changes in the code.
   * For use in the mobile app the state should be moved to the 3rd argument.
   *
   * @param location - The path of the new location.
   * @param searchParams - Optional search parameters to append to the URL.
   * @param state - Optional state object to associate with the new location.
   */
  const push = (
    location: string,
    searchParams?: Record<string, unknown>,
    state?: unknown,
  ) => {
    history.push(createLocationString(location, searchParams), state)
  }

  /**
   * Replaces the current location with the specified location and optional state.
   * This function has breaking changes, to the original push function.
   * The state is moved to the 3rd argument.
   * It can be used as replacement for the history push function in the web app without any changes in the code.
   * For use in the mobile app the state should be moved to the 3rd argument.
   *
   * @param location - The location to replace the current location with.
   * @param state - Optional state to include in the history.
   */
  const replace = (
    location: string,
    searchParams?: Record<string, unknown>,
    state?: unknown,
  ) => {
    history.replace(createLocationString(location, searchParams), state)
  }

  return {
    ...history,
    push,
    replace,
    createLocationString,
    getNextFromCurrentUrl,
    pushWithNext,
    pushToNextOr,
    searchParams: navigation.current.searchParams,
  }
}
