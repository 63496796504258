import React from "react"

import { Trans, useTranslation } from "react-i18next"

import { getLabel } from "../../../../../utils"
import EditDevicesForm from "./EditDevicesForm"

import Breadcrumbs from "../../../../../components/Breadcrumbs"
import Intro from "../../../../../components/Intro"
import Space from "../../../../../components/Space"
import View from "../../../../../components/View"

const Devices = () => {
  const { t } = useTranslation()

  const email = getLabel("links.supportEmail")

  return (
    <View className="Devices">
      <Breadcrumbs
        depth={3}
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.custom_content.title"),
          t("desktop.settings.rooms.custom_content.forms.devices.title"),
        ]}
      />

      <Intro isConstrained>
        <Trans
          i18nKey="desktop.settings.rooms.custom_content.forms.devices.intro"
          components={[
            <a key="email-link" href={`mailto:${email}`}>
              {email}
            </a>,
          ]}
          values={{ email }}
        />
      </Intro>

      <Space size={0.75} />

      <EditDevicesForm />
    </View>
  )
}

export default Devices
