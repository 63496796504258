import React, { useContext } from "react"

import { useTranslation } from "react-i18next"

import { FlowContext } from "../../providers/Tablet/RegistrationFlowProvider"
import { isNative } from "../../utils"

import { useAppSelector } from "../../redux/reducers"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"

import Button from "../../components/advanced/Button"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

const HealthInfo = () => {
  const { t } = useTranslation()

  const { nextStep, previousStep } = useContext(FlowContext)

  const { data, isLoading } = useAppSelector(selectVisitorRegistration)

  const handleContinue = () => nextStep()

  const handleGoBack = () => previousStep()

  return (
    <VisitorView>
      <VisitorHeader showNavigation={!isNative()} />
      <VisitorContent>
        <div className="subtitle">
          {data?.health_screening?.introduction_headline ??
            t("tablet.visitors.screens.health.fill_out")}
        </div>
        <div className="info">
          {data?.health_screening?.introduction ??
            t("tablet.visitors.screens.health.help_us")}
        </div>
      </VisitorContent>
      <VisitorFooter>
        <div>
          <Button onClick={handleContinue} isLoading={isLoading}>
            {t("tablet.visitors.continue")}
          </Button>
        </div>
        {isNative() && (
          <div>
            <Button
              variant="link"
              onClick={handleGoBack}
              isDisabled={isLoading}
            >
              {t("general.redirection.go_back")}
            </Button>
          </div>
        )}
      </VisitorFooter>
    </VisitorView>
  )
}

export default HealthInfo
