import { useId } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { USER_DATE_FORMAT } from "../../constants"
import { Children } from "../../types/sharedTypes"

import { SUBSCRIPTION_STATUSES } from "../../redux/api/billing/constants"
import { useFetchSubscriptionsQuery } from "../../redux/api/billing/subscriptions"
import {
  SubscriptionStatus,
  SubscriptionUnitType,
} from "../../redux/api/billing/types"
import { getLicenses } from "../../redux/api/billing/utils"

import { Tooltip } from "../../components/basic/Tooltip"

import InfoSVG from "../../assets/images/icons/InfoOutlined.svg"

import "./LicenseInfoList.sass"

type LicensesInfoListProps = {
  licenseType: SubscriptionUnitType
  tooltipContent?: Children
}

const LicensesInfoList = ({
  licenseType,
  tooltipContent,
}: LicensesInfoListProps) => {
  const { data: subscriptions } = useFetchSubscriptionsQuery()
  const licenses = getLicenses(subscriptions?.results ?? [], licenseType) || []

  // Filter out expired licenses
  const currentDate = dayjs()
  const validLicenses = licenses.filter((l) =>
    dayjs(l.renewalDate).isAfter(currentDate),
  )

  return (
    <div className="licenses-info-list">
      {validLicenses.map((l, i) => (
        <LicensesInfo
          key={i}
          quantity={l.quantity}
          quantityUsed={l.quantityUsed}
          renewalDate={l.renewalDate}
          status={l.status}
          tooltipContent={tooltipContent}
        />
      ))}
    </div>
  )
}

type LicensesInfoProps = {
  quantity: number
  renewalDate?: string
  quantityUsed: number
  status: SubscriptionStatus
  tooltipContent?: Children
}

const LicensesInfo = ({
  quantity,
  renewalDate,
  quantityUsed,
  status,
  tooltipContent,
}: LicensesInfoProps) => {
  const { t } = useTranslation()
  const formattedDate = dayjs(renewalDate).format(USER_DATE_FORMAT)
  const id = useId()

  return (
    <div className="license-info">
      <div className="license-info-icon">
        {tooltipContent ? (
          <Tooltip uniqueId={id} content={tooltipContent} place="right">
            <InfoSVG />
          </Tooltip>
        ) : (
          <InfoSVG />
        )}
      </div>
      <div className="license-info-details">
        {t("desktop.settings.visitors.visitor_settings.license_text", {
          quantity,
          quantity_active: quantityUsed,
        })}{" "}
        {status === SUBSCRIPTION_STATUSES.ACTIVE &&
          t("desktop.settings.visitors.visitor_settings.license_valid_until", {
            date: formattedDate,
          })}
        {status === SUBSCRIPTION_STATUSES.NON_RENEWING &&
          t("desktop.settings.visitors.visitor_settings.license_renews_on", {
            date: formattedDate,
          })}
      </div>
    </div>
  )
}

export default LicensesInfoList
