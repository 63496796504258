import { useEffect } from "react"

import { Trans, useTranslation } from "react-i18next"

import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { selectVisitorSettings } from "../../../redux/visitor_settings/selectors"
import { fetchVisitorSettings } from "../../../redux/visitor_settings/visitorSettingsSlice"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import VisitorSettingsForm from "../../../components/Form/Visitors/VisitorSettingsForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./VisitorSettings.sass"

const VisitorSettings = () => {
  const { t } = useTranslation()

  const { entries: visitorSettings, isLoaded } = useAppSelector(
    selectVisitorSettings,
  )

  const actions = useActions({
    fetchVisitorSettings: () => fetchVisitorSettings(),
  })

  useEffect(() => {
    actions.fetchVisitorSettings()
  }, [actions])

  return (
    <View className="VisitorSettings">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.visitors.title"),
          t("desktop.settings.visitors.visitor_settings.title"),
        ]}
      />

      <Intro>
        <Trans i18nKey="desktop.settings.visitors.visitor_settings.intro" />
      </Intro>

      <Space size={0.75} />

      {!isLoaded && <Loader className="loader" />}

      {isLoaded && visitorSettings && <VisitorSettingsForm />}
    </View>
  )
}

export default VisitorSettings
