import React, { useCallback, useContext, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
import SignatureCanvas from "react-signature-canvas"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { FlowContext } from "../../providers/Tablet/RegistrationFlowProvider"
import { isNative } from "../../utils"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import {
  RegistrationUpdateRequest,
  SignatureUploadRequest,
} from "../../redux/visitor_registration/types"
import {
  updateRegistration,
  uploadSignature,
} from "../../redux/visitor_registration/visitorRegistrationSlice"

import Button from "../../components/advanced/Button"
import { SignatureInput } from "../../components/advanced/SignatureInput"
import DocumentView from "../../components/Visitors/DocumentView"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

import "./Document.sass"

const Document = () => {
  const { t } = useTranslation()

  const { previousStep, nextStep } = useContext(FlowContext)

  const signatureRef = useRef<SignatureCanvas>(null)

  const [validSignature, setValidSignature] = useState(true)

  const { buildingId, id: tabletId } = useAppSelector(selectTablet)
  const { data, registration, isSubmitting } = useAppSelector(
    selectVisitorRegistration,
  )

  const actions = useActions({
    appError: (message: string) => appError(message),
    uploadSignature: (body: SignatureUploadRequest) => uploadSignature(body),
    updateRegistration: (
      buildingId: string,
      tabletId: string,
      id: string,
      body: RegistrationUpdateRequest,
    ) => updateRegistration({ buildingId, tabletId, id, ...body }),
  })

  const onSubmit = useCallback(async () => {
    if (!data?.document?.required_signing) {
      nextStep(registration?.id)
      return
    }

    if (buildingId && tabletId && registration && signatureRef.current) {
      if (!signatureRef.current.isEmpty()) {
        setValidSignature(true)
        const canvas = signatureRef.current.getCanvas()

        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], "signature.png")

            const fileResponse = await actions.uploadSignature({
              file,
              registrationId: registration.id,
            })

            if (uploadSignature.fulfilled.match(fileResponse)) {
              const response = await actions.updateRegistration(
                buildingId,
                tabletId,
                registration.id,
                {
                  signature_file_id: fileResponse.payload.id,
                },
              )

              if (updateRegistration.fulfilled.match(response)) {
                analyticsEvent(SupportedEvents.VISITOR_DOCUMENT_SIGNED, {
                  id: response?.payload?.id,
                  name: response?.payload?.full_name,
                  building_id: buildingId,
                  visitor_email: response?.payload?.visitor?.email,
                  host_email: response?.payload?.host?.email,
                })

                nextStep(response.payload.id)
              } else {
                actions.appError(
                  response.error.message ?? t("tablet.general_error"),
                )
              }
            } else {
              actions.appError(
                fileResponse.error.message ?? t("tablet.general_error"),
              )
            }
          }
        })
      } else {
        setValidSignature(false)
      }
    }
  }, [t, actions, nextStep, data, buildingId, tabletId, registration])

  const handleGoBack = () => previousStep()

  return (
    <VisitorView className="Document">
      <VisitorHeader
        showNavigation={!isNative()}
        title={t("tablet.visitors.screens.document.title")}
      />
      <VisitorContent>
        {data?.document && <DocumentView document={data.document} />}
        {data?.document?.required_signing && (
          <SignatureInput ref={signatureRef} hasError={!validSignature} />
        )}
      </VisitorContent>
      <VisitorFooter>
        <div>
          <Button onClick={onSubmit} isLoading={isSubmitting}>
            {t("tablet.visitors.continue")}
          </Button>
        </div>
        {isNative() && (
          <div>
            <Button
              variant="link"
              onClick={handleGoBack}
              isDisabled={isSubmitting}
            >
              {t("general.redirection.go_back")}
            </Button>
          </div>
        )}
      </VisitorFooter>
    </VisitorView>
  )
}

export default Document
