import { DependencyList, useEffect, useRef } from "react"

import { useLocation } from "react-router"

export const useScrollToLocation = (deps: DependencyList = [], timeout = 0) => {
  const scrolledRef = useRef(false)
  const { hash } = useLocation()
  const hashRef = useRef(hash)

  useEffect(() => {
    requestAnimationFrame(() => {
      if (hash) {
        if (hashRef.current !== hash) {
          hashRef.current = hash
          scrolledRef.current = false
        }

        if (!scrolledRef.current) {
          const id = hash.replace("#", "")
          const element = document.getElementById(id)
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({ behavior: "smooth" })
            }, timeout)
            scrolledRef.current = true
          }
        }
      }
    })
  }, [hash, ...deps])
}

export const useScrollToTop = (deps: DependencyList = []) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [...deps])
}
