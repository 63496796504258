import { api } from "../"
import {
  pairRoomDeviceURL,
  roomDeviceRepairURL,
  roomDeviceURL,
  roomsDevicesURL,
} from "../../../api"
import { PaginatedOptions } from "../../types"
import {
  DevicesFacetQuery,
  RoomDevicePairResponse,
  RoomDeviceRequestWithId,
  RoomDeviceResponse,
  RoomsDevicesFetchRequest,
  RoomsDevicesResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const roomsDevices = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchRoomsDevices: builder.query<
      RoomsDevicesResponse & { offset: number; facets?: DevicesFacetQuery },
      PaginatedOptions & RoomsDevicesFetchRequest
    >({
      query: ({
        floor,
        building,
        type,
        battery,
        connectivity,
        facets,
        ...options
      }) => {
        const params = {
          ...(facets && { facets: facets?.join(",") }),
          ...(building &&
            building !== FilterSpecialValues.ALL && { building_id: building }),
          ...(floor &&
            floor !== FilterSpecialValues.ALL && { floor_id: floor }),
          ...(type && type !== FilterSpecialValues.ALL && { type }),
          ...(battery &&
            battery !== FilterSpecialValues.ALL && { battery_max: battery }),
          ...(connectivity &&
            connectivity !== FilterSpecialValues.ALL && {
              connectivity: connectivity.toLowerCase(),
            }),
          ...options,
        }

        return {
          url: roomsDevicesURL({ ...params }),
        }
      },
      providesTags: (result, _error) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "RoomsDevices" as const,
                id,
              })),
              { type: "RoomsDevices", id: "LIST" },
            ]
          : [{ type: "RoomsDevices", id: "LIST" }],
      transformResponse: (response: RoomsDevicesResponse, _meta, arg) => {
        return { ...response, offset: arg?.offset ?? 0 }
      },
    }),

    fetchRoomDevice: builder.query<RoomDeviceResponse, string>({
      query: (id) => ({
        url: roomDeviceURL(id),
      }),
      providesTags: (_result, _error, id) => [{ type: "RoomsDevices", id }],
    }),

    updateRoomDevice: builder.mutation<
      RoomDeviceResponse,
      RoomDeviceRequestWithId
    >({
      query: ({ id, ...body }) => ({
        url: roomDeviceURL(id),
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "RoomsDevices", id: "LIST" },
        { type: "RoomsDevices", id },
      ],
    }),

    pairRoomDevice: builder.mutation<RoomDevicePairResponse, { pin: string }>({
      query: (body) => ({
        url: pairRoomDeviceURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "RoomsDevices", id: "LIST" }],
    }),

    repairRoomDevice: builder.mutation<void, string[]>({
      query: (body) => ({
        url: roomDeviceRepairURL(),
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "RoomsDevices", id: "LIST" }],
    }),

    unpairRoomDevice: builder.mutation<void, string[]>({
      query: (body) => ({
        url: roomsDevicesURL(),
        method: "DELETE",
        body,
      }),
      invalidatesTags: (_result, _error) => [
        { type: "RoomsDevices", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useFetchRoomsDevicesQuery,
  useLazyFetchRoomsDevicesQuery,
  useFetchRoomDeviceQuery,
  usePairRoomDeviceMutation,
  useRepairRoomDeviceMutation,
  useUpdateRoomDeviceMutation,
  useUnpairRoomDeviceMutation,
} = roomsDevices
