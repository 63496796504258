import { useEffect } from "react"

export const useOnEscape = (handler: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handler(event)
      }
    }
    document.addEventListener("keyup", listener)

    return () => {
      document.removeEventListener("keyup", listener)
    }
  }, [handler])
}
