import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { Children } from "../../../types/sharedTypes"
import Breadcrumbs from "../../Breadcrumbs"
import Intro from "../../Intro"
import Space from "../../Space"
import View from "../../View"
import SubscriptionOverview from "../SubscriptionOverview"

import { PlanGroup } from "../../../redux/api/billing/types"

import "./style.sass"

type SolutionOverviewProps = {
  planGroup: PlanGroup
  className?: string
  intro?: Children
  children?: Children
}

const SolutionOverview = ({
  planGroup,
  className,
  intro,
  children,
}: SolutionOverviewProps) => {
  const cn = classNames("SolutionOverview", className)
  const { t } = useTranslation()

  return (
    <View className={cn}>
      <Breadcrumbs
        depth={2}
        values={[
          t(`desktop.settings.${planGroup}s.title`),
          t("desktop.settings.navigation.overview"),
        ]}
        includeParamsAsPath
      />

      {!!intro && <Intro isConstrained>{intro}</Intro>}

      <Space size={0.75} />

      <div className="SolutionOverview__layout">
        <div className="SolutionOverview__layout__settings">{children}</div>
        <div className="SolutionOverview__layout__subscriptions">
          <SubscriptionOverview planGroup={planGroup} />
        </div>
      </div>
    </View>
  )
}

export default SolutionOverview
