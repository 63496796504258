import { ThunkApiConfig } from "RootType"

import { get, qrCodeURL } from "../../api"
import { getErrorMessage } from "../reduxUtils"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const qrCodeFetch = createAsyncThunk<string, string, ThunkApiConfig>(
  "qrCode/fetch",
  async (data, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await get(qrCodeURL({ data }), {}, access_token)

    if (response.ok && response.body) {
      return URL.createObjectURL(await response.blob())
    }

    throw new Error(await getErrorMessage(response))
  },
)
