import { Trans } from "react-i18next"

import { CreditCard } from "../../../../redux/api/billing/payments/types"

import "./CreditCardInfo.sass"

type CreditCardInfoProps = {
  creditCard: CreditCard
}

const CreditCardInfo = ({ creditCard }: CreditCardInfoProps) => (
  <div className="CreditCardInfo">
    <div>
      <Trans
        i18nKey="desktop.settings.billing.payment_method.credit_card.credit_card_info"
        tOptions={{
          brand: creditCard.card_brand.toLocaleUpperCase(),
          last4: creditCard.card_masked_number.slice(-4),
          expMonth: creditCard.card_expiry_month,
          expYear: creditCard.card_expiry_year,
        }}
      />
    </div>
  </div>
)

export default CreditCardInfo
