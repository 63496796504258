import { Redirect, Switch } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import { USER_DIRECTORY_PATHS } from "./constants"
import EditUserDirectory from "./EditUserDirectory"
import UserDirectoryOverview from "./UserDirectory"

import ProtectedRoute from "../../../components/ProtectedRoute"

const UserDirectory = () => {
  const changeUserPermission = PERMISSIONS.users.canChangeUser

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={USER_DIRECTORY_PATHS.overview}
        accessPermission={changeUserPermission}
        component={UserDirectoryOverview}
      />
      <ProtectedRoute
        exact
        path={USER_DIRECTORY_PATHS.add}
        accessPermission={changeUserPermission}
        component={EditUserDirectory}
      />
      <ProtectedRoute
        exact
        path={USER_DIRECTORY_PATHS.edit}
        accessPermission={changeUserPermission}
        component={EditUserDirectory}
      />
      <Redirect to={USER_DIRECTORY_PATHS.overview} />
    </Switch>
  )
}

export default UserDirectory
