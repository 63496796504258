import { useEffect, useRef, useState } from "react"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { useFetchAssetTypesQuery } from "../../redux/api/assetTypes"
import { AssetType } from "../../redux/asset_types/types"

type Props = {
  value: string
  onChange: (v: string) => void
  showAll?: boolean
  buildingId?: string
}

const AssetTypesFilter = ({ value, onChange, buildingId }: Props) => {
  const initValueRef = useRef<string>(value)

  const {
    data: { results: assetTypes = [] } = {},
    isSuccess: areAssetTypesLoaded,
  } = useFetchAssetTypesQuery()

  const [filteredAssetTypes, setFilteredAssetTypes] = useState<AssetType[]>(
    getFilteredAssetTypes(assetTypes, buildingId),
  )

  const innerOnChange = (id: string) => {
    if (id !== initValueRef.current) {
      onChange(id)
      initValueRef.current = id
    }
  }

  const assetTypesOptions = filteredAssetTypes.map((assetType) => ({
    value: assetType.id,
    label: assetType.name,
  }))

  useEffect(() => {
    // Handle if asset type is deleted or if not selected
    if (areAssetTypesLoaded) {
      const initValue = initValueRef.current
      if (initValue !== FilterSpecialValues.EMPTY) {
        const hasAssetType = filteredAssetTypes.find(
          (assetType) => assetType.id === initValue,
        )
        if (!hasAssetType) {
          if (filteredAssetTypes.length > 0) {
            innerOnChange(filteredAssetTypes[0].id)
          } else {
            innerOnChange(FilterSpecialValues.EMPTY)
          }
        } else {
          innerOnChange(hasAssetType.id)
        }
      } else {
        if (filteredAssetTypes.length > 0) {
          innerOnChange(filteredAssetTypes[0].id)
        }
      }
    }
  }, [filteredAssetTypes, areAssetTypesLoaded])

  useEffect(() => {
    if (areAssetTypesLoaded) {
      setFilteredAssetTypes(getFilteredAssetTypes(assetTypes, buildingId))
    }
  }, [buildingId, assetTypes, areAssetTypesLoaded])

  return (
    <Dropdown
      className="asset-type-filter"
      options={assetTypesOptions}
      value={value}
      onChange={innerOnChange}
    />
  )
}

const getFilteredAssetTypes = (
  assetTypes: AssetType[],
  buildingId?: string,
) => {
  if (buildingId === undefined || buildingId === FilterSpecialValues.ALL) {
    return assetTypes
  }
  return assetTypes.filter((a) => a.buildings[0]?.id === buildingId)
}

export default AssetTypesFilter
