import dayjs from "dayjs"
import { ThunkApiConfig } from "RootType"

import { IdAndName, InternalTime } from "../../types/sharedTypes"
import { isInternalTime, setTimeToDayjs } from "../../utils"
import { timeslots } from "../api/timeslots"
import { sliceInitialState } from "../reduxUtils"
import { SliceState } from "../types"
import { BookAsset, RecurringReservationProps } from "./types"
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit"

import { Asset } from "../assets/types"

export const bookAssetSetDefaultTime = createAsyncThunk<
  { start: string; end: string },
  undefined,
  ThunkApiConfig
>("bookAssets/bookAssetSetDefaultTime", async (_, { getState }) => {
  const timeslot = getState().bookAsset

  if (
    !!timeslot?.start &&
    !!timeslot?.end &&
    isInternalTime(timeslot.start) &&
    isInternalTime(timeslot.end)
  ) {
    return {
      start: setTimeToDayjs(dayjs(), timeslot.start).toISOString(),
      end: setTimeToDayjs(dayjs(), timeslot.end).toISOString(),
    }
  } else {
    return {
      start: dayjs().hour(9).startOf("hour").toISOString(),
      end: dayjs().hour(17).startOf("hour").toISOString(),
    }
  }
})

export interface BookAssetState extends SliceState {
  bookAsset: BookAsset
}

const initialState: BookAssetState = {
  bookAsset: {},
  ...sliceInitialState,
}

const bookAssetSlice: Slice<BookAssetState> = createSlice({
  name: "bookAsset",
  initialState,
  reducers: {
    bookAssetAddUserId: (state, action: PayloadAction<string>) => {
      state.bookAsset.userEmail = action.payload
    },
    bookAssetAddBuilding: (state, action: PayloadAction<IdAndName>) => {
      state.bookAsset.building = action.payload
    },
    bookAssetAddTimezone: (state, action: PayloadAction<string>) => {
      state.bookAsset.timezone = action.payload
    },
    bookAssetAddAssetTypeId: (state, action: PayloadAction<string>) => {
      state.bookAsset.assetTypeId = action.payload
    },
    bookAssetAddAsset: (state, action: PayloadAction<Asset>) => {
      state.bookAsset.asset = action.payload
    },
    bookAssetAddTime: (
      state,
      action: PayloadAction<{ start: string; end: string }>,
    ) => {
      state.bookAsset.start = action.payload.start
      state.bookAsset.end = action.payload.end
    },
    bookAssetAddRecurring: (
      state,
      action: PayloadAction<RecurringReservationProps>,
    ) => {
      state.bookAsset.recurring = action.payload
    },

    clearBookAsset: (state, _action: PayloadAction) => {
      state.bookAsset = {}
    },
    setBookAsset: (state, action: PayloadAction<BookAsset>) => {
      state.bookAsset = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(bookAssetSetDefaultTime.fulfilled, (state, { payload }) => {
      state.bookAsset.start = payload.start
      state.bookAsset.end = payload.end
    })
    builder.addMatcher(
      timeslots.endpoints.fetchTimeslots.matchFulfilled,
      (state, { payload }) => {
        if (payload.length) {
          state.bookAsset.start = setTimeToDayjs(
            dayjs(),
            payload[0].from as InternalTime,
          ).toISOString()
          state.bookAsset.end = setTimeToDayjs(
            dayjs(),
            payload[0].to as InternalTime,
          ).toISOString()
        }
      },
    )
  },
})

export const bookAssetReducer = bookAssetSlice.reducer
export const {
  bookAssetAddUserId,
  bookAssetAddBuilding,
  bookAssetAddTimezone,
  bookAssetAddAssetTypeId,
  bookAssetAddAsset,
  bookAssetAddTime,
  bookAssetAddRecurring,
  clearBookAsset,
  setBookAsset,
} = bookAssetSlice.actions
