import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { AssetType } from "../redux/asset_types/types"

import AssetTypeDeleteForm from "../components/Form/AssetTypeDeleteForm"

type Props = {
  open: boolean
  assetType: AssetType
}

export default function AssetTypeModal({ open, assetType }: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <AssetTypeDeleteForm assetType={assetType} />
    </ReactModal>
  )
}
