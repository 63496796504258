import { useMemo } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import i18n from "../../../../i18n"
import { Children } from "../../../../types/sharedTypes"

import {
  CURRENCIES,
  INVOICE_STATUSES,
} from "../../../../redux/api/billing/constants"
import { Invoice } from "../../../../redux/api/billing/invoices/types"
import { Currency, InvoiceStatus } from "../../../../redux/api/billing/types"

import Table from "../../../../components/advanced/Table"
import Status, { STATUSES } from "../../../../components/basic/Status"
import { Column } from "../../../../components/basic/Table"

import "./InvoiceTable.sass"

type Props = {
  invoices: Invoice[]
  pagination?: Children
  emptyTableCell?: string | JSX.Element
}

const InvoiceTable = ({ invoices = [], pagination, emptyTableCell }: Props) => {
  const { t } = useTranslation()

  const openInvoice = (url: string) => {
    window.open(url, "_blank")
  }

  const tableColumns = useMemo<Column<Invoice>[]>(() => {
    return [
      {
        field: "date",
        label: t("desktop.settings.billing.invoices.table_columns.date"),
        renderCell: (i) => {
          return <span>{dayjs(i.date).format("l")}</span>
        },
      },
      {
        field: "total",
        label: t("desktop.settings.billing.invoices.table_columns.amount"),
        renderCell: (i) => {
          return numberToCurrency(i.currency_code)(i.total)
        },
      },
      {
        field: "download_url",
        label: t("desktop.settings.billing.invoices.table_columns.invoice"),
        renderCell: (i) => (
          <a href={i.download_url} target="_blank" rel="noopener noreferrer">
            {prettifyInvoice(i)}
          </a>
        ),
      },
      {
        field: "status",
        label: t("desktop.settings.billing.invoices.table_columns.status"),
        renderCell: (i) => (
          <Status
            className="invoice-status"
            status={getInvoiceStatus(i.status)}
          >
            {t(`desktop.settings.billing.invoices.invoice_status.${i.status}`)}
          </Status>
        ),
      },
    ]
  }, [t])

  return (
    <Table
      className="InvoiceTable"
      loading={false}
      rows={invoices}
      columns={tableColumns}
      onRowClick={(invoice: Invoice) => openInvoice(invoice.download_url)}
      pagination={pagination}
      emptyTableCell={emptyTableCell}
    />
  )
}

export default InvoiceTable

const prettifyInvoice = ({ date, id }: Invoice) => {
  const partOfId = id.split("-")[2]
  const datePart = dayjs(date).format("DD-MM-YY")

  return `Invoice ${datePart}-${partOfId}.pdf`
}

const numberToCurrency = (
  currency: Currency = CURRENCIES.EUR,
  language: string = i18n.language,
) =>
  new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency ?? CURRENCIES.EUR,
  }).format

const invoiceStatusToStatus = {
  [INVOICE_STATUSES.PAID]: STATUSES.VALID,
  [INVOICE_STATUSES.POSTED]: STATUSES.WARNING,
  [INVOICE_STATUSES.PAYMENT_DUE]: STATUSES.WARNING,
  [INVOICE_STATUSES.NOT_PAID]: STATUSES.INVALID,
  [INVOICE_STATUSES.VOIDED]: STATUSES.VALID,
  [INVOICE_STATUSES.PENDING]: STATUSES.WARNING,
}
const getInvoiceStatus = (status: InvoiceStatus) =>
  invoiceStatusToStatus[status] ?? "valid"
