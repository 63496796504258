import React from "react"

import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import { BILLING_URL } from "../../../api"
import { FEATURE_FLAGS } from "../../../constants"
import { useCheckForFeatureFlag } from "../../../hooks/useCheckForFeatureFlag"
import { BILLING_PATHS } from "../../../screens/Settings/Billing/constants"
import { toFriendlyName } from "../../../screens/Settings/Billing/utils"
import Card from "../../basic/Card"

import { SubscriptionUnit } from "../../../redux/api/billing/subscriptions/types"
import {
  PlanGroup,
  PlanType,
  SubscriptionStatus,
} from "../../../redux/api/billing/types"

import "./style.sass"

type SubscriptionOverviewCardProps = {
  type: PlanType
  status: SubscriptionStatus
  unit: SubscriptionUnit[]
  planGroup: PlanGroup
}

const SubscriptionOverviewCard = ({
  type,
  unit,
  status,
  planGroup,
}: SubscriptionOverviewCardProps) => {
  const { t } = useTranslation()

  const isTrial = status === "in_trial"

  return (
    <Card className="SubscriptionOverviewCard">
      <div className="info">
        <div className="title">
          {isTrial
            ? t("desktop.components.subscription_card.free_trial")
            : t("desktop.components.subscription_card.subscription")}
        </div>
        <div className="type">{toFriendlyName(type)}</div>
      </div>
      <div className="licenses">
        <div>
          {t("desktop.components.subscription_card.licenses", {
            count: unit[0].quantity,
            unit: unit[0].type,
          })}
        </div>
        <div>
          {t("desktop.components.subscription_card.active_units", {
            count: unit[0].quantity_used,
            unit: unit[0].type,
          })}
        </div>
      </div>
      <div className="actions">
        <NavLink to={`${BILLING_PATHS.overview.root}#${planGroup}`}>
          {t(
            "desktop.settings.rooms.overview.subscriptions_details.go_to_billing",
          )}
        </NavLink>
      </div>
    </Card>
  )
}

export default SubscriptionOverviewCard
