import React, { useContext } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  FinishType,
  FlowContext,
} from "../../providers/Tablet/RegistrationFlowProvider"

import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { useActions } from "../../redux/utils"
import { CheckoutRequest } from "../../redux/visitor_registration/types"
import { checkoutRegistration } from "../../redux/visitor_registration/visitorRegistrationSlice"

import Button from "../../components/advanced/Button"
import { Input } from "../../components/basic/Input"
import Field from "../../components/Field"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorForm from "../../components/Visitors/VisitorForm"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

import "./Checkout.sass"

type FormValues = {
  full_name: string
}

const Checkout = () => {
  const { t } = useTranslation()

  const { startStep, finishStep } = useContext(FlowContext)

  const { buildingId, id: tabletId } = useAppSelector(selectTablet)

  const actions = useActions({
    checkoutRegistration: (
      buildingId: string,
      tabletId: string,
      payload: CheckoutRequest,
    ) => checkoutRegistration({ buildingId, tabletId, ...payload }),
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      full_name: "",
    },
  })
  const { control, setError } = methods

  const onSubmit = async (values: FormValues) => {
    if (buildingId && tabletId) {
      const response = await actions.checkoutRegistration(
        buildingId,
        tabletId,
        values,
      )

      if (checkoutRegistration.fulfilled.match(response)) {
        finishStep(FinishType.CHECK_OUT)
      } else {
        setError("full_name", {
          message: t("tablet.visitors.form.visitor_not_signed"),
          type: "server",
        })
      }
    }
  }

  const handleGoBack = () => startStep()

  return (
    <VisitorView className="Checkout">
      <FormProvider {...methods}>
        <VisitorForm onSubmit={onSubmit}>
          <VisitorHeader />
          <VisitorContent>
            <div className="fields">
              <div className="info">
                {t("tablet.visitors.screens.checkout.enter_full_name")}
              </div>
              <div>
                <Field
                  control={control}
                  name="full_name"
                  label={t("tablet.visitors.form.your_full_name")}
                  rules={{
                    required: t("tablet.visitors.form.your_full_name_required"),
                  }}
                >
                  {(props) => <Input autoFocus {...props} />}
                </Field>
              </div>
            </div>
          </VisitorContent>
          <VisitorFooter>
            <div>
              <Button isSubmit variant="submit">
                {t("tablet.visitors.check_out")}
              </Button>
            </div>
            <div>
              <Button variant="link" onClick={handleGoBack}>
                {t("general.redirection.go_back")}
              </Button>
            </div>
          </VisitorFooter>
        </VisitorForm>
      </FormProvider>
    </VisitorView>
  )
}

export default Checkout
