import dayjs from "dayjs"
import { ThunkApiConfig } from "RootType"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import {
  eventBookURL,
  eventCancelURL,
  eventCheckinURL,
  eventExtendURL,
  eventsURL,
  get,
  postJSON,
  resourcesURL,
} from "../../api"
import { timeZone } from "../../dayjs"
import {
  getErrorMessage,
  paginationInitialState,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import {
  fetchRoomsError,
  fetchRoomsStart,
  fetchRoomsSuccess,
} from "../rooms/roomsSlice"
import { RoomResponse, RoomsResponse } from "../rooms/types"
import { PaginationState, PayloadWithAction, SliceState } from "../types"
import { EventResponse, EventsRooms, EventsRoomsResponse } from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export const fetchEventsRooms = createAsyncThunk<
  EventResponse[],
  undefined,
  ThunkApiConfig
>("events/fetchEventsRooms", async (_, { getState, dispatch }) => {
  const {
    auth: { access_token },
  } = getState()

  dispatch(fetchRoomsStart())

  const eventsPromise = get(eventsURL(), {}, access_token)
  const resourcesPromise = get(resourcesURL(), {}, access_token)

  const [eventsResponse, resResponse] = await Promise.all([
    eventsPromise,
    resourcesPromise,
  ])

  if (eventsResponse.ok && resResponse.ok) {
    const eventsJsonPromise: Promise<EventsRoomsResponse> =
      eventsResponse.json()
    const resourcesJsonPromise: Promise<RoomsResponse> = resResponse.json()

    const [eventsJson, resourcesJson] = await Promise.all([
      eventsJsonPromise,
      resourcesJsonPromise,
    ])

    const events: EventResponse[] = []
    for (let el of eventsJson) {
      for (let event of el.events) {
        const room = el.room
        const roomData = resourcesJson.results.find(
          (r: RoomResponse) => r.key === room.email,
        )
        if (roomData) {
          room.settings = roomData.settings
          room.first_failed_sync = roomData.first_failed_sync
          event.room = room
        }

        events.push(event)
      }
    }
    // Rooms
    const rooms: RoomResponse[] = []
    resourcesJson.results.forEach((resource: RoomResponse) => {
      const eventsRoom = eventsJson.find(
        (ev: EventsRooms) => ev.room.email === resource.key,
      )

      if (eventsRoom !== undefined) {
        const { events, room } = eventsRoom
        rooms.push({
          ...resource,
          first_failed_sync: room.first_failed_sync,
          events: events,
        })
      }
    })

    dispatch(fetchRoomsSuccess(rooms))

    return events
  }

  if (!resResponse.ok) {
    return dispatch(fetchRoomsError(await getErrorMessage(resResponse)))
  }

  throw new Error(await getErrorMessage(eventsResponse))
})

export type BookEventProps = {
  room: RoomResponse
  organizer: string
  title: string
  start: string
  end: string
}

export const bookEvent = createAsyncThunk<
  EventResponse,
  BookEventProps,
  ThunkApiConfig
>(
  "events/book",
  async ({ room, organizer, title, start, end }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await postJSON(
      eventBookURL(),
      {
        body: {
          source: room.key,
          organizer,
          title,
          start,
          end,
          timezone: "UTC",
        },
      },
      access_token,
    )

    if (response.ok) {
      const json: EventResponse = await response.json()

      json.room = {
        name: room.name,
        status: 1,
        capacity: room.capacity,
        sync: room.first_failed_sync,
        settings: room.settings,
        email: room.key,
        locations: [],
      }

      if (room.building) {
        json.room.locations.push({
          id_str: room.building.name,
          type: 0,
          name: room.building.name,
        })
      }

      if (room.floor) {
        json.room.locations.push({
          id_str: room.floor.name,
          type: 1,
          name: room.floor.name,
        })
      }

      analyticsEvent(SupportedEvents.ROOM_BOOKED, { id: json.id })

      return json
    }

    throw new Error(await getErrorMessage(response))
  },
)

export type ExtendEventProps = {
  event: EventResponse
  extend_until: string
}

export const extendEvent = createAsyncThunk<
  EventResponse,
  ExtendEventProps,
  ThunkApiConfig
>("events/extend", async ({ event, extend_until }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    eventExtendURL(),
    {
      body: {
        event_id: event.id,
        room_id: event.room.email,
        extend_until,
      },
    },
    access_token,
  )

  if (response.ok) {
    analyticsEvent(SupportedEvents.ROOM_BOOKING_EXTEND, { id: event.id })

    return { ...event, end: extend_until }
  }

  throw new Error(await getErrorMessage(response))
})

export type CheckInEventProps = {
  event: EventResponse
  lifetime: number
}

export const checkinEvent = createAsyncThunk<
  EventResponse,
  CheckInEventProps,
  ThunkApiConfig
>("events/checkin", async ({ event, lifetime }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    eventCheckinURL(),
    {
      body: {
        event_id: event.id,
        room_id: event.room.email,
        lifetime,
      },
    },
    access_token,
  )

  if (response.ok) {
    analyticsEvent(SupportedEvents.ROOM_BOOKING_CHECKIN, { id: event.id })

    return { ...event, confirmed: true }
  }

  throw new Error(await getErrorMessage(response))
})

export const finishEvent = createAsyncThunk<
  EventResponse,
  EventResponse,
  ThunkApiConfig
>("events/finish", async (event, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    eventCancelURL(),
    {
      body: {
        finish: true,
        event_id: event.id,
        room_id: event.room.email,
        timezone: timeZone,
      },
    },
    access_token,
  )

  if (response.ok) {
    analyticsEvent(SupportedEvents.ROOM_BOOKING_END, { id: event.id })

    return { ...event, end: dayjs().toISOString() }
  }

  throw new Error(await getErrorMessage(response))
})

export const cancelEvent = createAsyncThunk<
  EventResponse,
  EventResponse,
  ThunkApiConfig
>("events/cancel", async (event, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    eventCancelURL(),
    {
      body: {
        finish: false,
        event_id: event.id,
        room_id: event.room.email,
        timezone: timeZone,
      },
    },
    access_token,
  )

  if (response.ok) {
    analyticsEvent(SupportedEvents.ROOM_BOOKING_END, { id: event.id })

    return event
  }

  throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface EventsState extends SliceState, PaginationState {
  entries: EventResponse[]
}

const initialState: EventsState = {
  entries: [],
  ...sliceInitialState,
  ...paginationInitialState,
}

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEventsRooms.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchEventsRooms.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchEventsRooms.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entries = payload
    })

    builder.addCase(cancelEvent.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      state.entries = [...state.entries.filter((e) => e.id !== payload.id)]
    })

    builder.addMatcher(
      isAnyOf(
        bookEvent.pending,
        extendEvent.pending,
        checkinEvent.pending,
        finishEvent.pending,
        cancelEvent.pending,
      ),
      (bookEvent.pending,
      (state) => {
        state.isSubmitting = true
      }),
    )

    builder.addMatcher(
      isAnyOf(
        bookEvent.rejected,
        extendEvent.rejected,
        checkinEvent.rejected,
        finishEvent.rejected,
        cancelEvent.rejected,
      ),
      (bookEvent.pending,
      (state, action) => {
        setSubmitErrorState(state, action as PayloadWithAction)
      }),
    )

    builder.addMatcher(
      isAnyOf(
        bookEvent.fulfilled,
        extendEvent.fulfilled,
        checkinEvent.fulfilled,
        finishEvent.fulfilled,
      ),
      (bookEvent.pending,
      (state, { payload }) => {
        setSubmitSuccessState(state)
        state.entries = [
          ...state.entries.filter((e) => e.id !== payload.id),
          payload,
        ]
      }),
    )
  },
})

export const eventsReducer = eventsSlice.reducer
