import React, { useCallback, useRef, useState } from "react"

import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import DeviceFilter from "../../../../components/Filter/DeviceFilter"
import Filters from "../../../../components/Filter/Filters"
import { FilterSpecialValues } from "../../../../components/Filter/types"

export type Filter = {
  building_id: string
  device_id: string
}

type FilterKeys = keyof Filter

type Props = {
  onChange: (filter: Filter) => Promise<void>
  defaultValues: Filter
}

const DeviceSettingsFilters = ({ onChange, defaultValues }: Props) => {
  const filtersRef = useRef<Filter>(defaultValues)
  const [filters, setFilters] = useState<Filter>(defaultValues)

  const handleOnChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[]) => {
      filtersRef.current = {
        ...filtersRef.current,
        [filterName]: value,
      }
      onChange(filtersRef.current as Filter)
      setFilters(filtersRef.current)
    },
    [onChange],
  )

  return (
    <Filters>
      <BuildingFilter
        value={filters.building_id}
        onChange={(value) => {
          handleOnChange("building_id")(value)
          handleOnChange("device_id")(FilterSpecialValues.ALL)
        }}
        showAll
      />

      <DeviceFilter
        value={filters.device_id}
        onChange={handleOnChange("device_id")}
        buildingId={filters.building_id}
        showAll
        showType
      />
    </Filters>
  )
}

export default DeviceSettingsFilters
