import React from "react"

import { useTranslation } from "react-i18next"

import "./NoDeskFound.sass"

type NoDeskFoundProps = {
  message?: string
}

const NoDeskFound = ({ message }: NoDeskFoundProps) => {
  const { t } = useTranslation()

  return (
    <div className="NoDeskFound">
      {message ? message : t("mobile.book.no_desk_found")}
    </div>
  )
}

export default NoDeskFound
