import { AnimatePresence, motion } from "framer-motion"

import { Children } from "../../../types/sharedTypes"

export type CollapseProps = {
  isExtended: boolean
  children: Children
  itemKey?: string
  className?: string
}

export const Collapse = ({
  isExtended,
  children,
  itemKey,
  className,
}: CollapseProps) => {
  return (
    <AnimatePresence>
      {isExtended && (
        <motion.div
          key={itemKey}
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
