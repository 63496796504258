import React from "react"

import { useTranslation } from "react-i18next"

import NotificationList from "../../components/advanced/NotificationList"
import NotificationsTutorialOverlay from "../../components/Mobile/NotificationsTutorialOverlay"
import SafeViewArea from "../../components/Mobile/SafeViewArea"
import { TopNav } from "../../components/Mobile/TopNav"

import "./Notifications.sass"

const Notifications = () => {
  const { t } = useTranslation()

  return (
    <SafeViewArea className="NotificationsMobile">
      <TopNav
        title={t("desktop.settings.profile.notifications.notifications")}
        titleCenter={true}
      />
      <div className="body">
        <NotificationList
          variant="mobile"
          fullWidth={true}
          containerHeight="calc(100vh - 3.5rem - 2.875rem)"
          limit={25}
          onlyShowUnread
        />
        <NotificationsTutorialOverlay />
      </div>
    </SafeViewArea>
  )
}

export default Notifications
