import React from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { useBookContext } from "../../contexts/Mobile/BookContext"
import { nameComparator, shortUserTimeFormat } from "../../utils"
import Loader from "../basic/Loader"
import { CategoryRow } from "./CategoryRow"
import { TopNav } from "./TopNav"

import { BuildingIds } from "../../redux/buildings/types"
import { formatFloorLabel } from "../../redux/floors/utils"
import { useAppSelector } from "../../redux/reducers"
import { RoomResponse } from "../../redux/rooms/types"
import { dateRangeOverlaps } from "../../redux/timeslots/utils"

import PersonSVG from "../../assets/images/icons/Person.svg"

import "./RoomPicker.sass"

function getAmenity(no: number) {
  const amenities = [
    "tv",
    "video_conferencing",
    "phone",
    "computer",
    "whiteboard",
    "projector",
  ]

  return amenities[no]
}

const isOccupied = (
  room: RoomResponse,
  from: Dayjs,
  to: Dayjs,
  excludeId?: string,
) => {
  const roomEvents = room.events!.filter(
    (evn) =>
      dateRangeOverlaps(
        from.toDate(),
        to.toDate(),
        new Date(evn.start),
        new Date(evn.end),
      ) &&
      (!excludeId || evn.id !== excludeId),
  )

  return roomEvents.length > 0 && roomEvents[0]
}

type FloorRowProps = {
  floorRooms: FloorRooms
  showOccupancy: boolean
  from: Dayjs
  to: Dayjs
  excludeResId?: string
  onPick: (room: RoomResponse | null) => void
}

const FloorRow: React.FC<FloorRowProps> = ({
  floorRooms,
  showOccupancy,
  from,
  to,
  excludeResId,
  onPick,
}) => {
  const { t } = useTranslation()

  return (
    <CategoryRow
      name={formatFloorLabel(t("mobile.book.floor"), floorRooms.name)}
    >
      {floorRooms.rooms.sort(nameComparator).map((room) => {
        const currentEvent = isOccupied(room, from, to, excludeResId)
        const isFull = !!currentEvent
        const isReadOnly = room.key.indexOf("http") > -1
        const isDesynchronized =
          room.first_failed_sync && room.first_failed_sync !== null

        const isDisabled = isFull || isDesynchronized || isReadOnly

        const itemName = classNames({
          "room-item": true,
          disabled: isDisabled,
        })

        return (
          <div
            className={itemName}
            key={room.key}
            onClick={() => !isDisabled && onPick(room)}
          >
            <div className="room-info">
              <div className="name">{room.name}</div>
              <div className="amenities">
                {room.amenities
                  .map((a: number) =>
                    t(`mobile.amenities.${getAmenity(a)}` as ParseKeys),
                  )
                  .join(", ")}
              </div>
              {isFull && (
                <div className="occupied-until">
                  {t("mobile.floor.booked")}:{" "}
                  {currentEvent &&
                    dayjs(currentEvent.start).format(
                      shortUserTimeFormat(),
                    )}{" "}
                  -{" "}
                  {currentEvent &&
                    dayjs(currentEvent.end).format(shortUserTimeFormat())}
                </div>
              )}
            </div>
            {(showOccupancy || isDisabled) && (
              <div className="occupancy">
                {(isDesynchronized || isFull || room.capacity > 0) && (
                  <span>
                    {isDesynchronized ? (
                      t("mobile.general.error")
                    ) : isReadOnly ? (
                      "iCal"
                    ) : isFull ? (
                      t("mobile.general.full")
                    ) : (
                      <>
                        {room.capacity} <PersonSVG />
                      </>
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
        )
      })}
    </CategoryRow>
  )
}

type FloorRooms = {
  name: string
  rooms: Array<RoomResponse>
}

type RoomPickerProps = {
  showOccupancy?: boolean
}

export const RoomPicker = ({ showOccupancy = true }: RoomPickerProps) => {
  const { date, building, timeslot, id, onRoomPick } = useBookContext()

  const { t } = useTranslation()

  const { isLoaded, entries: rooms } = useAppSelector((state) => state.rooms)

  const filteredRooms = rooms.filter((room) => {
    if (building?.id === BuildingIds.UNKNOWN) {
      return room.building === null
    }
    return room.building && room.building.id === building?.id
  })

  const floorsRooms: Array<FloorRooms> = []
  if (filteredRooms.length > 0) {
    const noLocationRooms = []

    for (let room of filteredRooms) {
      const floor = room.floor
      if (floor) {
        const floorRooms = floorsRooms.find(
          (fr: FloorRooms) => fr.name === floor.name,
        )

        if (floorRooms) {
          floorRooms.rooms.push(room)
        } else {
          floorsRooms.push({
            name: floor.name,
            rooms: [room],
          })
        }
      } else {
        noLocationRooms.push(room)
      }
    }

    if (noLocationRooms.length > 0) {
      floorsRooms.push({
        name: t("mobile.general.no_location"),
        rooms: noLocationRooms,
      })
    }
  }

  const dateStr = date?.format("DD-MM-YYYY")
  const from = dayjs(dateStr + " " + timeslot?.from, "DD-MM-YYYY HH:mm")
  const to = dayjs(dateStr + " " + timeslot?.to, "DD-MM-YYYY HH:mm")

  return (
    <div className="RoomPicker">
      <TopNav backArrow={true} onClose={() => onRoomPick(null)} />
      <div className="picker-content">
        <h2>{t("mobile.general.pick_room")}</h2>
        {isLoaded && (
          <div>
            {floorsRooms.sort(nameComparator).map((floorRooms, index) => {
              return (
                <FloorRow
                  floorRooms={floorRooms}
                  showOccupancy={showOccupancy}
                  from={from}
                  to={to}
                  excludeResId={id}
                  onPick={onRoomPick}
                  key={index}
                />
              )
            })}
            {isLoaded && floorsRooms.length === 0 && (
              <div>{t("mobile.general.no_rooms")}</div>
            )}
          </div>
        )}
        {!isLoaded && <Loader />}
      </div>
    </div>
  )
}
