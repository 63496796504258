import { useEffect } from "react"

import { fetchPrinterSettings } from "../../../redux/printer_settings/printerSettingsSlice"
import { selectPrinterSettings } from "../../../redux/printer_settings/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Loader from "../../../components/basic/Loader"
import PrinterSettingsForm from "../../../components/Form/Visitors/PrinterSettingsForm"
import View from "../../../components/View"

import "./PrinterSettings.sass"

const PrinterSettings = () => {
  const { entries: printerSettings, isLoaded } = useAppSelector(
    selectPrinterSettings,
  )

  const actions = useActions({
    fetchPrinterSettings: () => fetchPrinterSettings(),
  })

  useEffect(() => {
    actions.fetchPrinterSettings()
  }, [actions])

  return (
    <View className="PrinterSettings">
      {!isLoaded && <Loader className="loader" />}
      {isLoaded && printerSettings && <PrinterSettingsForm />}
    </View>
  )
}

export default PrinterSettings
