import React, { PropsWithChildren } from "react"

import AppParamsProvider from "./AppParamsProvider"
import LocalizationProvider from "./LocalizationProvider"

import { ToastContainer } from "../components/Toast"

export function OpenPathProviders({ children }: PropsWithChildren<unknown>) {
  return (
    <>
      <AppParamsProvider>
        <LocalizationProvider>{children}</LocalizationProvider>
      </AppParamsProvider>
      <ToastContainer />
    </>
  )
}
