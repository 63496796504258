import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./VisitorFooter.sass"

type Props = {
  className?: string
}

const VisitorFooter = ({ className, children }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(["VisitorFooter", className])}>{children}</div>
  )
}

export default VisitorFooter
