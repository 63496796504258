import React, { PropsWithChildren, useEffect } from "react"

import { useTranslation } from "react-i18next"

import { logBreadcrumb } from "../../analytics"
import { shadeColor } from "../../utils"
import { captureMessage } from "@sentry/capacitor"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectIsAuth } from "../../redux/selectors"
import { selectTablet } from "../../redux/tablet/selectors"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import {
  fetchBranding,
  FetchProps,
  fetchQrCode,
  fetchRegistrationData,
  RegistrationDataProps,
} from "../../redux/visitor_registration/visitorRegistrationSlice"

import Loader from "../../components/basic/Loader"

const RegistrationDataProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { t } = useTranslation()

  const isAuth = useAppSelector(selectIsAuth)
  const { id, buildingId, touchlessPin, inviteId } =
    useAppSelector(selectTablet)
  const { isLoaded, data, branding } = useAppSelector(selectVisitorRegistration)

  const actions = useActions({
    appError: (message: string) => appError(message),
    fetchQrCode: (props: FetchProps) => fetchQrCode(props),
    fetchBranding: (props: FetchProps) => fetchBranding(props),
    fetchRegistrationData: (props: RegistrationDataProps) =>
      fetchRegistrationData(props),
  })

  useEffect(() => {
    if ((isAuth || touchlessPin || inviteId) && id && buildingId && !isLoaded) {
      logBreadcrumb(
        `Fetching registration data for tablet ${id} in building ${buildingId}`,
      )
      actions
        .fetchRegistrationData({
          buildingId,
          tabletId: id,
          touchless_pin: touchlessPin,
          invite_id: inviteId,
        })
        .then((response) => {
          if (fetchRegistrationData.rejected.match(response)) {
            actions.appError(
              response.error.message ?? t("tablet.general_error"),
            )
          }
        })

      actions.fetchBranding({
        buildingId,
        tabletId: id,
      })

      if (isAuth) {
        actions.fetchQrCode({
          buildingId,
          tabletId: id,
        })
      }
      captureMessage(`Tablet - Fetch registration data`)
    }
  }, [t, actions, id, buildingId, isLoaded, isAuth, touchlessPin, inviteId])

  useEffect(() => {
    if (branding?.company_color) {
      if (document) {
        document.documentElement.style.setProperty(
          "--primary-color",
          branding.company_color,
        )
        document.documentElement.style.setProperty(
          "--primary-darker-color",
          shadeColor(branding.company_color, 1.1),
        )
        document.documentElement.style.setProperty(
          "--primary-lighter-color",
          shadeColor(branding.company_color, 0.9),
        )
      }
    }
  }, [branding?.company_color])

  if (
    (isAuth || touchlessPin || inviteId) &&
    id &&
    buildingId &&
    (data === undefined || branding === undefined)
  ) {
    return <Loader size="large" />
  }

  return <>{children}</>
}

export default RegistrationDataProvider
