import React, { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { FETCH_FOR_COUNT } from "../../../constants"
import { BILLING_PATHS } from "../Billing/constants"
import { BUILDINGS_PATHS } from "../Buildings/constants"
import OverviewStep from "./OverviewStep"
import SolutionOverviewCard from "./SolutionOverviewCard"

import { useFetchPaymentsQuery } from "../../../redux/api/billing/payments"
import { useFetchBuildingsQuery } from "../../../redux/api/buildings"
import { useFetchFloorsQuery } from "../../../redux/api/floors"

import "./OrganizationOverview.sass"

const OrganizationOverview = () => {
  const { t } = useTranslation()
  const { data: payments, isFetching: isFetchingPayments } =
    useFetchPaymentsQuery()
  const {
    data: { count: buildingCount = 0 } = {},
    isFetching: isFetchingBuildings,
  } = useFetchBuildingsQuery(FETCH_FOR_COUNT)
  const { data: { count: floorCount = 0 } = {}, isFetching: isFetchingFloors } =
    useFetchFloorsQuery({ ...FETCH_FOR_COUNT, building: null })

  const steps = useMemo(
    () => [
      {
        title: t("desktop.settings.overview.organization.steps.company.title"),
        description: t(
          "desktop.settings.overview.organization.steps.company.description",
        ),
        path: BILLING_PATHS.overview.companyDetails,
        completed: !!payments?.billing_details,
        isLoading: isFetchingPayments,
      },
      {
        title: t("desktop.settings.overview.organization.steps.building.title"),
        description: t(
          "desktop.settings.overview.organization.steps.building.description",
        ),
        path: BUILDINGS_PATHS.add,
        completed: buildingCount > 0,
        isLoading: isFetchingBuildings,
      },
      {
        title: t("desktop.settings.overview.organization.steps.floor.title"),
        description: t(
          "desktop.settings.overview.organization.steps.floor.description",
        ),
        path: "/settings/floor-plans",
        completed: floorCount > 0,
        isLoading: isFetchingFloors,
      },
    ],
    [
      t,
      buildingCount,
      isFetchingBuildings,
      floorCount,
      isFetchingFloors,
      payments?.billing_details,
      isFetchingPayments,
    ],
  )

  return (
    <SolutionOverviewCard
      className="OrganizationOverview"
      description={
        <div>
          <div className="title">
            {t("desktop.settings.overview.organization.title")}
          </div>
          <div>{t("desktop.settings.overview.organization.description")}</div>
        </div>
      }
    >
      {steps.map((step) => (
        <OverviewStep
          key={step.title}
          title={step.title}
          description={step.description}
          path={step.path}
          completed={step.completed}
          isLoading={step.isLoading}
        />
      ))}
    </SolutionOverviewCard>
  )
}

export default OrganizationOverview
