import { ThunkApiConfig } from "RootType"

import { companySettingsURL, get, putJSON } from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
  getErrorMessage,
  getErrorObject,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import { Settings, SettingsRequest, SettingsResponse } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export const fetchSettings = createAsyncThunk<
  SettingsResponse,
  void,
  ThunkApiConfig
>("settings/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(companySettingsURL(), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const updateSettings = createAsyncThunk<
  SettingsResponse,
  SettingsRequest,
  ThunkApiConfig<ResponseError>
>(
  "settings/update",
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await putJSON(
      companySettingsURL(),
      { body: payload },
      access_token,
    )

    if (response.ok) {
      const json = await response.json()
      return json
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

/**
 *  Slice
 */
export type SettingsState = SliceState & {
  entry: Settings | null
}

const initialState: SettingsState = {
  entry: null,
  ...sliceInitialState,
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSettings.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchSettings.fulfilled, (state, { payload }) => {
      state.entry = payload.settings_effective

      setFetchSuccessState(state)
    })
    builder.addCase(updateSettings.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(updateSettings.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(updateSettings.fulfilled, (state, { payload }) => {
      state.entry = { ...state.entry, ...payload.settings }

      setSubmitSuccessState(state)
    })
  },
})

export const settingsReducer = settingsSlice.reducer
