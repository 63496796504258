import { useTranslation } from "react-i18next"

import { useModals } from "@mattjennings/react-modal-stack"

import Button from "../../../components/advanced/Button"
import Table, { Column } from "../../../components/basic/Table"

import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./ImportUsersErrorTable.sass"

const getErrorKeys = (errors: ImportRowErrors[]): string[] => {
  const keys: string[] = []
  errors.forEach((error) => {
    Object.keys(error).forEach((key) => {
      if (!keys.includes(key)) {
        keys.push(key)
      }
    })
  })
  return keys
}

export type ImportRowErrors = {
  id: string
  email?: string
  first_name?: string
  last_name?: string
  groups?: string
  departments?: string
  phone?: string
}

export type ImportUsersErrorTableProps = { errors: ImportRowErrors[] }
const ImportUsersErrorTable = ({ errors = [] }: ImportUsersErrorTableProps) => {
  const { t } = useTranslation()
  const { closeModal, closeAllModals } = useModals()

  const allColumns: Column<ImportRowErrors>[] = [
    {
      field: "id",
      label: t("desktop.settings.people.import_users_errors_table.columns.row"),
      cellClassName: "row-number",
      renderCell: (e: ImportRowErrors) => <span>{e.id}.</span>,
    },
    {
      field: "email",
      label: t(
        "desktop.settings.people.import_users_errors_table.columns.email",
      ),
      renderCell: (e: ImportRowErrors) => <span>{e.email}</span>,
    },
    {
      field: "first_name",
      label: t(
        "desktop.settings.people.import_users_errors_table.columns.first_name",
      ),
      renderCell: (e: ImportRowErrors) => <span>{e.first_name}</span>,
    },
    {
      field: "last_name",
      label: t(
        "desktop.settings.people.import_users_errors_table.columns.last_name",
      ),
      renderCell: (e: ImportRowErrors) => <span>{e.last_name}</span>,
    },
    {
      field: "groups",
      label: t(
        "desktop.settings.people.import_users_errors_table.columns.groups",
      ),
      renderCell: (e: ImportRowErrors) => <span>{e.groups}</span>,
    },
    {
      field: "departments",
      label: t(
        "desktop.settings.people.import_users_errors_table.columns.departments",
      ),
      renderCell: (e: ImportRowErrors) => <span>{e.departments}</span>,
    },
    {
      field: "phone",
      label: t(
        "desktop.settings.people.import_users_errors_table.columns.phone",
      ),
      renderCell: (e: ImportRowErrors) => <span>{e.phone}</span>,
    },
  ]
  const columns = allColumns.filter((column) =>
    getErrorKeys(errors).includes(column.field),
  )

  return (
    <div className="ImportUsersErrorTable">
      <div className="title">
        <h1>
          {t("desktop.settings.people.import_users_errors_table.import_failed")}
        </h1>
      </div>
      <div className="close" onClick={() => closeAllModals()}>
        <CrossSVG />
      </div>
      <div className="info">
        {t(
          "desktop.settings.people.import_users_errors_table.user_with_errors",
          { count: errors.length },
        )}
      </div>
      <div className="info">
        {t(
          "desktop.settings.people.import_users_errors_table.check_table_errors",
        )}
      </div>
      <div className="info">
        {t(
          "desktop.settings.people.import_users_errors_table.import_cancelled",
        )}
      </div>

      <div className="ImportUsersErrorTable__error-list">
        <Table
          className="ImportUsersErrorTable__table"
          rows={errors}
          columns={columns}
        />
      </div>
      <div className="actions">
        <Button onClick={() => closeModal()}>
          {t(
            "desktop.settings.people.import_users_errors_table.upload_another_file",
          )}
        </Button>
      </div>
    </div>
  )
}

export default ImportUsersErrorTable
