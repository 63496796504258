import { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { FETCH_WITH_NO_LIMIT } from "../../../../constants"
import { useQuery } from "../../../../hooks/useQuery"
import { ROOMS_PATHS } from "../constants"
import MassEditRoomForm from "./MassEditRoomForm"

import { useFetchRoomsQuery } from "../../../../redux/api/rooms"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./MassEditRoom.sass"

const MassEditRoom = () => {
  const { t } = useTranslation()

  const { data: { results: rooms = [] } = {}, isLoading } = useFetchRoomsQuery({
    limit: FETCH_WITH_NO_LIMIT,
  })

  const query = useQuery()

  const queryRooms = query.get("rooms") ?? ""

  const queryRoomKeys = useMemo(() => queryRooms.split(","), [queryRooms])

  const selectedRooms = useMemo(
    () => rooms.filter((room) => queryRoomKeys.includes(room.key)),
    [rooms, queryRoomKeys],
  )

  return (
    <View className="MassEditSettingsRooms">
      <Breadcrumbs
        depth={3}
        urls={[ROOMS_PATHS.overview, ROOMS_PATHS.rooms]}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.rooms.title"),
          t("desktop.settings.rooms.rooms.edit_rooms"),
        ]}
      />
      <Intro isConstrained>
        {t("desktop.settings.rooms.rooms.edit_rooms_intro")}
      </Intro>
      <Space size={0.75} />

      <div className="rooms-container">
        {isLoading ? (
          <Loader size="small" />
        ) : (
          <MassEditRoomForm rooms={selectedRooms} />
        )}
      </div>
    </View>
  )
}

export default MassEditRoom
