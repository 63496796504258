import { useCallback, useState } from "react"

export const useLocalStorage = (
  key: string,
  defaultValue: string,
  override?: string,
) => {
  const savedValue = window.localStorage.getItem(key)

  const [filterValue, setFilterValue] = useState(
    override ?? savedValue ?? defaultValue,
  )

  const onChange = useCallback(
    (v: string | number) => {
      const value = v.toString()
      setFilterValue(value)
      window.localStorage.setItem(key, value)
    },
    [key],
  )

  return {
    value: filterValue,
    onChange,
  }
}
