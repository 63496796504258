const INTEGRATIONS_ROOT_PATH = "/settings/integrations"

export const INTEGRATIONS_PATHS = {
  root: INTEGRATIONS_ROOT_PATH,
  scim: `${INTEGRATIONS_ROOT_PATH}/scim`,
  apiKeys: `${INTEGRATIONS_ROOT_PATH}/api-keys`,
  newApiKey: `${INTEGRATIONS_ROOT_PATH}/api-keys/new`,
  calendars: `${INTEGRATIONS_ROOT_PATH}/calendars`,
  exchange: `${INTEGRATIONS_ROOT_PATH}/calendars/exchange`,
  icalendar: `${INTEGRATIONS_ROOT_PATH}/calendars/icalendar`,
  chatBots: `${INTEGRATIONS_ROOT_PATH}/chat-bots`,
  presence: `${INTEGRATIONS_ROOT_PATH}/presence`,
}
