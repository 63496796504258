const USER_DIRECTORY_ROOT_PATH = "/settings/people"
const USER_DIRECTORY_OVERVIEW_PATHNAME = `${USER_DIRECTORY_ROOT_PATH}/overview`
const USER_DIRECTORY_ADD_PATHNAME = `${USER_DIRECTORY_ROOT_PATH}/add`
const USER_DIRECTORY_EDIT_PATHNAME = `${USER_DIRECTORY_ROOT_PATH}/edit/:user_email`

export const USER_DIRECTORY_PATHS = {
  root: USER_DIRECTORY_ROOT_PATH,
  overview: USER_DIRECTORY_OVERVIEW_PATHNAME,
  add: USER_DIRECTORY_ADD_PATHNAME,
  edit: USER_DIRECTORY_EDIT_PATHNAME,
} as const
