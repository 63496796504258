import dayjs from "dayjs"

import "dayjs/locale/en"
import "dayjs/locale/es"
import "dayjs/locale/fr"
import "dayjs/locale/nl"
import "dayjs/locale/pl"
import "dayjs/locale/sl"
import customParseFormat from "dayjs/plugin/customParseFormat"
import duration from "dayjs/plugin/duration"
import isToday from "dayjs/plugin/isToday"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import weekday from "dayjs/plugin/weekday"

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(isToday)
dayjs.extend(duration)

/**
 * @param {string} tz
 * @returns {string}
 * @description
 * This function maps the timezone to the correct format since dayjs's guess() returns obsolete timezones for some regions.
 * JIRA: https://futurevisions.atlassian.net/browse/JOAN-6953
 * @example
 * const tz = "Asia/Calcutta"
 * const mappedTz = mapTz(tz)
 * console.log(mappedTz) // Asia/Kolkata
 * @returns {string}
 **/
const mapTz = (tz: string): string => {
  switch (tz) {
    case "Asia/Calcutta":
      return "Asia/Kolkata"
    default:
      return tz
  }
}

export const timeZone = mapTz(dayjs.tz.guess())
