import { t } from "i18next"

export const CUSTOM_CONTENT_ROOT_PATH = "/settings/rooms/custom-content"

export const CUSTOM_CONTENT_PATHS = {
  root: CUSTOM_CONTENT_ROOT_PATH,
  add: {
    root: `${CUSTOM_CONTENT_ROOT_PATH}/add`,
    contentTypes: `${CUSTOM_CONTENT_ROOT_PATH}/add/content-types`,
    text: `${CUSTOM_CONTENT_ROOT_PATH}/add/content-types/text`,
    gallery: `${CUSTOM_CONTENT_ROOT_PATH}/add/content-types/gallery`,
    webpage: `${CUSTOM_CONTENT_ROOT_PATH}/add/content-types/webpage`,
    roomStatus: `${CUSTOM_CONTENT_ROOT_PATH}/add/content-types/room-status`,
    devices: `${CUSTOM_CONTENT_ROOT_PATH}/add/devices`,
  },
  edit: {
    root: (id: string) => `${CUSTOM_CONTENT_ROOT_PATH}/${id}`,
    contentTypes: (id: string) =>
      `${CUSTOM_CONTENT_ROOT_PATH}/${id}/content-types`,
    text: (id: string) =>
      `${CUSTOM_CONTENT_ROOT_PATH}/${id}/content-types/text`,
    gallery: (id: string) =>
      `${CUSTOM_CONTENT_ROOT_PATH}/${id}/content-types/gallery`,
    webpage: (id: string) =>
      `${CUSTOM_CONTENT_ROOT_PATH}/${id}/content-types/webpage`,
    roomStatus: (id: string) =>
      `${CUSTOM_CONTENT_ROOT_PATH}/${id}/content-types/room-status`,
    devices: (id: string) => `${CUSTOM_CONTENT_ROOT_PATH}/${id}/devices`,
  },
} as const

export const LAYOUT_GRID = {
  FULL: "full",
  HORIZONTAL_HALF: "horizontal_half",
  HORIZONTAL_HALF_MAIN_TOP: "horizontal_half_main_top",
  HORIZONTAL_HALF_MAIN_BOTTOM: "horizontal_half_main_bottom",
  VERTICAL_HALF: "vertical_half",
  VERTICAL_HALF_MAIN_LEFT: "vertical_half_main_left",
  VERTICAL_HALF_MAIN_RIGHT: "vertical_half_main_right",
  QUARTER: "quarter",
} as const

export const LAYOUT_GRID_TYPES = {
  [LAYOUT_GRID.FULL]: 0,
  [LAYOUT_GRID.HORIZONTAL_HALF]: 1,
  [LAYOUT_GRID.HORIZONTAL_HALF_MAIN_BOTTOM]: 2,
  [LAYOUT_GRID.HORIZONTAL_HALF_MAIN_TOP]: 3,
  [LAYOUT_GRID.VERTICAL_HALF]: 4,
  [LAYOUT_GRID.VERTICAL_HALF_MAIN_LEFT]: 5,
  [LAYOUT_GRID.VERTICAL_HALF_MAIN_RIGHT]: 6,
  [LAYOUT_GRID.QUARTER]: 7,
} as const

export const CUSTOM_CONTENT_STATUS = {
  disabled: 0,
  enabled: 1,
} as const

export const SUPPORTED_TYPES = [...Object.values(LAYOUT_GRID_TYPES)] as const

export const SCHEDULE_TYPES = {
  ALWAYS_SHOW: "always_show",
  SHOW_AT_SPECIFIC_TIME: "show_at_specific_time",
}

export const getScheduleOptions = () => [
  {
    value: SCHEDULE_TYPES.ALWAYS_SHOW,
    label: t(
      "desktop.settings.rooms.custom_content.forms.general.schedule_options.always_show",
    ),
  },
  {
    value: SCHEDULE_TYPES.SHOW_AT_SPECIFIC_TIME,
    label: t(
      "desktop.settings.rooms.custom_content.forms.general.schedule_options.show_at_specific_time",
    ),
  },
]

export const REPEATS_WEEKLY_SUFFIX = "BYDAY="

export const SCHEDULE_REPEATS_TYPES = {
  NEVER_REPEATS: "NEVER",
  REPEATS_DAILY: "FREQ=DAILY",
  REPEATS_WEEKLY: `FREQ=DAILY;${REPEATS_WEEKLY_SUFFIX}`,
}

export const getScheduleRepeatsOptions = () => [
  {
    value: SCHEDULE_REPEATS_TYPES.NEVER_REPEATS,
    label: t(
      "desktop.settings.rooms.custom_content.forms.general.schedule_repeats_options.never_repeats",
    ),
  },
  {
    value: SCHEDULE_REPEATS_TYPES.REPEATS_DAILY,
    label: t(
      "desktop.settings.rooms.custom_content.forms.general.schedule_repeats_options.repeats_daily",
    ),
  },
  {
    value: SCHEDULE_REPEATS_TYPES.REPEATS_WEEKLY,
    label: t(
      "desktop.settings.rooms.custom_content.forms.general.schedule_repeats_options.repeats_weekly",
    ),
  },
]

export const CUSTOM_CONTENT_TYPES = {
  gallery: 0,
  text: 1,
  webpage: 3,
  roomStatus: 4,
} as const

export const ROTATION_INTERVALS = {
  seconds: "S",
  minutes: "M",
  hours: "H",
} as const

export const getRotationIntervalOptions = () => [
  { value: ROTATION_INTERVALS.seconds, label: t("general.time_units.seconds") },
  { value: ROTATION_INTERVALS.minutes, label: t("general.time_units.minutes") },
  { value: ROTATION_INTERVALS.hours, label: t("general.time_units.hours") },
]

export const getDaysOfWeekOptions = () => [
  {
    label: t("general.days_of_week.mon"),
    value: "MO",
  },
  {
    label: t("general.days_of_week.tue"),
    value: "TU",
  },
  {
    label: t("general.days_of_week.wed"),
    value: "WE",
  },
  {
    label: t("general.days_of_week.thu"),
    value: "TH",
  },
  {
    label: t("general.days_of_week.fri"),
    value: "FR",
  },
  {
    label: t("general.days_of_week.sat"),
    value: "SA",
  },
  {
    label: t("general.days_of_week.sun"),
    value: "SU",
  },
]

export const DAYS_REG_EXP = new RegExp(`${REPEATS_WEEKLY_SUFFIX}([A-Z,]+)`)
