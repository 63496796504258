import { PropsWithChildren, useEffect } from "react"

import { ConnectedRouter as Router } from "connected-react-router"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Redirect, Switch as RouteSwitch } from "react-router-dom"

import { FEATURE_FLAGS, HOME_PATHS } from "./constants"
import { BookContextProvider } from "./contexts/Mobile/BookContext"
import SolutionsGuard from "./guards/SolutionsGuard"
import { useCheckForFeatureFlag } from "./hooks/useCheckForFeatureFlag"
import { useLocalStorage } from "./hooks/useLocalStorage"
import { DEFAULT_LANGUAGE } from "./i18n"
import { MobileProviders } from "./providers/Mobile/MobileProviders"
import QRScannerProvider from "./providers/Mobile/Native/QRScannerProvider"
import { OpenPathProviders } from "./providers/OpenPathProviders"
import { Providers } from "./providers/Providers"
import { VisitorProviders } from "./providers/Tablet/VisitorProviders"
import {
  IE11Disclaimer,
  UserConsentDisclaimer,
} from "./screens/GuardDisclaimer"
import HealthScreening, {
  ScreeningCompleted,
  ScreeningFailed,
} from "./screens/HealthScreening"
import Manage from "./screens/Manage/Manage"
import Auth from "./screens/Mobile/Auth"
import Book from "./screens/Mobile/Book"
import AdhocDeskCheckin from "./screens/Mobile/Book/Desks/AdhocDeskCheckin"
import AdhocDeskConfirm from "./screens/Mobile/Book/Desks/AdhocDeskConfirm"
import OccupiedDesk from "./screens/Mobile/Book/Desks/OccupiedDesk"
import WrongDesk from "./screens/Mobile/Book/Desks/WrongDesk"
import Floor from "./screens/Mobile/Floor"
import Home from "./screens/Mobile/Home"
import { default as BuildingSelectMobile } from "./screens/Mobile/Home/BuildingSelect"
import Notifications from "./screens/Mobile/Notifications"
import Presence from "./screens/Mobile/Presence"
import Profile from "./screens/Mobile/Profile"
import QRScanner from "./screens/Mobile/QRScanner"
import NotFound from "./screens/NotFound"
import Onboarding from "./screens/Onboarding"
import { ONBOARDING_PATHS } from "./screens/Onboarding/constants"
import Settings from "./screens/Settings/Settings"
import ShareableFloorPlan from "./screens/Shareable/ShareableFloorPlan"
import VisitorAdHoc, { MeetingRequested } from "./screens/VisitorAdHoc"
import VisitorCheckIn, { CheckedIn } from "./screens/VisitorCheckIn"
import VisitorLogin from "./screens/VisitorLogin"
import Visitors from "./screens/Visitors/Visitors"
import TagManager from "./TagManager"
import { ModalStack } from "@mattjennings/react-modal-stack"
import throttle from "lodash.throttle"
import "react-toastify/dist/ReactToastify.css"
import "react-tooltip/dist/react-tooltip.css"

import { useFetchBuildingsQuery } from "./redux/api/buildings"
import { changeLanguage } from "./redux/app/appSlice"
import { selectIsMobile } from "./redux/app/selectors"
import { FEATURE } from "./redux/payment/paymentSlice"
import { historyObject, useAppSelector } from "./redux/reducers"
import { selectUser } from "./redux/user/selectors"
import { useActions } from "./redux/utils"

import Footer from "./components/Footer"
import Header from "./components/Header"
import MobileWrapper from "./components/Mobile/MobileWrapper"
import ProtectedRoute from "./components/ProtectedRoute"
import Route from "./components/Route"

import "./App.sass"

function FeatureGuards({ children }: PropsWithChildren<unknown>) {
  const isMobile = useAppSelector(selectIsMobile)
  const isTablet = useAppSelector((state) => state.app.isTablet)
  const isDesktop = !isMobile && !isTablet

  /* JOAN-6168
	Footer does not stick to the bottom when resizing the window
	A window listener is implemented to update the vh variable in the CSS as resizing changes the innerHeight
	This is only functional on Desktop, tablet and mobile are skipped as they do not have this issue
	*/
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight / 100
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    const debouncedHandleResize = throttle(handleResize, 100)

    if (!isDesktop) {
      handleResize()
      return
    }

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [isDesktop])

  const isIE11 =
    !!(window as any).MSInputMethodContext && !!(document as any).documentMode

  if (isMobile) {
    return MobileRoutes()
  } else {
    if (isIE11) {
      return <IE11Disclaimer />
    } else {
      return <UserConsentDisclaimer>{children}</UserConsentDisclaimer>
    }
  }
}

const MobileRoutes = () => {
  const { entry: user } = useAppSelector(selectUser)
  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  return (
    <MobileWrapper>
      <MobileProviders>
        <ModalStack>
          <RouteSwitch>
            <Route path="/auth" component={Auth} />
            <Route path="/home" component={Home} />
            <Route path="/notifications" component={Notifications} />
            <Route
              path="/building-selection"
              component={BuildingSelectMobile}
            />
            <Route
              path="/book/:type"
              component={() => (
                <BookContextProvider>
                  <RouteSwitch>
                    <Route
                      exact
                      path="/book/desk/confirm"
                      component={AdhocDeskConfirm}
                    />
                    <Route
                      exact
                      path="/book/desk/checkin"
                      component={AdhocDeskCheckin}
                    />
                    <Route
                      exact
                      path="/book/desk/occupied"
                      component={OccupiedDesk}
                    />
                    <Route
                      exact
                      path="/book/desk/wrong"
                      component={WrongDesk}
                    />
                    <Route path="/" component={Book} />
                  </RouteSwitch>
                </BookContextProvider>
              )}
            />
            <Route path="/floor" component={Floor} feature={FEATURE.desk} />
            <Route
              path="/presence"
              component={Presence}
              feature={FEATURE.desk}
            />
            <Route path="/profile" component={Profile} />
            <Route
              path="/screening/employee/:building_id"
              exact
              component={HealthScreening}
            />
            <Route exact path={HOME_PATHS.scanQrCode}>
              <QRScannerProvider>
                <QRScanner />
              </QRScannerProvider>
            </Route>

            <Route>
              <Redirect
                to={
                  !user?.building && buildings.length > 1
                    ? HOME_PATHS.buildingSelect
                    : HOME_PATHS.mobile
                }
              />
            </Route>
          </RouteSwitch>
        </ModalStack>
      </MobileProviders>
    </MobileWrapper>
  )
}
const App = () => {
  return (
    <div className="App Desktop">
      <Router history={historyObject}>
        <ModalStack>
          <Providers>
            <FeatureGuards>
              <Header />
              <RouteSwitch>
                <Route path="/" exact>
                  <Redirect to={`${HOME_PATHS.desktop}?reroute=true`} />
                </Route>

                <Route path={ONBOARDING_PATHS.root} component={Onboarding} />
                <ProtectedRoute path={HOME_PATHS.desktop}>
                  <SolutionsGuard>
                    <Manage />
                  </SolutionsGuard>
                </ProtectedRoute>
                <ProtectedRoute
                  path={HOME_PATHS.settings}
                  component={Settings}
                />
                <Route
                  path="/screening/employee/:building_id"
                  exact
                  component={HealthScreening}
                />
                <Route
                  path="/screening/employee/:building_id/completed"
                  exact
                  component={ScreeningCompleted}
                />
                <Route
                  path="/screening/employee/:building_id/failed"
                  exact
                  component={ScreeningFailed}
                />

                <Route path="/(loading|auth/login|auth/start|home|book|floor|presence|profile)">
                  <Redirect to={HOME_PATHS.desktop} />
                </Route>
                <Route component={NotFound} />
              </RouteSwitch>
              <Footer />
            </FeatureGuards>
            <TagManager />
          </Providers>
        </ModalStack>
      </Router>
    </div>
  )
}

const VisitorManagementApp = () => {
  return (
    <div className="App">
      <Router history={historyObject}>
        <VisitorProviders>
          <RouteSwitch>
            <Route path={HOME_PATHS.visitor} component={Visitors} />
            <Route>
              <Redirect to={HOME_PATHS.visitor} />
            </Route>
          </RouteSwitch>
        </VisitorProviders>
      </Router>
    </div>
  )
}

const OpenPathApp = () => {
  return (
    <div className="App">
      <Router history={historyObject}>
        <OpenPathProviders>
          <RouteSwitch>
            <Route
              path="/visitor-login/:company_id"
              exact
              component={VisitorLogin}
            />
            <Route
              path="/visitor-login/:company_id/new"
              exact
              component={VisitorAdHoc}
            />
            <Route
              path="/visitor-login/:company_id/requested"
              exact
              component={MeetingRequested}
            />
            <Route
              path="/visitor-login/:company_id/checked_in"
              exact
              component={CheckedIn}
            />
            <Route
              path="/visitor-login/:company_id/:appointment_id/:pin"
              exact
              component={VisitorCheckIn}
            />
            <Route
              path="/visitor-login/:company_id/:appointment_id"
              exact
              component={VisitorCheckIn}
            />

            <Route path="/" exact component={VisitorLogin} />
            <Route
              path="/device/shareable/:shareableId"
              exact
              component={ShareableFloorPlan}
            />

            <Route component={NotFound} />
          </RouteSwitch>
          <TagManager />
        </OpenPathProviders>
      </Router>
    </div>
  )
}

const isOpenPath = () => {
  return (
    window.location.pathname.startsWith("/visitor-login") ||
    window.location.pathname.startsWith("/device/shareable/") ||
    (window.location.pathname.startsWith("/health-screening") &&
      window.location.pathname.indexOf("employee") === -1)
  )
}

const AppVariant = () => {
  const isMobile = useAppSelector(selectIsMobile)
  const isTablet = useAppSelector((state) => state.app.isTablet)
  const visitorsPath = window.location.pathname.startsWith("/visitors")

  const actions = useActions({
    changeLanguage: (lang: string) => changeLanguage(lang),
  })

  const { i18n } = useTranslation()
  const isNativeTranslationsEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.NATIVE_TRANSLATIONS,
  )

  // Use the stored language if it exists, otherwise use the default language
  const { value: storedLanguage, onChange: setStoredLanguage } =
    useLocalStorage("app_language", DEFAULT_LANGUAGE)

  useEffect(() => {
    const initializeLanguage = storedLanguage || DEFAULT_LANGUAGE

    /**
     * JOAN-7606: Mobile app uses locale to determine the language displayed so we don't need to compare it with the stored language / default language
     *  */
    if (isMobile) {
      dayjs.locale(i18n.language)
      return
    }

    // Sync the language with i18n and dayjs if it's different
    if (i18n.language !== initializeLanguage) {
      i18n.changeLanguage(initializeLanguage).then(() => {
        const lang = i18n.language.includes("-")
          ? i18n.language.split("-")[0]
          : i18n.language

        actions.changeLanguage(lang)
        dayjs.locale(lang)
      })
    }

    // Ensure desktop uses DEFAULT_LANGUAGE if specific conditions are met
    if (
      !isMobile &&
      !isTablet &&
      !window.location.pathname.startsWith("/device/shareable/") &&
      !isNativeTranslationsEnabled
    ) {
      i18n.changeLanguage(DEFAULT_LANGUAGE)
      dayjs.locale(DEFAULT_LANGUAGE)
    }
  }, [
    i18n,
    storedLanguage,
    setStoredLanguage,
    actions,
    isMobile,
    isTablet,
    isNativeTranslationsEnabled,
  ])

  /**
   * Bug with Webpack 5.x and Observer. When you click the Departments
   * dropdown the error keeps popping up and disables you from using the
   * app, but is benign according to the docs.
   *
   * This problem only occurs in development mode.
   *
   * More info:
   *
   * https://stackoverflow.com/a/76163164
   */
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        )
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none")
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none")
        }
      }
    })
  }, [])

  if (isOpenPath()) {
    return <OpenPathApp />
  } else if (isTablet || visitorsPath) {
    return <VisitorManagementApp />
  } else {
    return <App />
  }
}

export default AppVariant
