import { api } from ".."
import { labsURL } from "../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import { FetchOptions, LabsRequest, LabsResponse } from "./types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
}

export const labs = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPrograms: builder.query<LabsResponse, FetchOptions | void>({
      query: (options) => ({
        url: labsURL({ ...FETCH_DEFAULTS, ...options }),
      }),
      providesTags: ["Labs"],
    }),
    enrollIntoProgram: builder.mutation<LabsResponse, LabsRequest>({
      query: (payload) => ({
        url: labsURL(),
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Labs"],
    }),
  }),
})

export const { useFetchProgramsQuery, useEnrollIntoProgramMutation } = labs
