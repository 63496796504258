import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import InviteCSVForm from "../components/Form/Visitors/InviteCSVForm"

type Props = {
  open: boolean
}

export default function CSVImportModal({ open }: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <InviteCSVForm />
    </ReactModal>
  )
}
