import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"

import { useNavigation } from "../hooks/useNavigation"
import { getLabel } from "../utils"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"

import Button from "../components/advanced/Button"
import Heading from "../components/Heading"
import { BottomNav } from "../components/Mobile/BottomNav"
import View from "../components/View"

import LockedSVG from "../assets/images/icons/lock_person.svg"

import "./NotPaid.sass"

type Props = {
  isMobile?: boolean
}

const PaymentRequired = ({ isMobile }: Props) => {
  const { t } = useTranslation()
  const { entry, isLoaded } = useAppSelector((state) => state.user)
  const className = classNames({ SmallScreen: isMobile })
  const { push } = useNavigation()

  const { appName } = useAppSelector(getAppParams)
  /**
   * Mobile flow is different then the web flow. On the mobile app
   * we just need to delete the local tokens and reload the page,
   * on the web part we need to redirect the user so that backend
   * also logs them out.
   */
  const logOut = () => {
    push("/auth/logout")
  }

  const goToSettings = () => {
    push("/settings")
  }

  if (!isLoaded) return null

  const isAdminText = entry.groups?.includes("portal_admin") && !isMobile

  const Text = isAdminText ? (
    <p>
      <Trans i18nKey="general.account.locked_admin">
        <span className={className}>
          It seems that your account does not have a subscription for Desk&Asset
          booking or Visitor Management. Please note, that this is not related
          to your Room booking solution.
        </span>{" "}
        Reach out to
        <a href={`mailto:${getLabel("links.supportEmail")}`}>
          {getLabel("links.supportEmail")}
        </a>
        for more information.
      </Trans>
    </p>
  ) : (
    <p>
      <Trans i18nKey="general.account.locked_user">
        <span className={className}>
          Your account has been temporarily locked, because of pending payment
          issues.
        </span>
        <span>
          {isMobile && (
            <>
              <br />
              <br />
            </>
          )}
        </span>
        <span>Please contact your company's {appName} administrator or </span>
        <a href={`mailto:${getLabel("links.supportEmail")}`}>
          {getLabel("links.supportEmail")}
        </a>{" "}
        for more information.
      </Trans>
    </p>
  )

  if (isMobile) {
    return (
      <View className="NotPaidNotice">
        <div className="LockImage">
          <LockedSVG />
        </div>
        <Heading>
          <strong>{t("general.account.payment_required")}</strong>
        </Heading>
        <span>{Text}</span>
        <Button variant="link" className="LogoutLink" onClick={logOut}>
          {t("general.account.back_to_login")}
        </Button>
        <BottomNav />
      </View>
    )
  }

  return (
    <View className="NotPaidNotice">
      <Heading>
        <strong>{t("general.account.payment_required")}</strong>
      </Heading>
      <span>{Text}</span>
      {isAdminText ? (
        <Button variant="link" className="SettingsLink" onClick={goToSettings}>
          {t("general.account.redirect_to_settings")}
        </Button>
      ) : (
        <Button variant="link" className="LogoutLink" onClick={logOut}>
          {t("general.account.back_to_login")}
        </Button>
      )}
    </View>
  )
}

/**
 * This screen will either show the mobile or web version of the payment required screen.
 */
export default function NotPaid({ onlyView = false }) {
  if (onlyView) {
    return (
      <View className="NotPaidMobile">
        <PaymentRequired isMobile={true} />
      </View>
    )
  }

  return (
    <View className="NotPaid">
      <PaymentRequired />
    </View>
  )
}
