import { Dayjs } from "dayjs"

import { RecurringType } from "../../types/sharedTypes"
import { PaginatedResponse } from "../types"

export enum EntryStatusType {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export type ImportErrors = {
  [fieldName: string]: string[]
}

export type RangeType = {
  from: Dayjs
  to: Dayjs
}

export type Desk = {
  id: string
  name: string
}

export type Building = {
  id: string
  name: string
}

export type Host = {
  id: string
  email: string
  first_name?: string
  last_name?: string
}

type Schedule = {
  freq: RecurringType
  until: string
}

type InviteCreatedBy = {
  email: string
  first_name?: string
  last_name?: string
}

export type InviteCSVResponse = {
  id: string
  full_name: string
  company_name?: string
  email: string
  phone?: string
  start: string
  end: string
  tz: string
  building: Building
  host: Host
  schedule?: Schedule
  guests?: number
  additional_info?: string
  send_invite_to_visitor?: boolean
  send_notification_to_host?: boolean
  errors?: ImportErrors
}

export type InviteListResponse = {
  id: string
  building: Building
  full_name: string
  host: Host
  created_by: InviteCreatedBy
  desk?: Desk
  document_signed?: boolean
  document_name?: string
  start: string
  end: string
  entry_status?: EntryStatusType
}

export type InvitesListResponse = PaginatedResponse<InviteListResponse>

export type FetchOptions = {
  start?: string
  end?: string
  building_id?: string
  search?: string
  show?: string
  limit?: number
  offset?: number
}
