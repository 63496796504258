import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "../modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { FetchOptions as InvitesFetchOptions } from "../../redux/invites/types"
import { InviteListResponse } from "../../redux/invites/types"

import InviteCancelForm from "../../components/Form/Visitors/InviteCancelForm"

type Props = {
  open: boolean
  invite: InviteListResponse
  fetchParams?: InvitesFetchOptions
}

const InviteCancelModal = ({ open, invite, fetchParams }: Props) => {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <InviteCancelForm invite={invite} fetchParams={fetchParams} />
    </ReactModal>
  )
}

export default InviteCancelModal
