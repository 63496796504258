import { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { selectVisitorEmailSettings } from "../../../redux/visitor_email_settings/selectors"
import { VisitorEmailType } from "../../../redux/visitor_email_settings/types"
import { fetchVisitorEmailSetting } from "../../../redux/visitor_email_settings/visitorEmailSettingsSlice"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import EmailSettingsForm from "../../../components/Form/Visitors/EmailSettingsForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./EmailSettings.sass"

type ParamsType = {
  building_id: string
  type: VisitorEmailType
}

const EmailSettings = () => {
  const { t } = useTranslation()
  const { building_id, type } = useParams<ParamsType>()
  const ref = useRef<HTMLDivElement>(null)

  const { entry: visitorEmailSetting, isLoading } = useAppSelector(
    selectVisitorEmailSettings,
  )

  const actions = useActions({
    fetchVisitorEmailSetting: (building_id, type) =>
      fetchVisitorEmailSetting({ building_id, type }),
  })

  useEffect(() => {
    if (building_id && type) {
      actions.fetchVisitorEmailSetting(building_id, type)
    }
    ref.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    })
  }, [actions, building_id, type])

  return (
    <div ref={ref}>
      <View className="EmailSettings">
        <Breadcrumbs
          depth={3}
          values={[
            t("desktop.settings.visitors.title"),
            t("desktop.settings.visitors.visitor_settings.title"),
            t("desktop.settings.visitors.email_settings.edit_email_title", {
              emailType: t(
                `desktop.settings.visitors.email_settings.email_type.${type}`,
              ),
            }),
          ]}
        />

        <Intro>
          {t(
            "desktop.settings.visitors.email_settings.configure_email_you_send_to_your_supervisors",
            {
              emailType: t(
                `desktop.settings.visitors.email_settings.email_type.${type}`,
              ),
            },
          )}
        </Intro>

        <Space size={0.75} />

        {isLoading && (
          <div className="loading">
            <Loader />
          </div>
        )}

        {!isLoading && (
          <EmailSettingsForm
            buildingId={building_id}
            type={type}
            emailSettings={visitorEmailSetting}
          />
        )}
      </View>
    </div>
  )
}

export default EmailSettings
