import { PrinterModelType } from "../devices/types"

export enum TabletConnectionType {
  PAIRED = "PAIRED",
  UNPAIRED = "UNPAIRED",
}

type Building = {
  id: string
  name: string
}

type ConnectedDevice = {
  id: string
  name: string
}

export type AuthenticateTabletRequest = {
  device_id: string
  pin: string
}

export type RegisterTabletRequest = {
  device_id: string
  model?: string
  version?: string
}

export type RegisterTabletResponse = {
  id: string
  device_id: string
  pin: string
  model: string
  version: string
  connection_status: TabletConnectionType
  building?: Building
}

export type TabletResponse = {
  id: string
  name?: string
  model: string
  version: string
  building_id?: string
  battery_status: number
  battery_charging?: boolean
  status_sent_at?: string
  connected_device?: ConnectedDevice
}

export type PrinterResponse = {
  id: string
  name?: string
  model: PrinterModelType
  ip_address: string
  building?: Building
  connected_device?: ConnectedDevice
}
