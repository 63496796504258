import dayjs from "dayjs"

import { ISODate } from "../../../../../types/sharedTypes"
import { userTimeFormat } from "../../../../../utils"
import { getTzLocation } from "../utils"

import ClockSVG from "../../../../../assets/images/icons/Clock.svg"

type Props = {
  start: ISODate | null
  end: ISODate | null
  tz: string | null
}

const Time = ({ start, end, tz }: Props) => {
  if (!start && !end) return null

  const tzLocation = getTzLocation(tz)

  return (
    <div className="description-row">
      <div className="description-row-icon">
        <ClockSVG />
      </div>

      <div className="description-row-text">
        {start && dayjs(start).format(userTimeFormat())}

        {start && end && " - "}

        {end && dayjs(end).format(userTimeFormat())}

        {tzLocation && ` · ${tzLocation.continent}/${tzLocation.region}`}
      </div>
    </div>
  )
}

export default Time
