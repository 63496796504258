import React from "react"

import { Redirect, Route, Switch as RouteSwitch } from "react-router-dom"

import QRScannerProvider from "../../providers/Tablet/Native/QRScannerProvider"
import RegistrationFlowProvider from "../../providers/Tablet/RegistrationFlowProvider"
import Checkout from "./Checkout"
import { VISITOR_BASE_URL, VISITOR_ONBOARDING_BASE_URL } from "./constants"
import Document from "./Document"
import DocumentInfo from "./DocumentInfo"
import Fields from "./Fields"
import Finish from "./Finish"
import Health from "./Health"
import HealthInfo from "./HealthInfo"
import Name from "./Name"
import AssignLocation from "./Onboarding/AssignLocation"
import Confirmation from "./Onboarding/Confirmation"
import Initial from "./Onboarding/Initial"
import Pin from "./Onboarding/Pin"
import Print from "./Print"
import Scan from "./Scan"
import Start from "./Start"

const Visitors = () => {
  return (
    <RouteSwitch>
      <Route path={VISITOR_ONBOARDING_BASE_URL} component={VisitorOnboarding} />
      <Route path={VISITOR_BASE_URL} component={VisitorRegistration} />
    </RouteSwitch>
  )
}

export default Visitors

const VisitorOnboarding = () => {
  return (
    <RouteSwitch>
      <Route exact path={VISITOR_ONBOARDING_BASE_URL} component={Initial} />
      <Route
        exact
        path={`${VISITOR_ONBOARDING_BASE_URL}/pin/:pin`}
        component={Pin}
      />
      <Route
        exact
        path={`${VISITOR_ONBOARDING_BASE_URL}/assign_location`}
        component={AssignLocation}
      />
      <Route
        exact
        path={`${VISITOR_ONBOARDING_BASE_URL}/confirmation`}
        component={Confirmation}
      />
    </RouteSwitch>
  )
}

const VisitorRegistration = () => {
  return (
    <QRScannerProvider>
      <RegistrationFlowProvider>
        <RouteSwitch>
          <Route exact path={`${VISITOR_BASE_URL}/start`} component={Start} />
          <Route exact path={`${VISITOR_BASE_URL}/name`} component={Name} />
          <Route exact path={`${VISITOR_BASE_URL}/scan`} component={Scan} />
          <Route
            exact
            path={`${VISITOR_BASE_URL}/checkout`}
            component={Checkout}
          />
          <Route exact path={`${VISITOR_BASE_URL}/fields`} component={Fields} />
          <Route
            exact
            path={`${VISITOR_BASE_URL}/health_info`}
            component={HealthInfo}
          />
          <Route exact path={`${VISITOR_BASE_URL}/health`} component={Health} />
          <Route
            exact
            path={`${VISITOR_BASE_URL}/document_info`}
            component={DocumentInfo}
          />
          <Route
            exact
            path={`${VISITOR_BASE_URL}/document`}
            component={Document}
          />
          <Route
            exact
            path={`${VISITOR_BASE_URL}/finish/:type`}
            component={Finish}
          />
          <Route exact path={`${VISITOR_BASE_URL}/print`} component={Print} />

          <Route exact path={VISITOR_BASE_URL}>
            <Redirect to={`${VISITOR_BASE_URL}/start`} />
          </Route>
        </RouteSwitch>
      </RegistrationFlowProvider>
    </QRScannerProvider>
  )
}
