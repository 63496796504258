import React, { useCallback } from "react"

import cn from "classnames"

import { SUPPORTED_TYPES } from "./constants"
import { useCustomContentContext } from "./CustomContentContext"
import { ensureSections, findIconLayoutType, getSectionCount } from "./utils"

import { LayoutGridType } from "../../../../redux/api/customContent/types"

import "./ContentTypeSelection.sass"

const ContentTypeSelection = () => {
  const { content, onChangeContent } = useCustomContentContext()

  const { type, sections } = content

  const handleSelectContentType = useCallback(
    (type: LayoutGridType) => {
      const numberOfSections = getSectionCount(type)

      const updatedSections = ensureSections(sections, numberOfSections)

      onChangeContent({
        type,
        sections: updatedSections,
      })
    },
    [onChangeContent, sections],
  )

  return (
    <div className="ContentTypeSelection">
      {SUPPORTED_TYPES.map((t) => (
        <div
          key={t}
          onClick={() => handleSelectContentType(t)}
          className={cn("ContentType", {
            Selected: t === type,
          })}
        >
          {findIconLayoutType(t)}
        </div>
      ))}
    </div>
  )
}

export default ContentTypeSelection
