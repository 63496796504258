import React, { useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { userTimeFormat } from "../../../../utils"
import "../Time.sass"

import { bookAssetSetDefaultTime } from "../../../../redux/book_asset/bookAssetSlice"
import { selectBookAsset } from "../../../../redux/book_asset/selectors"
import { useAppSelector } from "../../../../redux/reducers"
import { useActions } from "../../../../redux/utils"

import Button from "../../../../components/advanced/Button"
import { CategoryRow } from "../../../../components/Mobile/CategoryRow"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

type TimeProps = {
  onPicker: () => void
  onConfirm: () => void
  editing: boolean
  handleClose: () => void
}

const Time: React.FC<TimeProps> = ({
  onPicker,
  onConfirm,
  handleClose,
  editing,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { bookAsset } = useAppSelector(selectBookAsset)

  const actions = useActions({
    bookAssetSetDefaultTime: () => bookAssetSetDefaultTime(),
  })

  const date = bookAsset.start ? dayjs(bookAsset.start) : dayjs()
  const today = dayjs()
  const isToday = today.isSame(date, "day")
  const isTomorrow = today.add(1, "day").isSame(date, "day")
  const formattedDay = dayjs(date).format("dddd DD MMM")

  const day = isToday
    ? t("mobile.general.today")
    : isTomorrow
      ? t("mobile.general.tomorrow")
      : formattedDay

  const start = bookAsset.start
    ? dayjs(bookAsset.start).format(userTimeFormat())
    : ""
  const end = bookAsset.end ? dayjs(bookAsset.end).format(userTimeFormat()) : ""

  const buildingName: string | undefined = bookAsset.building?.name
  const assetName: string | undefined = bookAsset.asset?.name

  useEffect(() => {
    if (!bookAsset.start) {
      actions.bookAssetSetDefaultTime()
    }
  }, [actions, bookAsset.start])

  return (
    <SafeViewArea className="Time">
      <TopNav
        backArrow={true}
        onGoBack={history.goBack}
        onClose={handleClose}
      />
      <div className="body">
        <div className="info">
          {assetName
            ? t(`mobile.book.like_to_book_known_asset`, { assetName })
            : t("mobile.book.like_to_book_asset")}{" "}
          {t("mobile.book.for")} <span onClick={onPicker}>{day}</span>{" "}
          {t("mobile.book.from")}
          <br />
          <span onClick={onPicker}>{`${start} - ${end}`}</span>
          {buildingName && (
            <>
              <br />
              {t("mobile.book.at_building", { buildingName })}
            </>
          )}
        </div>
        <div className="instructions">{t("mobile.book.tap_value")}</div>
        <CategoryRow name={t("mobile.general.next_step")}>
          <div className="next-button">
            <Button variant="mobile-action" onClick={onConfirm}>
              {editing
                ? t("mobile.book.overview")
                : t("mobile.book.pick_asset")}
            </Button>
          </div>
        </CategoryRow>
      </div>
    </SafeViewArea>
  )
}

export default Time
