import { sliceInitialState } from "../reduxUtils"
import { SliceState } from "../types"
import { createSlice } from "@reduxjs/toolkit"

export interface AutoScanRoomsState extends SliceState {
  syncInProgress: boolean
}

const initialState: AutoScanRoomsState = {
  syncInProgress: false,
  ...sliceInitialState,
}

const autoScanRoomsSlice = createSlice({
  name: "autoScanRooms",
  initialState,
  reducers: {
    startScan: (state) => {
      state.syncInProgress = true
    },
    endScan: (state) => {
      state.syncInProgress = false
    },
  },
})

export const autoScanRoomsReducer = autoScanRoomsSlice.reducer
export const { startScan, endScan } = autoScanRoomsSlice.actions
