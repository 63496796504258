import { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { useToast } from "../../hooks/useToast"
import { BUILDINGS_PATHS } from "../../screens/Settings/Buildings/constants"
import { Input } from "../basic/Input"
import Field from "../Field"
import ModalDeleteForm from "./ModalDeleteForm"
import { useModals } from "@mattjennings/react-modal-stack"
import { isRejected } from "@reduxjs/toolkit"

import {
  useDestroyBuildingMutation,
  useFetchBuildingsQuery,
  useLazyFetchBuildingsQuery,
} from "../../redux/api/buildings"
import { BuildingResponse } from "../../redux/buildings/types"

import "./BuildingDeleteForm.sass"

type Props = {
  building: BuildingResponse
}

type FormValues = {
  confirmationName: string
}

const BuildingDeleteForm = ({ building }: Props) => {
  const { closeAllModals } = useModals()
  const { t } = useTranslation()
  const { infoToast, errorToast, warningToast } = useToast()

  const methods = useForm<FormValues>({
    defaultValues: {
      confirmationName: "",
    },
  })
  const {
    control,
    formState: { isSubmitting },
  } = methods

  const history = useHistory()

  const { data: { results: buildings = [] } = {} } = useFetchBuildingsQuery()

  const [fetchBuildings] = useLazyFetchBuildingsQuery()
  const [destroyBuilding] = useDestroyBuildingMutation()

  const { id, name, floors_count, desks_count } = building

  const onDeleteClick = useCallback(async () => {
    let anotherBuilding = buildings.find((bd: BuildingResponse) => bd.id !== id)

    // Prevent user from deleting last building
    if (!anotherBuilding) {
      const response = await fetchBuildings()
      if (!isRejected(response)) {
        anotherBuilding =
          response &&
          response.data?.results?.find((bd: BuildingResponse) => bd.id !== id)

        if (!anotherBuilding) {
          warningToast(
            t(
              "desktop.settings.buildings.delete_form.can_not_delete_last_building_toast",
            ),
          )
        }

        return
      }
    }

    const response = await destroyBuilding(id)

    if (isRejected(response)) {
      errorToast(response.error.message)
      return
    }

    analyticsEvent(SupportedEvents.LOCATION_DELETE, {
      id,
      total: buildings.length - 1,
    })

    infoToast(
      t("desktop.settings.buildings.delete_form.building_deleted_toast"),
    )
    closeAllModals()
    history.push(BUILDINGS_PATHS.root)
  }, [
    buildings,
    destroyBuilding,
    id,
    infoToast,
    t,
    closeAllModals,
    history,
    fetchBuildings,
    warningToast,
    errorToast,
  ])

  return (
    <FormProvider {...methods}>
      <ModalDeleteForm
        onDelete={onDeleteClick}
        title={t("desktop.settings.buildings.delete_form.title")}
        hint={
          <Trans
            i18nKey={"desktop.settings.buildings.delete_form.hint"}
            values={{
              name: name,
              floorsCount: floors_count ?? 0,
              desksCount: desks_count ?? 0,
            }}
          />
        }
        helpText={
          <Trans
            i18nKey={"desktop.settings.buildings.delete_form.help_text"}
            values={{
              name,
            }}
          />
        }
      >
        <Field
          control={control}
          name="confirmationName"
          label={t("desktop.settings.buildings.delete_form.name")}
          rules={{
            validate: (v: string) =>
              v === name
                ? undefined
                : t(
                    "desktop.settings.buildings.delete_form.incorrect_name_error",
                  ),
          }}
        >
          {(props) => <Input autoFocus disabled={isSubmitting} {...props} />}
        </Field>
      </ModalDeleteForm>
    </FormProvider>
  )
}

export default BuildingDeleteForm
