import { useCallback, useEffect, useMemo, useState } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import {
  TABLET_LOW_BATTERY_THRESHOLD,
  TABLET_STATUS_REFRESH_TIME,
} from "../../../constants"
import { useToast } from "../../../hooks/useToast"
import PairDeviceModal from "../../../modals/PairDeviceModal"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  deletePrinter,
  fetchDevices,
  repairTablet,
  unpairTablet,
} from "../../../redux/devices/devicesSlice"
import { selectDevices } from "../../../redux/devices/selectors"
import {
  ConnectedDevice,
  DeviceResponse,
  DeviceType,
} from "../../../redux/devices/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import { ConfirmationModal } from "../../../components/advanced/ConfirmationModal"
import Table from "../../../components/advanced/Table"
import { Column } from "../../../components/basic/Table"
import { Tooltip } from "../../../components/basic/Tooltip"
import Breadcrumbs from "../../../components/Breadcrumbs"
import DeviceFilter, {
  DeviceFilterValues,
} from "../../../components/Filter/DevicesFilter"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import SearchFilter from "../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../components/Filter/types"
import Intro from "../../../components/Intro"
import NoDataFound from "../../../components/NoDataFound"
import Space from "../../../components/Space"
import View from "../../../components/View"
import TabletStatus from "../../../components/Visitors/TabletStatus"

import InfoSVG from "../../../assets/images/icons/InfoOutlined.svg"

import "./Devices.sass"

const DevicesEmptyTableRows = () => {
  const { t } = useTranslation()
  const { openModal } = useModals()

  const onClick = () => openModal(PairDeviceModal, {})

  return (
    <>
      <NoDataFound>
        <InfoSVG />
        <p>{t("desktop.settings.visitors.devices.no_devices.title")}</p>
      </NoDataFound>
      <NoDataFound warning>
        {t("desktop.settings.visitors.devices.no_devices.description")}
        <Button variant="link" onClick={onClick}>
          {t("desktop.settings.visitors.devices.no_devices.description_link")}
        </Button>
      </NoDataFound>
    </>
  )
}

const Devices = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModals()
  const { errorToast, infoToast } = useToast()

  const [deviceFilter, setDeviceFilter] = useState<string>(
    FilterSpecialValues.ALL,
  )
  const [search, setSearch] = useState<string>("")
  const [selectedDevices, setSelectedDevices] = useState<DeviceResponse[]>([])

  const { entries: devices, isLoading: areDevicesLoading } =
    useAppSelector(selectDevices)

  const actions = useActions({
    fetchDevices: () => fetchDevices(),
    unpairTablet: (id: string) => unpairTablet(id),
    repairTablet: (id: string) => repairTablet(id),
    deletePrinter: (id: string) => deletePrinter(id),
  })

  useEffect(() => {
    actions.fetchDevices()
  }, [actions])

  const filteredDevices = useMemo(() => {
    if (!search && !deviceFilter) {
      return devices
    }
    let filteredDevices = devices
    if (search) {
      filteredDevices = devices.filter(
        (device) =>
          device.name &&
          device.name.toLowerCase().includes(search.toLowerCase()),
      )
    }
    if (deviceFilter && deviceFilter !== FilterSpecialValues.ALL) {
      if (deviceFilter === DeviceFilterValues.LOW_BATTERY) {
        filteredDevices = filteredDevices.filter(
          (device) =>
            device.type === DeviceType.TABLET &&
            device.battery_status <= TABLET_LOW_BATTERY_THRESHOLD,
        )
      }
      if (deviceFilter === DeviceFilterValues.NO_CONNECTION) {
        filteredDevices = filteredDevices.filter(
          (device) =>
            device.type === DeviceType.TABLET &&
            (!device.status_sent_at ||
              dayjs(device.status_sent_at)
                .add(TABLET_STATUS_REFRESH_TIME, "second")
                .isBefore(dayjs())),
        )
      }

      if (
        deviceFilter === DeviceFilterValues.PRINTER ||
        deviceFilter === DeviceFilterValues.TABLET
      ) {
        filteredDevices = filteredDevices.filter(
          (device) => device.type === deviceFilter,
        )
      }
    }
    return filteredDevices
  }, [search, deviceFilter, devices])

  const tableColumns = useMemo<Column<DeviceResponse>[]>(
    () => [
      {
        field: "name",
        label: t("desktop.settings.visitors.devices.table.device_name"),
        cellClassName: "DeviceNameCell",
        renderCell: (d) => {
          return (
            <Tooltip
              key={`name-${d.id}`}
              uniqueId={`name-${d.id}`}
              content={d.name}
            >
              {d.name}
            </Tooltip>
          )
        },
      },
      {
        field: "building",
        label: t("desktop.settings.visitors.devices.table.device_location"),
        renderCell: (d) => {
          return d.building?.name ?? "-"
        },
      },
      {
        field: "connected_device",
        label: t("desktop.settings.visitors.devices.table.device_paring"),
        cellClassName: "DevicesCell",
        renderCell: (d: DeviceResponse) => {
          return connectedDevicesCell(d.connected_devices ?? d.connected_device)
        },
      },
      {
        field: "type",
        label: t("desktop.settings.visitors.devices.table.device_status"),
        renderCell: (d) => {
          return d.type === DeviceType.TABLET ? <TabletStatus tablet={d} /> : ""
        },
      },
    ],
    [t],
  )

  const connectedDevicesCell = (
    devices?: ConnectedDevice[] | ConnectedDevice,
  ) => {
    if (Array.isArray(devices)) {
      return (
        <>
          {devices.length === 1 ? (
            <Tooltip
              className="single-device"
              key={devices[0].id}
              uniqueId={`${devices[0].id}`}
              content={devices[0].name}
            >
              {devices[0].name}
            </Tooltip>
          ) : (
            devices.map((device, index, array) => {
              const isLastElement = index === array.length - 1
              if (device.name && device.name.length > 10) {
                return (
                  <Tooltip
                    key={index}
                    uniqueId={device.id}
                    content={device.name}
                  >
                    {device.name + `${!isLastElement ? "," : ""}`}
                  </Tooltip>
                )
              }

              return (
                <span key={device?.name || index}>
                  {device?.name ?? ""}
                  {!isLastElement ? "," : ""}
                </span>
              )
            })
          )}
        </>
      )
    } else return devices?.name ?? ""
  }

  const handlePairDevice = () => {
    openModal(PairDeviceModal, {})
  }

  const handleRePair = useCallback(async () => {
    selectedDevices.forEach(async (sd) => {
      const tablet = filteredDevices.find(
        (d) => d.id === sd.id && d.type === DeviceType.TABLET,
      )
      if (tablet) {
        const response = await actions.repairTablet(sd.id)

        if (repairTablet.fulfilled.match(response)) {
          infoToast(t("desktop.settings.visitors.devices.tablet_repaired"))
        } else {
          errorToast(response.error.message)
        }
      }
    })
  }, [actions, filteredDevices, selectedDevices, infoToast, errorToast, t])

  const handleUnpairConfirmation = () => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleUnpair()
        closeModal()
      },
    })
  }

  const handleUnpair = useCallback(async () => {
    selectedDevices.forEach(async (sd) => {
      const device = filteredDevices.find((d) => d.id === sd.id)
      if (device) {
        if (device.type === DeviceType.TABLET) {
          const response = await actions.unpairTablet(sd.id)

          if (unpairTablet.fulfilled.match(response)) {
            infoToast(t("desktop.settings.visitors.devices.device_deleted"))
          } else {
            errorToast(response.error.message)
          }
        } else {
          const response = await actions.deletePrinter(sd.id)

          if (deletePrinter.fulfilled.match(response)) {
            infoToast(t("desktop.settings.visitors.devices.device_deleted"))
          } else {
            errorToast(response.error.message)
          }
        }
      }
    })
  }, [actions, filteredDevices, selectedDevices, infoToast, errorToast, t])

  const handleRowClick = (d: DeviceResponse) => {
    history.push(`/settings/visitors/devices/${d.id}`)
  }

  return (
    <View className="SettingsDevices">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.visitors.title"),
          t("desktop.settings.visitors.devices.title"),
        ]}
        includeParamsAsPath
      />

      <Intro>{t("desktop.settings.visitors.devices.intro")}</Intro>

      <Space size={0.75} />

      <Filters>
        <DeviceFilter onChange={setDeviceFilter} value={deviceFilter} />
        <SearchFilter
          placeholder={t("desktop.settings.visitors.devices.search_devices")}
          onChange={setSearch}
          value={search}
        />

        <FilterSpace />

        {selectedDevices.length > 0 && (
          <>
            <Button isSmall variant="secondary" onClick={handleRePair}>
              {t("desktop.settings.visitors.devices.re_pair")}
            </Button>
            <Button
              isSmall
              variant="danger-pop"
              noConfirm
              onClick={handleUnpairConfirmation}
            >
              {t("desktop.settings.visitors.devices.unpair")}
            </Button>
          </>
        )}
        <Button isSmall onClick={handlePairDevice}>
          {t("desktop.settings.visitors.devices.pair_new_device")}
        </Button>
      </Filters>

      <Space size={0.75} />

      <Table
        isSelectable
        loading={areDevicesLoading}
        rows={filteredDevices}
        columns={tableColumns}
        onSelectedRows={setSelectedDevices}
        emptyTableCell={<DevicesEmptyTableRows />}
        onRowClick={handleRowClick}
      />
    </View>
  )
}

export default Devices
