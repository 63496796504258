import { createSlice } from "@reduxjs/toolkit"

/**
 *  Slice
 */
export type AppParamsType = {
  isAppWhitelabel: boolean | null
  appName: string
  appLogo: {
    portrait: string
    landscape: string
    icon: string
    mobile: string
  }
  appStore: {
    appleStoreURL: string
    googlePlayURL: string
  }
  appManifest: string
}

const initialState: AppParamsType = {
  isAppWhitelabel: null,
  appName: "",
  appLogo: {
    icon: "",
    portrait: "",
    landscape: "",
    mobile: "",
  },
  appStore: {
    appleStoreURL: "",
    googlePlayURL: "",
  },
  appManifest: "",
}

const appParamsSlice = createSlice({
  name: "appParams",
  initialState,
  reducers: {
    changeAppParams: (state, action: { payload: AppParamsType }) => {
      state.isAppWhitelabel = action.payload.isAppWhitelabel
      state.appName = action.payload.appName
      state.appLogo = action.payload.appLogo
      state.appStore = action.payload.appStore
      state.appManifest = action.payload.appManifest
    },
  },
})

export const appParamsReducer = appParamsSlice.reducer
export const { changeAppParams } = appParamsSlice.actions
