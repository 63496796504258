import dayjs from "dayjs"
import { ThunkApiConfig } from "RootType"

import {
  appointmentCheckInURL,
  appointmentPinURL,
  appointmentsURL,
  appointmentURL,
  deleteJSON,
  get,
  postJSON,
  putJSON,
} from "../../api"
import { InternalTime } from "../../types/sharedTypes"
import { setTimeToDayjs } from "../../utils"
import {
  getErrorMessage,
  paginationInitialState,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { PaginatedOptions, PaginationState, SliceState } from "../types"
import {
  AppointmentCheckInRequest,
  AppointmentRequest,
  AppointmentResponse,
  AppointmentsResponse,
  HostEmailData,
  RequestAppointmentOptions,
  VisitorRequest,
} from "./types"
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit"

/**
 * Thunks
 */
export type CreateAppointmentProps = {
  host: HostEmailData
  visitor: VisitorRequest
  date: string
  startTime: InternalTime
  endTime: InternalTime
}

export const createAppointment = createAsyncThunk<
  AppointmentResponse,
  CreateAppointmentProps,
  ThunkApiConfig
>(
  "appointments/createAppointment",
  async ({ host, visitor, date, startTime, endTime }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()
    const day = dayjs(date)
    const payload = {
      host,
      visitors: [visitor],
      start: setTimeToDayjs(day, startTime).toISOString(),
      end: setTimeToDayjs(day, endTime).toISOString(),
    }

    const response = await postJSON(
      appointmentsURL(),
      { body: payload },
      access_token,
    )

    if (response.ok) {
      const json = await response.json()

      return json
    }

    throw new Error(await getErrorMessage(response))
  },
)

export type UpdateAppointmentProps = {
  id: string
  payload: AppointmentRequest
}

export const updateAppointment = createAsyncThunk<
  AppointmentResponse,
  UpdateAppointmentProps,
  ThunkApiConfig
>("appointments/updateAppointment", async ({ id, payload }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await putJSON(
    appointmentURL(id),
    { body: payload },
    access_token,
  )

  if (response.ok) {
    const json = await response.json()

    return json
  }
  throw new Error(await getErrorMessage(response))
})

export const destroyAppointment = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>("appointments/destroyAppointment", async (id, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await deleteJSON(
    appointmentURL(id),
    { body: {} },
    access_token,
  )

  if (response.ok) {
    return
  }
  throw new Error(await getErrorMessage(response))
})

export type RequestAppointmentProps = {
  cid: string
  payload: AppointmentRequest
  options: RequestAppointmentOptions
}

export const requestAppointment = createAsyncThunk<
  AppointmentResponse,
  RequestAppointmentProps,
  ThunkApiConfig
>(
  "appointments/requestAppointment",
  async ({ cid, payload, options = {} }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await postJSON(
      appointmentCheckInURL(cid),
      {
        body: {
          ...payload,
          ...(options.withCheckin === true
            ? { visitor_checked_in_datetime: dayjs().toISOString() }
            : {}),
        },
      },
      access_token,
    )

    if (response.ok) {
      const json: AppointmentResponse = await response.json()
      return json
    }
    throw new Error(await getErrorMessage(response))
  },
)

export type CheckIntoAppointmentProps = { cid: string; id: string }

export const checkIntoAppointment = createAsyncThunk<
  AppointmentResponse,
  CheckIntoAppointmentProps,
  ThunkApiConfig
>("appointments/checkIntoAppointment", async ({ cid, id }, { getState }) => {
  const {
    auth: { access_token },
    appointments: { entries },
  } = getState()

  const appointments = entries as Array<AppointmentResponse>

  const appointment = appointments.find((appt) => appt.id === id)

  if (!appointment) {
    throw new Error("Could not find appointment")
  }

  const payload = {
    host: appointment.host,
    visitors: appointment.visitors,
    visitor_checked_in_datetime: new Date().toISOString(),
  } as AppointmentCheckInRequest

  const response = await putJSON(
    appointmentCheckInURL(cid, id),
    { body: payload },
    access_token,
  )

  if (response.ok) {
    const json = (await response.json()) as AppointmentResponse
    return json
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchAppointments = createAsyncThunk<
  AppointmentsResponse,
  PaginatedOptions,
  ThunkApiConfig
>("appointments/fetchAppointments", async (params, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(appointmentsURL(params), {}, access_token)

  if (response.ok) {
    const json = await response.json()
    return json
  }

  throw new Error(await getErrorMessage(response))
})

export type FetchAppointmentProps = { cid: string; pin: string }

export const fetchAppointment = createAsyncThunk<
  AppointmentResponse,
  FetchAppointmentProps,
  ThunkApiConfig
>("appointments/fetchAppointment", async ({ cid, pin }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(appointmentPinURL(cid, pin), {}, access_token)

  if (response.ok) {
    const json = await response.json()
    return json
  }

  throw new Error(await getErrorMessage(response))
})

/**
 * Slice
 */

export type AppointmentsState = SliceState &
  PaginationState & {
    entries: AppointmentResponse[]
  }

const initialState: AppointmentsState = {
  entries: [],
  ...sliceInitialState,
  ...paginationInitialState,
}

const appointmentsSlice: Slice<AppointmentsState> = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //createAppointment
    builder.addCase(createAppointment.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(createAppointment.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(createAppointment.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      state.entries = [...state.entries, payload]
    })

    // updateAppointment
    builder.addCase(updateAppointment.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(updateAppointment.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(updateAppointment.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      const remain = state.entries.filter((e) => e.id !== payload.id)
      state.entries = [...remain, payload]
    })

    // destroyAppointment
    builder.addCase(destroyAppointment.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(destroyAppointment.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(destroyAppointment.fulfilled, (state, { meta }) => {
      setSubmitSuccessState(state)

      state.entries = state.entries.filter(
        (appointment) => appointment.id !== meta.arg,
      )
    })

    // requestAppointment
    builder.addCase(requestAppointment.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(requestAppointment.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(requestAppointment.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      state.entries = [...state.entries, payload]
    })

    // checkIntoAppointment
    builder.addCase(checkIntoAppointment.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(checkIntoAppointment.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(checkIntoAppointment.fulfilled, (state) => {
      setSubmitSuccessState(state)
    })

    // fetchAppointments
    builder.addCase(fetchAppointments.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAppointments.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchAppointments.fulfilled, (state, { meta, payload }) => {
      setFetchSuccessState(state)
      state.entries = payload.results
      state.count = payload.count
      state.offset = meta.arg.offset || 0
    })

    // fetchAppointment
    builder.addCase(fetchAppointment.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAppointment.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchAppointment.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)

      const remain = state.entries.filter((e) => e.id !== payload.id)

      state.entries = [...remain, payload]
    })
  },
})

export const appointmentsReducer = appointmentsSlice.reducer
