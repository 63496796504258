import React, { useCallback, useMemo, useState } from "react"

import { ParseKeys } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import { Link, useHistory, useLocation } from "react-router-dom"

import { ENTRIES_PER_PAGE_KEY } from "../../../../constants"
import { useBackendPagination } from "../../../../hooks/useBackendPagination"
import { useLocalStorage } from "../../../../hooks/useLocalStorage"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { OptionType } from "../../../../types/sharedTypes"
import { ENTRIES_PER_PAGE } from "../../../Manage/constants"
import { checkCalendarCompletion } from "../../Integrations/Calendars/constants"
import { INTEGRATIONS_PATHS } from "../../Integrations/constants"
import { ROOMS_PATHS } from "../constants"
import OnboardingTableInfo from "../OnboardingTableInfo"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchCalendarsQuery } from "../../../../redux/api/calendars"
import {
  useDestroyRoomMutation,
  useFetchRoomsQuery,
  useStartScanAndCheckRoomsMutation,
} from "../../../../redux/api/rooms"
import { RoomBulkAction, RoomResponse } from "../../../../redux/api/rooms/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"
import { selectAutoScanRooms } from "../../../../redux/autoScanRooms/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import Button from "../../../../components/advanced/Button"
import { ConfirmationModal } from "../../../../components/advanced/ConfirmationModal"
import Table from "../../../../components/advanced/Table"
import MultiActionButton from "../../../../components/basic/MultiActionButton"
import { Column } from "../../../../components/basic/Table"
import { Tooltip } from "../../../../components/basic/Tooltip"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import BuildingFilter from "../../../../components/Filter/BuildingFilter"
import Filters from "../../../../components/Filter/Filters"
import FilterSpace from "../../../../components/Filter/FilterSpace"
import FloorFilter from "../../../../components/Filter/FloorFilter"
import SearchFilter from "../../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"
import Intro from "../../../../components/Intro"
import NoDataFound from "../../../../components/NoDataFound"
import Pagination from "../../../../components/Pagination"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import RoomSVG from "../../../../assets/images/icons/Room.svg"

import "./styles.sass"

const NoRoomsFound = () => {
  const { t } = useTranslation()
  const { syncInProgress } = useAppSelector(selectAutoScanRooms)

  return (
    <NoDataFound>
      <>
        <RoomSVG />
        <p>{t("desktop.settings.rooms.rooms.no_rooms.title")}</p>
        {syncInProgress && (
          <p className="bold">
            {t("desktop.settings.rooms.device_scan_in_progress")}
          </p>
        )}
      </>
    </NoDataFound>
  )
}

const RoomList = () => {
  const { errorToast, infoToast } = useToast()
  const { openModal, closeModal } = useModals()

  const history = useHistory()
  const { t } = useTranslation()

  const { search } = useLocation()

  const { push } = useNavigation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const { syncInProgress } = useAppSelector(selectAutoScanRooms)
  const [page, setCurrentPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState<string>("")
  const [selectedRows, setSelectedRows] = useState<RoomResponse[]>([])

  // Filters
  const [buildingFilter, setBuildingFilter] = useState<string>(
    query.get("building") || FilterSpecialValues.ALL,
  )

  const [floorFilter, setFloorFilter] = useState<string>(
    query.get("floor") || FilterSpecialValues.ALL,
  )

  const [startScanAndCheckRoomsQuery] = useStartScanAndCheckRoomsMutation()
  const [destroyRoom] = useDestroyRoomMutation()
  const { data: { results: calendars = [] } = {} } = useFetchCalendarsQuery()

  const hasCalendar =
    calendars.length > 0 && checkCalendarCompletion(calendars[0])

  const handleSelectRow = (d: RoomResponse[]) => setSelectedRows(d)

  const handleBulkAction = async (action: RoomBulkAction) => {
    switch (action) {
      case RoomBulkAction.DELETE: {
        let deletedRoomCount = 0
        for (const room of selectedRows) {
          const response = await destroyRoom(room.key)
          if (isRejected(response)) {
            const { error } = response

            if (isApiResponseError(error)) {
              errorToast(error.message)
            }
            return
          }
          deletedRoomCount++
        }
        infoToast(
          t("desktop.settings.rooms.rooms.action_statuses.rooms_deleted", {
            count: deletedRoomCount,
          }),
        )
        break
      }
      case RoomBulkAction.EDIT: {
        push(ROOMS_PATHS.massEditRooms, {
          rooms: selectedRows.map((room) => room.key).join(","),
        })
        break
      }
      default:
        break
    }
  }

  const bulkActionOptions = useMemo<OptionType<RoomBulkAction>[]>(
    () =>
      Object.values(RoomBulkAction).map((action) => ({
        label: t(
          `desktop.settings.rooms.rooms.actions.${action.toLocaleLowerCase()}` as ParseKeys,
        ),
        value: action,
      })),
    [t],
  )

  const handleBulkActionConfirmation = (action: RoomBulkAction) => {
    if (selectedRows.length <= 0) {
      infoToast(
        t("desktop.settings.rooms.rooms.action_statuses.no_rooms_selected"),
      )
      return
    }

    if (action === RoomBulkAction.EDIT) {
      handleBulkAction(action)
      return
    }

    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleBulkAction(action)
        closeModal()
      },
    })
  }

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  const offset = (page - 1) * entriesPerPageNum

  const {
    data: { results: rooms = [], count = 0 } = {},
    isFetching: isLoading,
    refetch: refetchRooms,
  } = useFetchRoomsQuery({
    building: buildingFilter,
    floor: floorFilter,
    search: searchFilter,
    offset,
    limit: entriesPerPageNum,
  })

  const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const setPage = useCallback(
    (nextPage: number) => {
      setCurrentPage(nextPage)
      refetchRooms()
    },
    [refetchRooms],
  )

  const tableColumns = useMemo<Column<RoomResponse>[]>(
    () => [
      {
        field: "name",
        label: t("desktop.settings.timeslots.form.name"),
        renderCell: ({ name }) => name ?? "-",
      },
      {
        field: "building",
        label: t("desktop.settings.assets.asset_type_form.building"),
        renderCell: ({ building }) => building?.name ?? "-",
      },
      {
        field: "floor",
        label: t("mobile.book.floor"),
        renderCell: ({ floor }) => floor?.name ?? "-",
      },
    ],
    [t],
  )

  const handleRowClick = (r: RoomResponse) => {
    // the key needs to be double URI encoded because useParam partly decodes key if it is only encoded once
    history.push(`${ROOMS_PATHS.room(encodeURIComponent(r.key))}`)
  }

  const handleScanRooms = async () => {
    const response = await startScanAndCheckRoomsQuery()

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)
      }
      return
    }
  }

  const tooltip = hasCalendar ? (
    ""
  ) : (
    <Trans i18nKey={"desktop.settings.rooms.rooms.no_calendar_tooltip"}>
      <Link className="tooltip-link" to={INTEGRATIONS_PATHS.calendars}></Link>
    </Trans>
  )

  return (
    <View className="SettingsRooms">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.rooms.title"),
        ]}
      />

      <Intro>
        <div className="description">
          {t("desktop.settings.rooms.rooms.intro")}
        </div>
        <div className="buttons">
          <Tooltip uniqueId="add_room_button" clickable content={tooltip}>
            <Button to={ROOMS_PATHS.addRoom} isSmall isDisabled={!hasCalendar}>
              {t("desktop.settings.rooms.rooms.add_room")}
            </Button>
          </Tooltip>
          <Tooltip uniqueId="scan_rooms_button" clickable content={tooltip}>
            <Button
              isSmall
              variant="secondary-white"
              onClick={handleScanRooms}
              isLoading={syncInProgress}
              isDisabled={syncInProgress || !hasCalendar}
            >
              {t("desktop.settings.rooms.rooms.autoscan_rooms")}
            </Button>
          </Tooltip>
        </div>
      </Intro>

      <Space size={0.75} />

      <Filters>
        <BuildingFilter
          onChange={setBuildingFilter}
          value={buildingFilter}
          showAll
        />
        <FloorFilter
          onChange={setFloorFilter}
          value={floorFilter}
          buildingId={buildingFilter}
          showAll
        />
        <SearchFilter
          placeholder={t("desktop.settings.rooms.rooms.search_rooms")}
          onChange={setSearchFilter}
          value={searchFilter}
        />

        <FilterSpace />

        <MultiActionButton
          className="RoomBulkActions"
          options={bulkActionOptions}
          onAction={handleBulkActionConfirmation}
          label={t("desktop.settings.rooms.rooms.bulk_actions_filter_name", {
            count: selectedRows.length,
          })}
        />
      </Filters>

      <Space size={0.75} />

      <Table
        loading={isLoading}
        rows={rooms}
        isSelectable
        calcRowNumber={calcRowNumber}
        columns={tableColumns}
        emptyTableCell={<NoRoomsFound />}
        onRowClick={handleRowClick}
        onSelectedRows={handleSelectRow}
        pagination={
          <Pagination
            links={paginationLinks}
            setPage={setPage}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            from={from}
            to={to}
            total={count}
            items={t("desktop.settings.rooms.title", {
              count,
            })}
            entriesPerPage={entriesPerPageNum}
            setEntriesPerPage={setEntriesPerPage}
          />
        }
        footerInfo={<OnboardingTableInfo />}
      />
    </View>
  )
}

export default RoomList
