import React, { useEffect } from "react"

import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router-dom"

import { fetchAppointment } from "../redux/appointments/appointmentsSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"
import { storeConfiguration } from "../redux/visitor_login/visitorLoginSlice"

import VisitorPINForm from "../components/Form/VisitorLoginForm"
import Heading from "../components/Heading"
import Intro from "../components/Intro"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"
import Space from "../components/Space"
import { toast } from "../components/Toast"
import View from "../components/View"

import "./VisitorLogin.sass"

type Params = {
  company_id?: string
}

export default function VisitorLogin({
  match,
  location,
}: RouteComponentProps<Params>) {
  const { t } = useTranslation()
  const { params } = match || {}
  const { company_id } = params || {}
  const { search } = location || {}

  const actions = useActions({
    fetchAppointment: (cid: string, pin: string) =>
      fetchAppointment({ cid, pin }),
    storeConfiguration: (params: Record<string, string>) =>
      storeConfiguration(params),
  })

  const { companyName, disableRequestMeeting } = useAppSelector(
    (state) => state.visitor_login,
  )

  useEffect(() => {
    if (typeof search === "string" && search.length > 1) {
      const params = Object.fromEntries(
        new URLSearchParams(search.substring(1)),
      )
      const properParams: Record<string, string> = {}

      Object.keys(params).forEach((key) => {
        const prop = params[key]
        if (prop !== undefined) {
          properParams[key] = Array.isArray(prop) ? prop.join(",") : prop
        }
      })

      actions.storeConfiguration(properParams)
    }
  }, [actions, search])

  const { isLoading } = useAppSelector((state) => state.appointments)

  const history = useHistory()

  const handleSubmit = async (pin: string) => {
    if (company_id && pin) {
      const response = await actions.fetchAppointment(company_id, pin)

      if (
        fetchAppointment.fulfilled.match(response) &&
        response.payload.id &&
        response.payload.visitor_checked_in_datetime
      ) {
        toast.info(
          t("desktop.manage.visitors.form.already_checked_in_appointment"),
          {
            hideProgressBar: true,
          },
        )
      } else if (
        fetchAppointment.fulfilled.match(response) &&
        response.payload.id
      ) {
        toast.info(t("desktop.manage.visitors.form.found_your_appointment"), {
          hideProgressBar: true,
        })

        history.push(
          `/visitor-login/${company_id}/${response.payload.id}/${pin}`,
        )
      } else {
        toast.error(t("desktop.manage.visitors.form.not_found_appointment"), {
          hideProgressBar: true,
        })
      }
    }
  }

  function handleRequestMeeting() {
    history.push(`/visitor-login/${company_id}/new`)
  }

  const visitorClassName = classNames({
    VisitorLogin: true,
    isConstrained: true,
  })

  return (
    <RouteView className={visitorClassName}>
      <Screen>
        <View>
          <Heading isCentered>
            {companyName ? (
              <Trans
                i18nKey="desktop.settings.visitors.devices.form.visitor_log_in_welcome.welcome_with_company"
                values={{ companyName }}
              >
                Welcome to {{ companyName }}!
              </Trans>
            ) : (
              <Trans i18nKey="desktop.settings.visitors.devices.form.visitor_log_in_welcome.welcome_without_company">
                Welcome to our office!
              </Trans>
            )}
          </Heading>
          <Intro isCentered>
            {t("desktop.settings.visitors.devices.form.visitor_log_in_intro")}
          </Intro>

          <Space size={0.75} />

          <VisitorPINForm
            disableRequestMeeting={disableRequestMeeting}
            onRequestAdHoc={handleRequestMeeting}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </View>
      </Screen>
    </RouteView>
  )
}
