import classNames from "classnames"
import { NavLink } from "react-router-dom"

import ErrorSVG from "../../../assets/images/icons/Error.svg"
import LoaderSVG from "../../../assets/images/icons/Loader.svg"
import SuccessSVG from "../../../assets/images/icons/RadioCheck.svg"

import "./OverviewStep.sass"

type OverviewStepProps = {
  title: string
  description: string
  path: string
  completed: boolean
  isLoading: boolean
  showError?: boolean
  disabled?: boolean
}

const OverviewStep = ({
  title,
  description,
  path,
  completed,
  isLoading,
  disabled = false,
  showError = false,
}: OverviewStepProps) => {
  const statusIcon = completed ? (
    <SuccessSVG />
  ) : showError ? (
    <ErrorSVG />
  ) : null

  const iconClassNames = classNames("step-icon", {
    loading: isLoading,
    completed: completed,
    uncompleted: !completed,
  })

  const titleClassNames = classNames("step-title", {
    completed: completed,
    disabled,
  })

  const isCalendarURL = path.includes("/onboarding/calendar/select/")

  return (
    <>
      <div className="OverviewStep">
        <div className="step-head">
          <div className={iconClassNames}>
            {isLoading ? <LoaderSVG /> : statusIcon}
          </div>
          {isCalendarURL ? (
            <a
              href={path}
              className={titleClassNames}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          ) : (
            <NavLink to={path} className={titleClassNames}>
              {title}
            </NavLink>
          )}
        </div>
        <div className="step-body">{description}</div>
      </div>
    </>
  )
}

export default OverviewStep
