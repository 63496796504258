import React, { useState } from "react"

import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"

import { useBookContext } from "../../../contexts/Mobile/BookContext"

import { useAppSelector } from "../../../redux/reducers"
import { fetchSuggestions } from "../../../redux/suggestions/suggestionsSlice"
import { Suggestion } from "../../../redux/suggestions/types"
import { useActions } from "../../../redux/utils"

import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import { DatePicker } from "../../../components/Mobile/DatePicker"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { SuggestedDesk } from "../../../components/Mobile/SuggestedDesk"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./SingleSuggest.sass"

type ParamsType =
  | {
      id?: string
    }
  | undefined

const SingleSuggest = () => {
  const { date, onChangeDate, goToHome } = useBookContext()

  const { t } = useTranslation()
  const params = useParams<ParamsType>()
  const id = params && params.id

  const history = useHistory()

  const [selectedDate, setSelectedDate] = useState(date)

  const actions = useActions({
    fetchSuggestions: (date: Dayjs) =>
      fetchSuggestions({ now: date.toISOString() }),
  })

  const suggestions = useAppSelector((state) => state.suggestions.entries)

  const handleClose = () => goToHome()

  const suggestion = suggestions.find(
    (suggestion: Suggestion) => id === suggestion.desk.id,
  )

  const handleSelectDate = (date: Dayjs) => {
    actions.fetchSuggestions(date)
    setSelectedDate(date)
    onChangeDate(date)
  }

  return (
    <SafeViewArea className="SingleSuggest">
      <TopNav
        titleCenter={true}
        backArrow={true}
        onGoBack={history.goBack}
        onClose={handleClose}
      />
      <div className="body">
        <div className="title">{t("mobile.general.choose_time")}</div>
        <CategoryRow name={t("mobile.general.date")}>
          <DatePicker selectedDate={selectedDate} onSelect={handleSelectDate} />
        </CategoryRow>
        <CategoryRow name={t("mobile.book.suggested")}>
          {suggestion ? (
            <SuggestedDesk
              date={selectedDate}
              key={suggestion.desk.id}
              suggestion={suggestion}
              customTime={true}
            />
          ) : (
            <div>{t("mobile.book.no_suggestion_found")}</div>
          )}
        </CategoryRow>
      </div>
    </SafeViewArea>
  )
}

export default SingleSuggest
