import React from "react"

import { useTranslation } from "react-i18next"

import EmailForm from "./EmailForm"

import { useFetchMeQuery } from "../../../../redux/api/me"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const Email = () => {
  const { t } = useTranslation()

  const { data: user, isLoading } = useFetchMeQuery()

  return (
    <View className="Email">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.profile.general.title"),
          t("desktop.settings.profile.email.title"),
        ]}
      />

      <Intro isConstrained>{t("desktop.settings.profile.email.intro")}</Intro>

      <Space size={0.75} />

      {isLoading ? <Loader /> : <EmailForm email={user?.email} />}
    </View>
  )
}

export default Email
