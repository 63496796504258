import { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { VISITOR_ONBOARDING_BASE_URL } from "../constants"

import { appError } from "../../../redux/app/appSlice"
import { useAppSelector } from "../../../redux/reducers"
import { selectTablet } from "../../../redux/tablet/selectors"
import { fetchTablet } from "../../../redux/tablet/tabletSlice"
import { useActions } from "../../../redux/utils"

import VisitorContent from "../../../components/Visitors/VisitorContent"
import VisitorFooter from "../../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../../components/Visitors/VisitorHeader"
import VisitorView from "../../../components/Visitors/VisitorView"

const AssignLocation = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useAppSelector(selectTablet)

  const actions = useActions({
    appError: (message: string) => appError(message),
    fetchTablet: (id: string) => fetchTablet(id),
  })

  useEffect(() => {
    const timer = setInterval(async () => {
      const response = await actions.fetchTablet(id || "")

      if (fetchTablet.fulfilled.match(response)) {
        const tablet = response.payload

        if (tablet.building_id) {
          clearInterval(timer)
          history.push(`${VISITOR_ONBOARDING_BASE_URL}/confirmation`)
        }
      } else {
        actions.appError(response.error.message ?? t("tablet.general_error"))
      }
    }, 10000)
    return () => clearInterval(timer)
  }, [t, actions, history, id])

  return (
    <VisitorView>
      <VisitorHeader />
      <VisitorContent>
        <div className="subtitle">
          {t(
            "tablet.visitors.onboarding.screens.assign_location.assign_location_to_device",
          )}
        </div>
        <div className="info">
          {t(
            "tablet.visitors.onboarding.screens.assign_location.to_complete_onboarding",
          )}
        </div>
      </VisitorContent>
      <VisitorFooter>
        <div className="info">
          <div>{t("tablet.visitors.visit_joan_desktop")}</div>
          <div>
            {t(
              "tablet.visitors.onboarding.screens.assign_location.go_to_visitors_and_set_location",
            )}
          </div>
        </div>
      </VisitorFooter>
    </VisitorView>
  )
}

export default AssignLocation
