import React from "react"

import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"

import { Children } from "../../../../../types/sharedTypes"
import { toFriendlyName } from "../../utils"
import Price from "./Price"

import { Estimate } from "../../../../../redux/api/billing/estimates/types"
import { PlanType } from "../../../../../redux/api/billing/types"

import "./EstimateDetails.sass"

type SectionProps = {
  children: Children
  className?: string
}
/**
 * Section component
 */
const Section = ({ children, className }: SectionProps) => {
  const cn = classNames("EstimateDetails__section", className)
  return <div className={cn}>{children}</div>
}

type EstimateDetailsProps = {
  estimate: Estimate
  isFetching: boolean
  usedQuantity: number
  nextBillingDate: string
  currentPrice: number
  currentQuantity: number
  currentPlanType?: PlanType
  planType: PlanType
}

/**
 * EstimateDetails component
 */
const EstimateDetails = ({
  estimate: { plan_variation, sub_total, tax, total_amount, amount_due, unit },
  isFetching,
  usedQuantity,
  nextBillingDate,
  currentPrice,
  currentQuantity,
  currentPlanType,
  planType,
}: EstimateDetailsProps) => {
  const { t } = useTranslation()

  const planLabel = `${toFriendlyName(planType)} / ${t(
    `desktop.settings.billing.subscription_quantity.${
      plan_variation.period_unit === "month" ? "monthly" : "yearly"
    }`,
  )}`
  const isPlanTypeChanged = currentPlanType !== planType
  const isQuantityChanged = unit.quantity !== currentQuantity

  return (
    <div className="EstimateDetails">
      {(isPlanTypeChanged || isQuantityChanged) && (
        <Section className="plan-changes">
          <div className="">
            {isPlanTypeChanged && (
              <div className="EstimateDetails__row">
                {t(
                  "desktop.settings.billing.subscription_quantity.change_subscription_to",
                  {
                    planLabel,
                  },
                )}
              </div>
            )}
            {isQuantityChanged && (
              <div className="EstimateDetails__row">
                {t(
                  "desktop.settings.billing.subscription_quantity.change_no_of_licenses_to",
                  {
                    quantity: unit.quantity,
                  },
                )}
              </div>
            )}
          </div>
          <div>
            <Price
              isFetching={isFetching}
              currency={plan_variation.currency}
              price={sub_total - currentPrice}
              showPrefix
            />
          </div>
        </Section>
      )}

      <Section>
        <div className="EstimateDetails__row bold">
          {t(
            "desktop.settings.billing.subscription_quantity.no_of_unit_licenses_used",
            {
              used: usedQuantity,
              purchased: unit.quantity,
              unit: unit.type,
            },
          )}
        </div>
        <div className="EstimateDetails__row">
          <div>
            <Trans
              i18nKey="desktop.settings.billing.subscription_quantity.cost_per_period_with_renews"
              values={{
                period: plan_variation.period_unit,
                date: nextBillingDate,
              }}
            />
          </div>
          <strong>
            <Price
              isFetching={isFetching}
              currency={plan_variation.currency}
              price={sub_total}
            />
          </strong>
        </div>
        {tax.percent ? (
          <>
            <div className="EstimateDetails__row">
              <div>
                {t("desktop.settings.billing.subscription_quantity.tax", {
                  rate: tax.percent,
                })}
              </div>
              <Price
                isFetching={isFetching}
                currency={plan_variation.currency}
                price={tax.amount}
              />
            </div>
            <div className="EstimateDetails__row bold">
              <div>
                {t("desktop.settings.billing.subscription_quantity.total")}
              </div>
              <Price
                isFetching={isFetching}
                currency={plan_variation.currency}
                price={total_amount}
              />
            </div>
          </>
        ) : null}
      </Section>
      <Section>
        <div className="EstimateDetails__row bold">
          <div>
            {t(
              "desktop.settings.billing.subscription_quantity.total_due_today",
            )}
          </div>
          <strong>
            <Price
              isFetching={isFetching}
              currency={plan_variation.currency}
              price={amount_due}
            />
          </strong>
        </div>
      </Section>
    </div>
  )
}

export default EstimateDetails
