import React, { useContext, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { logBreadcrumb } from "../../analytics"
import {
  FlowContext,
  Step,
} from "../../providers/Tablet/RegistrationFlowProvider"
import { Device } from "@capacitor/device"
import { captureMessage } from "@sentry/capacitor"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { registerTablet } from "../../redux/tablet/tabletSlice"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import {
  fetchRegistrationData,
  RegistrationDataProps,
} from "../../redux/visitor_registration/visitorRegistrationSlice"

import Button from "../../components/advanced/Button"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

const Start = () => {
  const { t } = useTranslation()

  const { nextStep, goToStep } = useContext(FlowContext)

  const { id, buildingId } = useAppSelector(selectTablet)
  const { data, branding, touchlessQr } = useAppSelector(
    selectVisitorRegistration,
  )

  const { isLoading } = useAppSelector(selectVisitorRegistration)

  const [tabletRegistered, setTabletRegistered] = useState(false)

  const actions = useActions({
    appError: (message: string) => appError(message),
    fetchRegistrationData: (props: RegistrationDataProps) =>
      fetchRegistrationData(props),
    registerTablet: (body: { device_id: string }) => registerTablet(body),
  })

  useEffect(() => {
    if (tabletRegistered || id) {
      return
    }

    logBreadcrumb(
      `building ID: ${buildingId}, tablet ID: ${id}, branding: ${JSON.stringify(branding)}`,
    )
    captureMessage(`Missing tablet ID, reregistering the tablet (one attempt)`)

    Device.getId().then(async (deviceId) => {
      await actions.registerTablet({
        device_id: deviceId.identifier,
      })
      setTabletRegistered(true)
    })
  }, [id, tabletRegistered])

  const handleContinue = () => {
    if (id && buildingId) {
      actions
        .fetchRegistrationData({
          buildingId,
          tabletId: id,
          touchless_pin: null,
          invite_id: null,
        })
        .then((response) => {
          if (fetchRegistrationData.rejected.match(response)) {
            actions.appError(
              response.error.message ?? t("tablet.general_error"),
            )
          }
        })
    }

    nextStep()
  }

  const handleCheckOut = () => goToStep(Step.CHECKOUT)

  return (
    <VisitorView>
      <VisitorHeader showLanguageSelector />
      <VisitorContent>
        <div className="title">
          {t("tablet.visitors.screens.start.welcome")}
          <br />
          {branding?.company_name}!
        </div>
        <div className="info">
          {t("tablet.visitors.screens.start.to_continue")}
        </div>
      </VisitorContent>
      <VisitorFooter>
        {touchlessQr && (
          <div className="qr_code">
            <img alt="QrCode" src={touchlessQr} />
          </div>
        )}
        <div>
          <Button
            onClick={handleContinue}
            isDisabled={data === undefined}
            isLoading={isLoading}
          >
            {t("tablet.visitors.continue")}
          </Button>
        </div>
        <div>
          <Button
            variant="link"
            className="subtext"
            isDisabled={data === undefined}
            isLoading={isLoading}
            onClick={handleCheckOut}
          >
            {t("tablet.visitors.check_out")}
          </Button>
        </div>
      </VisitorFooter>
    </VisitorView>
  )
}

export default Start
