import React from "react"

import "./ErrorText.sass"

type ErrorTextProps = {
  error?: string
}
function ErrorText({ error }: ErrorTextProps) {
  if (!error) {
    return null
  }
  return <div className="error-text">{error}</div>
}

export default ErrorText
