import { api, invalidateOn } from ".."
import { companyPortalURL, updateCompanyURL } from "../../../api"
import { CompanyDetails, CompanyRequest } from "./types"

export const company = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompany: builder.query<CompanyDetails, void>({
      query: () => companyPortalURL(["settings, settings_effective"]),
      providesTags: ["Company"],
    }),

    updateCompanyDetails: builder.mutation<CompanyDetails, CompanyRequest>({
      query: (body) => ({
        url: updateCompanyURL(),
        method: "PUT",
        body,
      }),
      // the invalidateOn utility function that will invalidate provided tags only when mutation is successful
      invalidatesTags: invalidateOn({ success: ["Company"] }),
    }),

    updateCompany: builder.mutation<CompanyDetails, CompanyRequest>({
      query: (body) => ({
        url: updateCompanyURL(),
        method: "PATCH",
        body,
      }),
      invalidatesTags: invalidateOn({ success: ["Company"] }),
    }),

    deleteCompany: builder.mutation<void, void>({
      query: () => ({
        url: updateCompanyURL(),
        method: "DELETE",
      }),
      invalidatesTags: invalidateOn({ success: ["Company"] }),
    }),
  }),
})

export const {
  useFetchCompanyQuery,
  useLazyFetchCompanyQuery,
  useUpdateCompanyDetailsMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = company

export const selectCompanyDetails = company.endpoints.fetchCompany.select()
