import React from "react"

import { t } from "i18next"

import NotificationsForm from "./NotificationsForm"

import { useFetchMeQuery } from "../../../../../redux/api/me"

import Loader from "../../../../../components/basic/Loader"
import Breadcrumbs from "../../../../../components/Breadcrumbs"
import Intro from "../../../../../components/Intro"
import Space from "../../../../../components/Space"
import View from "../../../../../components/View"

import "./styles.sass"

const NotificationSettings = () => {
  const { data: user, isLoading } = useFetchMeQuery()

  return (
    <View className="NotificationsSettings">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.profile.general.account"),
          t("desktop.settings.profile.notifications.notification_settings"),
        ]}
      />

      <Intro isConstrained>
        {t(
          "desktop.settings.profile.notifications.notification_settings_intro",
        )}
      </Intro>

      <Space size={0.75} />

      {isLoading ? (
        <Loader className="loader" />
      ) : (
        <NotificationsForm user={user} />
      )}
    </View>
  )
}

export default NotificationSettings
