import React from "react"

import classNames from "classnames"

import "./Drawer.sass"

type DrawerProps = {
  open: boolean
  onClose?: () => void
}

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  open,
  children,
  onClose,
}) => {
  const drawerClass = classNames({
    Drawer: true,
    open,
  })

  return (
    <div className={drawerClass} onClick={() => onClose?.()}>
      <div className="drawer-content">{children}</div>
    </div>
  )
}
