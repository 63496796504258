import { ApiResponseError } from "../../api/apiUtils"
import { SerializedError } from "@reduxjs/toolkit"

export const isRejected = (
  response: unknown,
): response is {
  error: ApiResponseError | SerializedError
} => response !== null && typeof response === "object" && "error" in response

export const isApiResponseError = (
  error: ApiResponseError | SerializedError,
): error is ApiResponseError =>
  error !== null && typeof error === "object" && "formError" in error
