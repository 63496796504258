import { useCallback } from "react"

import dayjs from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { useToast } from "../../../hooks/useToast"
import ModalDeleteForm from "../ModalDeleteForm"
import { useModals } from "@mattjennings/react-modal-stack"

import { useActions } from "../../../redux/utils"
import { deleteVisitor } from "../../../redux/visitor/visitorSlice"
import {
  VisitorListResponse,
  FetchOptions as VisitorsFetchOptions,
} from "../../../redux/visitors/types"
import { fetchVisitors } from "../../../redux/visitors/visitorsSlice"

import "./VisitorDeleteForm.sass"

type Props = {
  visitor: VisitorListResponse
  fetchParams?: VisitorsFetchOptions
}

const VisitorDeleteForm = ({ visitor, fetchParams }: Props) => {
  const { t } = useTranslation()
  const { closeModal } = useModals()
  const { infoToast, errorToast } = useToast()

  const { full_name, checkin_at } = visitor || {}

  const {
    building_id,
    show,
    start: startDate,
    end: endDate,
    search,
  } = fetchParams || {}

  const actions = useActions({
    deleteVisitor: (id: string) => deleteVisitor(id),
    fetchVisitors: (params: VisitorsFetchOptions) => fetchVisitors(params),
  })

  const methods = useForm()

  const onDeleteClick = useCallback(async () => {
    const response = await actions.deleteVisitor(visitor.id)

    if (deleteVisitor.fulfilled.match(response)) {
      infoToast(t("desktop.manage.visitors.visitor_deleted"))
      actions.fetchVisitors({
        building_id,
        show,
        start: startDate,
        end: endDate,
        search,
      })
      closeModal()
    } else {
      errorToast(response.error.message)
    }
  }, [
    actions,
    closeModal,
    infoToast,
    errorToast,
    visitor.id,
    t,
    building_id,
    show,
    startDate,
    endDate,
    search,
  ])

  return (
    <FormProvider {...methods}>
      <ModalDeleteForm
        title={t("desktop.manage.visitors.modal.delete.title")}
        className="VisitorDeleteForm"
        onDelete={onDeleteClick}
        hint=""
        helpText=""
      >
        <p className="content-text">
          <Trans
            i18nKey={"desktop.manage.visitors.modal.delete.content"}
            values={{
              full_name,
              date: dayjs(checkin_at).format("dddd, MMMM D, YYYY"),
            }}
          />
        </p>
      </ModalDeleteForm>
    </FormProvider>
  )
}

export default VisitorDeleteForm
