import React, { useMemo } from "react"

import { useTranslation } from "react-i18next"

import { FETCH_FOR_COUNT } from "../../../constants"
import OverviewStep from "./OverviewStep"
import SolutionOverviewCard from "./SolutionOverviewCard"

import { useFetchSolutionsQuery } from "../../../redux/api/solutions"
import { useFetchVisitorDevicesQuery } from "../../../redux/api/visitor/devices"
import { useFetchInvitesQuery } from "../../../redux/api/visitor/invites"
import { useFetchVisitorSettingsQuery } from "../../../redux/api/visitor/settings"

import BadgeSVG from "../../../assets/images/icons/BadgeOutline.svg"

type VisitorOverviewProps = {
  isDefaultExtended?: boolean
}

const VisitorOverview = ({
  isDefaultExtended = false,
}: VisitorOverviewProps) => {
  const { t } = useTranslation()

  const {
    data: { results: settings = [] } = {},
    isFetching: isFetchingSettings,
  } = useFetchVisitorSettingsQuery(FETCH_FOR_COUNT)

  const {
    data: { count: deviceCount = 0 } = {},
    isFetching: isFetchingDevices,
  } = useFetchVisitorDevicesQuery(FETCH_FOR_COUNT)

  const {
    data: { count: invitationCount = 0 } = {},
    isFetching: isFetchingInvitations,
  } = useFetchInvitesQuery(FETCH_FOR_COUNT)

  const { data: solutions } = useFetchSolutionsQuery()

  const hasInvitationOrWelcomeEmail = settings.some(
    (setting) => setting.invitation_email_id || setting.welcome_email_id,
  )

  const hasDevices = deviceCount > 0
  const hasInvitations = invitationCount > 0
  const isAddInvitationDisabled = !solutions?.visitor?.active

  const steps = useMemo(
    () => [
      {
        title: t(
          "desktop.settings.overview.visitor.steps.setup_invitation.title",
        ),
        description: t(
          "desktop.settings.overview.visitor.steps.setup_invitation.description",
        ),
        path: "/settings/visitors/general",
        completed: hasInvitationOrWelcomeEmail,
        isLoading: isFetchingSettings,
      },
      {
        title: t(
          "desktop.settings.overview.visitor.steps.connect_tablet.title",
        ),
        description: t(
          "desktop.settings.overview.visitor.steps.connect_tablet.description",
        ),
        path: "/settings/visitors/devices",
        completed: hasDevices,
        isLoading: isFetchingDevices,
      },
      {
        title: t("desktop.settings.overview.visitor.steps.create_invite.title"),
        description: t(
          "desktop.settings.overview.visitor.steps.create_invite.description",
        ),
        path: "/manage/visitors/invites/add",
        completed: hasInvitations,
        isLoading: isFetchingInvitations,
        disabled: isAddInvitationDisabled,
      },
    ],
    [
      t,
      hasInvitationOrWelcomeEmail,
      isFetchingSettings,
      hasDevices,
      isFetchingDevices,
      hasInvitations,
      isFetchingInvitations,
      isAddInvitationDisabled,
    ],
  )

  return (
    <SolutionOverviewCard
      solution="visitor"
      className="VisitorOverview"
      description={t("desktop.settings.overview.visitor.description")}
      icon={<BadgeSVG />}
      isDefaultExtended={isDefaultExtended}
    >
      {steps.map((step) => (
        <OverviewStep
          key={step.title}
          title={step.title}
          description={step.description}
          path={step.path}
          completed={step.completed}
          isLoading={step.isLoading}
          disabled={step.disabled}
        />
      ))}
    </SolutionOverviewCard>
  )
}

export default VisitorOverview
