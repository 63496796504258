import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useToast } from "../../../../hooks/useToast"

import { Reseller } from "../../../../redux/api/billing/payments/types"
import {
  useCreateResellerMutation,
  useUpdateResellerMutation,
} from "../../../../redux/api/billing/reseller"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import { Input } from "../../../../components/basic/Input"
import { InputPhone } from "../../../../components/basic/InputPhone"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"

type ResellerFormProps = {
  reseller?: Reseller
  onSuccess: () => void
}

type FormValues = {
  name: string
  email: string
  phone: string
}

const ResellerForm = ({ reseller, onSuccess }: ResellerFormProps) => {
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const [createReseller] = useCreateResellerMutation()
  const [updateReseller] = useUpdateResellerMutation()

  const isUpdate = !!reseller
  const methods = useForm<FormValues>({
    defaultValues: {
      name: reseller?.name ?? "",
      email: reseller?.email ?? "",
      phone: reseller?.phone ?? "",
    },
  })

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (values: FormValues) => {
    const response = await (isUpdate
      ? updateReseller(values)
      : createReseller(values))

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast)
      }

      return
    }

    infoToast(
      t(
        `desktop.settings.billing.reseller_form.toasts.${
          isUpdate ? "update" : "add"
        }_success`,
      ),
    )
    onSuccess()
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit && handleSubmit(onSubmit as any)}>
        <Field
          control={control}
          name="name"
          label={t("desktop.settings.billing.reseller_form.name")}
        >
          {(props) => <Input {...props} />}
        </Field>
        <Field
          control={control}
          name="email"
          label={t("desktop.settings.billing.reseller_form.email")}
        >
          {(props) => <Input {...props} type="email" />}
        </Field>
        <Field
          control={control}
          name="phone"
          label={t("desktop.settings.billing.reseller_form.phone")}
        >
          {(props) => <InputPhone {...props} />}
        </Field>
        <Button
          isSubmit
          variant="submit"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          {t(
            `desktop.settings.billing.reseller_info.${
              isUpdate ? "edit" : "add"
            }_reseller_info`,
          )}
        </Button>
      </form>
    </FormProvider>
  )
}

export default ResellerForm
