import { forwardRef, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { Checkbox } from "../../basic/Checkbox"
import { Input, InputProps } from "../../basic/Input"
import { Label } from "../../Field/Label"

import "./style.sass"

type Props = {
  value: number | null
  onChange: (v: number | null) => void
  infoText: string
  serviceRestriction?: "desk" | "none"
} & Omit<InputProps, "onChange" | "value">

export const LimitOccupancyDecider = forwardRef<HTMLInputElement, Props>(
  (
    { onChange, label, infoText, value, serviceRestriction = "none", ...props },
    ref,
  ) => {
    const { t } = useTranslation()
    const [showPercent, setShowPercent] = useState(value !== null)

    useEffect(() => {
      if (showPercent) {
        onChange(value === null ? 100 : value)
      } else {
        onChange(null)
      }
    }, [showPercent, value, onChange])

    const innerOnChange = (value: string) => {
      const number = parseInt(value)

      if (number > 100 || number < 0) {
        return
      }

      onChange(showPercent ? number : null)
    }

    const parsedValue = value === null ? "" : value

    return (
      <div className="LimitOccupancyDecider">
        <Checkbox
          value={serviceRestriction === "none" && showPercent}
          onChange={() => setShowPercent(!showPercent)}
          label={label}
          disabled={serviceRestriction !== "none"}
        />
        {/* Show information about the restriction (solution not enabled) */}
        {serviceRestriction !== "none" && (
          <div className="LimitOccupancyDecider__restricted">
            {t(
              `desktop.components.limit_occupancy.${serviceRestriction}_restricted`,
            )}
          </div>
        )}
        {showPercent && serviceRestriction === "none" && (
          <div className="NumberField">
            <Input
              {...props}
              value={parsedValue}
              ref={ref}
              type="number"
              min={0}
              max={100}
              onChange={innerOnChange}
            />
            <Label className="LimitOccupancyDecider__infoText">
              % {infoText}
            </Label>
          </div>
        )}
      </div>
    )
  },
)
