import { Dispatcher } from "./api/dispatcher"
import { NON_RETRY_CODES } from "./constants"

import { FEATURE } from "./redux/payment/paymentSlice"

const MAX_RETRIES = 5
const RETRY_DELAY = 1000 // Base delay time for fetching
const MAX_RETRY_TIME = 10000 // Upper limit cap for refetching
const RETRY_AFTER_DELAY = 10000

/*
	I used the following calculation for Equal Jitter here:
	https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
*/
const equalJitterCalculator = (delay: number, retries: number, cap: number) => {
  const temp = Math.min(cap, delay * 2 ** retries)
  return temp / 2 + Math.random() * (temp / 2)
}
const dispatcher = Dispatcher.getInstance()

export async function fetchExtended(
  url: RequestInfo,
  settings: RequestInit,
  retry: number = 0,
  delay: number = RETRY_DELAY,
): Promise<any> {
  const response = await fetch(url, settings)

  // If 2xx code, continue
  if (response.ok) {
    return response
  }

  if (response.status === 401) {
    dispatcher.dispatch("clearAuth")

    return response
  }

  if (response.status === 402) {
    const lockedFeature = detectLockedFeature(url)
    dispatcher.dispatch("setHasPaid", lockedFeature)
  }

  // Handle all codes not in the NON_RETRY_CODES array
  if (!NON_RETRY_CODES.includes(response.status)) {
    /*
				If 429, we try to read the Retry-After header, if not present
				we simply continue.
			*/
    if (response.status === 429) {
      const retryAfterHeader: string | null =
        response.headers.get("Retry-After")

      const retryAfter =
        retryAfterHeader !== null
          ? parseInt(retryAfterHeader) * 1000
          : equalJitterCalculator(RETRY_AFTER_DELAY, 1, MAX_RETRY_TIME * 3)

      if (retryAfter) {
        console.log(
          `Throttling detected. Next retry in ${Math.floor(
            retryAfter / 1000,
          )} seconds.`,
        )

        return setTimeout(() => {
          fetchExtended(url, settings)
        }, retryAfter)
      }
    }

    const nextRetry = retry + 1
    if (nextRetry > MAX_RETRIES) {
      throw new Error(
        `Finished retrying, something went wrong when calling ${url}.`,
      )
    }

    const retryIn = equalJitterCalculator(delay, nextRetry, MAX_RETRY_TIME)

    console.log(
      `Retry number ${nextRetry}. Next retry in ${Math.floor(
        retryIn / 1000,
      )} seconds.`,
    )

    return setTimeout(() => {
      fetchExtended(url, settings, nextRetry)
    }, retryIn)
  }

  // It seems a NON_RETRY_CODES code was returned, simply continue. Underlying logic should handle this.
  return response
}

// parts of urls for detecting the feature for locking
const DESK_BOOKING_LOCK_URLS = [
  /desk\/company\/[\w-]+\/reservation/,
  /v2\/me\/reservation/,
  /reservation\/[/\w-]+\//,
  /portal\/desks\/schedule\//,
  /portal\/people\/schedule\//,
]

const ASSET_BOOKING_LOCK_URLS = ["/assets/schedule/"]

const VISITOR_LOCK_URLS = ["portal/visitors/"]

/*
 Function determines which feature is locked function is used when API return 402
*/
export const detectLockedFeature = (url: RequestInfo) => {
  const urlStr = url.toString()

  if (
    ASSET_BOOKING_LOCK_URLS.reduce(
      (isLocked, path) => urlStr.includes(path) || isLocked,
      false,
    )
  ) {
    return FEATURE.desk
  }
  if (
    DESK_BOOKING_LOCK_URLS.reduce(
      (isLocked, pathRegEx) => pathRegEx.test(urlStr) || isLocked,
      false,
    )
  ) {
    return FEATURE.desk
  }

  if (
    VISITOR_LOCK_URLS.reduce(
      (isLocked, path) => urlStr.includes(path) || isLocked,
      false,
    )
  ) {
    return FEATURE.visitor
  }
  return null
}
