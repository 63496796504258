import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

import { useAppSelector } from "../../../redux/reducers"
import { selectSettingsEffective } from "../../../redux/settings/selectors"
import { fetchSettings } from "../../../redux/settings/settingsSlice"
import { useActions } from "../../../redux/utils"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import BrandingForm from "../../../components/Form/BrandingForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./Branding.sass"

export const Branding = () => {
  const { t } = useTranslation()
  const { entry: settings_effective, isLoading } = useAppSelector(
    selectSettingsEffective,
  )
  const company_id = useAppSelector((state) => state.app.company)

  const actions = useActions({
    fetchSettings: () => fetchSettings(),
  })

  useEffect(() => {
    actions.fetchSettings()
  }, [actions, company_id])

  return (
    <View className="Branding">
      <Breadcrumbs
        values={[
          t("desktop.settings.organization.title"),
          t("desktop.settings.organization.branding.title"),
        ]}
        depth={2}
      />

      <Intro isConstrained>
        {t("desktop.settings.organization.branding.subtitle")}
      </Intro>

      <Space size={0.75} />

      {isLoading ? (
        <Loader className="loader" />
      ) : (
        <BrandingForm settings={settings_effective ?? undefined} />
      )}
    </View>
  )
}

export default Branding
