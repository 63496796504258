import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type Props = {
  value: string
  onChange: (v: string) => void
}

const DeviceWifiStatusValues = {
  STRONG: "STRONG",
  WEAK: "WEAK",
  DISCONNECTED: "DISCONNECTED",
}

export const { STRONG, WEAK, DISCONNECTED } = DeviceWifiStatusValues

const DeviceWiFiFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t("desktop.settings.rooms.devices.filters.wifi.all"),
      value: FilterSpecialValues.ALL,
    },
    ...Object.values(DeviceWifiStatusValues).map((value) => ({
      label: t(
        `desktop.settings.rooms.devices.filters.wifi.${value.toLowerCase()}` as ParseKeys,
      ),
      value,
    })),
  ]

  return (
    <Dropdown
      className="device-wifi-filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default DeviceWiFiFilter
