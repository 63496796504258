export const justifyIcon = `<svg class="custom-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183758" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183758)"><path d="M2 14V12.6667H14V14H2ZM2 11.3333V10H14V11.3333H2ZM2 8.66667V7.33333H14V8.66667H2ZM2 6V4.66667H14V6H2ZM2 3.33333V2H14V3.33333H2Z" fill="black"/></g></svg>`
export const rightIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183752" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183752)"><path d="M2 14V12.6667H14V14H2ZM6 11.3333V10H14V11.3333H6ZM2 8.66667V7.33333H14V8.66667H2ZM6 6V4.66667H14V6H6ZM2 3.33333V2H14V3.33333H2Z" fill="black"/></g></svg>`
export const leftIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183740" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183740)"><path d="M2 14V12.6667H14V14H2ZM2 11.3333V10H10V11.3333H2ZM2 8.66667V7.33333H14V8.66667H2ZM2 6V4.66667H10V6H2ZM2 3.33333V2H14V3.33333H2Z" fill="black"/></g></svg>`
export const centerIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183746" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183746)"><path d="M2 14V12.6667H14V14H2ZM4.66667 11.3333V10H11.3333V11.3333H4.66667ZM2 8.66667V7.33333H14V8.66667H2ZM4.66667 6V4.66667H11.3333V6H4.66667ZM2 3.33333V2H14V3.33333H2Z" fill="black"/></g></svg>`
export const boldIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183764" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183764)"><path d="M4.53333 12.6663V3.33301H8.21666C8.93888 3.33301 9.60555 3.55523 10.2167 3.99967C10.8278 4.44412 11.1333 5.06079 11.1333 5.84967C11.1333 6.41634 11.0055 6.85245 10.75 7.15801C10.4944 7.46356 10.2555 7.68301 10.0333 7.81634C10.3111 7.93856 10.6194 8.16634 10.9583 8.49967C11.2972 8.83301 11.4667 9.33301 11.4667 9.99967C11.4667 10.9886 11.1055 11.6802 10.3833 12.0747C9.6611 12.4691 8.98333 12.6663 8.34999 12.6663H4.53333ZM6.54999 10.7997H8.28333C8.81666 10.7997 9.14166 10.6636 9.25833 10.3913C9.37499 10.1191 9.43333 9.9219 9.43333 9.79967C9.43333 9.67745 9.37499 9.48023 9.25833 9.20801C9.14166 8.93579 8.79999 8.79967 8.23333 8.79967H6.54999V10.7997ZM6.54999 6.99967H8.09999C8.46666 6.99967 8.73333 6.90523 8.89999 6.71634C9.06666 6.52745 9.14999 6.31634 9.14999 6.08301C9.14999 5.81634 9.05555 5.59967 8.86666 5.43301C8.67777 5.26634 8.43333 5.18301 8.13333 5.18301H6.54999V6.99967Z" fill="black"/></g></svg>`
export const italicIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183789" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183789)"><path d="M3.33337 12.6663V10.9997H6.00004L8.00004 4.99967H5.33337V3.33301H12V4.99967H9.66671L7.66671 10.9997H10V12.6663H3.33337Z" fill="black"/></g></svg>`
export const underlineIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183782" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183782)"><path d="M3.33337 14V12.6667H12.6667V14H3.33337ZM8.00004 11.3333C6.87782 11.3333 6.0056 10.9833 5.38337 10.2833C4.76115 9.58333 4.45004 8.65556 4.45004 7.5V2H6.16671V7.6C6.16671 8.22222 6.32226 8.72778 6.63337 9.11667C6.94449 9.50556 7.40004 9.7 8.00004 9.7C8.60004 9.7 9.0556 9.50556 9.36671 9.11667C9.67782 8.72778 9.83337 8.22222 9.83337 7.6V2H11.55V7.5C11.55 8.65556 11.2389 9.58333 10.6167 10.2833C9.99448 10.9833 9.12226 11.3333 8.00004 11.3333Z" fill="black"/></g></svg>`
export const strikeIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183796" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183796)"><path d="M1.33337 9.33366V8.00033H14.6667V9.33366H1.33337ZM7.00004 6.66699V4.66699H3.33337V2.66699H12.6667V4.66699H9.00004V6.66699H7.00004ZM7.00004 13.3337V10.667H9.00004V13.3337H7.00004Z" fill="black"/></g></svg>`
export const clearIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183770" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183770)"><path d="M8.80002 6.89954L5.23335 3.33288H13.3334V5.33288H9.46668L8.80002 6.89954ZM13.2 15.0662L7.66668 9.53288L6.33335 12.6662H4.15002L6.13335 7.99954L0.93335 2.79954L1.86668 1.86621L14.1334 14.1329L13.2 15.0662Z" fill="black"/></g></svg>`
export const imageIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_14991_183841" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16"><rect width="16" height="16" fill="#D9D9D9"/></mask><g mask="url(#mask0_14991_183841)"><path d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H9.33333V3.33333H3.33333V12.6667H12.6667V6.66667H14V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM11.3333 6V4.66667H10V3.33333H11.3333V2H12.6667V3.33333H14V4.66667H12.6667V6H11.3333ZM4 11.3333H12L9.5 8L7.5 10.6667L6 8.66667L4 11.3333Z" fill="black"/></g></svg>`
export const videoIcon = `<svg fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><mask x="0" y="0" width="16" height="16" style="mask-type:alpha" maskUnits="userSpaceOnUse"><rect width="16" height="16" fill="#D9D9D9"/></mask><g transform="matrix(1.2661 0 0 1.3525 -7.2458 -23.831)" data-name="Group"><path d="m16.941 27.292v-5.0421c-1.72e-4 -0.18731-0.18123-0.30425-0.32605-0.2106l-2.4128 1.5594v-0.92273c0-0.67231-0.4866-1.2173-1.0868-1.2173h-4.5299c-0.60025 0-1.0868 0.54501-1.0868 1.2173v4.19c2e-7 0.67231 0.4866 1.2173 1.0868 1.2173h4.5299c0.60024 0 1.0868-0.54501 1.0868-1.2173v-0.92273l2.4128 1.5606c0.14483 0.09365 0.32588-0.02329 0.32605-0.2106zm-3.7887-0.28128h-4.6014v-4.4785h4.6014zm2.8038-1.1461-1.2038-0.77656v-0.636l1.2038-0.77847z" style="fill:#000000;stroke-width:.11502" data-name="Compound Path"/></g><path d="m11.535 5.756v-1.3333h-1.3333v-1.3333h1.3333v-1.3333h1.3334v1.3333h1.3333v1.3333h-1.3333v1.3333z" style="fill:#000000"/></svg>`
