import React, { useCallback, useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { HOME_PATHS } from "../../constants"
import { useNavigation } from "../../hooks/useNavigation"
import { DESK_PATHS } from "./Book/Desks/constants"
import {
  BarcodeScanner,
  SupportedFormat,
} from "@joan/capacitor-barcode-scanner"

import Button from "../../components/advanced/Button"
import SafeViewArea from "../../components/Mobile/SafeViewArea"

import "./QRScanner.sass"

/**
 * The regex captures the desk ID, which is a UUID (Universally Unique Identifier) in the format:
 * 8-4-4-4-12 hexadecimal characters. This ensures that the scanned QR code matches the expected
 * pattern for desk check-in URLs.
 *
 * We need this regex to extract the desk ID from the checkin URL. See the usage in `src/providers/Mobile/Native/DeepLinkProvider.tsx`.
 */
export const checkinUrlRegex =
  /\/manage\/desks\/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})\/checkin/

const QRScanner = () => {
  const isBarcodeScannerStarted = useRef<boolean>(false)
  const { t } = useTranslation()
  const history = useHistory()

  const startScanning = useCallback(() => {
    if (document !== null) {
      document.querySelector("body")!.classList.add("scanner-active")
      document.querySelector(".QRScanner")!.classList.add("scanner-active")
    }
    BarcodeScanner.hideBackground()
  }, [])

  const finishScanning = useCallback(() => {
    if (document !== null) {
      document.querySelector("body")!.classList.remove("scanner-active")
      document.querySelector(".QRScanner")!.classList.remove("scanner-active")
    }
    BarcodeScanner.showBackground()
  }, [])

  const handleGoBack = useCallback(() => {
    BarcodeScanner.stopScan()

    finishScanning()
    history.push(HOME_PATHS.mobile)
  }, [finishScanning, history])

  useEffect(() => {
    if (!isBarcodeScannerStarted.current) {
      isBarcodeScannerStarted.current = true

      startScanning()

      BarcodeScanner.startScan({
        targetedFormats: [SupportedFormat.QR_CODE],
        cameraDirection: "back",
      }).then((result) => {
        if (!result.hasContent) {
          return
        }

        BarcodeScanner.stopScan()
        finishScanning()

        // Check if the QR code is a desk check-in QR code
        if (checkinUrlRegex.test(result.content)) {
          const deskId = checkinUrlRegex.exec(result.content)![1]
          if (deskId) {
            // Redirect to the check-in screen
            history.push({
              pathname: DESK_PATHS.checkin,
              state: { desk_id: deskId }, // Pass the deskId to the check-in screen
            })
          }
        }
      })
    }
  }, [handleGoBack, startScanning, finishScanning, history])

  return (
    <SafeViewArea className="QRScanner">
      <div className="scanWindow"></div>
      <div className="scanInfo">
        {t("tablet.visitors.screens.scan.qr_code_focused")}
      </div>

      <Button variant="link" onClick={handleGoBack}>
        {t("general.redirection.go_back")}
      </Button>
    </SafeViewArea>
  )
}

export default QRScanner
