import { api } from "../.."
import { prepaidCodesURL } from "../../../../api"
import { PrepaidCodeRequest, PrepaidCodeResponse } from "./types"

export const prepaidCode = api.injectEndpoints({
  endpoints: (builder) => ({
    redeemPrepaidCode: builder.mutation<
      PrepaidCodeResponse,
      PrepaidCodeRequest
    >({
      query: (body) => ({
        url: prepaidCodesURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Subscriptions", id: "LIST" }],
    }),
  }),
})

export const { useRedeemPrepaidCodeMutation } = prepaidCode
