import React from "react"

import { useTranslation } from "react-i18next"

import PickerItem from "./PickerItem"

import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import timezones from "../../../../assets/Data/timezones.json"

import "./TimezonePicker.sass"

type TimezonePickerProps = {
  onTimezoneSelect: (timezone: string) => void
  onCancelClick: () => void
}

const TimezonePicker = ({
  onTimezoneSelect,
  onCancelClick,
}: TimezonePickerProps) => {
  const { t } = useTranslation()

  const [searchText, setSearchText] = React.useState("")

  const onPick = (timezone: string) => {
    onTimezoneSelect(timezone)
  }

  const filteredTimezones = timezones.filter((timezone: string) =>
    searchText.length > 0
      ? timezone.toLowerCase().includes(searchText.toLowerCase())
      : true,
  )

  return (
    <SafeViewArea className="timezone-picker">
      <TopNav onClose={onCancelClick} />
      <h2>{t("mobile.general.choose_timezone")}</h2>
      <div className="picker-content">
        <input
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value)
          }}
          placeholder={t("mobile.general.search_timezone")}
        />
        {filteredTimezones.map((timezone: string) => (
          <PickerItem
            key={timezone}
            name={timezone}
            onClick={() => {
              onPick(timezone)
            }}
          />
        ))}
      </div>
    </SafeViewArea>
  )
}

export default TimezonePicker
