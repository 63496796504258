import React, { memo, PropsWithChildren, useCallback, useEffect } from "react"

import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"

import { isNetworkError } from "../api/utils"
import { useNativeConnectivityChange } from "../hooks/useNativeConnectivityChange"
import { getLabel } from "../utils"
import Button from "./advanced/Button"
import Heading from "./Heading"
import { captureException, ErrorBoundary } from "@sentry/react"

import { appError } from "../redux/app/appSlice"
import { useActions } from "../redux/utils"

import "./RouteView.sass"

type ErrorProps = {
  error: unknown
  componentStack?: string
  resetError: () => void
}

type ReconnectRouteViewProps = {
  resetError: () => void
}

export const ErrorFallback = (props: ErrorProps) => {
  const { t } = useTranslation()
  const { error, resetError } = props

  const supportEmail = getLabel("links.supportEmail")

  useEffect(() => {
    if (error) {
      captureException(error)
    }
  }, [error])

  const errorMessage =
    error instanceof Error ? error.message : "An unknown error occurred"

  if (!window.navigator.onLine || isNetworkError(error)) {
    return <ReconnectRouteView resetError={resetError} />
  }

  return (
    <div className="RouteView Error">
      <Heading>{t("general.route_error_message.heading")}</Heading>
      <p>
        <code>{errorMessage}</code>
      </p>
      <p>
        <Trans
          i18nKey="general.route_error_message.text"
          values={{ supportEmail }}
          components={{
            1: <a href={`mailto:${supportEmail}`}>contact support</a>,
            br: <br />,
          }}
        />
      </p>
      <br />
      <p>
        <Button onClick={resetError}>
          {t("general.route_error_message.button")}
        </Button>
      </p>
    </div>
  )
}

export const MemoizedErrorFallback = memo(ErrorFallback)

type RouteProps = {
  className: string
}

const RouteView = ({ className, children }: PropsWithChildren<RouteProps>) => {
  const routeViewClassName = classNames({
    RouteView: true,
    [className]: !!className,
  })

  const actions = useActions({
    appError: (message: string) => appError(message),
  })

  const logError = useCallback(
    (error: unknown, componentStack?: string, eventId?: string) => {
      captureException(error)
      if (error instanceof Error && error.message) {
        actions.appError(`${error.message}\n${componentStack}`)
      } else {
        actions.appError(`An unknown error occurred\n${componentStack}`)
      }
    },
    [actions],
  )

  const reloadPage = useCallback(() => window.location.reload(), [])

  return (
    <ErrorBoundary
      fallback={ErrorFallback}
      onError={logError}
      onReset={reloadPage}
    >
      <div className={routeViewClassName}>{children}</div>
    </ErrorBoundary>
  )
}

export default RouteView

/**
 * We had some problems with Apple not wanting to click a button when network was lost
 * so we needed to implement some automatization in case there's problems with network issues.
 */
const ReconnectRouteView = ({ resetError }: ReconnectRouteViewProps) => {
  const { t } = useTranslation()

  useNativeConnectivityChange((connected) => {
    if (connected) {
      resetError()
    }
  })

  return (
    <div className="RouteView Error">
      <Heading>{t("mobile.general.no_connection")}</Heading>
      <p>{t("mobile.general.no_connection_detail")}</p>
      <br />
      <p>
        <Button onClick={resetError}>
          {t("general.route_error_message.button")}
        </Button>
      </p>
    </div>
  )
}
