import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { fetchInvite } from "../../../redux/invite/inviteSlice"
import { selectInvite } from "../../../redux/invite/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import InviteEditForm from "../../../components/Form/Visitors/InviteEditForm"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./InviteEdit.sass"

type ParamsType = {
  id: string
}

const InviteEdit = () => {
  const { id } = useParams<ParamsType>()
  const { t } = useTranslation()

  const { entry: invite, isLoading } = useAppSelector(selectInvite)

  const actions = useActions({
    fetchInvite: (id: string) => fetchInvite(id),
  })

  useEffect(() => {
    if (id && invite?.id !== id) {
      actions.fetchInvite(id)
    }
  }, [actions, id, invite?.id])

  return (
    <View className="InviteEdit">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.manage.visitors.invite.invites"),
          t("desktop.manage.visitors.invite.edit_invite"),
        ]}
      />
      <Space size={0.75} />

      {!invite || invite.id !== id || isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <InviteEditForm invite={invite} />
      )}
    </View>
  )
}
export default InviteEdit
