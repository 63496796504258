import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type Props = {
  value: string
  onChange: (v: string) => void
}

export const StatusContentFilterValues = {
  OFF: "0",
  ON: "1",
}

const StatusContentFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t("desktop.settings.rooms.custom_content.filters.statuses.all"),
      value: FilterSpecialValues.ALL,
    },
    {
      label: t("desktop.settings.rooms.custom_content.filters.statuses.off"),
      value: StatusContentFilterValues.OFF,
    },
    {
      label: t("desktop.settings.rooms.custom_content.filters.statuses.on"),
      value: StatusContentFilterValues.ON,
    },
  ]

  return (
    <Dropdown
      className="status-content-filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default StatusContentFilter
