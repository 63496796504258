import { IdAndName } from "../../types/sharedTypes"
import { DepartmentResponse } from "../api/departments/types"
import { UserProfile } from "../api/users/types"

export type Company = {
  id: string
  name?: string
  analytics_domain?: string
  country?: string
}

export type CheckIn = {
  id: string
  location_id: string
  type: string
  check_in_time: string
}

export type MeResponse = {
  id: string
  email: string
  first_name: string
  last_name: string
  infix?: string
  permissions: string[]
  groups: string[]
  companies: Company[]
  departments: DepartmentResponse[]
  open_check_ins: CheckIn[]
  location_tracking_enabled: boolean
  privacy_mode_enabled: boolean
  push_enabled: boolean
  building: IdAndName | null
  profile: UserProfile
}

export type UserConfigAction = {
  type: string
  payload: string | MeResponse | boolean
}

export type User = {
  email: string
  first_name?: string
  last_name?: string
}

export type UserData = {
  id?: number | string
  first_name?: string
  last_name?: string
  email?: string | null
  profile?: UserProfile | null
}

export enum UserGroup {
  ADMIN = "portal_admin",
  OFFICE_MANAGER = "portal_officer_manager",
  USER = "portal_user",
}

export type HasGroups = {
  groups?: string[]
}
