import { Children } from "../../../../types/sharedTypes"

import { IntegrationTypes } from "../../../../redux/api/integrations/types"

import Card from "../../../../components/basic/Card"

import "./ChatBotsCard.sass"

type ChatBotCardProps = {
  type: IntegrationTypes
  title: string
  description: string
  icon: Children
  isConnected?: boolean
  onClick: (type: IntegrationTypes) => void
  disabled?: boolean
}

const ChatBotCard = ({
  type,
  title,
  description,
  icon,
  isConnected = false,
  disabled = false,
  onClick,
}: ChatBotCardProps) => {
  const handleOnClick = () => !disabled && onClick(type)

  return (
    <button className="ChatBotCard" onClick={handleOnClick}>
      <Card className="ChatBotCard__card">
        <h3>{title}</h3>
        <div>{icon}</div>
        <div className="description">
          {isConnected && <div className="connected" />}
          <div>{description}</div>
        </div>
      </Card>
    </button>
  )
}

export default ChatBotCard
