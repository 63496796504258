import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react"

import { useTranslation } from "react-i18next"

import { HOME_PATHS } from "../../../constants"
import { useRefresh } from "../../../hooks/mobile/useRefresh"
import { useNavigation } from "../../../hooks/useNavigation"
import { Capacitor } from "@capacitor/core"
import { BarcodeScanner } from "@joan/capacitor-barcode-scanner"

import Button from "../../../components/advanced/Button"
import Loader from "../../../components/basic/Loader"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./QRScannerProvider.sass"

/**
 * This wrapper makes sure we have the necessary permissions
 * to run the QR code scanner and won't allow the app to
 * render until we get it.
 *
 * Capacitor doesn't offer a permissions library on its own,
 * the external library providers need to provide their own
 * Android permission check.
 *
 * We make sure this component does everything necessary to
 * handle all permission logic.
 */
const QRScannerProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { t } = useTranslation()
  const { push } = useNavigation()
  const [isScannerReady, setIsScannerReady] = useState<boolean>()

  const init = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) {
      setIsScannerReady(true)

      return
    }

    const status = await BarcodeScanner.checkPermission({ force: false })

    if (
      !status.granted ||
      status.denied ||
      status.asked ||
      status.neverAsked ||
      status.restricted ||
      status.unknown
    ) {
      setIsScannerReady(false)
    } else {
      setIsScannerReady(true)
    }
  }, [])

  const handleGivePermissions = useCallback(async () => {
    const status = await BarcodeScanner.checkPermission({ force: true })

    if (status.granted || status.asked) {
      init()
    }

    if (status.denied) {
      const c = window.confirm(
        t(
          "tablet.visitors.onboarding.screens.camera_permission.reenable_access",
        ),
      )

      if (c) {
        BarcodeScanner.openAppSettings()
      }
    }
  }, [init, t])

  useEffect(() => {
    init()
  }, [init])

  useRefresh(init)

  if (isScannerReady === undefined) {
    return <Loader size="large" variant="fullScreen" />
  }

  if (!isScannerReady) {
    return (
      <SafeViewArea className="QRScannerProvider">
        <TopNav
          title={t("mobile.qr_scanner_provider.title")}
          titleCenter
          onClose={() => {
            push(HOME_PATHS.mobile)
          }}
        />
        <div className="content">
          <div>
            <div className="subtitle">
              {t("mobile.qr_scanner_provider.subtitle")}
            </div>
            <div className="info">
              {t("mobile.qr_scanner_provider.we_need_access_to_camera_to_scan")}
            </div>
          </div>
          <Button onClick={handleGivePermissions}>
            {t("tablet.visitors.give_permission")}
          </Button>
        </div>
      </SafeViewArea>
    )
  }

  return <>{children}</>
}

export default QRScannerProvider
