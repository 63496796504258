import React, { PropsWithChildren, useEffect, useRef, useState } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"
import { useLocation } from "react-router-dom"

import { useLoadReducers } from "../hooks/useLoadReducers"
import { isDev } from "../utils"

import { selectCompanyDetails } from "../redux/api/company"
import { useAppSelector } from "../redux/reducers"

import JoanLoader from "../assets/images/icons/JoanLoader.json"

import "./LoadingProvider.sass"

function LoadingProvider({ children }: PropsWithChildren<unknown>) {
  const { pathname } = useLocation()

  const { isLoading: areCompanyDetailsLoaded } =
    useAppSelector(selectCompanyDetails)

  const [companyDetailsInitialized, setCompanyDetailsInitialized] =
    useState(false)

  /* This useEffect sets state to see if it loaded info prior as invalidating Company will cause a site wide loader which is not desired. */
  useEffect(() => {
    if (companyDetailsInitialized || !areCompanyDetailsLoaded) {
      return
    }
    setCompanyDetailsInitialized(true)
  }, [areCompanyDetailsLoaded, companyDetailsInitialized])

  const isAuthPath = pathname.indexOf("/auth/") > -1

  const { areSlicesLoaded } = useLoadReducers({
    reducerKeys: [
      "settings",
      "featureFlags",
      "experiments",
      "user_experiments",
      "user",
    ],
  })

  const [isAnimationComplete, setIsAnimationComplete] = useState(false)
  const [shouldLoop, setShouldLoop] = useState(false)
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  /**
   * JOAN-7013: Dev environment should not wait for animation to complete as it is not necessary
   */
  const shouldWaitForAnimation = !isDev()

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(2)
    }
  }, [lottieRef, shouldLoop])

  useEffect(() => {
    if (
      isAnimationComplete &&
      (!areSlicesLoaded || !companyDetailsInitialized)
    ) {
      setShouldLoop(true)
    }
  }, [isAnimationComplete, areSlicesLoaded, companyDetailsInitialized])

  const handleAnimationComplete = () => {
    if (!isAnimationComplete) {
      setIsAnimationComplete(true)
    }
  }

  return ((shouldWaitForAnimation ? !isAnimationComplete : false) ||
    !areSlicesLoaded ||
    !companyDetailsInitialized) &&
    !isAuthPath ? (
    <div className="joan-loader-container">
      <Lottie
        lottieRef={lottieRef}
        animationData={JoanLoader}
        loop={shouldLoop}
        onComplete={handleAnimationComplete}
        className="joan-loader-animation"
      />
    </div>
  ) : (
    <>{children}</>
  )
}

export default LoadingProvider
