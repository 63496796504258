export const CURRENCIES = {
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
} as const

export const SUBSCRIPTION_STATUSES = {
  FUTURE: "future",
  IN_TRIAL: "in_trial",
  PAUSED: "paused",
  ACTIVE: "active",
  NON_RENEWING: "non_renewing",
  CANCELLED: "cancelled",
} as const

export const SUBSCRIPTION_PERIODS = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
} as const

export const SUBSCRIPTION_TRIAL_PERIODS = {
  DAY: "day",
  MONTH: "month",
} as const

export const SUBSCRIPTION_PRICING_MODELS = {
  FLAT_FEE: "flat_fee",
  PER_UNIT: "per_unit",
  TIERED: "tiered",
  VOLUME: "volume",
  STAIRSTEP: "stairstep",
} as const

export const PLAN_STATUSES = {
  ACTIVE: "active",
  ARCHIVED: "archived",
} as const

export const SUBSCRIPTION_UNIT_TYPES = {
  DEVICE: "device",
  USER: "user",
  BUILDING: "building",
} as const

export const PAYMENT_METHODS = {
  CREDIT_CARD: "CREDIT_CARD",
  INVOICE: "INVOICE",
  RESELLER: "RESELLER",
  PREPAID_CODE: "PREPAID_CODE",
  NONE: "NONE",
} as const

export const CREDIT_CARD_STATUSES = {
  VALID: "valid",
  EXPIRING: "expiring",
  EXPIRED: "expired",
  INVALID: "invalid",
  PENDING_VERIFICATION: "pending_verification",
} as const

export const INVOICE_STATUSES = {
  PAID: "paid",
  POSTED: "posted",
  PAYMENT_DUE: "payment_due",
  NOT_PAID: "not_paid",
  VOIDED: "voided",
  PENDING: "pending",
} as const

export const PLAN_TYPES = {
  DESK_ESSENTIALS: "desk_essentials",
  DESK_AND_ASSET_ESSENTIALS: "desk_and_asset_essentials",
  JOAN_ON_TABLET: "jot",
  JOAN_ON_DISPLAY: "jod",
  ROOM_ESSENTIALS: "room_essentials",
  ROOM_PROFESSIONAL: "room_professional",
  ROOM_ENTERPRISE: "room_enterprise",
  SIGN_ESSENTIALS: "sign_essentials",
  SIGN_PROFESSIONAL: "sign_professional",
  VISITOR_PROFESSIONAL: "visitor_professional",
  VISITOR_PROFESSIONAL_EAP: "visitor_professional_eap",
} as const

export const TRIAL_TYPES = {
  DESK: "desk",
  ROOM: "room",
  SIGN: "sign",
  HOME: "home",
  VISITOR: "visitor",
} as const

export const PLAN_GROUPS = {
  DESK_BOOKING: "desk",
  ROOM: "room",
  HOME: "home",
  JOAN_ON_DISPLAY: "jod",
  JOAN_ON_TABLET: "jot",
  SIGN: "sign",
  VISITOR: "visitor",
  DEPRECATED: "deprecated",
} as const

export const ACTIVE_LICENSE_STATUSES = ["active", "in_trial", "non_renewing"]

// change order to decide the order shown in the UI on the billing overview page
export const LIST_PLAN_GROUPS = [
  PLAN_GROUPS.ROOM,
  PLAN_GROUPS.DESK_BOOKING,
  PLAN_GROUPS.VISITOR,
  PLAN_GROUPS.SIGN,
  PLAN_GROUPS.JOAN_ON_DISPLAY,
  PLAN_GROUPS.HOME,
  PLAN_GROUPS.JOAN_ON_TABLET,
]

export const NOTIFICATION_SCHEDULED_TYPE = {
  SCHEDULED_CANCELATION: "SUBSCRIPTIONS_SCHEDULED_CANCELATION",
  SCHEDULED_PLAN_CHANGE: "SUBSCRIPTIONS_SCHEDULED_PLAN_CHANGE",
  PREPAID_EXPIRES: "SUBSCRIPTIONS_PREPAID_EXPIRES",
} as const

export const RECOMMENDED_PLANS: Record<string, string> = {
  [PLAN_GROUPS.ROOM]: PLAN_TYPES.ROOM_PROFESSIONAL,
  [PLAN_GROUPS.DESK_BOOKING]: PLAN_TYPES.DESK_AND_ASSET_ESSENTIALS,
  [PLAN_GROUPS.VISITOR]: PLAN_TYPES.VISITOR_PROFESSIONAL,
  [PLAN_GROUPS.SIGN]: PLAN_TYPES.SIGN_PROFESSIONAL,
  [PLAN_GROUPS.JOAN_ON_DISPLAY]: PLAN_TYPES.JOAN_ON_DISPLAY,
  [PLAN_GROUPS.JOAN_ON_TABLET]: PLAN_TYPES.JOAN_ON_TABLET,
} as const
