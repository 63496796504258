import { PaginatedResponse } from "../types"

export enum SignInFieldType {
  NAME = "NAME",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  HOST = "HOST",
  CUSTOM = "CUSTOM",
}

export type SignInField = {
  id?: string
  type: SignInFieldType
  order: number
  required?: boolean
  custom_field_name?: string
}

export type TabletSettingResponse = {
  id: string
  building_id: string
  enable_sign_in: boolean
  signin_fields: SignInField[]
}

export type TabletSettingRequest = {
  building_id: string
  enable_sign_in: boolean
  signin_fields: SignInField[]
}

export type TabletSettingsResponse = PaginatedResponse<TabletSettingResponse>

export type TabletSettingsAction = {
  type: string
  payload: Array<TabletSettingsResponse> | TabletSettingsResponse | string
}
