import { useEffect, useState } from "react"

const useCursorPosition = (initialX = 0, initialY = 0) => {
  const [position, setPosition] = useState({ x: initialX, y: initialY })

  useEffect(() => {
    function handleMouseMove(e: MouseEvent) {
      setPosition({ x: e.clientX, y: e.clientY })
    }

    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])

  return position
}

export default useCursorPosition
