const ERROR_KEYS = ["_error", "detail"]

export type ErrorContext = {
  [key: string]: ErrorContext | ErrorContext[] | string | null
}

export type ResponseError =
  | {
      [key: string]: string | null | boolean
    }
  | boolean
  | string

export type ApiResponseError = {
  data: unknown
  formError: ResponseError
  message: string
  status: number
  errorCode: string
  url: string
}

/**
 * Will convert error structure from API to structure compatible with react-hook-form.
 * If there is a general error not related to the form,
 * the `_parseErrors` function will return an object with a `_error` property that contains the general error message.
 *
 * Example inputs:
 *
 * 1. `{"info":{"_error":["This field may not be blank."]}}`
 * 2. `{"info":{"company":["This field may not be blank."]}}`
 * 3. `{"detail":"Unsupported media type \"text/plain;charset=UTF-8\" in request."}`
 * Example outputs:
 *
 * 1. `{"info":"This field may not be blank."}`
 * 2. `{"info.company":"This field may not be blank."}`
 * 3. `{"_error":"Unsupported media type \"text/plain;charset=UTF-8\" in request."}`
 * @param {unknown} error Error returned from API
 * @returns {ResponseError | false} - The response error object or false.
 */

export const parseApiError = (error: unknown): ResponseError => {
  const parsedError = _parseErrors(error)

  if (isResponseError(parsedError)) {
    return parsedError
  }
  return false
}

const isResponseError = (
  error: ResponseError | ErrorContext,
): error is ResponseError => getObjectDepth(error) < 2

/**
 * Parses errors in an error object.
 * @param {unknown} error - The error object.
 * @returns {ErrorContext | string | false} - The error context object or a string or false.
 */

function _parseErrors(error: unknown): ErrorContext | string | boolean {
  if (!error) {
    return false
  }

  const errors: ErrorContext = {}

  if (Array.isArray(error)) {
    if (error.length > 1) {
      return error.join(", ")
    } else if (error.length === 1) {
      error = error[0]
    } else {
      return false
    }
  }

  Object.keys(error as ErrorContext).forEach((key) => {
    const value: any = (error as any)[key]
    if (typeof value === "string") {
      if (ERROR_KEYS.includes(key)) {
        errors[ERROR_KEYS[0]] = value
      } else {
        errors[key] = value
      }
    } else if (Array.isArray(value)) {
      errors[key] = value.join(", ")
    } else if (typeof value === "object") {
      const subErrors = _parseErrors(value)
      Object.keys(subErrors).forEach((subKey) => {
        if (typeof subErrors === "object") {
          // if subKey is _error it will remove that key and apply string directly to parent property
          if (
            subKey === ERROR_KEYS[0] &&
            typeof subErrors[subKey] === "string"
          ) {
            errors[key] = subErrors[subKey]
          } else {
            errors[`${key}.${subKey}`] = subErrors[subKey]
          }
        }
      })
    }
  })

  return errors
}

/**
 * Gets the depth of an object.
 * @param {Object} o - The object to get the depth of.
 * @returns {number} - The depth of the object.
 */
const getObjectDepth = (o: Object): number =>
  Object(o) === o
    ? 1 + Math.max(-1, ...Object.values(o).map(getObjectDepth))
    : 0

export const getErrorCode = (response: unknown) =>
  response &&
  typeof response === "object" &&
  "error" in response &&
  response.error &&
  typeof response.error === "object" &&
  "data" in response.error &&
  response.error.data &&
  typeof response.error.data === "object" &&
  "_error_code" in response.error.data &&
  typeof response.error.data._error_code === "string"
    ? response.error.data._error_code
    : ""
