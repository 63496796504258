import { api } from "../.."
import {
  subscriptionActivationURL,
  subscriptionsGetURL,
  subscriptionsURL,
  subscriptionURL,
} from "../../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../../constants"
import { FetchOptions } from "../../../users/types"
import { PlanGroup, TrialType } from "../types"
import { getPlanGroup } from "../utils"
import {
  Subscription,
  SubscriptionBe,
  SubscriptionCreateRequest,
  SubscriptionResponseBe,
  SubscriptionsResponse,
  SubscriptionUpdateRequest,
} from "./types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
}

export const subscriptions = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscriptions: builder.query<
      SubscriptionsResponse,
      FetchOptions | void
    >({
      query: (options) => ({
        url: subscriptionsGetURL({ ...FETCH_DEFAULTS, ...options }),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ subscription_id }) => ({
                type: "Subscriptions" as const,
                id: subscription_id,
              })),
              { type: "Subscriptions", id: "LIST" },
            ]
          : [{ type: "Subscriptions", id: "LIST" }],
      transformResponse: (response: SubscriptionResponseBe) => {
        const subscriptions = getSubscriptionsWithPlanGroup(response)
        const subsByPlanGroup = getSubscriptionByPlanGroup(subscriptions)

        return {
          ...response,
          results: subscriptions,
          subsByPlanGroup,
        }
      },
    }),

    fetchSubscription: builder.query<Subscription, number>({
      query: (id) => ({
        url: subscriptionURL(id),
      }),
      providesTags: (_r, _e, id) => [{ type: "Subscriptions", id }],
      transformResponse: (response: SubscriptionBe) => ({
        ...response,
        id: response.subscription_id,
        planGroup: getPlanGroup(response.plan_type),
      }),
    }),

    createTrialSubscription: builder.mutation<void, TrialType>({
      query: (type) => ({
        url: subscriptionActivationURL({ type }),
        method: "POST",
      }),
      invalidatesTags: [{ type: "Subscriptions", id: "LIST" }],
    }),

    createSubscription: builder.mutation<
      SubscriptionBe,
      SubscriptionCreateRequest
    >({
      query: (body) => ({
        url: subscriptionsURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Subscriptions", id: "LIST" }, "Solutions"],
    }),

    updateSubscription: builder.mutation<
      SubscriptionBe,
      SubscriptionUpdateRequest
    >({
      query: ({ id, ...body }) => ({
        url: subscriptionURL(id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (_r, _e, { id }) => [{ type: "Subscriptions", id }],
    }),
  }),
})

const getSubscriptionsWithPlanGroup = (
  response: SubscriptionResponseBe,
): Subscription[] =>
  response.results.map((subscription) => ({
    ...subscription,
    id: subscription.subscription_id,
    planGroup: getPlanGroup(subscription.plan_type),
  }))

const getSubscriptionByPlanGroup = (
  subscriptions: Subscription[],
): Record<PlanGroup, Subscription[]> =>
  subscriptions.reduce(
    (acc, sub) => {
      const { planGroup } = sub
      if (!acc[planGroup]) {
        acc[planGroup] = []
      }
      acc[planGroup].push(sub)
      return acc
    },
    {} as Record<PlanGroup, Subscription[]>,
  )

export const {
  useFetchSubscriptionsQuery,
  useLazyFetchSubscriptionsQuery,
  useFetchSubscriptionQuery,
  useCreateTrialSubscriptionMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
} = subscriptions
