import React, { useState } from "react"

import { useTranslation } from "react-i18next"

import ResellerForm from "./ResellerForm"

import { Reseller } from "../../../../redux/api/billing/payments/types"

import Button from "../../../../components/advanced/Button"
import Card from "../../../../components/basic/Card"

import EditSVG from "../../../../assets/images/icons/Pencil.svg"
import AddSVG from "../../../../assets/images/icons/PlusCircle.svg"

import "./ResellerInfo.sass"

type ResellerInfoProps = {
  reseller?: Reseller
}
const ResellerInfo = ({ reseller }: ResellerInfoProps) => {
  const [isFormShown, setIsFormShown] = useState(false)
  const { t } = useTranslation()
  const handleOpenForm = () => setIsFormShown(true)
  const handleCloseForm = () => setIsFormShown(false)

  return (
    <Card className="ResellerInfo">
      <div className="ResellerInfo__header">
        {t("desktop.settings.billing.reseller_info.title")}
      </div>
      {reseller ? (
        <div className="ResellerInfo__row">
          <p>{reseller.name}</p>
          <p>{reseller.email}</p>
          <p>{reseller.phone}</p>
        </div>
      ) : (
        <div className="ResellerInfo__row">
          {t("desktop.settings.billing.reseller_info.no_reseller_info")}
        </div>
      )}
      {isFormShown ? (
        <ResellerForm reseller={reseller} onSuccess={handleCloseForm} />
      ) : (
        <>
          {reseller ? (
            <Button variant="link" icon={<EditSVG />} onClick={handleOpenForm}>
              {t("desktop.settings.billing.reseller_info.edit_reseller_info")}
            </Button>
          ) : (
            <Button variant="link" icon={<AddSVG />} onClick={handleOpenForm}>
              {t("desktop.settings.billing.reseller_info.add_reseller_info")}
            </Button>
          )}
        </>
      )}
    </Card>
  )
}

export default ResellerInfo
