import { ThunkApiConfig } from "RootType"

import {
  deleteJSON,
  get,
  postJSON,
  putJSON,
  reprintURL,
  visitCheckoutURL,
  visitDocumentURL,
  visitsURL,
  visitURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
  getErrorMessage,
  getErrorObject,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import { visitorRemove } from "../visitors/visitorsSlice"
import {
  VisitorCreateRequest,
  VisitorResponse,
  VisitorUpdateRequest,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchVisitor = createAsyncThunk<
  VisitorResponse,
  string,
  ThunkApiConfig
>("visitor/fetch", async (id, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(visitURL(id), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  return rejectWithValue(await getErrorMessage(response))
})

export const createVisitor = createAsyncThunk<
  VisitorResponse,
  VisitorCreateRequest,
  ThunkApiConfig<ResponseError>
>("visitor/create", async (payload, { getState, rejectWithValue }) => {
  const { access_token } = getState().auth

  const response = await postJSON(visitsURL(), { body: payload }, access_token)

  if (response.ok) {
    return await response.json()
  }

  return rejectWithValue(await getErrorObject(response))
})

type UpdateVisitorProps = VisitorUpdateRequest & {
  id: string
}

export const updateVisitor = createAsyncThunk<
  VisitorResponse,
  UpdateVisitorProps,
  ThunkApiConfig<ResponseError>
>(
  "visitor/update",
  async ({ id, ...payload }, { getState, rejectWithValue }) => {
    const { access_token } = getState().auth

    const response = await putJSON(
      visitURL(id),
      { body: payload },
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

export const checkoutVisitor = createAsyncThunk<
  VisitorResponse,
  string,
  ThunkApiConfig<ResponseError>
>("visitor/checkout", async (id, { getState, rejectWithValue }) => {
  const { access_token } = getState().auth

  const response = await postJSON(visitCheckoutURL(id), undefined, access_token)

  if (response.ok) {
    return await response.json()
  }

  return rejectWithValue(await getErrorObject(response))
})

export const visitsReprintBadge = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>("visitor/reprint", async (id, { getState }) => {
  const { access_token } = getState().auth

  const response = await postJSON(reprintURL(id), undefined, access_token)

  if (response.ok) {
    return
  }

  throw new Error(await getErrorMessage(response))
})

export const deleteVisitor = createAsyncThunk<void, string, ThunkApiConfig>(
  "visitor/delete",
  async (id, { getState, dispatch }) => {
    const { access_token } = getState().auth

    const response = await deleteJSON(visitURL(id), { body: {} }, access_token)

    if (response.ok) {
      dispatch(visitorRemove(id))
      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

export const visitorDocument = createAsyncThunk<
  Blob,
  string,
  ThunkApiConfig<string>
>("visitor/download", async (id, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(visitDocumentURL(id), {}, access_token)

  if (response.ok && response.body) {
    return await response.blob()
  }

  return rejectWithValue(await getErrorMessage(response))
})

export interface VisitorsState extends SliceState {
  entry?: VisitorResponse
}

const initialState: VisitorsState = {
  ...sliceInitialState,
  entry: undefined,
}

const visitorSlice = createSlice({
  name: "visitor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVisitor.fulfilled, (state, { payload }) => {
      state.entry = payload

      setFetchSuccessState(state)
      return state
    })
    builder.addCase(deleteVisitor.fulfilled, (state) => {
      setSubmitSuccessState(state)
    })
    builder.addMatcher(
      isAnyOf(visitorDocument.fulfilled, visitsReprintBadge.fulfilled),
      (state) => {
        setSubmitSuccessState(state)
      },
    )
    builder.addMatcher(
      isAnyOf(
        createVisitor.fulfilled,
        updateVisitor.fulfilled,
        checkoutVisitor.fulfilled,
      ),
      (state, { payload }) => {
        state.entry = payload

        setSubmitSuccessState(state)
        return state
      },
    )

    builder.addMatcher(
      isAnyOf(
        createVisitor.pending,
        updateVisitor.pending,
        checkoutVisitor.pending,
        deleteVisitor.pending,
        visitorDocument.pending,
        visitsReprintBadge.pending,
      ),
      (state) => {
        state.isSubmitting = true
      },
    )
    builder.addMatcher(isAnyOf(fetchVisitor.rejected), (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addMatcher(
      isAnyOf(
        createVisitor.rejected,
        updateVisitor.rejected,
        checkoutVisitor.rejected,
        deleteVisitor.rejected,
        visitorDocument.rejected,
        visitsReprintBadge.rejected,
      ),
      (state, action) => {
        setSubmitErrorState(state, action)
      },
    )
  },
})

export const visitorReducer = visitorSlice.reducer
