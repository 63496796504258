import { useCallback, useEffect, useMemo, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { buildingsURL, departmentsURL } from "../../api"
import { PERMISSIONS } from "../../constants"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import { useNavigation } from "../../hooks/useNavigation"
import { useToast } from "../../hooks/useToast"
import { USER_DIRECTORY_PATHS } from "../../screens/Settings/UserDirectory/constants"
import { IdAndName, OptionType } from "../../types/sharedTypes"
import { getOption } from "../../utils"
import AsyncSelect from "../advanced/AsyncSelect"
import { Input } from "../basic/Input"
import { InputPhone } from "../basic/InputPhone"
import { Select } from "../basic/Select"
import Field from "../Field"
import { setErrors } from "./formUtils"
import PageForm from "./PageFormHook"

import { DepartmentResponse } from "../../redux/api/departments/types"
import { useFetchMeQuery } from "../../redux/api/me"
import { useFetchScimSettingsQuery } from "../../redux/api/scim"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import {
  useCreateUsersMutation,
  useDestroyUsersMutation,
  useFetchUsersQuery,
  useUpdateUserMutation,
} from "../../redux/api/users"
import { UserResponse } from "../../redux/api/users/types"
import { UserGroup } from "../../redux/user/types"

import "./UserDirectoryForm.sass"

const FORM_MAPPING = {
  building_id: "building",
} as const

type Props = {
  person: Partial<UserResponse>
}

type FormValues = {
  first_name: string
  last_name: string
  email: string
  phone: string
  building?: IdAndName
  groups: OptionType
  departments?: DepartmentResponse[]
}

const UserDirectoryForm = ({ person }: Props) => {
  const { push } = useNavigation()

  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const { data: user } = useFetchMeQuery()

  const { data: { count: userCount = 0 } = {} } = useFetchUsersQuery({
    limit: 1,
  })

  const [createManagementUser] = useCreateUsersMutation()
  const [updateManagementUser] = useUpdateUserMutation()
  const [destroyManagementUser] = useDestroyUsersMutation()

  const { data: scimSettings } = useFetchScimSettingsQuery()

  const { enabled: isSCIMEnabled } = scimSettings ?? {}
  const isEditingEnabled = !isSCIMEnabled

  const { first_name, last_name, email, groups, departments, building, phone } =
    person || {}

  const personNotProvided = email === "" || !email

  const userGroupOptions = useMemo(
    () => [
      {
        value: UserGroup.USER,
        label: t("desktop.settings.person.person_form.employee"),
      },
      {
        value: UserGroup.OFFICE_MANAGER,
        label: t("desktop.settings.person.person_form.office_manager"),
      },
    ],
    [t],
  )

  const methods = useForm<FormValues>({
    defaultValues: {
      first_name: first_name ?? "",
      last_name: last_name ?? "",
      email: email ?? "",
      phone: phone ?? "",
      building: building ?? undefined,
      groups: groups
        ? {
            value: groups[0],
            label: getOption(userGroupOptions, groups[0])?.label,
          }
        : undefined,
      departments,
    },
  })

  const { setError, control, watch } = methods

  const selectedGroup = watch("groups")

  useEffect(() => {
    if (selectedGroup) {
      setPersonType(selectedGroup.value)
    }
  }, [selectedGroup])

  const [personType, setPersonType] = useState(groups?.[0] ?? UserGroup.USER)

  const onCreateClick = useCallback(
    async ({
      first_name,
      last_name,
      phone,
      building,
      email,
      departments,
    }: FormValues) => {
      const response = await createManagementUser([
        {
          first_name,
          last_name,
          phone,
          email,
          building_id: building?.id,
          groups: [personType],
          departments: departments?.map((d: DepartmentResponse) => d.id),
        },
      ])

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        }
        return
      }

      analyticsEvent(SupportedEvents.PEOPLE_ADD, {
        num_users_added: 1,
        total: userCount + 1,
      })
      infoToast(t("desktop.settings.person.person_form.person_created"))
      push(USER_DIRECTORY_PATHS.overview)
    },
    [
      personType,
      userCount,
      push,
      createManagementUser,
      setError,
      errorToast,
      infoToast,
      t,
    ],
  )

  const onUpdateClick = useCallback(
    async ({
      first_name,
      last_name,
      phone,
      building,
      departments,
    }: FormValues) => {
      if (personNotProvided) {
        return
      }
      const response = await updateManagementUser({
        email,
        first_name,
        last_name,
        phone,
        building_id: building?.id,
        groups: [personType],
        departments: departments?.map((d: DepartmentResponse) => d.id),
      })

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast, FORM_MAPPING)
        }
        return
      }

      infoToast(t("desktop.settings.person.person_form.person_updated"))
      push(USER_DIRECTORY_PATHS.overview)
    },
    [
      personNotProvided,
      email,
      personType,
      push,
      updateManagementUser,
      setError,
      errorToast,
      infoToast,
      t,
    ],
  )

  const onDeleteClick = useCallback(async () => {
    if (personNotProvided) {
      return
    }
    const response = await destroyManagementUser([email])

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast, FORM_MAPPING)
      }
      return
    }

    infoToast(t("desktop.settings.person.person_form.person_deleted"))
    analyticsEvent(SupportedEvents.PEOPLE_REMOVE, {
      num_users_removed: 1,
      total: userCount - 1,
    })

    push(USER_DIRECTORY_PATHS.overview)
  }, [
    email,
    userCount,
    personNotProvided,
    push,
    destroyManagementUser,
    infoToast,
    errorToast,
    setError,
    t,
  ])

  const isCurrentUser = user && email === user.email
  const isPortalAdmin = personType === "portal_admin"
  const hasPermissions =
    useCheckForPermission(PERMISSIONS.users.canChangeUser) || isCurrentUser

  const updateMode = !personNotProvided

  return (
    <FormProvider {...methods}>
      <PageForm
        className="UserDirectoryForm"
        backUrl={USER_DIRECTORY_PATHS.overview}
        updateMode={updateMode}
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        onDelete={
          hasPermissions && updateMode && !isCurrentUser
            ? onDeleteClick
            : undefined
        }
      >
        <Field
          control={control}
          name="first_name"
          className="field-width-50"
          label={t("desktop.settings.person.person_form.first_name")}
          required
        >
          {(props) => (
            <Input
              {...props}
              maxLength={30}
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>
        <Field
          control={control}
          name="last_name"
          className="field-width-50"
          label={t("desktop.settings.person.person_form.last_name")}
          required
        >
          {(props) => (
            <Input
              {...props}
              maxLength={30}
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>
        <Field
          control={control}
          name="email"
          className="field-width-50"
          label={t("desktop.settings.person.person_form.email")}
          required
        >
          {(props) => (
            <Input
              {...props}
              type="email"
              disabled={
                !(hasPermissions && isEditingEnabled && personNotProvided)
              }
            />
          )}
        </Field>
        <Field
          control={control}
          name="phone"
          className="field-width-50"
          label={t("desktop.settings.person.person_form.phone_number")}
        >
          {(props) => (
            <InputPhone
              {...props}
              placeholder="+386 68 123 456"
              disabled={!(hasPermissions && isEditingEnabled)}
            />
          )}
        </Field>
        <Field
          control={control}
          name="building"
          className="field-width-50"
          label={t("desktop.settings.person.person_form.default_building")}
        >
          {(props) => (
            <AsyncSelect
              urlGenerator={(fetchOptions) => {
                return buildingsURL(fetchOptions)
              }}
              nothingFoundMessage={t(
                "desktop.settings.person.person_form.errors.no_building_found",
              )}
              getOptionLabel={(building) => building.name}
              getOptionValue={(building) => building.id}
              isPaginated
              {...props}
            />
          )}
        </Field>
        <Field
          control={control}
          name="departments"
          className="field-width-50"
          label={t("desktop.settings.person.person_form.departments")}
        >
          {({ ref, ...props }) => (
            <AsyncSelect<DepartmentResponse, true>
              urlGenerator={(fetchOptions) => {
                return departmentsURL(fetchOptions)
              }}
              nothingFoundMessage={t(
                "desktop.settings.person.person_form.errors.no_departments_found",
              )}
              getOptionLabel={(department) => department.name}
              getOptionValue={(department) => department.id}
              isMulti
              {...props}
            />
          )}
        </Field>
        {!isCurrentUser && !isPortalAdmin && (
          <Field
            control={control}
            name="groups"
            className="field-width-50"
            label={t("desktop.settings.person.person_form.role")}
          >
            {({ ref, ...props }) => (
              <Select
                {...props}
                options={userGroupOptions}
                disabled={!hasPermissions}
              />
            )}
          </Field>
        )}
      </PageForm>
    </FormProvider>
  )
}

export default UserDirectoryForm
