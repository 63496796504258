import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import PickerItem from "./PickerItem"

import { useFetchAssetsQuery } from "../../../../redux/api/assets"
import { useFetchAssetTypeQuery } from "../../../../redux/api/assetTypes"
import { getAssetScheduleSelector } from "../../../../redux/asset_schedule/selectors"
import { Asset } from "../../../../redux/assets/types"
import { useAppSelector } from "../../../../redux/reducers"

import Loader from "../../../../components/basic/Loader"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./AssetPicker.sass"

type AssetPickerProps = {
  onAssetSelect: (id: Asset) => void
  onCancelClick: () => void
}
type AssetWithDisabled = Asset & {
  disabled: boolean
}
type ParamsType = {
  assetTypeId: string
}

const AssetPicker = ({
  onAssetSelect: onAssetTypeSelect,
  onCancelClick,
}: AssetPickerProps) => {
  const { assetTypeId } = useParams<ParamsType>()
  const { t } = useTranslation()

  const { data: assetType } = useFetchAssetTypeQuery(assetTypeId)

  const { data: { results: assets = [] } = {}, isSuccess: areAssetsLoaded } =
    useFetchAssetsQuery(
      {
        active: true,
        asset_type: assetTypeId,
      },
      {
        skip: !assetTypeId,
      },
    )

  const { schedule } = useAppSelector(getAssetScheduleSelector)

  const onPick = (asset: Asset) => {
    if (asset) {
      onAssetTypeSelect(asset)
    }
  }
  const assetsWithDisabled = useMemo((): AssetWithDisabled[] => {
    return assets.map((a) => ({
      ...a,
      disabled: !!schedule.find((s) => s.id === a.id),
    }))
  }, [assets, schedule])

  return (
    <SafeViewArea className="asset-picker">
      <TopNav backArrow onClose={onCancelClick} />
      <h2>
        {t("mobile.general.choose_asset", { name: assetType?.name ?? "" })}
      </h2>
      <div className="picker-content">
        {!areAssetsLoaded ? (
          <Loader />
        ) : (
          <div>
            {assetsWithDisabled.map((asset) => (
              <PickerItem
                key={asset.id}
                name={asset.name}
                description={asset.description}
                iconName={asset.asset_type.icon}
                disabled={asset.disabled}
                onClick={() => {
                  onPick(asset)
                }}
              />
            ))}
            {assets.length === 0 && <div>{t("mobile.general.no_assets")}</div>}
          </div>
        )}
      </div>
    </SafeViewArea>
  )
}

export default AssetPicker
