import queryString from "query-string"

import isObject from "lodash.isobject"

const { stringify } = queryString

export type UrlParameters = QueryBuilderRestQlParams & QueryBuilderParams

type QueryBuilderParams = {
  [x: string]: number | boolean | string | undefined | null | RestQlQuery
}

type QueryBuilderRestQlParams = {
  query?: RestQlQuery | null
}

type RestQlQuery = {
  conditions?: RestQlConditions
  fields?: string[]
}

type RestQlConditions = {
  [x: string]: string | number | boolean | undefined | string[] | number[]
}

/*
	Special query builder, it will treat the query parameter
	as a restQLQuery and other parameters as normal queries.
*/
export const queryBuilder = (options?: UrlParameters) => {
  if (!options) return ""

  const { query, ...otherOptions } = options

  const normalParams = stringify(otherOptions)
  const restQLParams = buildRestQLQuery(query)

  return `?${normalParams}${restQLParams}`
}

export const buildRestQLQuery = (restQlObj: RestQlQuery | undefined | null) => {
  if (!restQlObj) return ""
  if (!restQlObj.conditions && !restQlObj.fields) return "&query=(){*}"

  const { conditions = {}, fields = ["*"] } = restQlObj

  const finalQuery = Object.entries(conditions).reduce(
    (previous, [key, value]) => {
      if (isObject(value)) {
        throw new Error("I was not written to handle nested objects")
      }

      if (!value || (Array.isArray(value) && value.length < 1)) {
        return previous
      }

      if (Array.isArray(value)) {
        value = value.join(",")
      }

      if (previous === "") {
        return `${key}: "${value}"`
      }

      return `${previous},${key}: "${value}"`
    },
    "",
  )

  return `&query=(${finalQuery}){${fields.join(",")}}`
}
