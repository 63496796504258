import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { useToast } from "../../hooks/useToast"
import { Input } from "../basic/Input"
import Field from "../Field"
import { setErrors } from "./formUtils"
import PageForm from "./PageFormHook"

import { AmenityResponse } from "../../redux/amenities/types"
import {
  useCreateAmenityMutation,
  useDeleteAmenityMutation,
  useFetchAmenitiesQuery,
  useUpdateAmenityMutation,
} from "../../redux/api/amenities"
import { isApiResponseError, isRejected } from "../../redux/api/types"

import "./AmenityForm.sass"

type AmenityFormProps = {
  amenity: Partial<AmenityResponse>
  query: string
}

type FormValues = {
  name: string
}

const AmenityForm = ({ amenity, query }: AmenityFormProps) => {
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()

  const history = useHistory()

  const { data: { count: amenityCount = 0 } = {} } = useFetchAmenitiesQuery({
    limit: 1,
  })

  const { id, name } = amenity ?? {}

  const methods = useForm<FormValues>({
    defaultValues: {
      name: name ?? "",
    },
  })
  const {
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const [createAmenity] = useCreateAmenityMutation()
  const [updateAmenity] = useUpdateAmenityMutation()
  const [deleteAmenity] = useDeleteAmenityMutation()

  const onCreateClick = useCallback(
    async ({ name }: FormValues) => {
      const response = await createAmenity(name)

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
          return
        }
      }

      analyticsEvent(SupportedEvents.AMENITY_ADD, {
        id,
        name,
        total: amenityCount + 1,
      })

      infoToast(t("desktop.settings.amenities.form.amenity_created_toast"))
      history.push(`/settings/desks/amenities?${query}`)
    },
    [
      history,
      query,
      id,
      amenityCount,
      createAmenity,
      setError,
      errorToast,
      infoToast,
      t,
    ],
  )

  const onUpdateClick = useCallback(
    async ({ name }: FormValues) => {
      if (!id) {
        return
      }
      const response = await updateAmenity({
        id,
        name,
      })

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
          return
        }
      }

      infoToast(t("desktop.settings.amenities.form.amenity_update_toast"))
      history.push(`/settings/desks/amenities?${query}`)
    },
    [id, history, query, updateAmenity, setError, errorToast, infoToast, t],
  )

  const onDeleteClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()

      if (!id) {
        return
      }

      const response = await deleteAmenity(id)

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
          return
        }
      }

      analyticsEvent(SupportedEvents.AMENITY_DELETE, {
        id,
        total: amenityCount - 1,
      })

      infoToast(t("desktop.settings.amenities.form.amenity_deleted_toast"))
      history.push(`/settings/desks/amenities?${query}`)
    },
    [
      id,
      history,
      query,
      amenityCount,
      deleteAmenity,
      setError,
      infoToast,
      t,
      errorToast,
    ],
  )

  const updateMode = !!id

  return (
    <FormProvider {...methods}>
      <PageForm
        backUrl={`/settings/desks/amenities?${query}`}
        className="AmenityForm"
        updateMode={updateMode}
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        onDelete={onDeleteClick}
      >
        <Field
          control={control}
          name="name"
          label={t("desktop.settings.amenities.form.name")}
        >
          {(props) => (
            <Input
              autoFocus
              maxLength={60}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>
      </PageForm>
    </FormProvider>
  )
}

export default AmenityForm
