import { useAppSelector } from "../redux/reducers"
import { selectSettingsEffective } from "../redux/settings/selectors"
import { selectUser } from "../redux/user/selectors"
import { isNormalUser } from "../redux/user/utils"

type Options = {
  globalSettings?: boolean
  adminSettings?: boolean
}

const ADMIN_WINDOW_LENGTH = 365

const useCheckReservationWindowLength = (
  options: Options = {
    globalSettings: false,
    adminSettings: false,
  },
) => {
  const { globalSettings, adminSettings } = options
  const { entry: settings } = useAppSelector(selectSettingsEffective)
  const { entry: user } = useAppSelector(selectUser)

  if (adminSettings) {
    return ADMIN_WINDOW_LENGTH
  }

  if (globalSettings || isNormalUser(user)) {
    return settings?.desk_reservation_window_length
  }

  return ADMIN_WINDOW_LENGTH
}

export default useCheckReservationWindowLength
