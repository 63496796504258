import { ThunkApiConfig } from "RootType"

import { companyConsentURL, get, patchJSON, postJSON } from "../../api"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import { Consent } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export const fetchCompanyConsent = createAsyncThunk<
  undefined,
  void,
  ThunkApiConfig
>("consent/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(
    companyConsentURL("desk_booking"),
    {},
    access_token,
  )

  if (response.ok) {
    const json = await response.json()
    const consent =
      Array.isArray(json.results) &&
      json.results.find((res: Consent) => res.consent_type === "desk_booking")

    return consent?.status ?? null
  }

  throw new Error(await getErrorMessage(response))
})

export const createCompanyConsent = createAsyncThunk<
  boolean,
  boolean | undefined,
  ThunkApiConfig
>("consent/create", async (status = true, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    companyConsentURL("desk_booking"),
    { body: { status } },
    access_token,
  )

  if (response.ok) {
    const json = await response.json()
    return json
  }

  throw new Error(await getErrorMessage(response))
})

export const updateCompanyConsent = createAsyncThunk<
  boolean,
  boolean | undefined,
  ThunkApiConfig
>("consent/update", async (status = true, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await patchJSON(
    companyConsentURL("desk_booking"),
    { body: { status } },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface ConsentState extends SliceState {
  entry: boolean | null
}

const initialState: ConsentState = {
  ...sliceInitialState,
  entry: null,
}

const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyConsent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCompanyConsent.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchCompanyConsent.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload ?? null
    })

    builder.addCase(createCompanyConsent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createCompanyConsent.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(createCompanyConsent.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })

    builder.addCase(updateCompanyConsent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateCompanyConsent.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(updateCompanyConsent.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })
  },
})

export const consentReducer = consentSlice.reducer
