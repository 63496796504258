import dayjs from "dayjs"
import i18next from "i18next"

import { parseApiError, ResponseError } from "../api/apiUtils"
import { formatErrorResponse } from "../api/utils"
import {
  PaginationState,
  PayloadWithAction,
  ScheduleState,
  SliceState,
} from "./types"

export const sliceInitialState: SliceState = {
  isLoaded: false,
  isLoading: false,
  error: null,
  isSubmitting: false,
  submitError: null,
}

export const paginationInitialState: PaginationState = {
  offset: 0,
  count: 0,
}

export const getErrorMessage = async (response: Response): Promise<string> => {
  let text = await response.text()
  try {
    text = JSON.parse(text)
  } catch {}

  return formatErrorResponse(text || response.statusText)
}

export const getErrorObject = async (
  response: Response,
): Promise<ResponseError> => {
  const json = await response.json()

  return parseApiError(json)
}

export const setFetchErrorState = <
  TState extends SliceState,
  TAction extends PayloadWithAction,
>(
  state: TState,
  action: TAction,
): void => {
  state.isLoading = false
  state.isLoaded = true
  state.error = action.error.message ?? getDefaultErrorMessage()
}

export const setFetchSuccessState = <TState extends SliceState>(
  state: TState,
): void => {
  state.isLoading = false
  state.isLoaded = true
  state.error = null
}

export const setSubmitErrorState = <
  TState extends SliceState,
  TAction extends PayloadWithAction,
>(
  state: TState,
  action: TAction,
): void => {
  state.isSubmitting = false
  state.submitError = action.error.message || getDefaultErrorMessage()
}

export const setSubmitSuccessState = <TState extends SliceState>(
  state: TState,
): void => {
  state.isSubmitting = false
  state.submitError = null
}

export const getDefaultErrorMessage = () =>
  i18next.t("mobile.general.something_wrong")

export const checkInReservation = (
  state: ScheduleState,
  payload: string,
): void => {
  state.schedule.map((key) => {
    return key.schedule.map((day) => {
      return day.reservations.map((reservation) => {
        if (reservation.id === payload) {
          reservation.checked_in = {
            check_in_at: dayjs().toISOString(),
          }
        }
        return reservation
      })
    })
  })
}
