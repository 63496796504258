import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"

type Props = {
  value: string
  onChange: (v: string) => void
}

const VisitorsFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const visitorsOptions = [
    {
      label: t("desktop.manage.visitors.filters.all_visitors"),
      value: "all",
    },
    {
      label: t("desktop.manage.visitors.filters.currently_signed_in"),
      value: "present",
    },
  ]

  return (
    <Dropdown
      className="visitors-filter"
      options={visitorsOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export default VisitorsFilter
