import { Dayjs } from "dayjs"

import ReservationData from "./ReservationData"
import { ScheduleCheckinLabel } from "./ScheduleCheckingLabel"

import {
  AssetScheduleReservation,
  ScheduleAssetDataRow,
} from "../../redux/asset_schedule/types"
import { formatUser } from "../../redux/user/utils"

type DeskCellProps = {
  day: Dayjs
  header: ScheduleAssetDataRow
  schedule: AssetScheduleReservation[]
  renderVacancy?: (d: {
    header: ScheduleAssetDataRow
    day: Dayjs
    disabled?: boolean
  }) => JSX.Element
  onClick: (r: AssetScheduleReservation) => void
  rowIndex: string
  disabled?: boolean
}

/**
 * NOTE: this cell differs from other scheduling since for asset the timeslots
 * options are not taken into account. No check for partial timeslot or for
 * other available timeslot is performed here
 */
export default function AssetScheduleCell({
  day,
  header,
  schedule,
  onClick,
  renderVacancy,
  rowIndex,
  disabled,
}: DeskCellProps) {
  return (
    <>
      {schedule.map((entry, k) => (
        <ReservationData
          key={`entry-${rowIndex}- ${k}`}
          onClick={() => onClick(entry)}
          showOwnership={false}
          idString={entry.user.email}
          iconType="ellipse"
          isPartial={false}
          label={formatUser(entry.user)}
          user={entry.user}
        >
          <ScheduleCheckinLabel day={day} reservation={entry} />
        </ReservationData>
      ))}

      {!!renderVacancy &&
        renderVacancy({
          header,
          day,
        })}
    </>
  )
}
