import React, { PropsWithChildren, useEffect } from "react"

import i18n from "i18next"
import { I18nextProvider } from "react-i18next"

import { DEFAULT_NS, WHITELABEL_NS } from "../constants"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"

function LocalizationProvider({ children }: PropsWithChildren<unknown>) {
  const { isAppWhitelabel } = useAppSelector(getAppParams)

  const isWl = isAppWhitelabel ? WHITELABEL_NS : DEFAULT_NS

  useEffect(() => {
    i18n.setDefaultNamespace(isWl)
  }, [isWl])

  return (
    <I18nextProvider i18n={i18n} defaultNS={isWl}>
      {children}
    </I18nextProvider>
  )
}

export default LocalizationProvider
