import React from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { useCustomContentContext } from "./CustomContentContext"
import EditCustomContentForm from "./EditCustomContentForm"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchCustomContentQuery } from "../../../../redux/api/customContent"

import Switch from "../../../../components/basic/Switch"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

type ParamsType = {
  content_id: string
}

const EditCustomContent = () => {
  const { t } = useTranslation()

  const { content_id } = useParams<ParamsType>()

  const { data: customContent } = useFetchCustomContentQuery(
    content_id ?? skipToken,
  )

  const { status, onChangeStatus } = useCustomContentContext()

  const title = customContent
    ? t("desktop.settings.rooms.custom_content.forms.general.edit_title")
    : t("desktop.settings.rooms.custom_content.forms.general.add_title")

  return (
    <View className="EditCustomContent">
      <Breadcrumbs
        depth={3}
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.custom_content.title"),
          title,
        ]}
      />

      <Intro>
        <div className="description">
          {t("desktop.settings.rooms.custom_content.intro")}
        </div>
      </Intro>

      <Space size={0.75} />

      <div>
        <Switch
          label={t(
            "desktop.settings.rooms.custom_content.forms.general.enabled_custom_content_label",
          )}
          value={Boolean(status)}
          onChange={(checked) => onChangeStatus(Number(checked))}
        />
      </div>

      <Space size={0.8} />

      <EditCustomContentForm customContent={customContent} />
    </View>
  )
}

export default EditCustomContent
