import {
  DESK_EVENTS,
  ROOM_EVENTS,
  SYSTEM_EVENTS,
  VISITOR_EVENTS,
} from "../../../../redux/api/notifications/types"

export const ROLE_EVENTS = {
  ADMIN: {
    SYSTEM: [
      SYSTEM_EVENTS.DEVICE_BATTERY_LOW,
      SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC,
      SYSTEM_EVENTS.PREPAID_CODE_EXPIRED,
      SYSTEM_EVENTS.UNPAID_INVOICE,
      SYSTEM_EVENTS.TRIAL_EXPIRED,
      SYSTEM_EVENTS.MISSING_BILLING_DETAILS,
      SYSTEM_EVENTS.DEVICE_WITHOUT_LICENSE,
      SYSTEM_EVENTS.USER_WITHOUT_LICENSE,
    ],
    DESK: [
      DESK_EVENTS.RESERVATION_NEW,
      DESK_EVENTS.RESERVATION_DELETE,
      DESK_EVENTS.RESERVATION_CANCELLED,
      DESK_EVENTS.RESERVATION_RELEASED,
      DESK_EVENTS.CHECKIN_REMINDER,
    ],
    ROOM: [ROOM_EVENTS.MEETING_REMINDER, ROOM_EVENTS.MEETING_ENDING],
    VISITOR: [
      VISITOR_EVENTS.INVITE_CREATED_HOST,
      VISITOR_EVENTS.INVITE_UPDATED_HOST,
      VISITOR_EVENTS.INVITE_CANCELLED_HOST,
      VISITOR_EVENTS.INVITE_CHECKED_IN_HOST,
      VISITOR_EVENTS.INVITE_CREATED_VISITOR,
      VISITOR_EVENTS.INVITE_UPDATED_VISITOR,
      VISITOR_EVENTS.INVITE_CANCELLED_VISITOR,
      VISITOR_EVENTS.INVITE_CHECKED_IN_VISITOR,
      VISITOR_EVENTS.EASY_CHECKIN,
      VISITOR_EVENTS.DOCUMENT_SIGNED,
      VISITOR_EVENTS.QUESTIONNAIRE_FAILED,
      VISITOR_EVENTS.DEVICE_ERROR,
    ],
  },
  OFFICE_MANAGER: {
    SYSTEM: [
      SYSTEM_EVENTS.DEVICE_BATTERY_LOW,
      SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC,
    ],
    DESK: [
      DESK_EVENTS.RESERVATION_NEW,
      DESK_EVENTS.RESERVATION_DELETE,
      DESK_EVENTS.RESERVATION_CANCELLED,
      DESK_EVENTS.RESERVATION_RELEASED,
      DESK_EVENTS.CHECKIN_REMINDER,
    ],
    ROOM: [ROOM_EVENTS.MEETING_REMINDER, ROOM_EVENTS.MEETING_ENDING],
    VISITOR: [
      VISITOR_EVENTS.INVITE_CREATED_VISITOR,
      VISITOR_EVENTS.INVITE_UPDATED_VISITOR,
      VISITOR_EVENTS.INVITE_CANCELLED_VISITOR,
      VISITOR_EVENTS.INVITE_CHECKED_IN_VISITOR,
      VISITOR_EVENTS.EASY_CHECKIN,
      VISITOR_EVENTS.DOCUMENT_SIGNED,
      VISITOR_EVENTS.QUESTIONNAIRE_FAILED,
      VISITOR_EVENTS.DEVICE_ERROR,
    ],
  },
  REGULAR_USER: {
    DESK: [
      DESK_EVENTS.RESERVATION_NEW,
      DESK_EVENTS.RESERVATION_DELETE,
      DESK_EVENTS.RESERVATION_CANCELLED,
      DESK_EVENTS.RESERVATION_RELEASED,
      DESK_EVENTS.CHECKIN_REMINDER,
    ],
    ROOM: [ROOM_EVENTS.MEETING_REMINDER, ROOM_EVENTS.MEETING_ENDING],
    VISITOR: [
      VISITOR_EVENTS.INVITE_CREATED_VISITOR,
      VISITOR_EVENTS.INVITE_UPDATED_VISITOR,
      VISITOR_EVENTS.INVITE_CANCELLED_VISITOR,
      VISITOR_EVENTS.INVITE_CHECKED_IN_VISITOR,
      VISITOR_EVENTS.EASY_CHECKIN,
      VISITOR_EVENTS.DOCUMENT_SIGNED,
      VISITOR_EVENTS.QUESTIONNAIRE_FAILED,
      VISITOR_EVENTS.DEVICE_ERROR,
    ],
  },
}
