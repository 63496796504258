import isObject from "lodash.isobject"
import isString from "lodash.isstring"

export const isNetworkError = (e) =>
  e?.message?.includes("TypeError: Failed to fetch") ||
  e?.message?.includes("The Internet connection appears to be offline.")

function concatObjectValues(object) {
  return Object.keys(object)
    .map((key) => {
      if (key === "_error_code") {
        return ""
      }
      let item = object[key]
      if (Array.isArray(item)) {
        if (key !== "detail") {
          item = item.map((i) => {
            if (isObject(i)) {
              return formatErrorResponse(i)
            }
            return i
          })
          return `${key} - ${[...new Set(item.flat())]}`
        } else {
          return [...new Set(item.flat())].join(", ")
        }
      } else {
        if (key !== "detail") {
          return `${key} - ${item}`
        } else {
          return item
        }
      }
    })
    .filter((s) => s !== "")
    .join("\n")
    .replace("_error", "error")
}

function concatErrorArray(errors) {
  return errors
    .map((entry) => {
      if (isObject(entry)) {
        return concatObjectValues(entry)
      } else {
        return entry
      }
    })
    .join("\n")
    .replace("_error", "error")
}

export function formatErrorResponse(response) {
  if (Array.isArray(response)) {
    return concatErrorArray(response)
  } else if (isObject(response)) {
    return concatObjectValues(response)
  } else if (isString(response)) {
    return response
  } else {
    return JSON.stringify(response)
  }
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
