import React from "react"

import { useTranslation } from "react-i18next"

import BillingSection from "../BillingSection"
import LicenseCodeCard from "../PrepaidCode/PrepaidCodeCard"
import PaymentDetails from "./PaymentDetails"

import "./styles.sass"

const Payments = () => {
  const { t } = useTranslation()

  return (
    <BillingSection
      title={t("desktop.settings.billing.payments.title")}
      intro={t("desktop.settings.billing.payments.intro")}
      className="Payments"
    >
      <PaymentDetails />
      <LicenseCodeCard />
    </BillingSection>
  )
}

export default Payments
