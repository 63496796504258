import { api, invalidateOn } from "../.."
import { resellersURL } from "../../../../api"
import { Reseller } from "./types"

export const reseller = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchReseller: builder.query<Reseller, void>({
      query: () => resellersURL(),
      providesTags: ["Reseller"],
    }),

    createReseller: builder.mutation<Reseller, Reseller>({
      query: (body) => ({
        url: resellersURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: invalidateOn({ success: ["Reseller", "Payments"] }),
    }),

    updateReseller: builder.mutation<Reseller, Reseller>({
      query: (body) => ({
        url: resellersURL(),
        method: "PATCH",
        body,
      }),
      invalidatesTags: invalidateOn({ success: ["Reseller", "Payments"] }),
    }),
  }),
})

export const {
  useFetchResellerQuery,
  useCreateResellerMutation,
  useUpdateResellerMutation,
} = reseller
