import { useState } from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import ReactModal from "react-modal"

import { TYPEFORM_CANCELLATION_FORM_ID } from "../../../constants"
import { contentStyle, overlayStyle } from "../../../modals/modalStyles"
import { getLabel } from "../../../utils"
import { getSolutionNameTranslationKey } from "./utils"
import { Widget } from "@typeform/embed-react"

import { useFetchSubscriptionsQuery } from "../../../redux/api/billing/subscriptions"
import { PlanGroup } from "../../../redux/api/billing/types"
import { groupToTranslationKey } from "../../../redux/api/billing/utils"
import { useFetchMeQuery } from "../../../redux/api/me"
import { SolutionTypes } from "../../../redux/api/solutions/types"

import Button from "../../../components/advanced/Button"
import Space from "../../../components/Space"

import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./SolutionActivationModal.sass"

type CancellationFormProps = {
  solution: string
}

export const CancellationForm = ({ solution }: CancellationFormProps) => {
  const { t } = useTranslation()

  const { data: { subsByPlanGroup } = {}, isLoading: areSubscriptionsLoading } =
    useFetchSubscriptionsQuery()

  const { data: user } = useFetchMeQuery()

  const email = user?.email ?? ""

  if (
    !TYPEFORM_CANCELLATION_FORM_ID ||
    areSubscriptionsLoading ||
    !subsByPlanGroup
  ) {
    return null
  }

  let solutionName = solution

  const keys = Object.keys(subsByPlanGroup) as PlanGroup[]

  const planName = keys.find((key) => {
    const group = t(groupToTranslationKey(key))
    if (group === solution) {
      solutionName = t(groupToTranslationKey(key), {
        lng: "en",
      })
      return true
    }
    return false
  })

  if (!planName) {
    return null
  }

  const planSubscriptions = subsByPlanGroup[planName].map((sub) => ({
    plan_name: sub.plan_name,
    plan_id: sub.plan_id,
  }))

  return (
    <Widget
      className="cancellation-form"
      id={TYPEFORM_CANCELLATION_FORM_ID}
      style={{
        height: "32rem",
      }}
      hidden={{
        email,
        solutions: solutionName,
        subscription_id: planSubscriptions.map((sub) => sub.plan_id).join(","),
        plan_name: planSubscriptions.map((sub) => sub.plan_name).join(","),
      }}
    />
  )
}

type Props = {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  className?: string
  isActivation: boolean
  isTrial: boolean
  hasTrialAvailable: boolean
  hasActiveSubscription: boolean
  solution: SolutionTypes
}

const SolutionActivationModal = ({
  open,
  onConfirm,
  onCancel,
  className,
  isActivation,
  isTrial,
  hasTrialAvailable,
  hasActiveSubscription,
  solution,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()
  const solutionName = t(getSolutionNameTranslationKey(solution))

  const cn = classNames("SolutionActivationModal ModalForm", className)

  const confirmButtonText = t(
    `desktop.components.solution_activation_modal.buttons.yes_${
      isActivation
        ? hasTrialAvailable
          ? "activate_trial"
          : hasActiveSubscription
            ? "activate_solution"
            : "activate"
        : "deactivate"
    }` as ParseKeys,
  )

  const title = t(
    `desktop.components.solution_activation_modal.${
      isActivation ? "enable" : "disable"
    }.title` as ParseKeys,
    { solutionName },
  )

  const getDescription = () => {
    const supportEmail = getLabel("links.supportEmail")

    if (!isActivation) {
      if (isTrial) {
        return (
          <Trans
            i18nKey="desktop.components.solution_activation_modal.disable.description"
            values={{ solutionName }}
          >
            <Space size={0.5} />
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </Trans>
        )
      }
      if (!hasActiveSubscription) {
        return t(
          "desktop.components.solution_activation_modal.disable.without_subscription_description",
          { solutionName },
        )
      }
      return (
        <div>
          {solution === "visitor" && (
            <div className="mb-s">
              {t(
                "desktop.components.solution_activation_modal.disable.visitor_description",
              )}
            </div>
          )}
          <CancellationForm solution={solutionName} />
        </div>
      )
    }
    if (hasTrialAvailable) {
      return t(
        "desktop.components.solution_activation_modal.enable.description",
      )
    }

    if (hasActiveSubscription) {
      return t(
        "desktop.components.solution_activation_modal.enable.with_subscription_description",
        { solutionName },
      )
    }

    return (
      <Trans i18nKey="desktop.components.solution_activation_modal.enable.no_trial_description">
        <Space size={0.5} />
      </Trans>
    )
  }

  const handleOnConfirm = async () => {
    setIsSubmitting(true)
    await onConfirm()
    setIsSubmitting(false)
  }
  const areButtonsVisible =
    isActivation || isTrial || (!isActivation && !hasActiveSubscription)

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => onCancel()}
      ariaHideApp={false}
      style={{
        content: contentStyle,
        overlay: overlayStyle,
      }}
    >
      <div className={cn}>
        <div className="top-bar">
          <h1>{title}</h1>
          <button className="close" onClick={() => onCancel()}>
            <CrossSVG />
          </button>
        </div>

        <div className="hint">{getDescription()}</div>
        {areButtonsVisible && (
          <div className="actions actions-edit">
            <Button variant="secondary" onClick={onCancel}>
              {t("general.no")}
            </Button>
            <Button
              variant="primary"
              onClick={handleOnConfirm}
              isDisabled={isSubmitting}
            >
              {confirmButtonText}
            </Button>
          </div>
        )}
      </div>
    </ReactModal>
  )
}
export default SolutionActivationModal
