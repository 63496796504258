import { ThunkApiConfig } from "RootType"

import {
  get,
  invitesExportURL,
  invitesImportURL,
  invitesURL,
  postData,
  postJSON,
} from "../../api"
import { timeZone } from "../../dayjs"
import { InviteRequest } from "../invite/types"
import {
  getErrorMessage,
  paginationInitialState,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { PaginationState, SliceState } from "../types"
import {
  FetchOptions,
  InviteCSVResponse,
  InviteListResponse,
  InvitesListResponse,
} from "./types"
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit"

import { FilterSpecialValues } from "../../components/Filter/types"

export const fetchInvites = createAsyncThunk<
  InvitesListResponse,
  FetchOptions,
  ThunkApiConfig
>("invites/fetch", async (options, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const { building_id, ...rest } = options
  const isAllBuilding = building_id === FilterSpecialValues.ALL

  const response = await get(
    invitesURL({ ...rest, ...(!isAllBuilding && { building_id }) }),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }
  return rejectWithValue(await getErrorMessage(response))
})

export const getInvitesTotal = createAsyncThunk<
  number,
  FetchOptions,
  ThunkApiConfig
>("invites/getTotal", async (options, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const { building_id, search, show, ...rest } = options
  const isAllBuilding = building_id === FilterSpecialValues.ALL
  const response = await get(
    invitesURL({ ...rest, ...(!isAllBuilding && { building_id }), limit: 1 }),
    {},
    access_token,
  )

  if (response.ok) {
    const result = await response.json()

    return result.count
  }

  return 0
})

export const importInvites = createAsyncThunk<
  InviteCSVResponse[],
  File,
  ThunkApiConfig
>("invites/importCSV", async (file, { getState }) => {
  const { access_token } = getState().auth

  const response = await postData(
    invitesImportURL({
      tz: timeZone,
    }),
    { body: { file } },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export type SaveImportProps = {
  send_invite_to_visitor: boolean
  send_notification_to_host: boolean
  invites: InviteRequest[]
}

export const importInvitesSave = createAsyncThunk<
  InviteCSVResponse[],
  SaveImportProps,
  ThunkApiConfig
>("invites/importSave", async (payload, { getState }) => {
  const { access_token } = getState().auth

  const response = await postJSON(
    invitesImportURL({
      send_invite_to_visitor: payload.send_invite_to_visitor,
      send_notification_to_host: payload.send_notification_to_host,
    }),
    { body: payload.invites },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const exportInvites = createAsyncThunk<
  string,
  FetchOptions,
  ThunkApiConfig
>("invites/export", async (options, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(invitesExportURL(options), {}, access_token)

  if (response.ok) {
    return await response.text()
  }
  return rejectWithValue(await getErrorMessage(response))
})

export interface InvitesState extends SliceState, PaginationState {
  entries: InviteListResponse[]
  imports: InviteCSVResponse[]
  total: number
}

const initialState: InvitesState = {
  ...sliceInitialState,
  ...paginationInitialState,
  entries: [],
  imports: [],
  total: 0,
}

const invitesSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {
    importInvitesEdit: (
      state,
      { payload }: PayloadAction<InviteCSVResponse>,
    ) => {
      const imports = state.imports.slice(0)
      const index = imports.findIndex((i) => i.id === payload.id)

      imports.splice(index, 1, payload)

      state.imports = imports
    },
    importInvitesRemove: (state, { payload }: PayloadAction<string>) => {
      state.imports = state.imports.filter((i) => i.id !== payload)
    },
    importInvitesRemoveAll: (state) => {
      state.imports = []
    },
    inviteRemove: (state, { payload }: PayloadAction<string>) => {
      state.entries = state.entries.filter((invite) => invite.id !== payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvites.fulfilled, (state, { payload, meta }) => {
      state.count = payload.count
      state.offset = meta.arg?.offset ?? 0
      state.entries = payload.results
      if (
        (meta.arg.search === "" && meta.arg.show === "") ||
        state.total === -1
      ) {
        state.total = state.count
      }
      setFetchSuccessState(state)
    })
    builder.addCase(getInvitesTotal.fulfilled, (state, { payload }) => {
      state.total = payload
    })

    builder.addMatcher(
      isAnyOf(importInvites.fulfilled, importInvitesSave.fulfilled),
      (state, { payload }) => {
        const imports = payload
          .map((p, i) => {
            return {
              ...p,
              id: `import-${i}`,
            }
          })
          .sort((a, b) => {
            if (a.errors && !b.errors) {
              return -1
            }
            if (!a.errors && b.errors) {
              return 1
            }
            return 0
          })

        state.imports = imports

        setSubmitSuccessState(state)
      },
    )

    builder.addMatcher(isAnyOf(fetchInvites.pending), (state) => {
      state.isLoading = true
    })
    builder.addMatcher(
      isAnyOf(importInvites.pending, importInvitesSave.pending),
      (state) => {
        state.isSubmitting = true
      },
    )
    builder.addMatcher(isAnyOf(fetchInvites.rejected), (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addMatcher(
      isAnyOf(importInvites.rejected, importInvitesSave.rejected),
      (state, action) => {
        setSubmitErrorState(state, action)
      },
    )
  },
})

export const invitesReducer = invitesSlice.reducer
export const {
  importInvitesEdit,
  importInvitesRemove,
  importInvitesRemoveAll,
  inviteRemove,
} = invitesSlice.actions
