import React from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import Button from "../advanced/Button"

import ArrowBackSVG from "../../assets/images/icons/ArrowBack.svg"
import CrossSVG from "../../assets/images/icons/Cross.svg"

import "./TopNav.sass"

type TopNavProps = {
  backArrow?: boolean
  onGoBack?: () => void
  title?: string | React.ReactNode
  titleCenter?: boolean
  rightIcon?: JSX.Element
  onTopScreen?: boolean
  onClose?: () => void
}

export const TopNav: React.FC<TopNavProps> = ({
  backArrow = true,
  onGoBack,
  title,
  titleCenter = false,
  rightIcon,
  onTopScreen = true,
  onClose,
}) => {
  const history = useHistory()

  const TopNavName = classNames({
    TopNav: true,
    top: onTopScreen,
    "with-close": !!onClose,
  })

  const titleClassName = classNames({
    title: true,
    center: titleCenter,
  })

  return (
    <div className={TopNavName}>
      <div className="back-arrow">
        {backArrow && (
          <div
            className="arrow"
            onClick={onGoBack ? onGoBack : () => history.goBack()}
          >
            <ArrowBackSVG />
          </div>
        )}
      </div>
      <div className={titleClassName}>{title}</div>
      {rightIcon && <div className="right-icon">{rightIcon}</div>}
      {onClose && (
        <Button className="close-button" onClick={onClose}>
          <CrossSVG />
        </Button>
      )}
    </div>
  )
}
