import { useMemo } from "react"

import { useDispatch } from "react-redux"
import { ActionCreatorsMapObject, bindActionCreators } from "redux"

import { AppDispatch } from "./reducers"
import { ReservationResponse } from "./reservations/types"

import { FilterSpecialValues } from "../components/Filter/types"

type ReplaceReturnType<T extends (...a: any) => any, TNewReturn> = (
  ...a: Parameters<T>
) => TNewReturn

type ActionCreators<A extends ActionCreatorsMapObject> = {
  [K in keyof A]: ReplaceReturnType<A[K], ReturnType<ReturnType<A[K]>>>
}

export function useActions<A extends ActionCreatorsMapObject>(
  actions: A,
  deps?: any[],
) {
  const dispatch = useDispatch<AppDispatch>()
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    deps ? [dispatch, ...deps] : [dispatch],
  ) as ActionCreators<A>
}

export const ignoreAllFilter = (filter: string) => {
  return filter === FilterSpecialValues.ALL ? FilterSpecialValues.EMPTY : filter
}

export const byDateString =
  (dateString: string) => (res: ReservationResponse) =>
    res.date === dateString

export const bySeatId = (seat_id: string) => (res: ReservationResponse) =>
  res.seat && res.seat.id === seat_id

export const byUserId = (user_id: string) => (res: ReservationResponse) =>
  res.user && res.user.id === user_id

export const byNotUserId = (user_id: string) => (res: ReservationResponse) =>
  res.user && res.user.id !== user_id
