import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useTranslation } from "react-i18next"
import { Value, ValueJSON } from "slate"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { CUSTOM_CONTENT_TYPES } from "../constants"
import { useCustomContentContext } from "../CustomContentContext"
import { isTextContent } from "../utils"
import {
  GRAY_100,
  PARAGRAPH,
  VERTICAL_ALIGNMENT_TOP,
} from "./TextEditor/constants"
import TextEditor from "./TextEditor/TextEditor"

import PageForm from "../../../../../components/Form/PageFormHook"

import "./EditTextForm.sass"

const initialValue: ValueJSON = {
  document: {
    object: "document",
    nodes: [
      {
        data: {
          theme: GRAY_100,
          verticalAlignment: VERTICAL_ALIGNMENT_TOP,
        },
        isVoid: false,
        object: "block",
        type: "background-theme",
        nodes: [
          {
            object: "block",
            type: PARAGRAPH,
            nodes: [
              {
                object: "text",
                leaves: [
                  {
                    object: "leaf",
                    text: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}

const EditTextForm = () => {
  const { t } = useTranslation()
  const { push } = useNavigation()

  const {
    paths,
    content,
    selectedSection,
    onUpdateSectionContent,
    isUpdateMode,
  } = useCustomContentContext()

  const [value, setValue] = useState<Value>(Value.fromJSON(initialValue))

  const sectionContent = useMemo(
    () => content.sections[selectedSection],
    [content.sections, selectedSection],
  )

  const onUpdateClick = useCallback(async () => {
    onUpdateSectionContent(selectedSection, {
      type: CUSTOM_CONTENT_TYPES.text,
      content: {
        text: value.toJSON(),
      },
    })

    push(paths.root)
  }, [onUpdateSectionContent, paths.root, push, selectedSection, value])

  useEffect(() => {
    if (!sectionContent?.content) return

    const { content } = sectionContent

    if (isTextContent(content)) {
      setValue(Value.fromJSON(content.text ?? initialValue))
    }
  }, [sectionContent, sectionContent.content])

  return (
    <div className="EditTextForm">
      <PageForm
        updateMode
        onUpdate={onUpdateClick}
        backUrl={isUpdateMode ? paths.root : paths.contentTypes}
      >
        <div>
          <div className="Label">
            {t(
              "desktop.settings.rooms.custom_content.forms.text.add_text_label",
            )}
          </div>

          <div className="SubText">
            {t(
              "desktop.settings.rooms.custom_content.forms.text.add_text_subtext",
            )}
          </div>
        </div>

        <TextEditor value={value} setValue={setValue} />
      </PageForm>
    </div>
  )
}

export default EditTextForm
