import React, { FC, useEffect, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { timeZone } from "../../../../dayjs"
import { useToast } from "../../../../hooks/useToast"
import {
  isRecurringType,
  RecurringType,
  RepeatPickerTypes,
} from "../../../../types/sharedTypes"
import { shortUserTimeFormat } from "../../../../utils"
import { current } from "@reduxjs/toolkit"
import { skipToken } from "@reduxjs/toolkit/query"

import { useFetchBuildingQuery } from "../../../../redux/api/buildings"
import {
  createAssetReservation,
  updateAssetReservation,
} from "../../../../redux/asset_reservation/assetReservationSlice"
import {
  CreateAssetReservation,
  FailedRecurringAssetReservation,
  isRecurringAssetReservation,
} from "../../../../redux/asset_reservation/types"
import {
  FetchAssetScheduleProps,
  fetchAssetsSchedule,
} from "../../../../redux/asset_schedule/assetScheduleSlice"
import {
  bookAssetAddRecurring,
  clearBookAsset,
  setBookAsset,
} from "../../../../redux/book_asset/bookAssetSlice"
import {
  BookAsset,
  RecurringReservationProps,
} from "../../../../redux/book_asset/types"
import { RootState, useAppSelector } from "../../../../redux/reducers"
import { selectUser } from "../../../../redux/user/selectors"
import { useActions } from "../../../../redux/utils"

import Button from "../../../../components/advanced/Button"
import FailedReservations from "../../../../components/FailedReservations"
import { Drawer } from "../../../../components/Mobile/Drawer"
import {
  RepeatPicker,
  repeatPickerTypes,
} from "../../../../components/Mobile/RepeatPicker"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import PencilSVG from "../../../../assets/images/icons/Pencil.svg"

import "./Summary.sass"

type SummaryProps = {
  title?: string
}

const Summary: React.FC<SummaryProps> = ({ title }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { errorToast } = useToast()

  const [loading, setLoading] = useState(false)
  const [recurringMeetingsError, setRecurringMeetingsError] =
    useState<boolean>(false)
  const [failedRecurringMeetings, setFailedRecurringMeetings] = useState<
    FailedRecurringAssetReservation[]
  >([])

  const actions = useActions({
    bookAssetAddRecurring: (recu: RecurringReservationProps) =>
      bookAssetAddRecurring(recu),
    clearBookAsset: () => clearBookAsset(null),
    setBookAsset: (book: BookAsset) => setBookAsset(book),
    fetchAssetsSchedule: (params: FetchAssetScheduleProps) =>
      fetchAssetsSchedule({ ...params, show: "reserved" }),
    createAssetReservation: (reservation: CreateAssetReservation) =>
      createAssetReservation(reservation),
    updateAssetReservation: (reservation: {
      reservationId: string
      payload: Partial<CreateAssetReservation>
    }) => updateAssetReservation(reservation),
  })

  const {
    id: reservationId,
    building,
    asset,
    start,
    end,
    recurring,
    timezone,
  } = useAppSelector((state: RootState) => state.bookAsset.bookAsset)
  const { entry: user } = useAppSelector(selectUser)

  if (title === "") {
    title = t("mobile.book.default_title")
  }

  const handleClose = () => {
    actions.clearBookAsset()
    history.push("/home/reservations")
  }

  const [repeatPicker, setRepeatPicker] = useState(false)

  const { data: currentBuilding } = useFetchBuildingQuery(
    building?.id
      ? {
          id: building?.id,
        }
      : skipToken,
  )

  const buildingTimezone =
    currentBuilding?.settings_effective?.timezone ?? timeZone

  const forceTimeslotUse =
    currentBuilding?.settings_effective?.desk_force_timeslot_use ?? false

  useEffect(() => {
    if (buildingTimezone && !timezone) {
      setCurrentTz(buildingTimezone)
    }
  }, [buildingTimezone])

  const [currentTz, setCurrentTz] = useState<string>(timezone ?? timeZone)

  const handleRepeatPick = (
    repeatOption: RepeatPickerTypes,
    untilDate: Dayjs | null,
  ) => {
    setRepeatPicker(false)
    actions.bookAssetAddRecurring({
      freq: repeatOption,
      until: untilDate?.toISOString() ?? dayjs().toISOString(),
    })
  }

  const handleContinue = async () => {
    if (start && end) {
      actions.setBookAsset({
        building,
        start,
        end,
      })

      await actions.fetchAssetsSchedule({
        building_id: building?.id,
        start,
        end,
      })
      history.push("/book/asset/done")
    }
  }

  const handleRecurringMeetingsError = (
    failedArray: FailedRecurringAssetReservation[],
  ) => {
    setRecurringMeetingsError(true)
    setFailedRecurringMeetings(failedArray)
  }

  const handleConfirm = async () => {
    if (asset && start && end) {
      const reservation: CreateAssetReservation = {
        tz: currentTz,
        asset_id: asset?.id,
        user_email: user.email,
        start,
        end,
        ...(recurring &&
          isRecurringType(recurring?.freq) && {
            recurring: {
              freq: recurring?.freq as RecurringType,
              until: recurring.until,
            },
          }),
      }

      setLoading(true)

      const response = reservationId
        ? await actions.updateAssetReservation({
            reservationId,
            payload: reservation,
          })
        : await actions.createAssetReservation(reservation)

      if (
        createAssetReservation.rejected.match(response) ||
        updateAssetReservation.rejected.match(response)
      ) {
        errorToast(response.error.message)
        setLoading(false)
        return
      }

      if (
        createAssetReservation.fulfilled.match(response) &&
        isRecurringAssetReservation(response.payload) &&
        response.payload?.failed &&
        response.payload?.failed.length > 0
      ) {
        handleRecurringMeetingsError(response.payload?.failed)
        setLoading(false)
        return
      }

      setLoading(false)
      handleContinue()
    }
  }

  const getRepeatText = () => {
    const isOnceOrUndefined: boolean =
      !recurring?.freq || recurring?.freq === repeatPickerTypes.ONCE
    if (isOnceOrUndefined) {
      return t(`mobile.general.repeat.${repeatPickerTypes.ONCE}`)
    }
    return `${t(`mobile.general.repeat.${recurring?.freq}` as ParseKeys)}${
      recurring?.freq === repeatPickerTypes.EVERY_DAY_OF_WEEK
        ? ` ${dayjs(start).format(" dddd")}`
        : ""
    } ${
      recurring?.until &&
      `(${t("mobile.general.until")}: ${dayjs(recurring?.until).format(
        "MMM D",
      )})`
    }`
  }

  return (
    <SafeViewArea className="Summary">
      <div className="body">
        <TopNav onClose={handleClose} />
        {reservationId ? (
          <div className="summary-title">
            {t("mobile.book.edit_reservation")}
          </div>
        ) : (
          <div className="summary-title">
            {t("mobile.book.almost_finished")}
            <br />
            {t("mobile.book.check_information")}
          </div>
        )}

        <div className="details">
          <div className="scroller">
            <SummaryItem
              type={t("mobile.book.location")}
              text={building?.name ?? t("mobile.general.unknown")}
              onClick={() => {
                history.push("/book/asset/building")
              }}
            />
            <SummaryItem
              type={t("mobile.book.date_time")}
              text={`${dayjs(start).format("MMM D")}, ${dayjs(start).format(
                shortUserTimeFormat(),
              )} - ${dayjs(end).format(shortUserTimeFormat())}`}
              onClick={() => {
                history.push("/book/asset/time")
              }}
            />
            <SummaryItem
              type={t("mobile.book.timezone")}
              text={currentTz}
              onClick={() => {
                history.push("/book/asset/timezone")
              }}
              isEditable={!forceTimeslotUse}
            />
            <SummaryItem
              type={t("mobile.book.asset")}
              text={asset?.name}
              onClick={() => {
                history.push("/book/asset/asset-type")
              }}
            />
            <SummaryItem
              type={t("mobile.book.repeat")}
              text={getRepeatText()}
              onClick={() => {
                setRepeatPicker(true)
              }}
            />
          </div>
        </div>
      </div>
      <Drawer open={recurringMeetingsError}>
        <FailedReservations
          title={t("mobile.book.error_recurring_reservation")}
          buttonText={t("mobile.book.confirm_reservation")}
          failedReservations={failedRecurringMeetings}
          handleClose={handleContinue}
          type="mobile"
          reservationType="asset"
        />
      </Drawer>
      <Drawer open={repeatPicker}>
        <RepeatPicker
          date={dayjs(start)}
          value={recurring?.freq ?? repeatPickerTypes.ONCE}
          until={dayjs(recurring?.until) ?? null}
          onPick={handleRepeatPick}
        />
      </Drawer>
      <div className="action">
        <Button
          variant="mobile-action"
          isLoading={loading}
          onClick={handleConfirm}
        >
          {t("mobile.book.confirm_reservation")}
        </Button>
      </div>
    </SafeViewArea>
  )
}

export default Summary

type SummaryItemProps = {
  type: string
  text?: string
  onClick: () => void
  isEditable?: boolean
}

const SummaryItem: FC<SummaryItemProps> = ({
  type,
  text,
  onClick,
  isEditable = true,
}) => {
  return (
    <button
      className="detail-row"
      onClick={isEditable ? onClick : undefined}
      type="button"
    >
      <div className="data">
        <div className="type">{type}</div>
        <div className="value">{text}</div>
      </div>
      {isEditable && (
        <div className="edit">
          <PencilSVG />
        </div>
      )}
    </button>
  )
}
