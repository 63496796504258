import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { shortUserTimeFormat } from "../../utils"

import "./CheckinSummary.sass"

type Props = {
  checkin_at?: string
  checkout_at?: string
}

export const CheckinSummary = ({ checkin_at, checkout_at }: Props) => {
  const { t } = useTranslation()

  return checkin_at || checkout_at ? (
    <div className="CheckinSummary">
      <p className="check-in-status">
        {checkin_at && (
          <span>
            {t("desktop.manage.checked_in_at")}{" "}
            {dayjs(checkin_at).format(shortUserTimeFormat())}.
          </span>
        )}
        {checkout_at && (
          <span>
            {t("desktop.manage.checked_out_at")}{" "}
            {dayjs(checkout_at).format(shortUserTimeFormat())}.
          </span>
        )}
      </p>
    </div>
  ) : (
    <></>
  )
}
