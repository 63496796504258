import React, { PropsWithChildren, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { Redirect, useLocation } from "react-router-dom"

import AuthCallback from "../screens/AuthCallback"
import AuthLogout from "../screens/AuthLogout"
import Login from "../screens/Login"

import { selectIsMobile } from "../redux/app/selectors"
import { fetchAuthToken } from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { selectIsAuth } from "../redux/selectors"
import { useActions } from "../redux/utils"

import { toast } from "../components/Toast"

function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const isAuthPath = pathname.indexOf("/auth/") > -1
  const isLoginPath = pathname.indexOf("/auth/callback") > -1
  const isLogoutPath = pathname.indexOf("/auth/logout") > -1

  const isMobile = useAppSelector(selectIsMobile)
  const code = useAppSelector((state) => state.auth.code)
  const isAuth = useAppSelector(selectIsAuth)

  const { fetchToken } = useActions({ fetchToken: () => fetchAuthToken() })

  useEffect(() => {
    async function asyncFetchToken() {
      if (code && !isAuth && !isAuthPath) {
        const response = await fetchToken()

        if (fetchAuthToken.rejected.match(response)) {
          toast.error(t("mobile.auth.could_not_authenticate"), {
            hideProgressBar: true,
          })
        }
      }
    }

    asyncFetchToken()
  }, [isAuth, code, fetchToken, isAuthPath])

  if (!isAuthPath && !code && !isAuth) {
    if (isMobile) {
      const next = window.location.pathname
      return <Redirect to={`/auth/start?next=${next}`} />
    }
    return <Login />
  }

  if (isLogoutPath) {
    return <AuthLogout />
  }
  if (isLoginPath) {
    return <AuthCallback />
  }

  return <>{children}</>
}

export default AuthProvider
