import { api } from "../"
import { buildingsURL, buildingURL } from "../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import {
  BuildingFetchProps,
  BuildingRequest,
  BuildingResponse,
  BuildingsFetchOptions,
  BuildingsResponse,
  UpdateBuildingProps,
} from "./types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
  stats: false,
}

export const buildings = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchBuildings: builder.query<
      BuildingsResponse,
      BuildingsFetchOptions | void
    >({
      query: (options = {}) => {
        return {
          url: buildingsURL({
            ...FETCH_DEFAULTS,
            ...options,
            query: {
              fields: ["*", "settings"],
            },
          }),
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Buildings" as const,
                id,
              })),
              { type: "Buildings", id: "LIST" },
            ]
          : [{ type: "Buildings", id: "LIST" }],
    }),

    fetchBuilding: builder.query<BuildingResponse, BuildingFetchProps>({
      query: ({ id, stats = false }) => ({
        url: buildingURL(id, {
          stats,
          query: {
            fields: ["*", "settings", "settings_effective"],
          },
        }),
      }),
      providesTags: (_result, _error, { id }) => [{ type: "Buildings", id }],
    }),

    createBuilding: builder.mutation<BuildingResponse, BuildingRequest>({
      query: (body) => ({
        url: buildingsURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Buildings", id: "LIST" }],
    }),

    updateBuilding: builder.mutation<BuildingResponse, UpdateBuildingProps>({
      query: ({ id, ...body }) => ({
        url: buildingURL(id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Buildings", id: "LIST" },
        { type: "Buildings", id },
      ],
    }),

    destroyBuilding: builder.mutation<void, string>({
      query: (id) => ({
        url: buildingURL(id),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "Buildings", id: "LIST" },
        { type: "Buildings", id },
      ],
    }),
  }),
})

export const {
  useFetchBuildingsQuery,
  useLazyFetchBuildingsQuery,
  useFetchBuildingQuery,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDestroyBuildingMutation,
} = buildings

export const selectBuildingsWithStatuses =
  buildings.endpoints.fetchBuildings.select({
    stats: true,
  })
