import { api } from "../.."
import { invitesURL } from "../../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../../constants"
import { FetchOptions, InvitesListResponse } from "../../../invites/types"

import { FilterSpecialValues } from "../../../../components/Filter/types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
  stats: false,
}

export const invites = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchInvites: builder.query<InvitesListResponse, FetchOptions | void>({
      query: ({ building_id, ...rest } = {}) => {
        const isAllBuilding = building_id === FilterSpecialValues.ALL
        return invitesURL({
          ...FETCH_DEFAULTS,
          ...rest,
          ...(!isAllBuilding && { building_id }),
        })
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Invites" as const,
                id,
              })),
              { type: "Invites", id: "LIST" },
            ]
          : [{ type: "Invites", id: "LIST" }],
    }),
  }),
})

export const { useFetchInvitesQuery } = invites
