import React from "react"

import dayjs, { Dayjs } from "dayjs"
import { Route, useHistory } from "react-router-dom"

import { IdAndName, RepeatPickerTypes } from "../../../../types/sharedTypes"
import {
  internalTimeFormat,
  isInternalTime,
  setTimeToDayjs,
} from "../../../../utils"
import Done from "../Done"
import AssetSelector from "./AssetPicker"
import AssetTypePicker from "./AssetTypePicker"
import BuildingPicker from "./BuildingPicker"
import DateTimePicker from "./DateTimePicker"
import Summary from "./Summary"
import TimezonePicker from "./TimezonePicker"

import { fetchAssetsSchedule } from "../../../../redux/asset_schedule/assetScheduleSlice"
import { Asset as AssetT } from "../../../../redux/assets/types"
import {
  bookAssetAddAsset,
  bookAssetAddAssetTypeId,
  bookAssetAddBuilding,
  bookAssetAddRecurring,
  bookAssetAddTime,
  bookAssetAddTimezone,
  bookAssetSetDefaultTime,
  clearBookAsset,
} from "../../../../redux/book_asset/bookAssetSlice"
import { selectBookAsset } from "../../../../redux/book_asset/selectors"
import { RecurringReservationProps } from "../../../../redux/book_asset/types"
import { useAppSelector } from "../../../../redux/reducers"
import { TimeslotResponse } from "../../../../redux/timeslots/types"
import { selectDefaultUserBuilding } from "../../../../redux/user/selectors"
import { useActions } from "../../../../redux/utils"

import { AnimatedSwitch } from "../../../../components/Mobile/AnimatedSwitch"

const Asset = () => {
  const history = useHistory()

  const actions = useActions({
    bookAssetAddBuilding: (building: IdAndName) =>
      bookAssetAddBuilding(building),
    bookAssetAddTimezone: (timezone: string) => bookAssetAddTimezone(timezone),
    bookAssetAddAssetTypeId: (id: string) => bookAssetAddAssetTypeId(id),
    bookAssetAddTime: (times: { start: string; end: string }) =>
      bookAssetAddTime(times),
    bookAssetAddAsset: (asset: AssetT) => bookAssetAddAsset(asset),
    clearBookAsset: () => clearBookAsset(null),
    bookAssetSetDefaultTime: () => bookAssetSetDefaultTime(),
    fetchAssetsSchedule: (dates: { start: string; end: string }) =>
      fetchAssetsSchedule({ ...dates, show: "reserved" }),
    bookAssetAddRecurring: (recu: RecurringReservationProps) =>
      bookAssetAddRecurring(recu),
  })

  const { bookAsset } = useAppSelector(selectBookAsset)
  const defaultUserBuilding = useAppSelector(selectDefaultUserBuilding)

  const handleCloseClick = () => {
    history.push("/home/reservations")
    actions.clearBookAsset()
  }

  const handleBuildingSelect = (building: IdAndName) => {
    actions.bookAssetAddBuilding(building)
    history.push("/book/asset/asset-type")
  }

  const handleTimezoneSelect = (timezone: string) => {
    actions.bookAssetAddTimezone(timezone)
    history.push("/book/asset/summary")
  }

  const handleAssetTypeSelect = (id: string) => {
    actions.bookAssetAddAssetTypeId(id)
    history.push(`/book/asset/${id}`)
  }

  const handleAssetSelect = (asset: AssetT) => {
    actions.bookAssetAddAsset(asset)
    history.push("/book/asset/summary")
  }

  const handleAssetTimeSelect = (
    date: Dayjs | null,
    timeslot: Partial<TimeslotResponse> | null,
    repeat: RepeatPickerTypes | null,
    repeatUntil: Dayjs | null,
  ) => {
    if (repeat && repeatUntil) {
      actions.bookAssetAddRecurring({
        freq: repeat,
        until: repeatUntil?.toISOString() ?? dayjs().toISOString(),
      })
    }
    if (
      date !== null &&
      timeslot !== null &&
      isInternalTime(timeslot.from) &&
      isInternalTime(timeslot.to)
    ) {
      const start = setTimeToDayjs(date, timeslot.from)
      const end = setTimeToDayjs(date, timeslot.to)
      if (start.isBefore(end)) {
        actions.bookAssetAddTime({
          start: start.toISOString(),
          end: end.toISOString(),
        })
      } else {
        actions.bookAssetAddTime({
          start: end.toISOString(),
          end: start.toISOString(),
        })
      }
    }
  }

  const handleAssetTimeConfirm = () => {
    if (bookAsset.start && bookAsset.end) {
      actions.fetchAssetsSchedule({
        start: bookAsset.start,
        end: bookAsset.end,
      })

      if (bookAsset.asset) {
        history.push("/book/asset/summary")
      } else if (defaultUserBuilding?.id) {
        handleBuildingSelect(defaultUserBuilding)
      } else {
        history.push("/book/asset/building")
      }
    }
    return
  }

  const handleTimeCloseClick = () => {
    if (!bookAsset.start) {
      actions.bookAssetSetDefaultTime()
    }
  }

  const from = dayjs(bookAsset.start).format(internalTimeFormat())
  const to = dayjs(bookAsset.end).format(internalTimeFormat())
  const date = dayjs(bookAsset.start)

  return (
    <AnimatedSwitch>
      <Route exact path={"/book/asset/time"}>
        <DateTimePicker
          onConfirm={handleAssetTimeConfirm}
          editing={!!bookAsset.asset}
          handleClose={handleCloseClick}
          onPick={handleAssetTimeSelect}
          onCancelClick={handleTimeCloseClick}
          timeslot={{
            from,
            to,
          }}
          date={date}
          preferredTime={dayjs(bookAsset.start).format(internalTimeFormat())}
        />
      </Route>
      <Route exact path={"/book/asset/building"}>
        <BuildingPicker
          onBuildingSelect={handleBuildingSelect}
          onCancelClick={handleCloseClick}
        />
      </Route>
      <Route exact path={"/book/asset/timezone"}>
        <TimezonePicker
          onTimezoneSelect={handleTimezoneSelect}
          onCancelClick={() => history.push("/book/asset/summary")}
        />
      </Route>
      <Route exact path={"/book/asset/asset-type"}>
        <AssetTypePicker
          onAssetTypeSelect={handleAssetTypeSelect}
          onCancelClick={handleCloseClick}
        />
      </Route>
      <Route exact path={"/book/asset/summary"}>
        <Summary />
      </Route>
      <Route exact path={"/book/asset/done"}>
        <Done isAsset />
      </Route>
      <Route exact path={"/book/asset/:assetTypeId"}>
        <AssetSelector
          onAssetSelect={handleAssetSelect}
          onCancelClick={handleCloseClick}
        />
      </Route>
    </AnimatedSwitch>
  )
}

export default Asset
