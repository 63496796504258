import React, { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { buildingsWithVMURL, devicesURL } from "../../../api"
import { useToast } from "../../../hooks/useToast"
import { IP_ADDRESS_REGEX } from "../../../utils"
import AsyncSelect from "../../advanced/AsyncSelect"
import { Input } from "../../basic/Input"
import Field from "../../Field"
import { setErrors } from "../formUtils"
import PageForm from "../PageFormHook"

import { getAppParams } from "../../../redux/appParams/selectors"
import { BuildingResponse } from "../../../redux/buildings/types"
import {
  updatePrinter,
  UpdatePrinterProps,
  updateTablet,
  UpdateTabletProps,
} from "../../../redux/devices/devicesSlice"
import {
  DeviceResponse,
  DeviceType,
  PrinterModelType,
} from "../../../redux/devices/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import qlwPng from "../../../assets/images/devices/QL-710W.png"
import qlnwPng from "../../../assets/images/devices/QL-720NW.png"
import qlnwbPng from "../../../assets/images/devices/QL-820NWB.png"
import tabletWhitelabelPng from "../../../assets/images/devices/Tablet-whitelabel.png"
import tabletPng from "../../../assets/images/devices/Tablet.png"

import "./DeviceForm.sass"

type FormValues = {
  name: string
  building?: BuildingResponse
  connected_device?: DeviceResponse
  ip_address?: string
}

type Props = {
  device: DeviceResponse
}

const FORM_MAPPING = {
  connected_device_id: "connected_device",
  building_id: "building",
} as const

const DeviceForm = ({ device }: Props) => {
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const history = useHistory()

  const { isAppWhitelabel } = useAppSelector(getAppParams)

  const actions = useActions({
    updatePrinter: (printer: UpdatePrinterProps) => updatePrinter(printer),
    updateTablet: (tablet: UpdateTabletProps) => updateTablet(tablet),
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      name: device.name ?? "",
      building: device.building,
      connected_device: device.connected_device,
      ip_address:
        device.type === DeviceType.PRINTER ? device.ip_address : undefined,
    },
  })
  const {
    control,
    setError,
    formState: { isSubmitting },
  } = methods

  const printerImage = (model: PrinterModelType) => {
    let image
    switch (model) {
      case PrinterModelType["QL-820NWB"]:
        image = qlnwbPng
        break
      case PrinterModelType["QL-720NW"]:
        image = qlnwPng
        break
      default:
        image = qlwPng
        break
    }

    return <img alt="printer" src={image} />
  }

  const getBuildingId = (building?: BuildingResponse) =>
    building?.id ? building.id : undefined

  const getDeviceId = (device?: DeviceResponse) =>
    device?.id ? device.id : null

  const onUpdateClick = useCallback(
    async ({ name, building, connected_device, ip_address }: FormValues) => {
      if (device.type === DeviceType.TABLET) {
        const response = await actions.updateTablet({
          id: device.id,
          name,
          building_id: getBuildingId(building),
          connected_device_id: getDeviceId(connected_device),
        })

        if (updateTablet.fulfilled.match(response)) {
          infoToast(t("desktop.settings.visitors.devices.device_updated"))
          history.push("/settings/visitors/devices")
        } else {
          if (response.payload) {
            setErrors(response.payload, setError, errorToast, FORM_MAPPING)
          }
        }
      } else {
        const response = await actions.updatePrinter({
          id: device.id,
          model: device.model,
          name,
          building_id: getBuildingId(building),
          ip_address,
        })

        if (updatePrinter.fulfilled.match(response)) {
          infoToast(t("desktop.settings.visitors.devices.device_updated"))
          history.push("/settings/visitors/devices")
        } else {
          if (response.payload) {
            setErrors(response.payload, setError, errorToast, FORM_MAPPING)
          }
        }
      }
    },
    [actions, device, infoToast, errorToast, t, history, setError],
  )

  return (
    <FormProvider {...methods}>
      <PageForm
        className="DeviceForm"
        updateMode={true}
        onUpdate={onUpdateClick}
        backUrl="/settings/visitors/devices"
        disabled={isSubmitting}
      >
        <Field
          control={control}
          name="name"
          label={t("desktop.settings.visitors.devices.form.name")}
          helpText={t("desktop.settings.visitors.devices.form.name_info")}
        >
          {(props) => <Input {...props} />}
        </Field>
        <div className="device-info">
          <div className="device-image">
            {device.type === DeviceType.TABLET ? (
              <img
                alt="tablet"
                src={isAppWhitelabel ? tabletWhitelabelPng : tabletPng}
              />
            ) : (
              printerImage(device.model)
            )}
          </div>
          <div className="device-info-text">
            <div className="title">
              {device.type === DeviceType.TABLET
                ? t("desktop.settings.visitors.devices.form.visitor_management")
                : t("desktop.settings.visitors.devices.form.badge_printing")}
            </div>
            <div className="description">
              {device.type === DeviceType.TABLET
                ? t(
                    "desktop.settings.visitors.devices.form.visitor_management_info",
                  )
                : t(
                    "desktop.settings.visitors.devices.form.badge_printing_info",
                  )}
            </div>
          </div>
        </div>
        <Field
          control={control}
          name="building"
          label={t("desktop.settings.visitors.devices.form.location")}
          helpText={t("desktop.settings.visitors.devices.form.location_info")}
        >
          {(props) => (
            <AsyncSelect
              {...props}
              urlGenerator={(fetchOptions) => buildingsWithVMURL(fetchOptions)}
              nothingFoundMessage={t(
                "desktop.settings.visitors.devices.form.no_building_found",
              )}
              getOptionLabel={(building) => building.name}
              getOptionValue={(building) => building.id}
              disabled={
                device.type === DeviceType.PRINTER &&
                !!device.connected_device?.id &&
                !!device.building?.id
              }
            />
          )}
        </Field>
        {device.type === DeviceType.TABLET && (
          <Field
            control={control}
            name="connected_device"
            label={t(
              "desktop.settings.visitors.devices.form.connected_printer",
            )}
          >
            {(props) => (
              <AsyncSelect
                {...props}
                urlGenerator={(fetchOptions) => devicesURL(fetchOptions)}
                nothingFoundMessage={t(
                  "desktop.settings.visitors.devices.form.no_printers",
                )}
                getOptionLabel={(printer) => printer.name || printer.model}
                getOptionValue={(printer) => printer.id}
                filterResultsFn={(device) => device.type === DeviceType.PRINTER}
                clearable
              />
            )}
          </Field>
        )}
        {device.type === DeviceType.PRINTER && (
          <Field
            control={control}
            name="ip_address"
            label={t(
              "desktop.settings.visitors.devices.form.printer_ip_address",
            )}
            rules={{
              pattern: {
                value: IP_ADDRESS_REGEX,
                message: t(
                  "desktop.settings.visitors.devices.form.printer_ip_address_invalid",
                ),
              },
            }}
          >
            {(props) => <Input {...props} />}
          </Field>
        )}
      </PageForm>
    </FormProvider>
  )
}

export default DeviceForm
