import { useEffect } from "react"

import { isNative } from "../utils"
import {
  OrientationLockType,
  ScreenOrientation,
} from "@capacitor/screen-orientation"

export const useUnlockOrientation = (
  lockAfter: boolean = true,
  lockedOrientation: OrientationLockType = "portrait",
) => {
  useEffect(() => {
    if (!isNative()) {
      return
    }
    ScreenOrientation.unlock()

    return () => {
      if (lockAfter) {
        ScreenOrientation.lock({ orientation: lockedOrientation })
      }
    }
  }, [lockAfter, lockedOrientation])
}
