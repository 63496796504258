import React from "react"

import { Trans } from "react-i18next"

import DeskOverview from "../../Overview/DeskOverview"

import { PLAN_GROUPS } from "../../../../redux/api/billing/constants"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import SolutionOverview from "../../../../components/advanced/SolutionOverview"

const DesksOverview = () => {
  return (
    <SolutionOverview
      planGroup={PLAN_GROUPS.DESK_BOOKING}
      intro={
        <Trans i18nKey="desktop.settings.desks.general.intro">
          <RedirectLink to="desk-booking-instructions">link</RedirectLink>
        </Trans>
      }
    >
      <DeskOverview isDefaultExtended />
    </SolutionOverview>
  )
}

export default DesksOverview
