import React from "react"

import { Facet } from "../../types/sharedTypes"
import Dropdown from "../basic/Dropdown"

export type FacetsFilterProps = {
  value: string
  onChange?: (v: string) => void
  options?: Facet[]
  showCount?: boolean
}

export default function FacetsFilter({
  value,
  onChange,
  options,
  showCount = true,
}: FacetsFilterProps) {
  const selectedValue = options?.find((option) => option?.id === value)
    ? value
    : options?.[0]?.id

  const facetOptions = options?.map((option) => ({
    label: (
      <>
        {option.name}
        {showCount && <span className="count"> &middot; {option.count}</span>}
      </>
    ),
    value: option?.id,
  }))

  const handleOnChange = (newValue: string) => onChange?.(newValue)

  return (
    <Dropdown
      className="facet-filter"
      options={facetOptions ?? []}
      value={selectedValue}
      onChange={handleOnChange}
    />
  )
}
