import { useTranslation } from "react-i18next"

import PrepaidCodeForm from "./PrepaidCodeForm"
import { usePrepaidCodeForm } from "./usePrepaidCodeForm"

import Button from "../../../../components/advanced/Button"
import Card from "../../../../components/basic/Card"

import "./PrepaidCodeCard.sass"

const PrepaidCodeCard = () => {
  const { t } = useTranslation()

  const { methods, submitPrePaidCode } = usePrepaidCodeForm()

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods

  return (
    <form
      className="PrepaidCodeCard"
      onSubmit={handleSubmit(submitPrePaidCode)}
    >
      <Card
        actions={
          <Button isSubmit variant="submit">
            {t(
              "desktop.settings.billing.pre_paid_code_card.redeem_code_button",
            )}
          </Button>
        }
      >
        <PrepaidCodeForm
          control={control}
          watch={watch}
          isSubmitting={isSubmitting}
        />
      </Card>
    </form>
  )
}

export default PrepaidCodeCard
