import React from "react"

import ReactModal from "react-modal"

import { RangeStartEnd } from "../types/sharedTypes"
import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import ExportAssetsForm from "../components/Form/ExportAssetsForm"

type Props = {
  open: boolean
  range: RangeStartEnd
  departmentId?: string
  assetTypeId?: string
}

export default function ExportAssetsModal({
  open,
  range,
  departmentId,
  assetTypeId,
}: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <ExportAssetsForm
        range={range}
        defaultAssetTypeId={assetTypeId}
        defaultDepartmentId={departmentId}
      />
    </ReactModal>
  )
}
