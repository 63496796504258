import { PropsWithChildren, ReactNode } from "react"

import classNames from "classnames"
import {
  Control,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form"

import ErrorText from "../ErrorText"
import { Label } from "./Label"

import "./Field.sass"

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  control: Control<TFieldValues>
  className?: string
  label?: ReactNode
  subText?: ReactNode
  helpText?: ReactNode
  required?: boolean
  children: (
    props: ControllerRenderProps<TFieldValues, TName> & {
      id: string
      hasError?: boolean
    },
  ) => ReactNode
  hideErrorsOnField?: boolean
  exclusions?: string[]
} & UseControllerProps<TFieldValues, TName>

const Field = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  className,
  name,
  rules,
  label,
  helpText,
  subText,
  control,
  required = false,
  hideErrorsOnField = false,
  exclusions,
}: Props<TFieldValues, TName>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules,
    control,
  })
  const hasError = error?.message ? true : undefined

  /* 
	  We want to exclude certain fields from being rendered as settings differ device to device
	*/
  if (exclusions && exclusions.includes(name)) {
    return null
  }

  return (
    <div className={classNames(["FieldWrapper", className])}>
      {label && (
        <Label forInput={name} subText={subText}>
          {label}
          {required && <span className="RequiredMarker">*</span>}
        </Label>
      )}
      {children({
        ...field,
        id: name,
        hasError: !hideErrorsOnField && hasError,
      })}
      {helpText && !hasError && <div className="HelpText">{helpText}</div>}
      {!hideErrorsOnField && <ErrorText error={error?.message} />}
    </div>
  )
}

export default Field

type UncontrolledFieldProps = {
  className?: string
  label?: ReactNode
  subText?: ReactNode
  helpText?: ReactNode
  errorText?: string
}

export const UncontrolledField = ({
  children,
  className,
  label,
  subText,
  helpText,
  errorText,
}: PropsWithChildren<UncontrolledFieldProps>) => {
  return (
    <div className={classNames(["FieldWrapper", className])}>
      {label && <Label subText={subText}>{label}</Label>}
      {children}
      {helpText && !errorText && <div className="HelpText">{helpText}</div>}
      <ErrorText error={errorText} />
    </div>
  )
}
