import { useEffect } from "react"

import { fetchCompanyConsent } from "../redux/consent/consentSlice"
import { fetchEventsRooms } from "../redux/events/eventsSlice"
import { fetchExperiments } from "../redux/experiments/experimentsSlice"
import { fetchFeatureFlags } from "../redux/feature_flags/featureFlagsSlice"
import { useAppSelector } from "../redux/reducers"
import { selectIsAuth } from "../redux/selectors"
import { fetchSettings } from "../redux/settings/settingsSlice"
import { fetchUserExperiments } from "../redux/user_experiments/userExperimentsSlice"
import { useActions } from "../redux/utils"

function MobileCompanyProvider() {
  const actions = useActions({
    fetchSettings: () => fetchSettings(),
    fetchEventsRooms: () => fetchEventsRooms(),
    fetchExperiments: () => fetchExperiments(),
    fetchUserExperiments: () => fetchUserExperiments(),
    fetchConsent: () => fetchCompanyConsent(),
    fetchFeatureFlags: () => fetchFeatureFlags(),
    // Buildings are currently fetched in the InitializationProvider already
  })

  const isAuth = useAppSelector(selectIsAuth)

  const { isLoaded: areSettingsLoaded } = useAppSelector(
    (state) => state.settings,
  )

  const { isLoaded: areFeatureFlagsLoaded, isWithoutAccessToken } =
    useAppSelector((state) => state.featureFlags)

  const { isLoaded: areExperimentsLoaded } = useAppSelector(
    (state) => state.experiments,
  )

  const { isLoaded: areUserExperimentsLoaded } = useAppSelector(
    (state) => state.user_experiments,
  )

  const { isLoaded: isConsentLoaded, isLoading: isConsentLoading } =
    useAppSelector((state) => state.consent)

  useEffect(() => {
    if (!areSettingsLoaded && isAuth) {
      actions.fetchSettings()
    }
  }, [areSettingsLoaded, isAuth, actions])

  useEffect(() => {
    // check if flags were fetched without access token
    if ((!areFeatureFlagsLoaded || isWithoutAccessToken) && isAuth) {
      actions.fetchFeatureFlags()
    }
  }, [areFeatureFlagsLoaded, isWithoutAccessToken, isAuth, actions])

  useEffect(() => {
    if (!isConsentLoaded && !isConsentLoading && isAuth) {
      actions.fetchConsent()
    }
  }, [actions, isConsentLoaded, isAuth, isConsentLoading])

  useEffect(() => {
    if (!areExperimentsLoaded && isAuth) {
      actions.fetchExperiments()
    }
  }, [actions, isAuth, areExperimentsLoaded])

  useEffect(() => {
    if (!areUserExperimentsLoaded && isAuth) {
      actions.fetchUserExperiments()
    }
  }, [actions, isAuth, areUserExperimentsLoaded])

  return null
}

export default MobileCompanyProvider
