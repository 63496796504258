import React from "react"

import LocationPin from "../../../../assets/images/icons/LocationPin.svg"

import "./DeskList.sass"

type DeskListItem = {
  id: string
  name: string
  buildingName: string
  floorName: string
}

type DeskListProps = {
  desks: DeskListItem[]
  onDeskClick?: (desk: DeskListItem) => void
}

const DeskList = ({ desks, onDeskClick }: DeskListProps) => {
  return (
    <div className="DeskList">
      {desks.map((desk) => (
        <div
          key={desk.id}
          className="detail-row"
          onClick={() => onDeskClick?.(desk)}
        >
          <div>
            <span className="name">{desk.name}</span>
            <span className="location">
              {desk.buildingName}, {desk.floorName}
            </span>
          </div>

          <div>
            <LocationPin />
          </div>
        </div>
      ))}
    </div>
  )
}

export default DeskList
