import React, { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import BuildingFilter from "../../../components/Filter/BuildingFilter"
import DepartmentFilter from "../../../components/Filter/DepartmentFilter"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import FloorFilter from "../../../components/Filter/FloorFilter"
import MultiAmenitiesFilter from "../../../components/Filter/MultiAmenitiesFilter"
import SearchFilter from "../../../components/Filter/SearchFilter"

export type Filter = {
  building_id: string
  floor_id: string
  department_id: string
  amenity_id: string[]
  search: string
  page?: number
}
type FilterKeys = keyof Filter

type Props = {
  onChange: (filter: Filter) => Promise<void>
  defaultValues: Filter
}

const DeskFilters = ({ onChange, defaultValues }: Props) => {
  const floorRef = useRef<string>(defaultValues.floor_id)
  const filtersRef = useRef<Filter>(defaultValues)
  const [filters, setFilters] = useState<Filter>(defaultValues)

  const { t } = useTranslation()

  const handleOnChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[]) => {
      // the FloorFilter triggers unwanted onChanges; this is temporary fix
      if (filterName === "floor_id" && floorRef.current === value) {
        return
      }

      filtersRef.current = {
        ...filtersRef.current,
        [filterName]: value,
      }
      onChange(filtersRef.current as Filter)
      floorRef.current = filtersRef.current.floor_id
      setFilters(filtersRef.current)
    },
    [onChange],
  )

  const amenities =
    typeof filters.amenity_id === "string"
      ? [filters.amenity_id]
      : filters.amenity_id

  return (
    <Filters>
      <BuildingFilter
        value={filters.building_id ?? ""}
        onChange={handleOnChange("building_id")}
        showAll
      />
      <FloorFilter
        value={filters.floor_id ?? ""}
        onChange={handleOnChange("floor_id")}
        buildingId={filters.building_id}
        showAll
        showOnlyWithPlan
      />
      <DepartmentFilter
        value={filters.department_id ?? ""}
        onChange={handleOnChange("department_id")}
        showAll={true}
        showNotAssigned={true}
      />
      <MultiAmenitiesFilter
        preSelectedValues={amenities}
        onChange={handleOnChange("amenity_id")}
      />
      <FilterSpace />
      <SearchFilter
        value={filters.search ?? ""}
        onChange={handleOnChange("search")}
        placeholder={t("desktop.manage.desk_booking.filter_desks")}
      />
    </Filters>
  )
}

export default DeskFilters
