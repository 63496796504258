import React, { useState } from "react"

import { Trans, useTranslation } from "react-i18next"

import { useLocalStorage } from "../../../hooks/useLocalStorage"
import AssetModal from "../../../modals/AssetModal"
import AssetTypeModal from "../../../modals/AssetTypeModal"
import AssetList from "./AssetList"
import AssetTypeItem from "./AssetTypeItem"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchAssetTypesQuery } from "../../../redux/api/assetTypes"
import { AssetType } from "../../../redux/asset_types/types"
import { Asset } from "../../../redux/assets/types"

import Button from "../../../components/advanced/Button"
import Card from "../../../components/basic/Card"
import Loader from "../../../components/basic/Loader"
import BuildingFilter from "../../../components/Filter/BuildingFilter"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import SearchFilter from "../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../components/Filter/types"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import NoDataFound from "../../../components/NoDataFound"
import Space from "../../../components/Space"

import AssetSVG from "../../../assets/images/icons/Asset.svg"

import "./Assets.sass"

const Assets = () => {
  const [search, setSearch] = useState<string>("")
  const { value: buildingFilter, onChange: setBuildingFilter } =
    useLocalStorage("settings-assets-building-filter", FilterSpecialValues.ALL)
  const { openModal } = useModals()
  const { t } = useTranslation()

  const {
    data: { results: assetTypes = [] } = {},
    isSuccess: areAssetTypesLoaded,
  } = useFetchAssetTypesQuery()

  const handleEditAssetTypeClick = (id: string) => {
    const assetType = assetTypes.find((a) => a.id === id)
    if (assetType) {
      openModal(AssetTypeModal, { assetType })
    }
  }

  const handleNewAssetTypeClick = () => {
    openModal(AssetTypeModal, {})
  }

  const handleAddAssetClick = (id: string) => {
    const assetType = assetTypes.find((a) => a.id === id)
    if (assetType) {
      openModal(AssetModal, { assetType })
    }
  }

  const handleEditAssetClick = (asset: Asset) => {
    const assetType = assetTypes.find(
      (a: AssetType) => a.id === asset.asset_type.id,
    )
    if (assetType) {
      openModal(AssetModal, { assetType, asset })
    }
  }

  let filteredAssetTypes = assetTypes

  if (buildingFilter && buildingFilter !== FilterSpecialValues.ALL) {
    filteredAssetTypes = filteredAssetTypes.filter(
      (a) => a.buildings[0]?.id === buildingFilter,
    )
  }

  if (search) {
    filteredAssetTypes = filteredAssetTypes.filter(
      (a) =>
        a.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        a.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
    )
  }

  const hasAssetTypes = assetTypes.length > 0

  return (
    <div className="View AssetsSettings">
      <Heading>{t("desktop.settings.assets.title")}</Heading>

      <Intro isConstrained>
        {t("desktop.settings.assets.designate_bookable_assets")}
      </Intro>

      <Space size={0.75} />

      <Filters>
        <BuildingFilter
          value={buildingFilter}
          onChange={setBuildingFilter}
          showAll
        />
        <SearchFilter
          value={search}
          onChange={setSearch}
          placeholder={t("desktop.settings.assets.search")}
        />
        <FilterSpace />
        <Button onClick={() => handleNewAssetTypeClick()} isSmall>
          {t("desktop.settings.assets.new_asset_type")}
        </Button>
      </Filters>

      {!areAssetTypesLoaded && <Loader className="loader" />}

      <Space size={0.75} />

      {areAssetTypesLoaded && (
        <>
          {!hasAssetTypes && (
            <div className="asset-missing">
              <NoDataFound>
                <AssetSVG />
                <div>{t("desktop.settings.assets.no_assets")}</div>
              </NoDataFound>

              <NoDataFound warning>
                <>
                  <div>{t("desktop.settings.assets.no_asset_types")}</div>
                  <div>
                    <Trans i18nKey="desktop.settings.assets.create_new_asset_type">
                      <span
                        className="text-link"
                        onClick={handleNewAssetTypeClick}
                      >
                        Add an asset type
                      </span>
                    </Trans>
                  </div>
                </>
              </NoDataFound>
            </div>
          )}

          {filteredAssetTypes.length > 0 && (
            <div className="asset-type-list">
              {filteredAssetTypes.map((at: AssetType) => (
                <Card key={at.id}>
                  <div className="card-header">
                    <AssetTypeItem
                      assetType={at}
                      onEditClick={handleEditAssetTypeClick}
                      onAddAssetClick={handleAddAssetClick}
                    />
                  </div>

                  <AssetList
                    assetTypeId={at.id}
                    onAddAssetClick={handleAddAssetClick}
                    onEditAssetClick={handleEditAssetClick}
                  />
                </Card>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Assets
