import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./VisitorView.sass"

type Props = {
  className?: string
}

const VisitorView = ({ className, children }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(["VisitorView", className])}>{children}</div>
  )
}

export default VisitorView
