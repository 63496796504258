export const getTzLocation = (tz: string | null) => {
  if (!tz) {
    return null
  }

  try {
    const [continent, city] = tz.split("/")

    if (!continent || !city) throw new Error("Invalid timezone format")

    const region = city.replace("_", " ")

    return { continent, region }
  } catch (error) {
    return null
  }
}
