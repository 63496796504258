import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import ShareFloorAccessActivity from "../components/ShareFloorAccessActivity"

import "./ShareFloorModal.sass"

type Props = {
  open: boolean
  shareableId: string
}

export default function ShareFloorAccessActivityModal({
  open,
  shareableId,
}: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <ShareFloorAccessActivity shareableId={shareableId} />
    </ReactModal>
  )
}
