import { ChangeEvent, FC, useState } from "react"
import { useEffect } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { isNetworkError } from "../../api/utils"
import { InternalTime } from "../../types/sharedTypes"
import {
  isInternalTime,
  setTimeToDayjs,
  shortUserTimeFormat,
  toInternalTime,
} from "../../utils"
import Button from "../advanced/Button"
import { toast } from "../Toast"
import { FromToTimePicker } from "./FromToTimePicker"

import { useLazyFetchDeskQuery } from "../../redux/api/desks"
import { isRejected } from "../../redux/api/types"
import {
  FetchAssetScheduleProps,
  fetchAssetsSchedule,
} from "../../redux/asset_schedule/assetScheduleSlice"
import { setBookAsset } from "../../redux/book_asset/bookAssetSlice"
import { BookAsset } from "../../redux/book_asset/types"
import { RootState, useAppSelector } from "../../redux/reducers"
import { createReservation } from "../../redux/reservations/reservationsSlice"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { Suggestion } from "../../redux/suggestions/types"
import { selectUser } from "../../redux/user/selectors"
import { useActions } from "../../redux/utils"
import { SuggestionTimeSlot } from "./../../redux/suggestions/types"

import PlusCircleSVG from "../../assets/images/icons/PlusCircle.svg"

import "./SuggestedDesk.sass"

type SuggestedDeskProps = {
  suggestion: Suggestion
  customTime?: boolean
  date: Dayjs
  isQrDeskBooking?: boolean
}

export const SuggestedDesk: FC<SuggestedDeskProps> = ({
  suggestion,
  customTime,
  date,
  isQrDeskBooking,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [unavailable, setUnavailable] = useState<string[]>([])

  const [customFrom, setCustomFrom] = useState<InternalTime>(
    toInternalTime(date),
  )
  const [customTo, setCustomTo] = useState<InternalTime>(
    toInternalTime(date.set("hour", 17).set("minute", 0)),
  )

  const actions = useActions({
    createReservation: (
      date: Dayjs,
      seat_id: string,
      start: string,
      end: string,
      user_id: string,
      timezone: string,
      timeslot_id?: string,
    ) =>
      createReservation({
        date: date.toISOString(),
        seat_id,
        start,
        end,
        user_id,
        tz: timezone,
        timeslot_id: timeslot_id ?? undefined,
      }),
    setBookAsset: (params: BookAsset) => setBookAsset(params),
    fetchAssetsSchedule: (params: FetchAssetScheduleProps) =>
      fetchAssetsSchedule({ ...params, show: "reserved" }),
  })

  const [fetchDesk] = useLazyFetchDeskQuery()

  const { isLoading } = useAppSelector((state: RootState) => state.reservations)
  const { entry: currentUser } = useAppSelector(selectUser)
  const { entry: settings } = useAppSelector(selectSettingsEffective)

  const handleCustomTime = () => {
    history.push("/book/desk/suggest/" + suggestion.desk.id)
  }

  const handleCustomFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomFrom(e.target.value as InternalTime)
  }

  const handleCustomToChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomTo(e.target.value as InternalTime)
  }

  const setBookAssetSuggestion = async (timeslot?: SuggestionTimeSlot) => {
    const deskResponse = await fetchDesk(suggestion.desk.id)

    if (isRejected(deskResponse)) {
      return
    }

    const desk = deskResponse.data
    if (!desk) {
      return
    }
    const start = setTimeToDayjs(
      date,
      timeslot && isInternalTime(timeslot?.start) ? timeslot.start : customFrom,
    ).toISOString()
    const end = setTimeToDayjs(
      date,
      timeslot && isInternalTime(timeslot?.end) ? timeslot.end : customTo,
    ).toISOString()

    actions.setBookAsset({
      building: desk.building,
      start,
      end,
    })

    await actions.fetchAssetsSchedule({
      building_id: desk.building_id,
      start,
      end,
    })
  }

  const book = async (timeslot?: SuggestionTimeSlot) => {
    const { start, end, timeslot_id } = timeslot || {}

    try {
      const response = await actions.createReservation(
        date,
        suggestion.desk.id,
        start || customFrom,
        end || customTo,
        currentUser.id,
        suggestion.desk.tz,
        timeslot_id,
      )

      if (createReservation.fulfilled.match(response)) {
        await setBookAssetSuggestion(timeslot)

        analyticsEvent(SupportedEvents.DESK_RESERVATION_RECOMMENDED, {
          id: response.payload.id,
          seat_id: suggestion.desk.id,
        })

        history.push("/book/desk/done", response.payload)
      } else {
        if (isNetworkError(new Error(response.error.message))) {
          toast.error(t("mobile.general.something_wrong"), {
            hideProgressBar: true,
          })
          return
        }

        timeslot &&
          setUnavailable((prevState) => [
            ...prevState,
            convertTimeslotToKey(timeslot),
          ])

        toast.error(response.error.message, { hideProgressBar: true })
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    setUnavailable([])
  }, [date])

  const SuggestedDeskClasses = classNames({
    SuggestedDesk: true,
    "is-qr-desk-booking": isQrDeskBooking,
  })

  const actionButton = classNames({
    action: true,
    isBooking: isLoading,
  })

  return (
    <div className={SuggestedDeskClasses}>
      <div className="desk-name">{suggestion.desk.name}</div>
      {!isQrDeskBooking && (
        <div className="desk-type">{t("mobile.general.desk")}</div>
      )}
      <hr />

      <div className="desk-timeslots">
        {suggestion.timeslots.map((timeslot) => {
          const key = convertTimeslotToKey(timeslot)
          const startTime = dayjs(timeslot.start, "HH:mm")
          const endTime = dayjs(timeslot.end, "HH:mm")

          return (
            <div className="desk-timeslot" key={key}>
              <div className="time">
                {startTime.format(shortUserTimeFormat())}
                {" - "}
                {endTime.format(shortUserTimeFormat())}
              </div>
              <div className="name">{timeslot.name}</div>

              <div className="spacer">&nbsp;</div>

              {unavailable.includes(key) ? (
                <div className={`${actionButton} isDisabled`}>
                  {t("mobile.book.unavailable")}
                </div>
              ) : (
                <div className={actionButton} onClick={() => book(timeslot)}>
                  {t("mobile.general.book")}
                </div>
              )}
            </div>
          )
        })}
      </div>

      {!customTime && !settings?.desk_force_timeslot_use && (
        <div className="desk-custom-time-button" onClick={handleCustomTime}>
          <PlusCircleSVG />
          <div className="custom-time-label">
            {t("mobile.book.add_custom_timeslot")}
          </div>
        </div>
      )}

      {customTime && (
        <div className="desk-custom-time-inputs">
          <FromToTimePicker
            from={customFrom}
            to={customTo}
            onFromChange={handleCustomFromChange}
            onToChange={handleCustomToChange}
          />
          <div className="book-button">
            <Button
              variant="mobile-action"
              isDisabled={isLoading}
              onClick={() => book()}
            >
              {t("mobile.general.book")}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const convertTimeslotToKey = (timeslot: SuggestionTimeSlot) =>
  [...Object.values(timeslot)].join("_")
