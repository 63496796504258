import { Middleware, MiddlewareAPI } from "redux"

import { isSentryActive, logBreadcrumb } from "../analytics"
import { parseApiError } from "../api/apiUtils"
import { detectLockedFeature } from "../fetch"
import { clearAuth } from "./auth/authSlice"
import { setHasPaid } from "./payment/paymentSlice"
import { isFulfilled, isRejected, isRejectedWithValue } from "@reduxjs/toolkit"
import { captureException, captureMessage } from "@sentry/react"

type ParsedReduxError = {
  message?: string
}

// Those errors are expected and we are using them to set app state
const INFO_ERRORS = [
  "Authentication credentials were not provided.",
  "Payment required for functionalities to work.",
]

const mappedError = (error: string): string => {
  const normalizedError = error.toLowerCase()
  if (normalizedError.includes("not found")) {
    return "Requested resource was not found."
  }

  return error
}

export const sentryMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    try {
      if (!isSentryActive || isFulfilled(action)) {
        return next(action)
      }

      if (isRejectedWithValue(action)) {
        const apiError = parseApiError(action.payload) as ParsedReduxError
        if (!apiError) {
          return next(action)
        }

        logBreadcrumb("Redux action failed (middleware rejected with value)")

        if (apiError?.message) {
          logBreadcrumb(`Error: ${apiError.message}`)
          captureMessage(mappedError(apiError.message))
          return next(action)
        }

        if (typeof action.payload === "string") {
          logBreadcrumb(`Error: ${action.payload}`)
          captureMessage(mappedError(action.payload))
          return next(action)
        }

        for (const key in action.payload) {
          const value = action.payload[key]
          logBreadcrumb(`Error: ${key} - ${value}`)
        }

        if (Object.keys(action.payload).length === 0) {
          apiError.message = "An unknown error occurred"
        } else {
          apiError.message = Object.values(action.payload)[0] as string
        }

        captureMessage(apiError.message)

        return next(action)
      }

      if (isRejected(action)) {
        const { error, meta, type } = action

        /*
         * RTK-query rejects with a ConditionError when the query is set to be skipped
         */
        if ("name" in error && error.name === "ConditionError") {
          return next(action)
        }

        /*
         * we are fetching employee screening data every time the user check-ins
         * to check if the user has a screening the endpoint returns 404 if the user
         * doesn't have a screening so we are ignoring this error
         */
        if (
          type === "api/executeQuery/rejected" &&
          meta?.arg?.endpointName === "fetchEmployeeScreening"
        ) {
          return next(action)
        }

        logBreadcrumb("Redux action failed (middleware rejected)")

        /*
         * we are using those errors to set app state
         */
        if (INFO_ERRORS.includes(error.message)) {
          captureMessage(mappedError(error.message), "info")
          return next(action)
        }

        if (error.message) {
          logBreadcrumb(`Error: ${error.message}`)
          captureMessage(mappedError(error.message))
          captureException(error)
        }

        return next(action)
      }

      return next(action)
    } catch (error) {
      console.error("Sentry middleware error", error)
      return next(action)
    }
  }

export const apiErrorHandlingMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    try {
      if (!isRejectedWithValue(action) && !isRejected(action)) {
        return next(action)
      }

      if (isRejected(action)) {
        return next(action)
      }

      const { payload } = action
      const apiError = parseApiError(payload) as ParsedReduxError

      if (!apiError) {
        return next(action)
      }

      const { status } = payload

      if (status === 401) {
        dispatch(clearAuth())
        return next(action)
      }

      if (status === 402) {
        dispatch(setHasPaid(detectLockedFeature(payload.url)))
        return next(action)
      }

      return next(action)
    } catch (error) {
      console.error("API error handling middleware error", error)
      return next(action)
    }
  }
