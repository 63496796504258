export const animationVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: "auto" },
  opacityHidden: { opacity: 0 },
  opacityVisible: { opacity: 1 },
}

export const animationDelays = {
  checkmark: {
    ms: 150,
    s: 0.15,
  },
  card: {
    ms: 250,
    s: 0.25,
  },
  swipeIndicator: {
    ms: 400,
    s: 0.4,
  },
}

export const mobileSwipeOffset = -72
