import { useEffect, useState } from "react"

import throttle from "lodash.throttle"

export const useMediaQuery = (
  query: string,
  interval: number = 250,
): boolean => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = throttle(() => setMatches(media.matches), interval)
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [matches, query, interval])

  return matches
}
