import React, { useCallback, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { ENTRIES_PER_PAGE_KEY } from "../../../constants"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { parseQueryWithDefault } from "../../../utils"
import AssetItem from "./AssetItem"

import { useFetchAssetsQuery } from "../../../redux/api/assets"
import { Asset } from "../../../redux/assets/types"

import Loader from "../../../components/basic/Loader"
import NoDataFound from "../../../components/NoDataFound"
import Pagination from "../../../components/Pagination"

import "./AssetList.sass"

type Props = {
  assetTypeId: string
  onAddAssetClick: (assetTypeId: string) => void
  onEditAssetClick: (asset: Asset) => void
}

const ENTRIES_PER_PAGE = 10

const AssetList = ({
  assetTypeId,
  onAddAssetClick,
  onEditAssetClick,
}: Props) => {
  const { t } = useTranslation()

  const history = useHistory()

  const location = history.location

  const { page: queryPage } = parseQueryWithDefault(location.search, {
    page: 1,
  })

  const [page, setCurrentPage] = useState(queryPage)

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  const offset = (page - 1) * entriesPerPageNum

  const {
    data: { count = 0, results: assets = [] } = {},
    refetch: refetchAssets,
    isFetching: areAssetsTypesLoading,
  } = useFetchAssetsQuery({
    asset_type: assetTypeId,
    offset,
    limit: entriesPerPageNum,
  })

  const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 3,
      maxTrailingLinks: 2,
    })

  const setPage = useCallback(
    (nextPage: number) => {
      setCurrentPage(nextPage)
      refetchAssets()
    },
    [refetchAssets],
  )

  if (areAssetsTypesLoading) {
    return <Loader className="asset-list-loader" />
  }

  return (
    <>
      {assets.length ? (
        <div className="card-body">
          <div className="asset-list">
            {assets.map((asset, index) => (
              <AssetItem
                key={asset.id}
                rowNumber={calcRowNumber(index)}
                asset={asset}
                onEditAssetClick={onEditAssetClick}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoDataFound warning className="card-footer">
          <div>{t("desktop.settings.assets.asset_type_has_no_assets")}</div>
          <div>
            <Trans i18nKey="desktop.settings.assets.create_new_asset">
              <span
                className="text-link"
                onClick={() => onAddAssetClick(assetTypeId)}
              >
                Add an asset
              </span>
            </Trans>
          </div>
        </NoDataFound>
      )}

      <div className="card-footer card-footer-pagination">
        <Pagination
          links={paginationLinks}
          setPage={setPage}
          onPrevious={() => setPage(page - 1)}
          onNext={() => setPage(page + 1)}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          from={from}
          to={to}
          total={count}
          items={t("desktop.settings.assets.assets", {
            count,
          })}
          entriesPerPage={entriesPerPageNum}
          setEntriesPerPage={setEntriesPerPage}
        />
      </div>
    </>
  )
}

export default AssetList
