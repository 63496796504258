import React, { PropsWithChildren, useCallback, useEffect } from "react"

import { useTranslation } from "react-i18next"

import { useNativeConnectivityChange } from "../../../hooks/useNativeConnectivityChange"
import { App as CapacitorApp } from "@capacitor/app"

import { appError } from "../../../redux/app/appSlice"
import { useActions } from "../../../redux/utils"

// TODO: check if IosSwipeBack.enable() works
export const NativeProviders = ({ children }: PropsWithChildren<unknown>) => {
  const { t } = useTranslation()

  const actions = useActions({
    appError: (message: string | null) => appError(message),
  })

  const checkConnectivity = useCallback(
    (connected: boolean) => {
      if (connected) {
        actions.appError(null)
      } else {
        actions.appError(t("tablet.no_internet"))
      }
    },
    [actions, t],
  )

  useNativeConnectivityChange(checkConnectivity)

  useEffect(() => {
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp()
      } else {
        window.history.back()
      }
    })
  }, [actions, checkConnectivity, t])

  return <>{children}</>
}
