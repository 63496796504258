import React, { MouseEvent } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Link, LinkProps } from "react-router-dom"

import { openInAppBrowser } from "../../../utils"
import BasicButton, { BasicButtonProps } from "../../basic/Button"

export type ButtonProps<
  C extends string | undefined = undefined,
  K extends string | undefined = undefined,
> = BasicButtonProps<C, K> & {
  to?: C
  href?: K
  target?: string
  rel?: string
  confirmationPrompt?: string
  download?: string
}

const Button = <C extends string | undefined, K extends string | undefined>(
  props: ButtonProps<C, K>,
) => {
  const { t } = useTranslation()

  const isDanger = ["danger", "danger-pop"].includes(props.variant ?? "")
  const buttonClass = classNames("Button", props.className, props.variant, {
    isSmall: props.isSmall,
    isDisabled: props.isDisabled,
    isLoading: props.isLoading,
    "has-icon": !!props.icon,
  })

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (
      isDanger &&
      !props.noConfirm &&
      !window.confirm(
        props.confirmationPrompt ??
          t("desktop.settings.person.person_form.confirm_delete"),
      )
    ) {
      e.preventDefault()
      return
    }

    if (props.href) {
      e.preventDefault()
      openInAppBrowser(props.href as string)
      return
    }

    props.onClick?.(e)
  }

  // Render a link if the `to` prop is provided
  if (props.to) {
    // we need to remove the props that are not valid for an Link element
    const {
      isSmall,
      noConfirm,
      isVisible,
      icon,
      isSubmit,
      isDisabled,
      isLoading,
      variant,
      ...linkProps
    } = props

    // the Link can not be disabled so we need to render a button instead
    if (isDisabled) {
      return <BasicButton {...props} />
    }

    return (
      <Link
        ref={props.ref as React.Ref<HTMLAnchorElement>}
        {...(linkProps as LinkProps)}
        onClick={props.onClick ? handleClick : undefined}
        className={buttonClass}
      >
        <span className="label">{props.children}</span>
      </Link>
    )
  }

  // Render an anchor if the `href` prop is provided
  if (props.href) {
    // we need to remove the props that are not valid for an anchor element
    const {
      isSmall,
      noConfirm,
      isVisible,
      icon,
      isSubmit,
      isDisabled,
      isLoading,
      variant,
      onClick,
      rel,
      ref,
      ...anchorProps
    } = props
    return (
      <a
        ref={ref as React.Ref<HTMLAnchorElement>}
        {...(anchorProps as React.HTMLProps<HTMLAnchorElement>)}
        onClick={onClick ? handleClick : undefined}
        className={buttonClass}
        rel={rel ?? "noopener noreferrer"}
      >
        <span className="label">{props.children}</span>
      </a>
    )
  }

  // Render a button if the `to` prop is not provided
  return <BasicButton {...props} onClick={handleClick} />
}

export default Button
