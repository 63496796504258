import { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import { LabelPaths } from "../../../../constants"
import { Facet, OptionalKeysRecord } from "../../../../types/sharedTypes"
import { getLabel } from "../../../../utils"

import { DevicesFacets } from "../../../../redux/api/devices/types"

import DeviceBatteryFilter from "../../../../components/Filter/DeviceBatteryFilter"
import DeviceWiFiFilter from "../../../../components/Filter/DeviceWiFiFilter"
import FacetsFilter from "../../../../components/Filter/FacetsFilter"
import Filters from "../../../../components/Filter/Filters"
import SearchFilter from "../../../../components/Filter/SearchFilter"
import { FilterSpecialValues } from "../../../../components/Filter/types"

export type Filter = {
  building_id: string
  floor_id: string
  type: string
  battery: string
  connectivity: string
  search: string
}

export type FilterKeys = keyof Filter

type MapFacetToFilters = OptionalKeysRecord<DevicesFacets, FilterKeys>

type Props = {
  onChange: (filter: Filter) => Promise<void>
  defaultValues: Filter
  mapFacetToFilters: MapFacetToFilters
  facets?: OptionalKeysRecord<DevicesFacets, Facet[]>
  useFacets: readonly DevicesFacets[]
  showCount?: boolean
}

const isFilterKey = (value: string): value is FilterKeys => {
  const validValues: FilterKeys[] = ["building_id", "floor_id", "type"]
  return validValues.includes(value as FilterKeys)
}

const isDevicesFacets = (value: string): value is DevicesFacets => {
  const validValues: DevicesFacets[] = ["building", "floor", "type"]
  return validValues.includes(value as DevicesFacets)
}

const getOnChangeKey = (
  facet: DevicesFacets,
  mapFacetToFilters: MapFacetToFilters,
) => {
  const onChangeKey = mapFacetToFilters[facet] ?? facet

  if (isFilterKey(onChangeKey)) {
    return onChangeKey
  }
}

const DevicesFilters = ({
  onChange,
  defaultValues,
  mapFacetToFilters,
  facets,
  useFacets,
  showCount = true,
}: Props) => {
  const { t } = useTranslation()

  const floorRef = useRef<string>(defaultValues.floor_id)
  const filtersRef = useRef<Filter>(defaultValues)

  const [filters, setFilters] = useState<Filter>(defaultValues)

  const handleOnChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[]) => {
      // the FloorFilter triggers unwanted onChanges this is temporary fix
      if (filterName === "floor_id" && floorRef.current === value) {
        return
      }

      filtersRef.current = {
        ...filtersRef.current,
        [filterName]: value,
      }
      onChange(filtersRef.current as Filter)
      floorRef.current = filtersRef.current.floor_id
      setFilters(filtersRef.current)
    },
    [onChange],
  )

  return (
    <Filters>
      <div className="FiltersContainer">
        {facets &&
          useFacets.map((facetName) => {
            if (isDevicesFacets(facetName)) {
              const onChangeKey = getOnChangeKey(facetName, mapFacetToFilters)

              const facetOptions =
                facetName === "type" && facets[facetName]
                  ? facets[facetName].map((option) => ({
                      ...option,
                      name:
                        option.id !== FilterSpecialValues.ALL
                          ? getLabel(
                              `deviceTypes.${option.id.toLowerCase()}` as LabelPaths,
                            )
                          : option.name,
                    }))
                  : facets[facetName]

              return (
                <FacetsFilter
                  showCount={showCount}
                  key={facetName}
                  options={facetOptions}
                  onChange={onChangeKey && handleOnChange(onChangeKey)}
                  value={
                    ((onChangeKey && filters[onChangeKey]) ?? "") as string
                  }
                />
              )
            }
            return null
          })}

        <DeviceBatteryFilter
          onChange={handleOnChange("battery")}
          value={filters.battery}
        />

        <DeviceWiFiFilter
          onChange={handleOnChange("connectivity")}
          value={filters.connectivity}
        />

        <SearchFilter
          placeholder={t("desktop.settings.visitors.devices.search_devices")}
          onChange={handleOnChange("search")}
          value={filters.search}
        />
      </div>
    </Filters>
  )
}

export default DevicesFilters
