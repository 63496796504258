import { t } from "i18next"

const PROFILE_ROOT = "/settings/profile"
const PROFILE_GENERAL = `${PROFILE_ROOT}/general`
const PROFILE_EMAIL = `${PROFILE_ROOT}/change-email`
const PROFILE_PASSWORD = `${PROFILE_ROOT}/reset-password`
const PROFILE_NOTIFICATIONS = `${PROFILE_ROOT}/notifications`
const PROFILE_NOTIFICATION_SETTINGS = `${PROFILE_ROOT}/notification-settings`

export const ACCOUNT_PATHS = {
  root: PROFILE_ROOT,
  general: PROFILE_GENERAL,
  email: PROFILE_EMAIL,
  password: PROFILE_PASSWORD,
  notifications: {
    root: PROFILE_NOTIFICATIONS,
    settings: PROFILE_NOTIFICATION_SETTINGS,
  },
} as const

export const ROLES = {
  portal_admin: t(
    "desktop.settings.profile.general.form.user_roles.portal_admin",
  ),
  portal_officer_manager: t(
    "desktop.settings.profile.general.form.user_roles.portal_officer_manager",
  ),
  portal_user: t(
    "desktop.settings.profile.general.form.user_roles.portal_user",
  ),
}
