import React from "react"

import classNames from "classnames"

import "./Popup.sass"

type PopupProps = {
  open: boolean
  position?: "high" | "medium" | "low"
  className?: string
}

export const Popup: React.FC<React.PropsWithChildren<PopupProps>> = ({
  open,
  position = "medium",
  children,
  className,
}) => {
  const drawerClass = classNames(className, position, "Popup", {
    open,
  })

  return (
    <div className={drawerClass}>
      <div className="popup-content">{children}</div>
    </div>
  )
}

type PopupButtonProps = {
  label: React.ReactNode
  color?: "blue" | "gray" | "none"
  small?: boolean
  noIcon?: boolean
  disabled?: boolean
  onClick: () => void
}

export const PopupButton: React.FC<PopupButtonProps> = ({
  label,
  color = "blue",
  small = true,
  noIcon = false,
  disabled = false,
  onClick,
}) => {
  const drawerClass = classNames("PopupButton", color, {
    small,
    noIcon,
    disabled,
  })

  return (
    <div
      className={drawerClass}
      onClick={() => {
        if (!disabled) {
          onClick()
        }
      }}
    >
      {label}
    </div>
  )
}
