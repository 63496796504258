export type VisitorEmailSettingsRequestParams = {
  building_id: string
  type: VisitorEmailType
}

export type VisitorEmailPreviewRequestParams = {
  building_id: string
  type: VisitorEmailType
  content: VisitorEmailSettingRequest
}

export enum VisitorEmailType {
  INVITATION = "invitation",
  WELCOME = "welcome",
}

export type InvitationEmailResponse = {
  type: VisitorEmailType.INVITATION
  id: string
  show_details: boolean
  show_location: boolean
  address: string
  directions_link: boolean
  additional_info: boolean
  additional_info_text?: string
  easy_check_in: boolean
  is_easy_check_in_available: boolean
  attach_signed_documents?: boolean
}

export type WelcomeEmailResponse = {
  type: VisitorEmailType.WELCOME
  id: string
  show_details: boolean
  show_location: boolean
  address: string
  directions_link: boolean
  additional_info: boolean
  additional_info_text?: string
  easy_check_in: boolean
  is_easy_check_in_available: boolean
  attach_signed_documents?: boolean
}

export type VisitorEmailSettingResponse =
  | InvitationEmailResponse
  | WelcomeEmailResponse

export type VisitorEmailSettingRequest = {
  id?: string
  show_details: boolean
  show_location: boolean
  address?: string
  directions_link?: string
  additional_info: boolean
  additional_info_text?: string
  easy_check_in?: boolean
  attach_signed_documents?: boolean
}

export type VisitorEmailPreviewResponse = string
