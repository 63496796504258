import React, { useCallback, useMemo } from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { URL_REGEX } from "../../../../../utils"
import { CUSTOM_CONTENT_TYPES } from "../constants"
import { useCustomContentContext } from "../CustomContentContext"

import { Input } from "../../../../../components/basic/Input"
import Field from "../../../../../components/Field"
import PageForm from "../../../../../components/Form/PageFormHook"

import "./EditWebpageForm.sass"

const EditWebpageForm = () => {
  const { t } = useTranslation()
  const { push } = useNavigation()

  const {
    paths,
    selectedSection,
    content,
    onUpdateSectionContent,
    isUpdateMode,
  } = useCustomContentContext()

  const { control } = useFormContext()

  const sectionContent = useMemo(
    () => content.sections.at(selectedSection),
    [content.sections, selectedSection],
  )

  const onUpdateClick = useCallback(async () => {
    if (sectionContent?.content) {
      onUpdateSectionContent(selectedSection, {
        type: CUSTOM_CONTENT_TYPES.webpage,
        content: sectionContent.content,
      })

      push(paths.root)
    }
  }, [
    onUpdateSectionContent,
    paths.root,
    push,
    sectionContent,
    selectedSection,
  ])

  return (
    <div className="EditWebpageForm">
      <PageForm
        updateMode
        onUpdate={onUpdateClick}
        backUrl={isUpdateMode ? paths.root : paths.contentTypes}
      >
        <Field
          control={control}
          name={`content.sections.${selectedSection}.content.url`}
          label={t(
            "desktop.settings.rooms.custom_content.forms.webpage.add_url_label",
          )}
          subText={t(
            "desktop.settings.rooms.custom_content.forms.webpage.add_url_subtext",
          )}
          helpText={t(
            "desktop.settings.rooms.custom_content.forms.webpage.add_url_subtext",
          )}
          rules={{
            validate: (value) => {
              if (!value) {
                return t(
                  "desktop.settings.rooms.custom_content.forms.webpage.url_required",
                )
              }
            },
            pattern: {
              value: URL_REGEX,
              message: t(
                "desktop.settings.rooms.custom_content.forms.webpage.invalid_url_format",
              ),
            },
          }}
        >
          {(props) => <Input {...props} />}
        </Field>
      </PageForm>
    </div>
  )
}

export default EditWebpageForm
