import { Trans, useTranslation } from "react-i18next"

import Breadcrumbs from "../../../components/Breadcrumbs"
import VisitorSettingsUserForm from "../../../components/Form/Visitors/VisitorSettingsUserForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./VisitorSettings.sass"

const VisitorSettingsUser = () => {
  const { t } = useTranslation()

  return (
    <View className="VisitorSettings">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.visitors.title"),
          t("desktop.settings.visitors.visitor_settings.title"),
        ]}
      />

      <Intro isConstrained>
        <Trans i18nKey="desktop.settings.visitors.visitor_settings.subtitle" />
      </Intro>

      <Space size={0.75} />

      <VisitorSettingsUserForm />
    </View>
  )
}

export default VisitorSettingsUser
