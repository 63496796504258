import React from "react"

import { useTranslation } from "react-i18next"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { CUSTOM_CONTENT_TYPES } from "../constants"
import { useCustomContentContext } from "../CustomContentContext"
import { CONTENT_TYPES_OPTIONS, getSectionContentTypeAsString } from "../utils"

import { SectionContentType } from "../../../../../redux/api/customContent/types"

import Button from "../../../../../components/advanced/Button"
import Card from "../../../../../components/basic/Card"

const SelectContentTypes = () => {
  const { push } = useNavigation()
  const { t } = useTranslation()

  const { paths, selectedSection, onUpdateSectionContent } =
    useCustomContentContext()

  const handleContentTypeClick = (type: SectionContentType) => {
    const contentType = getSectionContentTypeAsString(type)

    if (type === CUSTOM_CONTENT_TYPES.roomStatus) {
      onUpdateSectionContent(selectedSection, {
        type: CUSTOM_CONTENT_TYPES.roomStatus,
        content: {
          rooms: [],
        },
      })

      push(paths.root)
    } else {
      push(paths[contentType])
    }
  }

  return (
    <div className="SelectContentTypes">
      <div className="SelectContentTypes__list">
        {CONTENT_TYPES_OPTIONS.map(({ type, title, icon, description }) => (
          <div
            key={type}
            className="ContentTypeCard"
            onClick={() => handleContentTypeClick(type)}
          >
            <Card>
              <div className="ContentTypeCardTitle">{title}</div>
              <div className="ContentTypeCardIcon">{icon}</div>
              <div className="ContentTypeCardDescr">{description}</div>
            </Card>
          </div>
        ))}
      </div>
      <Button className="back-button" variant="link" to={paths.root}>
        {t("general.back")}
      </Button>
    </div>
  )
}

export default SelectContentTypes
