import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react"

import dayjs, { Dayjs } from "dayjs"
import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { DeskWithReservations } from "./types"

import { store } from "../../../redux/reducers"

const { stringify } = queryString

export const FLOOR_PLAN_SCHEDULE_PATHNAME = "/manage/floor-plan"

type FloorPlanState = {
  hoveredDeskId: string
  openedDeskId: string
  currentDate: Dayjs
  scrollToView: boolean
}

const initialState: FloorPlanState = {
  hoveredDeskId: "",
  openedDeskId: "",
  currentDate: dayjs(),
  scrollToView: false,
}

/**
 * Provider hook
 */
type FloorPlanContextState = {
  floorPlanState: FloorPlanState
  setFloorPlanState: Dispatch<SetStateAction<FloorPlanState>>
}

const useFloorPlanProvider = (): FloorPlanContextState => {
  const [floorPlanState, setFloorPlanState] =
    useState<FloorPlanState>(initialState)

  return {
    floorPlanState,
    setFloorPlanState,
  }
}

/**
 * Context
 */
const FloorPlanContext = createContext<FloorPlanContextState | null>(null)

/**
 * Provider
 */
type FloorPlanProviderProps = {
  children: ReactNode
}
const FloorPlanProvider: FC<FloorPlanProviderProps> = ({ children }) => {
  const providerValue: FloorPlanContextState = useFloorPlanProvider()

  return (
    <FloorPlanContext.Provider value={providerValue}>
      {children}
    </FloorPlanContext.Provider>
  )
}

/**
 * context hook
 */
type UseFloorPlanContext = {
  hoveredDesk: string
  openedDesk: string
  scrollToView: boolean
  setHoveredDesk: (id: string) => void
  clearHoveredDesk: () => void
  setOpenedDesk: (id: string, scrollToView?: boolean) => void
  clearOpenedDesk: () => void
  openReservation: (id: string) => void
  createNewReservation: (desk: DeskWithReservations) => void
}

export const useFloorPlanContext = (): UseFloorPlanContext => {
  const state: FloorPlanContextState | null = useContext(FloorPlanContext)
  const history = useHistory()

  if (!state) {
    throw new Error("useFloorPlanContext must be used within FloorPlanProvider")
  }

  const setHoveredDesk = (id: string) => {
    if (id !== state.floorPlanState.hoveredDeskId)
      state.setFloorPlanState((s) => ({ ...s, hoveredDeskId: id }))
  }

  const clearHoveredDesk = () => {
    state.setFloorPlanState((s) => ({ ...s, hoveredDeskId: "" }))
  }

  const setOpenedDesk = (id: string, scrollToView: boolean = false) => {
    state.setFloorPlanState((s) => ({ ...s, openedDeskId: id, scrollToView }))
  }

  const clearOpenedDesk = () => {
    state.setFloorPlanState((s) => ({
      ...s,
      openedDeskId: "",
      scrollToView: false,
    }))
  }

  const openReservation = (id: string) => {
    history.push(`${FLOOR_PLAN_SCHEDULE_PATHNAME}/${id}`)
  }

  const createNewReservation = ({ name, id }: DeskWithReservations) => {
    const state = store.getState()

    const { email, first_name, last_name } = state.user.entry
    const reservation = {
      date: dayjs(state.app.currentDate).toISOString(),
      desk: id,
      deskName: name,
      user: email,
      userFirstName: first_name,
      userLastName: last_name,
    }

    history.push({
      pathname: `${FLOOR_PLAN_SCHEDULE_PATHNAME}/add`,
      search: stringify(reservation),
    })
  }

  return {
    hoveredDesk: state.floorPlanState.hoveredDeskId,
    openedDesk: state.floorPlanState.openedDeskId,
    scrollToView: state.floorPlanState.scrollToView,
    setHoveredDesk,
    clearHoveredDesk,
    setOpenedDesk,
    clearOpenedDesk,
    openReservation,
    createNewReservation,
  }
}

export default FloorPlanProvider
