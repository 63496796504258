import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import Button from "./advanced/Button"

import { ReservationType } from "../redux/reservations/types"

import "./FailedReservations.sass"

type DeskFailedReservation = {
  message: string
  code: number
  date: string
  reservation_date: string
}

type FailedReservation = {
  _error?: string[]
  date: string
  start?: string
  end?: string
}

interface IProps {
  failedReservations: DeskFailedReservation[] | FailedReservation[]
  handleClose: () => void
  title: string
  buttonText: string
  type: string
  reservationType: ReservationType
}

export const FailedReservations = ({
  failedReservations,
  handleClose,
  title,
  buttonText,
  type,
  reservationType,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <div className={`failed-meetings-${type}`}>
      <div>
        <div>{title}:</div>
        <div className="container">
          {reservationType === "desk"
            ? (failedReservations as DeskFailedReservation[]).map(
                (failedMeeting, i: number) => {
                  const { message, reservation_date } = failedMeeting
                  const formattedDay =
                    dayjs(reservation_date).format("dddd DD MMM")
                  return (
                    <div className="row" key={i}>
                      <div className="reservation-date">{formattedDay}</div>
                      <div className="message">{t(message as ParseKeys)}</div>
                    </div>
                  )
                },
              )
            : (failedReservations as FailedReservation[]).map(
                (failedMeeting, i: number) => {
                  const { _error, date, start, end } = failedMeeting

                  const errorMessage = _error
                    ? t(`${(_error[0] as ParseKeys) || ""}`)
                    : start && end
                      ? `${start}, ${end}`
                      : ""

                  const formattedDay = dayjs(date).format("dddd DD MMM")
                  return (
                    <div className="row" key={i}>
                      <div className="reservation-date">{formattedDay}</div>
                      <div className="message">{errorMessage}</div>
                    </div>
                  )
                },
              )}
        </div>
        <Button variant="mobile-action" onClick={handleClose}>
          {buttonText}{" "}
        </Button>
      </div>
    </div>
  )
}

export default FailedReservations
