import { ScheduleDeskDataRow } from "../../../redux/desk_schedule/types"

type Props = {
  deskRow: ScheduleDeskDataRow
  rowNumber: number
}

export const DeskRowHeading = ({ deskRow, rowNumber }: Props) => (
  <div className="DeskRowHeading RowHeading">
    <div className="row-number">{rowNumber}</div>
    <span className="desk" title={deskRow.name}>
      {deskRow.name}
    </span>
  </div>
)
