import { api } from ".."
import { slotsURL, slotURL } from "../../../api"
import { TimeslotResponse } from "../../timeslots/types"
import {
  TimeslotApiResponse,
  TimeslotCreateRequest,
  TimeslotUpdateRequest,
} from "./types"

export const timeslots = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchTimeslots: builder.query<TimeslotApiResponse, void>({
      query: () => ({
        url: slotsURL(),
      }),
      providesTags: ["Timeslots"],
    }),
    fetchTimeslot: builder.query<TimeslotResponse | undefined, string>({
      query: () => ({
        url: slotsURL(),
      }),
      providesTags: ["Timeslots"],
      transformResponse: (response: TimeslotApiResponse, meta, slotId) => {
        return response.find((slot) => slot.id === slotId)
      },
    }),
    createTimeslot: builder.mutation<TimeslotResponse, TimeslotCreateRequest>({
      query: (body) => ({
        url: slotsURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: ["Timeslots"],
    }),
    updateTimeslot: builder.mutation<TimeslotResponse, TimeslotUpdateRequest>({
      query: ({ id, ...body }) => ({
        url: slotURL(id),
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Timeslots"],
    }),
    deleteTimeslot: builder.mutation<void, string>({
      query: (id) => ({
        url: slotURL(id),
        method: "DELETE",
      }),
      invalidatesTags: ["Timeslots"],
    }),
  }),
})

export const {
  useFetchTimeslotsQuery,
  useLazyFetchTimeslotsQuery,
  useFetchTimeslotQuery,
  useCreateTimeslotMutation,
  useUpdateTimeslotMutation,
  useDeleteTimeslotMutation,
} = timeslots
