import { PropsWithChildren } from "react"

import { getAppParams } from "../../../redux/appParams/selectors"
import { useAppSelector } from "../../../redux/reducers"

type RedirectLinkProps = {
  to: string
  className?: string
  openInNewWindow?: boolean
}

/**
 * Automatically creates a normal / whitelabelled link to the getjoan.com site which then redirects to a specific URL (knowledge base, store, ...).
 * For whitelabeled apps, the URL will be `https://getjoan.com/re/${to}` and for non-whitelabeled apps, the URL will be `https://getjoan.com/r/${to}`.
 * The redirects have to be set up on the Wordpress site.
 * @param to - the slug of the page to redirect to
 * @param className - optional class name
 * @param openInNewWindow - whether to open the link in a new window or not
 * @returns constructed URL
 */
export const RedirectLink = ({
  children,
  to,
  className,
  openInNewWindow = true,
}: PropsWithChildren<RedirectLinkProps>) => {
  const { isAppWhitelabel } = useAppSelector(getAppParams)
  const redirectType = isAppWhitelabel ? "re" : "r"
  const url = `https://getjoan.com/${redirectType}/${to}`

  if (openInNewWindow) {
    return (
      <a
        onClick={(e) => e.stopPropagation()}
        className={className}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <a onClick={(e) => e.stopPropagation()} className={className} href={url}>
      {children}
    </a>
  )
}
