import dayjs from "dayjs"

import { ISODate } from "../../../../../types/sharedTypes"

import CalendarSVG from "../../../../../assets/images/icons/Calendar.svg"

type Props = {
  dateTime: ISODate | null
}

const Date = ({ dateTime }: Props) => {
  if (!dateTime) return null

  return (
    <div className="description-row">
      <div className="description-row-icon">
        <CalendarSVG />
      </div>

      <div className="description-row-text">
        {dayjs(dateTime).format("MMM D YYYY")}
      </div>
    </div>
  )
}

export default Date
