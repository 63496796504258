import React, { useCallback, useMemo, useState } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { buildingsWithVMURL, devicesURL } from "../../../api"
import { useToast } from "../../../hooks/useToast"
import { OptionType } from "../../../types/sharedTypes"
import { IP_ADDRESS_REGEX } from "../../../utils"
import AsyncSelect from "../../advanced/AsyncSelect"
import { RedirectLink } from "../../advanced/RedirectLink"
import { Input } from "../../basic/Input"
import MultiToggle from "../../basic/MultiToggle"
import { RadioGroup } from "../../basic/Radio"
import { Select } from "../../basic/Select"
import Field from "../../Field"
import { setErrors } from "../formUtils"
import ModalForm from "../ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { getAppParams } from "../../../redux/appParams/selectors"
import { BuildingResponse } from "../../../redux/buildings/types"
import {
  createPrinter,
  fetchDevices,
  pairTablet,
} from "../../../redux/devices/devicesSlice"
import {
  DeviceResponse,
  DeviceType,
  PrinterModelType,
  PrinterRequest,
  TabletPairRequest,
} from "../../../redux/devices/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import "./PairDeviceForm.sass"

type FormValues = {
  name: string
  building?: BuildingResponse
  connected_device?: DeviceResponse
  pin: string
  model: OptionType<string>
  ip_address: string
}

enum PairDeviceType {
  TABLET = "tablet",
  PRINTER = "printer",
}

enum ConnectionMethod {
  WIFI = "wifi",
  ETHERNET = "ethernet",
}

const FORM_MAPPING = {
  connected_device_id: "connected_device",
  building_id: "building",
} as const

const PairDeviceForm = () => {
  const { closeModal } = useModals()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const [selectedDevice, setSelectedDevice] = useState(PairDeviceType.TABLET)
  const [connectionMethod, setConnectionMethod] = useState(
    ConnectionMethod.WIFI,
  )

  const connectionMethodOptions = useMemo(
    () => [
      {
        value: ConnectionMethod.WIFI,
        label: t("desktop.settings.visitors.devices.form.wifi"),
      },
      {
        value: ConnectionMethod.ETHERNET,
        label: t("desktop.settings.visitors.devices.form.ethernet"),
      },
    ],
    [t],
  )

  const printerModelsOptions = useMemo(
    () =>
      Object.values(PrinterModelType).map((model) => ({
        label: model,
        value: model,
      })),
    [],
  )

  const methods = useForm<FormValues>({
    defaultValues: {
      name: "",
      building: undefined,
      connected_device: undefined,
      pin: "",
      model: printerModelsOptions[0],
      ip_address: "",
    },
  })

  const {
    setError,
    control,
    formState: { isSubmitting },
    reset,
  } = methods
  const actions = useActions({
    fetchDevices: () => fetchDevices(),
    pairTablet: (tablet: TabletPairRequest) => pairTablet(tablet),
    createPrinter: (printer: PrinterRequest) => createPrinter(printer),
  })

  const getBuildingId = (building?: BuildingResponse) =>
    building?.id ? building.id : undefined

  const getDeviceId = (device?: DeviceResponse) =>
    device?.id ? device.id : null

  const onCreateClick = useCallback(
    async ({
      name,
      building,
      connected_device,
      pin,
      model,
      ip_address,
    }: FormValues) => {
      if (selectedDevice === PairDeviceType.TABLET) {
        const response = await actions.pairTablet({
          pin: pin.toUpperCase(),
          name,
          building_id: getBuildingId(building),
          connected_device_id: getDeviceId(connected_device),
        })

        if (pairTablet.rejected.match(response)) {
          if (response.payload) {
            setErrors(response.payload, setError, errorToast, FORM_MAPPING)
          }
        } else {
          actions.fetchDevices()
          infoToast(t("desktop.settings.visitors.devices.form.paired_toast"))
          closeModal()
        }
      } else {
        const response = await actions.createPrinter({
          name,
          model: model.value,
          ip_address,
        })

        if (createPrinter.rejected.match(response)) {
          if (response.payload) {
            setErrors(response.payload, setError, errorToast)
          }
        } else {
          actions.fetchDevices()
          infoToast(t("desktop.settings.visitors.devices.form.paired_toast"))
          closeModal()
        }
      }
    },
    [actions, errorToast, infoToast, closeModal, setError, selectedDevice, t],
  )

  const onDeviceChangeHandler = (device: PairDeviceType) => {
    reset()
    setSelectedDevice(device)
  }

  const onMethodChangeHandler = (method: string) => {
    setConnectionMethod(method as ConnectionMethod)
  }

  const {
    isAppWhitelabel,
    appStore: { appleStoreURL, googlePlayURL },
  } = useAppSelector(getAppParams)

  return (
    <FormProvider {...methods}>
      <ModalForm
        className="PairDeviceForm"
        updateMode={false}
        title={t("desktop.settings.visitors.devices.form.pair_new_device")}
        onCreate={onCreateClick}
        hasConfirmationPrompt={false}
        createButtonText={t(
          "desktop.settings.visitors.devices.form.pair_device",
        )}
        disabled={isSubmitting}
      >
        <div className="switch">
          <MultiToggle
            options={[
              {
                label: t(
                  "desktop.settings.visitors.devices.device_types.tablet",
                ),
                value: PairDeviceType.TABLET,
              },
              {
                label: t(
                  "desktop.settings.visitors.devices.device_types.printer",
                ),
                value: PairDeviceType.PRINTER,
              },
            ]}
            onChange={onDeviceChangeHandler}
            value={selectedDevice}
          />
        </div>
        <div className="info">
          {t(
            "desktop.settings.visitors.devices.form.pair_new_device_info_start",
          )}{" "}
          <RedirectLink to="how-to-add-devices">
            {t("desktop.settings.visitors.devices.form.this_article")}
          </RedirectLink>{" "}
          {t("desktop.settings.visitors.devices.form.pair_new_device_info_end")}
        </div>
        {selectedDevice === PairDeviceType.PRINTER && (
          <Field
            className="field-width-50"
            control={control}
            name="model"
            label={t("desktop.settings.visitors.devices.form.select_printer")}
          >
            {(props) => (
              <Select
                {...props}
                options={printerModelsOptions}
                disabled={isSubmitting}
              />
            )}
          </Field>
        )}
        <Field
          className={
            selectedDevice === PairDeviceType.PRINTER ? "field-width-50" : ""
          }
          control={control}
          name="name"
          label={
            selectedDevice === PairDeviceType.TABLET
              ? t("desktop.settings.visitors.devices.form.tablet_name")
              : t("desktop.settings.visitors.devices.form.printer_name")
          }
        >
          {(props) => (
            <Input
              {...props}
              autoFocus
              maxLength={60}
              disabled={isSubmitting}
            />
          )}
        </Field>
        {selectedDevice === PairDeviceType.TABLET && (
          <>
            <Field
              className="field-width-50"
              control={control}
              name="building"
              label={t(
                "desktop.settings.visitors.devices.form.select_location",
              )}
            >
              {(props) => (
                <AsyncSelect
                  {...props}
                  urlGenerator={(fetchOptions) =>
                    buildingsWithVMURL(fetchOptions)
                  }
                  nothingFoundMessage={t(
                    "desktop.settings.visitors.devices.form.errors.no_location",
                  )}
                  getOptionLabel={(building) => building.name}
                  getOptionValue={(building) => building.id}
                />
              )}
            </Field>
            <Field
              className="field-width-50"
              control={control}
              name="connected_device"
              label={t(
                "desktop.settings.visitors.devices.form.connected_printer",
              )}
            >
              {(props) => (
                <AsyncSelect
                  {...props}
                  urlGenerator={(fetchOptions) => devicesURL(fetchOptions)}
                  nothingFoundMessage={t(
                    "desktop.settings.visitors.devices.form.no_printers",
                  )}
                  getOptionLabel={(printer) => printer.name || printer.model}
                  getOptionValue={(printer) => printer.id}
                  filterResultsFn={(device) =>
                    device.type === DeviceType.PRINTER
                  }
                  clearable
                />
              )}
            </Field>
            <div className="input-info">
              <label className="Label">
                {t("desktop.settings.visitors.devices.form.device_pin")}
              </label>
              <div className="text">
                <Trans
                  i18nKey="desktop.settings.visitors.devices.form.device_pin_help"
                  components={
                    isAppWhitelabel
                      ? {
                          a: <></>,
                          b: <></>,
                        }
                      : {
                          a: (
                            <a
                              href={appleStoreURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              iPad
                            </a>
                          ),
                          b: (
                            <a
                              href={googlePlayURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Android
                            </a>
                          ),
                        }
                  }
                  values={{
                    ipad: "iPad",
                    android: "Android",
                  }}
                ></Trans>
              </div>
            </div>
            <Field
              className="field-width-50 Uppercase"
              control={control}
              name="pin"
              rules={{
                minLength: {
                  value: 6,
                  message: t(
                    "desktop.settings.visitors.devices.form.tablet_pin_too_short",
                  ),
                },
              }}
            >
              {(props) => (
                <Input
                  {...props}
                  maxLength={6}
                  disabled={isSubmitting}
                  placeholder={t(
                    "desktop.settings.visitors.devices.form.tablet_pin",
                  )}
                />
              )}
            </Field>
          </>
        )}
        {selectedDevice === PairDeviceType.PRINTER && (
          <>
            <div className="input-info">
              <label className="Label">
                {t("desktop.settings.visitors.devices.form.connection_method")}
              </label>
              <RadioGroup
                options={connectionMethodOptions}
                value={connectionMethod}
                onChange={onMethodChangeHandler}
              />
            </div>
            <div className="input-info">
              <label className="Label">
                {t("desktop.settings.visitors.devices.form.find_ip")}
              </label>
              {connectionMethod === ConnectionMethod.WIFI && (
                <div className="text">
                  {t("desktop.settings.visitors.devices.form.wifi_info")}
                  <br />
                  <br />
                  {t("desktop.settings.visitors.devices.form.wifi_info_extra")}
                </div>
              )}
              {connectionMethod === ConnectionMethod.ETHERNET && (
                <div className="text">
                  {t("desktop.settings.visitors.devices.form.ethernet_info")}
                  <br />
                  <br />
                  {t(
                    "desktop.settings.visitors.devices.form.ethernet_info_extra",
                  )}
                </div>
              )}
            </div>
            <Field
              className="field-width-50"
              control={control}
              name="ip_address"
              rules={{
                pattern: {
                  value: IP_ADDRESS_REGEX,
                  message: t(
                    "desktop.settings.visitors.devices.form.printer_ip_address_invalid",
                  ),
                },
              }}
            >
              {(props) => (
                <Input
                  {...props}
                  maxLength={15}
                  disabled={isSubmitting}
                  placeholder={t(
                    "desktop.settings.visitors.devices.form.printer_ip_address",
                  )}
                />
              )}
            </Field>
          </>
        )}
      </ModalForm>
    </FormProvider>
  )
}

export default PairDeviceForm
