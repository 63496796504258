import {
  ChangeEvent,
  createRef,
  forwardRef,
  HTMLProps,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react"

import classNames from "classnames"

import { generateString } from "../../../utils"
import Button, { BasicButtonProps } from "../Button"

import "./style.sass"

export type UploadFileProps = {
  failedAttempts?: number
  loading?: boolean
  className?: string
  buttonProps?: BasicButtonProps<HTMLButtonElement, never>
  onChange: (files: FileList | null) => void
  value?: FileList | null
  hasError?: boolean
} & Omit<HTMLProps<HTMLInputElement>, "onChange" | "value">

export const UploadFile = forwardRef<
  HTMLDivElement,
  PropsWithChildren<UploadFileProps>
>(
  (
    {
      value,
      onChange,
      failedAttempts,
      loading,
      children,
      className,
      buttonProps = {},
      ...props
    },
    ref,
  ) => {
    const fileRef = createRef<HTMLInputElement>()

    const openFileUpload = (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      fileRef.current?.click()
    }

    const innerOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.files)
      },
      [onChange],
    )

    const recognizer = useMemo(() => {
      return props.name || generateString(5)
    }, [props.name])

    let displayNames = null

    if (value && value.length > 0) {
      displayNames = Array.from(value).map((file: File, i) => (
        <div key={i}>{file.name}</div>
      ))
    }

    return (
      <div ref={ref} className={classNames("UploadFile", className)}>
        <input
          accept="image/png,image/jpeg,image/jpg,image/svg+xml,image/svg"
          style={{ display: "none" }}
          ref={fileRef}
          key={`input-${recognizer}-${failedAttempts}`}
          type="file"
          {...props}
          onChange={innerOnChange}
        />
        <Button isDisabled={loading} {...buttonProps} onClick={openFileUpload}>
          {children}
        </Button>
        {displayNames && <div className="UploadFileNames">{displayNames}</div>}
      </div>
    )
  },
)
