import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { departmentsURL, desksURL, usersURL } from "../../api"
import { ReservationCheckinUtils } from "../../checkin_utils"
import { PERMISSIONS } from "../../constants"
import { timeZone as defaultTimezone } from "../../dayjs"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import useCheckReservationWindowLength from "../../hooks/useCheckReservationWindowLength"
import { useDeskCheckIn } from "../../hooks/useDeskCheckIn"
import { useFromToCalculator } from "../../hooks/useFromToCalculator"
import { useIsOnDeskSpotCheckIn } from "../../hooks/useIsOnSpotDeskCheckIn"
import { useToast } from "../../hooks/useToast"
import DeleteReservationModal from "../../modals/DeleteReservationModal"
import FailedReservationsModal from "../../modals/FailedReservationsModal"
import {
  GroupBase,
  InternalTime,
  OptionType,
  RecurringType,
} from "../../types/sharedTypes"
import { setTimeToDayjs } from "../../utils"
import AsyncSelect from "../advanced/AsyncSelect"
import Button from "../advanced/Button"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import { DatePicker } from "../advanced/DatePicker"
import Loader from "../basic/Loader"
import Options from "../basic/Options"
import { Select } from "../basic/Select"
import { TimeRange, TimeRangePicker } from "../basic/TimeRangePicker"
import Field from "../Field"
import QuickSlots from "../Field/QuickSlots"
import { CheckinSummary } from "../Manage/CheckinSummary"
import { setErrors } from "./formUtils"
import ModalForm from "./ModalFormHook"
import { getScheduleOptions, TIMEZONE_OPTIONS, timezoneMapper } from "./options"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { DepartmentResponse } from "../../redux/api/departments/types"
import {
  useCheckoutDeskReservationMutation,
  useCreateDeskReservationMutation,
  useDeleteDeskReservationMutation,
  useFetchDeskReservationQuery,
  useUpdateDeskReservationMutation,
} from "../../redux/api/deskReservations"
import {
  CreateDeskReservation,
  isRecurringReservation,
} from "../../redux/api/deskReservations/types"
import { useLazyFetchDeskQuery } from "../../redux/api/desks"
import { DeskResponse } from "../../redux/api/desks/types"
import { useFetchTimeslotsQuery } from "../../redux/api/timeslots"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { selectApp } from "../../redux/app/selectors"
import { CHECK_INS_TYPES } from "../../redux/check_ins/types"
import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { TimeslotResponse } from "../../redux/timeslots/types"
import { formatSlotTime } from "../../redux/timeslots/utils"
import { selectUser } from "../../redux/user/selectors"
import {
  formatUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"

import "./DeskBookingForm.sass"

const getFormMapping = (forceTimeslots: boolean) =>
  ({
    end: forceTimeslots ? ["timeslot_id", "date"] : ["timeRange", "date"],
    start: forceTimeslots ? ["timeslot_id", "date"] : ["timeRange", "date"],
    "recurring_rule.until": "scheduleDate",
    desk_id: "desk",
    user_id: "user",
  }) as const

export type FormType = {
  start: string
  end: string
  date: Dayjs
  schedule?: OptionType<string | null>
  desk?: string
  deskName?: string
  user?: string
  userFirstName?: string
  userLastName?: string
  scheduleDate?: Dayjs
  timezone?: string | null
}

type FormValues = {
  user: UserResponse | null
  timeRange: Required<TimeRange>
  date: Date
  schedule?: OptionType<string | null>
  desk: DeskResponse | null
  scheduleDate: Date
  timezone?: OptionType<string | null>
  department?: DepartmentResponse
  timeslot_id?: string
}

type Props = {
  reservationId?: string
  formData?: FormType
  onClose?: () => void
}

export const DeskBookingForm = ({
  reservationId,
  formData,
  onClose,
}: Props) => {
  const [isCheckSubmitting, setIsCheckSubmitting] = useState(false)

  const { openModal, closeModal } = useModals()
  const { errorToast, infoToast } = useToast()
  const history = useHistory()
  const { t } = useTranslation()

  /**
   * RTK query hooks
   */
  const { data: slots = [] } = useFetchTimeslotsQuery()
  const { data: reservation, isLoading } = useFetchDeskReservationQuery(
    reservationId ?? skipToken,
  )
  const [createDeskReservation] = useCreateDeskReservationMutation()
  const [updateDeskReservation] = useUpdateDeskReservationMutation()
  const [checkoutDeskReservation] = useCheckoutDeskReservationMutation()
  const [deleteDeskReservation] = useDeleteDeskReservationMutation()
  const [fetchDesk, { data: reservationDesk }] = useLazyFetchDeskQuery()
  const checkInDesk = useDeskCheckIn()

  const { entry: currentUser } = useAppSelector(selectUser)

  const { entry: settings } = useAppSelector(selectSettingsEffective)
  const { desk_force_timeslot_use } = settings ?? {}
  const { showWeekends } = useAppSelector(selectApp)

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })

  const admin_desk_reservation_window_length = useCheckReservationWindowLength()
  const isOnSpotCheckIn = useIsOnDeskSpotCheckIn()

  const isAdminManager =
    isOfficeManager(currentUser) || isPortalAdmin(currentUser)

  const ownReservation = reservation
    ? reservation.user.email === currentUser.email
    : true

  const canCreatePermission = useCheckForPermission(
    PERMISSIONS.desks.canAddDeskReservation,
  )
  const canDeletePermission = useCheckForPermission(
    PERMISSIONS.desks.canDeleteDeskReservation,
  )
  const canEditPermission = useCheckForPermission(
    PERMISSIONS.desks.canChangeDesk,
  )

  const reservationUser = reservation ? reservation.user : currentUser
  const checkoutEnabled = ReservationCheckinUtils.isEnabled(
    "checkout",
    settings,
    currentUser,
    reservation,
  )

  const maxDate = dayjs().add(
    (isAdminManager
      ? admin_desk_reservation_window_length
      : desk_reservation_window_length) ?? 0,
    "day",
  )
  const maxAdminDate = dayjs().add(
    admin_desk_reservation_window_length ?? 0,
    "day",
  )
  const canCreate = ownReservation || canCreatePermission
  const canDelete = ownReservation || canDeletePermission
  const canEdit = ownReservation || canEditPermission
  const formFieldEnabled = canEdit && !checkoutEnabled
  const { defaultFrom, defaultTo } = useFromToCalculator(reservation)
  const slotOptions: OptionType[] = slots.map((s) => ({
    value: s.id,
    label: `${formatSlotTime(s.from)} - ${formatSlotTime(s.to)} / ${s.name}`,
  }))

  const showForm = reservationId ? reservation : true

  const isFormDisabled = reservation ? !canEdit : !canCreate

  /**
   * Memo
   */
  const calculateFormValue = useMemo(() => {
    const {
      start,
      end,
      date,
      schedule,
      desk,
      scheduleDate,
      user,
      userFirstName,
      userLastName,
    } = formData || {}
    const defaultTimeslot = slots.find((s) => s.is_default) ?? slots[0]

    return {
      user: user
        ? { email: user, first_name: userFirstName, last_name: userLastName }
        : reservationUser,
      date: reservation
        ? dayjs(reservation.start).toDate()
        : (date?.toDate() ?? dayjs().toDate()),
      timeRange: {
        start: (start || defaultFrom) as InternalTime,
        end: (end || defaultTo) as InternalTime,
      },
      schedule: schedule ?? { value: null, label: t("general.repeat.once") },
      desk: reservationId ? reservation?.desk : desk ? reservationDesk : null,
      scheduleDate: !reservation
        ? (scheduleDate?.toDate() ?? maxDate.toDate())
        : maxDate.toDate(),
      timezone: reservation
        ? { value: reservation.tz, label: reservation.tz }
        : { value: defaultTimezone, label: defaultTimezone },
      timeslot_id: reservation ? reservation.timeslot_id : defaultTimeslot?.id,
    }
  }, [
    formData,
    reservation,
    reservationUser,
    defaultFrom,
    defaultTo,
    t,
    reservationId,
    maxDate,
    slots,
    reservationDesk,
  ])

  const methods = useForm<FormValues>({
    defaultValues: calculateFormValue,
  })

  const {
    setError,
    control,
    formState: { isSubmitting },
    reset,
    watch,
    setValue,
    getValues,
  } = methods

  const department = watch("department")
  const desk = watch("desk")

  const handleOnCloseClick = useCallback(() => {
    onClose ? onClose() : history.goBack()
  }, [history, onClose])

  const onCreateClick = async ({
    user,
    timeRange,
    date,
    schedule,
    desk,
    scheduleDate,
    timezone,
    timeslot_id,
  }: FormValues) => {
    const isRecurring = schedule?.value && scheduleDate
    const timeSlot = timeslot_id
      ? slots.find((s) => s.id === timeslot_id)
      : null
    const tz = timezone?.value ?? defaultTimezone

    const dateStr = dayjs(date ?? "").format("YYYY-MM-DDThh:mm")

    const createPayload: CreateDeskReservation = {
      user_email: user?.email ?? "",
      desk_id: desk?.id,
      tz,
      start: date
        ? setTimeToDayjs(
            dayjs.tz(dateStr ?? "", tz),
            desk_force_timeslot_use && timeSlot
              ? (timeSlot.from as InternalTime)
              : timeRange.start,
          ).format()
        : undefined,
      end: date
        ? setTimeToDayjs(
            dayjs.tz(dateStr ?? "", tz),
            desk_force_timeslot_use && timeSlot
              ? (timeSlot.to as InternalTime)
              : timeRange.end,
          ).format()
        : undefined,
      timeslot_id: desk_force_timeslot_use && timeslot_id ? timeslot_id : "",
      recurring: isRecurring
        ? {
            freq: schedule.value as RecurringType,
            until: dayjs(scheduleDate ?? "")
              .endOf("day")
              .toISOString(),
          }
        : undefined,
    }
    const response = await createDeskReservation(createPayload)
    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        setErrors(
          error.formError,
          setError,
          errorToast,
          getFormMapping(!!desk_force_timeslot_use),
        )
      }
      return
    }

    analyticsEvent(SupportedEvents.RESERVATION_ADD, {
      seat_id: desk?.id,
      type: CHECK_INS_TYPES.DESK,
    })

    // Special error handling for recurring reservation errors
    if (
      isRecurringReservation(response.data) &&
      response.data.failed?.length > 0
    ) {
      openModal(FailedReservationsModal, {
        failedReservations: response.data.failed,
        reservationType: "asset",
        onClose: handleOnCloseClick,
      })
      return
    }
    infoToast(
      isRecurring
        ? t("desktop.manage.desk_booking.form.reservations_created_toast")
        : t("desktop.manage.desk_booking.form.reservation_created_toast"),
    )
    handleOnCloseClick()
  }

  const onUpdateClick = async ({
    user,
    timeRange,
    date,
    desk,
    timezone,
    timeslot_id,
  }: FormValues) => {
    if (reservationId) {
      const timeSlot = timeslot_id
        ? slots.find((s: TimeslotResponse) => s.id === timeslot_id)
        : null
      const tz = timezone?.value ?? defaultTimezone
      const dateStr = dayjs(date ?? "").format("YYYY-MM-DDThh:mm")

      const editPayload: Partial<CreateDeskReservation> = {
        user_email: user?.email ?? "",
        desk_id: desk?.id,
        tz,
        start: date
          ? setTimeToDayjs(
              dayjs.tz(dateStr, tz),
              desk_force_timeslot_use && timeSlot
                ? (timeSlot.from as InternalTime)
                : timeRange.start,
            ).format()
          : undefined,
        end: date
          ? setTimeToDayjs(
              dayjs.tz(dateStr, tz),
              desk_force_timeslot_use && timeSlot
                ? (timeSlot.to as InternalTime)
                : timeRange.end,
            ).format()
          : undefined,
        timeslot_id: desk_force_timeslot_use && timeslot_id ? timeslot_id : "",
      }

      const response = await updateDeskReservation({
        id: reservationId,
        payload: editPayload,
      })
      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(
            error.formError,
            setError,
            errorToast,
            getFormMapping(!!desk_force_timeslot_use),
          )
        }
        return
      }

      analyticsEvent(SupportedEvents.RESERVATION_UPDATE, {
        id: reservationId,
      })

      infoToast(t("desktop.manage.desk_booking.form.reservation_updated_toast"))
      handleOnCloseClick()
    }
  }

  const onDeleteClick = async (e: MouseEvent) => {
    if (reservation?.recurring?.id) {
      openModal(DeleteReservationModal, {
        reservationId: reservation.id,
        reservationType: "desk",
        dataUpdate: handleOnCloseClick,
      })
    } else {
      if (reservationId) {
        const response = await deleteDeskReservation({ id: reservationId })

        if (isRejected(response)) {
          errorToast(response.error.message)
          return
        }

        analyticsEvent(SupportedEvents.RESERVATION_DELETE, {
          id: reservationId,
          type: 0,
        })

        infoToast(
          t("desktop.manage.desk_booking.form.reservation_deleted_toast"),
        )
        handleOnCloseClick()
      }
    }
  }

  const onCheckInClick = useCallback(
    async (e: MouseEvent) => {
      if (reservation && reservationId) {
        checkInDesk({
          reservation,
          onCheckinCallback: () => {
            infoToast(t("desktop.manage.desk_booking.form.checked_in_toast"))
            setIsCheckSubmitting(false)
            handleOnCloseClick()
          },
          onRedirectionCallback: closeModal,
        })
      }
    },
    [
      reservation,
      reservationId,
      checkInDesk,
      closeModal,
      infoToast,
      t,
      handleOnCloseClick,
    ],
  )

  const handleCheckOutConfirmation = async (e: MouseEvent) => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await onCheckOutClick(e)
        closeModal()
      },
    })
  }

  const onCheckOutClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()
      if (reservationId) {
        setIsCheckSubmitting(true)
        const response = await checkoutDeskReservation(reservationId)
        if (isRejected(response)) {
          errorToast(response.error.message)
          return
        }

        if (reservation) {
          analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKOUT, {
            id: reservation.id,
            seat_id: reservation.desk.id,
          })
        }

        infoToast(t("desktop.manage.desk_booking.form.checked_out_toast"))
        setIsCheckSubmitting(false)
        handleOnCloseClick()
      }
    },
    [
      reservationId,
      reservation,
      checkoutDeskReservation,
      infoToast,
      t,
      handleOnCloseClick,
      errorToast,
    ],
  )

  const datePickerOnChange = (
    value: Date | null,
    onChange: (...event: any[]) => void,
  ) => {
    onChange(value)
    if (
      value &&
      getValues().schedule?.value === RecurringType.EVERY_DAY_OF_WEEK
    ) {
      setValue("schedule", {
        label: t("general.repeat.every_day_of_week", {
          day: dayjs(value).format("dddd"),
        }),
        value: RecurringType.EVERY_DAY_OF_WEEK,
      })
    }
  }

  /**
   * Effects
   */

  useEffect(() => {
    reset(calculateFormValue)
  }, [reservation, reservationDesk])

  useEffect(() => {
    if (!desk || (desk.id === reservation?.desk?.id && reservation?.tz)) {
      return
    }

    setValue("timezone", {
      value: desk?.tz ?? "UTC",
      label: timezoneMapper(desk?.tz ?? "UTC"),
    })
  }, [desk, setValue, reservation])

  useEffect(() => {
    if (formData?.desk) {
      fetchDesk(formData?.desk)
    }
  }, [fetchDesk, formData])
  /**
   * Function to group desks by floors used in the desk AsyncSelect
   */
  const createGroupedOptions = (desks: DeskResponse[]) => {
    const o = desks.reduce(
      (obj, d) => {
        obj[d.floor_id]
          ? obj[d.floor_id].options.push(d)
          : (obj[d.floor_id] = {
              label: `${d.building.name} / ${d.floor.name}`,
              options: [d],
            })
        return obj
      },
      {} as Record<string, GroupBase<DeskResponse>>,
    )
    return Object.values(o)
  }

  const getAdditionalButton = () => {
    if (
      ReservationCheckinUtils.isEnabled(
        "checkin",
        settings,
        currentUser,
        reservation,
      ) &&
      !isOnSpotCheckIn
    ) {
      return (
        <Button
          className="checkin"
          onClick={onCheckInClick}
          isDisabled={isLoading || isSubmitting || isCheckSubmitting}
          variant="secondary"
        >
          {t("desktop.manage.desk_booking.form.check_in")}
        </Button>
      )
    }

    if (checkoutEnabled) {
      return (
        <Button
          variant="danger-pop"
          className="checkout"
          noConfirm
          onClick={handleCheckOutConfirmation}
          isLoading={isLoading || isSubmitting || isCheckSubmitting}
        >
          {t("desktop.manage.desk_booking.form.check_out")}
        </Button>
      )
    }
  }

  /**
   * Reservations are loaded but none was found.
   */
  if (!isLoading && !reservation && reservationId) {
    return (
      <div className="BookingForm NotFound">
        {t("general.reservation.not_exist")}
      </div>
    )
  }

  return showForm ? (
    <FormProvider {...methods}>
      <ModalForm
        className="BookingForm"
        updateMode={checkoutEnabled ? false : !!reservation}
        title={
          !reservation
            ? t("desktop.manage.desk_booking.form.new_reservation")
            : canEdit
              ? t("desktop.manage.desk_booking.form.edit_reservation")
              : t("desktop.manage.desk_booking.form.view_reservation")
        }
        onCreate={!checkoutEnabled ? onCreateClick : undefined}
        onUpdate={!checkoutEnabled ? onUpdateClick : undefined}
        onDelete={
          !!reservation && canDelete && formFieldEnabled
            ? onDeleteClick
            : undefined
        }
        hasConfirmationPrompt={!reservation?.recurring?.id}
        onClose={handleOnCloseClick}
        additionalButton={getAdditionalButton()}
        disabled={isFormDisabled}
      >
        <div className="field-width-50">
          <Field
            control={control}
            name="user"
            label={t("desktop.manage.desk_booking.form.person")}
          >
            {(props) => (
              <AsyncSelect
                {...props}
                urlGenerator={(fetchOptions) => {
                  return usersURL(fetchOptions)
                }}
                nothingFoundMessage={"no_users_found"}
                getOptionLabel={(user) => formatUser(user ?? {})}
                getOptionValue={(user) => user?.email ?? ""}
                disabled={
                  isSubmitting ||
                  checkoutEnabled ||
                  !(isOfficeManager(currentUser) || isPortalAdmin(currentUser))
                }
              />
            )}
          </Field>

          <Field
            control={control}
            name="date"
            label={t("desktop.manage.desk_booking.form.date")}
          >
            {({ onChange, ...props }) => (
              <DatePicker
                {...props}
                maxDate={maxAdminDate.toDate()}
                disabled={isSubmitting}
                onChange={(v: Date | null) => datePickerOnChange(v, onChange)}
              />
            )}
          </Field>

          {desk_force_timeslot_use ? (
            <Field
              control={control}
              name="timeslot_id"
              label={t("desktop.manage.desk_booking.form.time")}
            >
              {({ value, onChange }) => (
                <Options
                  onChange={onChange}
                  value={value ?? ""}
                  options={slotOptions}
                />
              )}
            </Field>
          ) : (
            <Field
              control={control}
              name="timeRange"
              label={t("desktop.manage.desk_booking.form.time")}
            >
              {({ onChange, ...props }) => (
                <>
                  <TimeRangePicker
                    {...props}
                    disabled={isSubmitting}
                    onChange={onChange}
                  />

                  <QuickSlots
                    slots={slots}
                    onChange={(from, to) => {
                      onChange({ start: from, end: to })
                    }}
                  />
                </>
              )}
            </Field>
          )}

          <Field
            control={control}
            name="timezone"
            label={t("desktop.manage.desk_booking.form.timezone")}
            subText={t("general.optional")}
          >
            {(props) => (
              <Select
                {...props}
                options={TIMEZONE_OPTIONS}
                disabled={isSubmitting}
                clearable
              />
            )}
          </Field>
        </div>

        <div className="field-width-50">
          <Field
            control={control}
            name="department"
            label={t("desktop.manage.desk_booking.form.department")}
          >
            {(props) => (
              <AsyncSelect
                {...props}
                urlGenerator={(fetchOptions) => {
                  return departmentsURL({
                    ...fetchOptions,
                  })
                }}
                nothingFoundMessage={t(
                  "desktop.manage.desk_booking.form.no_departments_found",
                )}
                getOptionLabel={(desk) => desk.name}
                getOptionValue={(desk) => desk.id}
                disabled={isSubmitting}
                filterResultsFn={(desk) => desk.active !== false}
                clearable
              />
            )}
          </Field>

          <Field
            control={control}
            name="desk"
            label={t("desktop.manage.desk_booking.form.desk")}
          >
            {(props) => (
              <AsyncSelect
                {...props}
                key={department?.id ?? ""} // using key to trigger refetch when department changes
                urlGenerator={({ search, ...fetchOptions }) => {
                  return desksURL({
                    ...fetchOptions,
                    active: true,
                    name: search,
                    ...(department && { department_id: department.id }), // add department id to search only for desks in selected department
                  })
                }}
                createGroupedOptions={createGroupedOptions}
                nothingFoundMessage={t(
                  "desktop.manage.desk_booking.form.no_desks_found",
                )}
                getOptionLabel={(desk) => desk.name}
                getOptionValue={(desk) => desk.id}
                disabled={isSubmitting}
                cacheOptions={false}
                shouldClear={(desks, selectedDesk) =>
                  !desks.find((desk) => selectedDesk?.id === desk.id)
                }
                fetchLimit={10000}
              />
            )}
          </Field>

          {!reservation && (
            <Field
              control={control}
              name="schedule"
              label={t("desktop.manage.desk_booking.form.schedule")}
            >
              {(props) => (
                <Select
                  {...props}
                  options={getScheduleOptions(
                    // @ts-ignore https://github.com/orgs/react-hook-form/discussions/7764
                    dayjs(watch("date") ?? "").format("dddd"),
                    showWeekends,
                  )}
                  disabled={isSubmitting}
                />
              )}
            </Field>
          )}

          {watch("schedule")?.value !== null && (
            <Field
              control={control}
              name="scheduleDate"
              label={t("desktop.manage.desk_booking.form.until_date")}
            >
              {(props) => (
                <DatePicker
                  {...props}
                  maxDate={maxDate.toDate()}
                  value={props.value || maxDate.toDate()}
                  disabled={isSubmitting}
                />
              )}
            </Field>
          )}
        </div>

        <CheckinSummary
          checkin_at={reservation?.checked_in?.check_in_at}
          checkout_at={reservation?.checked_in?.check_out_at}
        />
      </ModalForm>
    </FormProvider>
  ) : (
    <Loader />
  )
}
