export const GRAY_100 = "gray-100"
export const GRAY_80 = "gray-80"
export const GRAY_60 = "gray-60"
export const GRAY_40 = "gray-40"
export const GRAY_20 = "gray-20"
export const GRAY_0 = "gray-0"

export const VERTICAL_ALIGNMENT_TOP = "flex-start"
export const VERTICAL_ALIGNMENT_CENTER = "center"
export const VERTICAL_ALIGNMENT_BOTTOM = "flex-end"

export const HORIZONTAL_ALIGNMENT_LEFT = "left"
export const HORIZONTAL_ALIGNMENT_CENTER = "center"
export const HORIZONTAL_ALIGNMENT_RIGHT = "right"

export const BACKGROUND_THEMES = [
  GRAY_100,
  GRAY_80,
  GRAY_60,
  GRAY_40,
  GRAY_20,
  GRAY_0,
]

export const HEADING_ONE = "heading-one"
export const HEADING_TWO = "heading-two"
export const HEADING_THREE = "heading-tree"
export const PARAGRAPH = "paragraph"

export const TYPOGRAPHY_OPTIONS = [
  { value: HEADING_ONE, label: "Heading 1" },
  { value: HEADING_TWO, label: "Heading 2" },
  { value: HEADING_THREE, label: "Heading 3" },
  { value: PARAGRAPH, label: "Paragraph" },
]
