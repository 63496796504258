import { t } from "i18next"
import { Trans } from "react-i18next"

import { PAYMENT_METHODS } from "../../../../redux/api/billing/constants"

import { Step } from "../../../../components/basic/Stepper"

export const STEPS: Step[] = [
  { id: "details", title: t("desktop.settings.billing.steps.details") },
  { id: "payment", title: t("desktop.settings.billing.steps.payment") },
  { id: "overview", title: t("desktop.settings.billing.steps.overview") },
]

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: "desktop.settings.billing.payment_method.options.credit_card",

    value: PAYMENT_METHODS.CREDIT_CARD,
    name: PAYMENT_METHODS.CREDIT_CARD,
  },
  {
    label: "desktop.settings.billing.payment_method.options.prepaid_code",
    value: PAYMENT_METHODS.PREPAID_CODE,
    name: PAYMENT_METHODS.PREPAID_CODE,
  },
  {
    label: "desktop.settings.billing.payment_method.options.invoice",
    value: PAYMENT_METHODS.INVOICE,
    name: PAYMENT_METHODS.INVOICE,
    disabled: true,
    tooltip: (
      <Trans
        i18nKey={"desktop.settings.billing.payment_method.invoice_tooltip"}
      ></Trans>
    ),
  },
]
