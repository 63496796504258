import { MutableRefObject, useCallback } from "react"

import queryString from "query-string"
import { useHistory } from "react-router-dom"

import { ENTRIES_PER_PAGE } from "../screens/Manage/constants"

const { stringify } = queryString

type Props<
  Filter extends { page?: number },
  ActionProps extends Partial<Filter & { offset: number; limit: number }>,
> = {
  reqParams: MutableRefObject<Filter>
  fetchCall: (props: ActionProps) => unknown
  path: string
  fetchOptions: Pick<
    ActionProps,
    Exclude<keyof ActionProps, keyof Filter | "offset" | "limit">
  >
  offset: number
  limit: number
}

export const useFetchPaginatedData = <
  Filter extends { page?: number },
  ActionProps extends Partial<Filter & { offset: number; limit: number }>,
>({
  reqParams,
  path,
  fetchCall,
  fetchOptions,
  offset,
  limit = ENTRIES_PER_PAGE,
}: Props<Filter, ActionProps>) => {
  const history = useHistory()

  const page = Math.floor(offset / limit) + 1

  const fetchDataPagination = useCallback(() => {
    const { page: newPage, ...params } = reqParams.current
    const offset = limit * ((newPage ?? 1) - 1)

    fetchCall({
      ...params,
      ...fetchOptions,
      offset,
      limit,
    } as ActionProps)
  }, [fetchCall, reqParams, fetchOptions, limit])

  const setPage = useCallback(
    (nextPage: number) => {
      reqParams.current = { ...reqParams.current, page: nextPage }
      history.push(`${path}?${stringify(reqParams.current)}`)
      fetchDataPagination()
    },
    [history, path, reqParams, fetchDataPagination],
  )

  return {
    setPage,
    fetchDataPagination,
    page,
  }
}
