import { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"

import { OptionType } from "../../../types/sharedTypes"
import Button from "../Button"

import "./style.sass"

export type ButtonSelectProps<T> = {
  options: OptionType<T>[]
  value?: T
  onChange: (v: T) => void
  className?: string
  disable?: boolean
} & { ref?: ForwardedRef<HTMLDivElement> }

const ButtonSelect = <T,>(
  {
    options,
    value: currentValue,
    onChange,
    className,
    disable,
  }: ButtonSelectProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div ref={ref} className={classNames("ButtonSelect", className)}>
      {options.map(({ label, value }, i) => (
        <Button
          key={`button-${i}`}
          isDisabled={disable}
          variant={value !== currentValue ? "secondary" : "primary"}
          onClick={() => onChange(value)}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

// type assertion to make sure the component accepts generic props
// and also accepts ref
export default forwardRef(ButtonSelect) as <T>(
  props: ButtonSelectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof ButtonSelect>
