import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useCallback,
} from "react"

import classNames from "classnames"
import Textarea, { TextareaAutosizeProps } from "react-textarea-autosize"

import ErrorSVG from "../../../assets/images/icons/Error.svg"
import LockSVG from "../../../assets/images/icons/Lock.svg"

import "./style.sass"

export type InputType = HTMLInputElement | HTMLTextAreaElement

type CommonProps = {
  multiline?: boolean
  className?: string
  onChange?: (value: string) => void
  hasError?: boolean
  variant?: "desktop" | "mobile"
}

type ConditionalProps = HTMLProps<HTMLInputElement> & TextareaAutosizeProps

export type InputProps = CommonProps & Omit<ConditionalProps, "onChange">

export const Input = forwardRef<InputType, InputProps>(
  (
    {
      className,
      onChange,
      multiline,
      hasError,
      value,
      variant = "desktop",
      ...props
    },
    ref,
  ) => {
    const innerOnChange = useCallback(
      (e: ChangeEvent<InputType>) => {
        onChange && onChange(e.target.value)
      },
      [onChange],
    )

    const sanitizedValue = value ?? ""

    const errorClassName = classNames("error-icon", {
      visible: !!hasError,
    })

    return (
      <div
        className={classNames([
          {
            disabled: !!props.disabled,
            error: hasError,
          },
          "Input",
          variant,
          className,
        ])}
      >
        {multiline ? (
          <Textarea
            {...(props as TextareaAutosizeProps)}
            onChange={innerOnChange}
            value={sanitizedValue}
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
          />
        ) : (
          <input
            {...(props as HTMLProps<HTMLInputElement>)}
            onChange={innerOnChange}
            value={sanitizedValue}
            ref={ref as ForwardedRef<HTMLInputElement>}
          />
        )}
        {props.disabled && <LockSVG className="lock" />}
        <ErrorSVG className={errorClassName} />
      </div>
    )
  },
)
