import { MouseEvent as ReactMouseEvent, useCallback, useRef } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useToast } from "../../hooks/useToast"
import CopyToClipboard from "../advanced/CopyToClipboard"
import { Checkbox } from "../basic/Checkbox"
import Field from "../Field"
import FillSpace from "../FillSpace"
import YouAreHere from "../YouAreHere"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useFetchFloorQuery,
  useLazyFetchFloorQuery,
} from "../../redux/api/floors"
import { fetchFloorsOnly } from "../../redux/floors/floorsSlice"
import { FloorResponse, HereCoords } from "../../redux/floors/types"
import {
  createShareable,
  destroyShareable,
} from "../../redux/shareable/shareableSlice"
import {
  CreateShareableRequest,
  ShareableType,
} from "../../redux/shareable/types"
import { useActions } from "../../redux/utils"

import Map from "../../components/Map"

import InfoSVG from "../../assets/images/icons/Info.svg"

import "./ShareFloorForm.sass"

type Props = {
  floorId: string
  onAccessActivityClick: (shareableId: string) => void
}

type FormValues = {
  here: HereCoords
  dark_mode: boolean
  show_here: string
}

const ShareFloorForm = ({ floorId, onAccessActivityClick }: Props) => {
  const { closeModal } = useModals()
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()

  const [fetchFloor] = useLazyFetchFloorQuery()

  const { data: floor } = useFetchFloorQuery(
    {
      id: floorId,
      stats: true,
      desks: false,
    },
    { skip: !floorId },
  )

  const { value: showHere, onChange: setShowHere } = useLocalStorage(
    "show-you-are-here-marker",
    "true",
  )

  const { shareable, width, height } = floor ?? {}

  const methods = useForm<FormValues>({
    defaultValues: {
      show_here: showHere ?? "true",
      here: {
        coord_x: Math.floor(
          shareable?.payload.here?.coord_x ?? (width ?? 300) / 2,
        ),
        coord_y: Math.floor(
          shareable?.payload.here?.coord_y ?? (height ?? 300) / 2,
        ),
      },
      dark_mode: Boolean(shareable?.payload.dark_mode),
    },
  })
  const { control, watch, setValue } = methods
  const hasURL = Boolean(floor?.shareable?.url)

  const actions = useActions({
    createShareable: (payload: CreateShareableRequest) =>
      createShareable(payload),
    destroyShareable: (id: string) => destroyShareable(id),
    fetchFloorsOnly: () => fetchFloorsOnly(null),
  })

  const imageRef = useRef<HTMLImageElement>(null)

  const handleSave = async ({ show_here, here, dark_mode }: FormValues) => {
    const response = await actions.createShareable({
      type: ShareableType.FLOOR_PLAN,
      floor_id: floorId,
      payload: { show_here, here, dark_mode },
    })
    if (createShareable.rejected.match(response)) {
      errorToast(response.error.message)
    } else {
      setShowHere(show_here)
      infoToast(
        hasURL
          ? t("desktop.settings.floor_plans.share.toast_sharing_updated")
          : t("desktop.settings.floor_plans.share.toast_sharing_started"),
      )
      await fetchFloor({ id: floorId, stats: true, desks: false })
      if (hasURL) {
        closeModal()
      } else {
        actions.fetchFloorsOnly().then((res) => {
          if (fetchFloorsOnly.fulfilled.match(res)) {
            const total = countShareables(res.payload.results)
            analyticsEvent(SupportedEvents.FLOORPLAN_SHARING_ENABLED, {
              total: total,
            })
          }
        })
      }
    }
  }

  const handleStopSharing = async (e: ReactMouseEvent) => {
    e.preventDefault()
    if (floor?.shareable?.token) {
      const response = await actions.destroyShareable(floor.shareable.token)
      if (destroyShareable.rejected.match(response)) {
        errorToast(response.error.message)
      } else {
        infoToast(t("desktop.settings.floor_plans.share.toast_sharing_stopped"))
        await fetchFloor({ id: floorId, stats: true, desks: false })
        actions.fetchFloorsOnly().then((res) => {
          if (fetchFloorsOnly.fulfilled.match(res)) {
            const total = countShareables(res.payload.results)
            analyticsEvent(SupportedEvents.FLOORPLAN_SHARING_DISABLED, {
              total: total,
            })
          }
        })
        closeModal()
      }
    }
  }

  const handleMapClick = useCallback(
    ({ nativeEvent }: ReactMouseEvent, onChange: (v: HereCoords) => void) => {
      if (floor) {
        const { x: imgX, y: imgY } = getMouseXY(
          nativeEvent,
          imageRef.current!,
          floor,
        )
        onChange({
          coord_x: Math.floor(imgX),
          coord_y: Math.floor(imgY),
        })
      }
    },
    [floor],
  )

  return (
    <FormProvider {...methods}>
      <ModalForm
        className="FloorForm ModalForm ShareFloorForm"
        title={t("desktop.settings.floor_plans.share.share_to_tv")}
        updateMode
        onUpdate={handleSave}
        onDelete={hasURL ? handleStopSharing : undefined}
        deleteButtonText={t(
          "desktop.settings.floor_plans.share.button_stop_sharing",
        )}
        hasConfirmationPrompt
        deleteConfirmationPrompt={t(
          "desktop.settings.floor_plans.share.button_stop_sharing_alert_message",
        )}
      >
        <div className="floor-label">
          {t("desktop.settings.floor_plans.share.floor_name_text")}
        </div>
        <span className="floor-pill">{floor?.name}</span>

        {hasURL && (
          <CopyToClipboard
            text={`${floor?.shareable?.url ?? ""}${
              watch("show_here") === "false" ? "?show_location=false" : ""
            }`}
            successText={t(
              "desktop.settings.floor_plans.share.button_copy_link_success",
            )}
          />
        )}

        {floor && (
          <>
            <Checkbox
              className="show-here"
              label={t(
                "desktop.settings.floor_plans.share.show_your_here_text",
              )}
              value={watch("show_here") === "true"}
              onChange={(value) => {
                setValue("show_here", value ? "true" : "false")
              }}
            />
          </>
        )}

        {watch("show_here") === "true" && (
          <div className="mark-here">
            <Trans>
              {"desktop.settings.floor_plans.share.mark_your_here_text"}
            </Trans>
          </div>
        )}
        {floor && (
          <Field control={control} name="here">
            {({ onChange, value }) => (
              <Map
                // TODO: uncomment when enabling dark mode for the shareable
                // className={watch("dark_mode") ? "inverted" : undefined}
                map={floor}
                onClick={(e) => handleMapClick(e, onChange)}
                ref={imageRef}
              >
                {watch("show_here") === "true" && (
                  <YouAreHere
                    x={value.coord_x}
                    y={value.coord_y}
                    mapWidth={floor.width ?? 0}
                    mapHeight={floor.height ?? 0}
                    isDarkModeEnabled={watch("dark_mode")}
                  />
                )}
              </Map>
            )}
          </Field>
        )}
        {/* <Field control={control} name="dark_mode">
					{({ value, ...props }) => (
						<Checkbox
							{...props}
							value={value}
							label={
								value
									? t("desktop.settings.floor_plans.share.toggle_to_light_mode")
									: t("desktop.settings.floor_plans.share.toggle_to_dark_mode")
							}
						/>
					)}
				</Field> */}
        <div className="activity-wrapper">
          {hasURL && (
            <button
              type="button"
              className="access-activity-button"
              onClick={() =>
                floor?.shareable?.token &&
                onAccessActivityClick(floor.shareable.token)
              }
            >
              {t("desktop.settings.floor_plans.share.access_activity_log")}
            </button>
          )}
          <FillSpace />
          <div className="early-access">
            <div className="early-access-title">
              <InfoSVG />{" "}
              {t("desktop.settings.floor_plans.share.early_access_title")}
            </div>
            <div className="early-access-info">
              {t("desktop.settings.floor_plans.share.early_access_description")}
            </div>
          </div>
        </div>
      </ModalForm>
    </FormProvider>
  )
}

export default ShareFloorForm

const countShareables = (floors?: FloorResponse[]) =>
  floors?.filter((floor) => floor.shareable).length ?? 0

const getMouseXY = (
  event: MouseEvent,
  img: HTMLImageElement,
  map: FloorResponse,
) => {
  const { x: mouseX, y: mouseY } = event
  const { width: sourceWidth, height: sourceHeight } = map

  const {
    x: clientX,
    y: clientY,
    width: clientWidth,
    height: clientHeight,
  } = img.getBoundingClientRect()

  const localX = mouseX - clientX
  const localY = mouseY - clientY

  const widthRatio = sourceWidth! / clientWidth
  const heightRatio = sourceHeight! / clientHeight

  const x = localX * widthRatio
  const y = localY * heightRatio

  return { x, y }
}
