import { api } from "../.."
import { creditCardURL } from "../../../../api"
import { CreditCardUrl } from "./types"

export const creditCard = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCreditCardUrl: builder.query<CreditCardUrl, void>({
      query: () => creditCardURL(),
      providesTags: ["CreditCard"],
    }),
  }),
})

export const { useFetchCreditCardUrlQuery } = creditCard
