import React, { useMemo } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { FETCH_FOR_COUNT } from "../../../constants"
import { INTEGRATIONS_PATHS } from "../Integrations/constants"
import { ROOMS_PATHS } from "../Rooms/constants"
import { SETTINGS_GROUPS } from "../Rooms/DeviceSettings/constants"
import OverviewStep from "./OverviewStep"
import SolutionOverviewCard from "./SolutionOverviewCard"

import { useFetchCalendarsQuery } from "../../../redux/api/calendars"
import { useFetchCompanyQuery } from "../../../redux/api/company"
import { useFetchRoomsDevicesQuery } from "../../../redux/api/devices"
import { useFetchRoomsQuery } from "../../../redux/api/rooms"
import { useFetchSolutionsQuery } from "../../../redux/api/solutions"

import RoomsForm from "../../../components/Form/RoomsForm"

import RoomSVG from "../../../assets/images/icons/Room.svg"

import "./RoomsOverview.sass"

type RoomsOverviewProps = {
  isDefaultExtended?: boolean
}

const RoomsOverview = ({ isDefaultExtended = false }: RoomsOverviewProps) => {
  const { t } = useTranslation()

  const {
    data: { count: calendarsCount = 0 } = {},
    isFetching: areCalendarsLoading,
  } = useFetchCalendarsQuery(FETCH_FOR_COUNT)
  const { data: { count: roomsCount = 0 } = {}, isFetching: areRoomsLoading } =
    useFetchRoomsQuery(FETCH_FOR_COUNT)
  const {
    data: { count: devicesCount = 0 } = {},
    isFetching: areDevicesLoading,
  } = useFetchRoomsDevicesQuery(FETCH_FOR_COUNT)
  const { data: company, isFetching: isFetchingCompany } =
    useFetchCompanyQuery()

  const hasCalendar = calendarsCount > 0
  const hasRooms = roomsCount > 0
  const hasPairedDevices = devicesCount > 0
  const hasOfficeHours = !!company?.settings?.office_hours

  const { data: solutions } = useFetchSolutionsQuery()
  const isRoomSolutionEnabled = !!solutions?.room?.active

  const steps = useMemo(
    () => [
      {
        title: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.connect_calendar.title",
        ),
        description: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.connect_calendar.description",
        ),
        path: INTEGRATIONS_PATHS.calendars,
        completed: hasCalendar,
        isLoading: areCalendarsLoading,
      },
      {
        title: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.add_rooms.title",
        ),
        description: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.add_rooms.description",
        ),
        path: ROOMS_PATHS.addRoom,
        completed: hasRooms,
        isLoading: areRoomsLoading,
      },
      {
        title: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.pair_devices.title",
        ),
        description: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.pair_devices.description",
        ),
        path: ROOMS_PATHS.pair,
        completed: hasPairedDevices,
        isLoading: areDevicesLoading,
      },
      {
        title: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.office_hours.title",
        ),
        description: t(
          "desktop.settings.rooms.overview.onboarding_details.steps.office_hours.description",
        ),
        path: `${ROOMS_PATHS.deviceSettings}#${SETTINGS_GROUPS.OFFICE_HOURS_GROUP}`,
        completed: hasOfficeHours,
        isLoading: isFetchingCompany,
      },
    ],
    [
      t,
      areCalendarsLoading,
      areDevicesLoading,
      areRoomsLoading,
      hasCalendar,
      hasPairedDevices,
      hasRooms,
      hasOfficeHours,
      isFetchingCompany,
    ],
  )

  const cn = classNames("RoomsOnboardingOverview", {
    "RoomsOnboardingOverview--enabled": isRoomSolutionEnabled,
  })

  return (
    <SolutionOverviewCard
      solution="room"
      className={cn}
      description={t("desktop.settings.rooms.overview.onboarding_details.info")}
      icon={<RoomSVG />}
      isDefaultExtended={isDefaultExtended}
    >
      {steps.map((step) => (
        <OverviewStep
          key={step.title}
          title={step.title}
          description={step.description}
          path={step.path}
          completed={step.completed}
          isLoading={step.isLoading}
        />
      ))}
      {isRoomSolutionEnabled && (
        <>
          <hr />
          <RoomsForm />
        </>
      )}
    </SolutionOverviewCard>
  )
}

export default RoomsOverview
