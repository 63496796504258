import { useTranslation } from "react-i18next"

import { getLabel } from "../../utils"

import Button from "../../components/advanced/Button"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

type ErrorProps = {
  error: string
}

export const Error = ({ error }: ErrorProps) => {
  const { t } = useTranslation()

  const reloadPage = () => window.location.reload()

  return (
    <VisitorView>
      <VisitorHeader />
      <VisitorContent>
        <div className="title">{t("tablet.visitors.screens.error.title")}</div>
        <div className="info">{error}</div>
      </VisitorContent>
      <VisitorFooter>
        <div>
          <Button onClick={reloadPage}>
            {t("tablet.visitors.screens.error.retry")}
          </Button>
        </div>
        <div>
          <Button variant="link" href={getLabel("links.knowledgeURL")}>
            {t("tablet.visitors.screens.error.contact_support")}
          </Button>
        </div>
      </VisitorFooter>
    </VisitorView>
  )
}
