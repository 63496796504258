import { DepartmentResponse } from "../api/departments/types"
import { TimeslotResponse } from "../timeslots/types"

export enum DeleteType {
  CURRENT = "0",
  CURRENT_FUTURE = "1",
}

export type UserReservation = {
  id: string
  email: string
  first_name?: string
  last_name?: string
  infix?: string
  checkedIn: boolean
  checkin_at?: string
  checkout_at?: string
}

export type LocationReservation = {
  id: string
  name: string
  visible: boolean
}

export type FloorReservation = {
  id: string
  floor_id: string
  name: string
  visible: boolean
  location: LocationReservation
}

export type SeatReservation = {
  id: string
  name: string
  type: string
  map?: FloorReservation
  departments?: DepartmentResponse[]
}

export type ReservationResponse = {
  id: string
  date: string
  from: string
  to: string
  include_parking?: boolean
  type?: string
  timeslot?: TimeslotResponse
  recurring_rule?: string
  user: UserReservation
  seat: SeatReservation
  visit_id: string | null
}

export type ReservationRequest = {
  id: string
  date: string
  from: string
  to: string
  desk_id: string
  timezone: string
  timeslot_id?: string
}

export type ReservationConfigAction = {
  type: string
  payload: Array<ReservationResponse> | ReservationResponse | string
}

export type FailedRecurringReservation = {
  code: number
  date: string
  reservation_date: string
  message: string
}

export type CreatedRecurringReservation = {
  id: string
  date: string
  reservation_date: string
}

export type RecurringReservationResponse = {
  created: CreatedRecurringReservation[]
  failed: FailedRecurringReservation[]
}

export type ReservationType = "desk" | "asset"
