import { Settings } from "../settings/types"
import { PaginatedResponse } from "../types"

export enum BuildingIds {
  UNKNOWN = "Unknown",
}

export type BuildingResponse = {
  id: string
  name: string
  address?: string
  latitude?: number
  longitude?: number
  parking_slots?: number
  active?: boolean
  desks_count?: number
  floors_count?: number
  departments_count?: number
  employees_count?: number
  rooms_count?: number
  settings?: Settings
  capacity_limit?: number
  questionnaire_enabled?: boolean
}

export type BuildingRequest = {
  name: string
  address?: string
  latitude?: number
  longitude?: number
  parking_slots?: number
  active?: boolean
  settings?: Partial<Settings>
}

export type BuildingError = {
  detail: string
}

export type BuildingsResponse = PaginatedResponse<BuildingResponse>

export type BuildingConfigAction = {
  type: string
  payload: Array<BuildingResponse> | BuildingResponse | BuildingError | string
}

export type FetchOptions = {
  stats?: boolean
  search?: string
}
