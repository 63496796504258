import { api } from ".."
import { apiKeysURL, apiKeyURL } from "../../../api/urls"
import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import { PaginatedOptions } from "../../types"
import {
  ApiKeyResponse,
  ApiKeysResponse,
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  DeleteApiKeyRequest,
} from "./types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
}

export const apiKeys = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchApiKeys: builder.query<
      ApiKeysResponse & { offset: number },
      PaginatedOptions | void
    >({
      query: (options) => ({
        url: apiKeysURL({ ...FETCH_DEFAULTS, ...options }),
      }),
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.results.map(({ client_id }) => ({
                type: "ApiKeys" as const,
                id: client_id,
              })),
              { type: "ApiKeys", id: "LIST" },
            ]
          : [{ type: "ApiKeys", id: "LIST" }],
      transformResponse: (response: ApiKeysResponse, _meta, arg) => {
        return { ...response, offset: arg?.offset ?? 0 }
      },
    }),
    fetchApiKey: builder.query<ApiKeyResponse, string>({
      query: (client_id) => ({
        url: apiKeyURL(client_id),
      }),
      providesTags: (_result, _error, client_id) => [
        { type: "ApiKeys", id: client_id },
      ],
    }),

    createApiKey: builder.mutation<CreateApiKeyResponse, CreateApiKeyRequest>({
      query: (body) => ({
        url: apiKeysURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "ApiKeys", id: "LIST" }],
    }),

    deleteApiKey: builder.mutation<void, DeleteApiKeyRequest>({
      query: (body) => ({
        url: apiKeyURL(body.client_id),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { client_id }) => [
        { type: "ApiKeys", id: "LIST" },
        { type: "ApiKeys", id: client_id },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchApiKeysQuery,
  useFetchApiKeyQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
} = apiKeys
