import React from "react"

import { Trans, useTranslation } from "react-i18next"

import { toFriendlyName } from "../../utils"
import Price from "./Price"

import { PAYMENT_METHODS } from "../../../../../redux/api/billing/constants"
import { Estimate } from "../../../../../redux/api/billing/estimates/types"
import { useFetchPaymentsQuery } from "../../../../../redux/api/billing/payments"
import { PlanType } from "../../../../../redux/api/billing/types"

import WarningCard from "../../../../../components/basic/WarningCard"

export const determinePlanChange = (
  planType: PlanType,
  currentPlanType: PlanType,
): number => planTypeValuesMap[planType] - planTypeValuesMap[currentPlanType]

const planTypeValuesMap = {
  desk_and_asset_essentials: 1,
  desk_essentials: 1,
  jod: 1,
  jot: 1,
  room_essentials: 1,
  room_professional: 2,
  room_enterprise: 3,
  sign_essentials: 1,
  sign_professional: 2,
  visitor_professional_eap: 1,
  visitor_professional: 1,
}

type SubscriptionChangeWarningsProps = {
  estimate: Estimate
  isFetching: boolean
  usedQuantity: number
  nextBillingDate: string
  currentPrice: number
  currentQuantity: number
  currentPlanType?: PlanType
  planType: PlanType
  newPlanType?: PlanType
}

const SubscriptionChangeWarnings = ({
  estimate: { plan_variation, amount_due, unit },
  usedQuantity,
  nextBillingDate,
  currentQuantity,
  currentPlanType,
  planType,
}: SubscriptionChangeWarningsProps) => {
  const { t } = useTranslation()
  const { data: payments } = useFetchPaymentsQuery()
  const isPlanTypeChanged = currentPlanType !== planType

  const getWarnings = () => {
    const warnings = []
    if (isPlanTypeChanged && currentPlanType) {
      if (determinePlanChange(planType, currentPlanType) > 0) {
        warnings.push(
          t(
            "desktop.settings.billing.subscription_quantity.warnings.upgrade_plan_immediately",
            { planName: toFriendlyName(planType) },
          ),
        )
      } else {
        warnings.push(
          <Trans i18nKey="desktop.settings.billing.subscription_quantity.warnings.downgrade_plan" />,
        )
      }
    }

    if (amount_due > 0) {
      if (payments?.type === PAYMENT_METHODS.CREDIT_CARD) {
        warnings.push(
          <Trans
            i18nKey="desktop.settings.billing.subscription_quantity.warnings.credit_card_charged"
            values={{
              brand: payments.credit_card?.card_brand.toUpperCase(),
              maskedNumber: payments.credit_card?.card_masked_number.slice(-4),
              amount: amount_due,
            }}
          >
            <Price currency={plan_variation.currency} price={amount_due} />
          </Trans>,
        )
      }
      if (
        payments?.type === PAYMENT_METHODS.INVOICE &&
        payments?.billing_details?.email
      ) {
        warnings.push(
          <Trans
            i18nKey="desktop.settings.billing.subscription_quantity.warnings.receive_invoice"
            values={{
              amount: amount_due,
              billingEmail: payments.billing_details.email,
            }}
          >
            <Price currency={plan_variation.currency} price={amount_due} />
            <a href={`mailto:${payments.billing_details.email}`}>
              {payments.billing_details.email}.
            </a>
          </Trans>,
        )
      }
    }

    return warnings
  }

  const warnings = getWarnings()

  return (
    <>
      {warnings.length > 0 && (
        <WarningCard className="SubscriptionChangeWarnings">
          {warnings.map((warning, i) => (
            <div key={i}>{warning}</div>
          ))}
        </WarningCard>
      )}
    </>
  )
}

export default SubscriptionChangeWarnings
