import React from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { ReservationCheckinUtils } from "../../checkin_utils"
import { timeZone as defaultTimezone } from "../../dayjs"
import { useDeskCheckIn } from "../../hooks/useDeskCheckIn"
import { useToast } from "../../hooks/useToast"
import { shortUserTimeFormat } from "../../utils"
import Button from "../advanced/Button"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import { IconMapper } from "../CorrectIconMapper"
import { useModals } from "@mattjennings/react-modal-stack"

import { useCheckoutDeskReservationMutation } from "../../redux/api/deskReservations"
import { DeskReservation } from "../../redux/api/deskReservations/types"
import { isRejected } from "../../redux/api/types"
import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"

import ClockSVG from "../../assets/images/icons/Clock.svg"
import PinOutlineSVG from "../../assets/images/icons/PinOutline.svg"

import "./ReservationRow.sass"

type ReservationRowProps = {
  reservation: DeskReservation
}

export const ReservationRow: React.FC<ReservationRowProps> = ({
  reservation,
}) => {
  const { errorToast } = useToast()
  const { openModal, closeModal } = useModals()
  const { t } = useTranslation()
  const history = useHistory()
  const [checkoutDeskReservation] = useCheckoutDeskReservationMutation()
  const handleCheckIn = useDeskCheckIn()

  const { entry: settings } = useAppSelector(selectSettingsEffective)
  const { desk_check_in_enabled } = settings ?? {}

  const canCheckIn = ReservationCheckinUtils.isEnabled(
    "checkin",
    settings,
    undefined,
    reservation,
  )
  const canCheckOut = ReservationCheckinUtils.isEnabled(
    "checkout",
    settings,
    undefined,
    reservation,
  )

  const reservationRowClassName = classNames({
    canBeActive: canCheckIn,
    ReservationRow: true,
    isActive: canCheckOut,
  })

  const building = reservation.building
  const floor = reservation.floor.name

  const actionClicked = (type: "checkin" | "checkout") => {
    const title =
      type === "checkout"
        ? t("mobile.home.confirm_checkout")
        : t("mobile.home.confirm_checkin")
    const action =
      type === "checkout"
        ? handleCheckOut
        : () => handleCheckIn({ reservation })

    openModal(ConfirmationModal, {
      title,
      onConfirm: async () => {
        await action()
        closeModal()
      },
    })
  }

  const handleCheckOut = async () => {
    const response = await checkoutDeskReservation(reservation.id)

    if (isRejected(response)) {
      errorToast(response.error.message)
    }

    analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKOUT, {
      id: reservation.id,
      seat_id: reservation.desk.id,
    })
  }

  const timezoneDiffers = reservation.tz !== defaultTimezone

  return (
    <div
      className={reservationRowClassName}
      onClick={() => {
        history.push("/home/reservation/" + reservation.id)
      }}
    >
      <div className="resource-info">
        <div className="desk-name">{reservation.desk.name}</div>
        <div className="aux-info">
          <div className="resource-time">
            <ClockSVG className="resource-time-icon" />
            <span className="from">
              {dayjs(reservation.start).format(shortUserTimeFormat())}
            </span>
            <span className="dash">{"–"}</span>
            <span className="to">
              {dayjs(reservation.end).format(shortUserTimeFormat())}
            </span>
            {timezoneDiffers && (
              <div className="diff-tz-container">
                <span> &middot; </span>
                <span className="diff-tz">{reservation.tz}</span>
              </div>
            )}
          </div>
        </div>
        <span className="building-floor">
          <PinOutlineSVG className="building-floor-icon" />
          {building ? `${building.name}, ${floor}` : floor}
        </span>
        {desk_check_in_enabled && (
          <>
            {reservation.checked_in?.check_out_at && (
              <div className="checkout-info">
                {t("mobile.general.checked_out")}
              </div>
            )}
            {canCheckIn && (
              <div className="actions-row">
                <Button
                  variant="link"
                  className="action"
                  onClick={() => actionClicked("checkin")}
                >
                  {t("mobile.general.check_in")}
                </Button>
              </div>
            )}
            {canCheckOut && (
              <div className="actions-row">
                <Button
                  variant="link"
                  className="action"
                  onClick={() => actionClicked("checkout")}
                >
                  {t("mobile.general.check_out")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <IconMapper
        color={canCheckOut ? "white" : "default"}
        iconType="desk"
        parentClassName="icon"
      />
    </div>
  )
}
