import React, { FC, useCallback, useMemo } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import PickerItem from "./PickerItem"

import { useFetchAssetsQuery } from "../../../../redux/api/assets"
import { useFetchAssetTypesQuery } from "../../../../redux/api/assetTypes"
import { getAssetScheduleSelector } from "../../../../redux/asset_schedule/selectors"
import { AssetType } from "../../../../redux/asset_types/types"
import { Asset } from "../../../../redux/assets/types"
import { selectBookAsset } from "../../../../redux/book_asset/selectors"
import { useAppSelector } from "../../../../redux/reducers"

import Button from "../../../../components/advanced/Button"
import Loader from "../../../../components/basic/Loader"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./AssetTypePicker.sass"

type AssetTypePickerProps = {
  onAssetTypeSelect: (id: string) => void
  onCancelClick: () => void
}
type AssetTypeWithDisabled = AssetType & {
  disabled: boolean
}

const AssetTypePicker: FC<AssetTypePickerProps> = ({
  onAssetTypeSelect,
  onCancelClick,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const {
    data: { results: assetTypes = [] } = {},
    isSuccess: areAssetTypesLoaded,
  } = useFetchAssetTypesQuery()

  const { data: { results: assets = [] } = {}, isSuccess: areAssetsLoaded } =
    useFetchAssetsQuery({ active: true })

  const { bookAsset } = useAppSelector(selectBookAsset)
  const { schedule } = useAppSelector(getAssetScheduleSelector)

  const getUsedAssetTypes = useCallback(
    () =>
      assets
        .filter((a) => a.building?.id === bookAsset.building?.id)
        .reduce((acc: AssetType[], a: Asset) => {
          if (acc.find((at) => at?.id === a?.asset_type?.id)) {
            return acc
          }
          const currentAssetType = assetTypes.find(
            (at: AssetType) => at.id === a?.asset_type.id,
          )
          if (currentAssetType) {
            return [...acc, currentAssetType]
          }
          return acc
        }, []),
    [assetTypes, assets, bookAsset.building?.id],
  )

  const assetTypesWithDisabled = useMemo((): AssetTypeWithDisabled[] => {
    const filteredAssets = schedule
      ? assets.filter((a) => !schedule.find((s) => s.id === a.id))
      : assets

    return getUsedAssetTypes()
      .map((at) => ({
        ...at,
        disabled: !filteredAssets.find((a) => a?.asset_type?.id === at?.id),
      }))
      .sort(sortDisabledLastByName)
  }, [assets, getUsedAssetTypes, schedule])

  const onPick = (id: string) => {
    if (id) {
      onAssetTypeSelect(id)
    }
  }

  const isLoaded = areAssetsLoaded && areAssetTypesLoaded

  return (
    <SafeViewArea className="asset-type-picker">
      <TopNav backArrow onClose={onCancelClick} />
      <h2>{t("mobile.general.choose_asset_type")}</h2>
      <div className="picker-content">
        <div className="list">
          {!isLoaded ? (
            <Loader />
          ) : (
            <div>
              {assetTypesWithDisabled.map((assetType) => (
                <PickerItem
                  key={assetType.id}
                  name={assetType.name}
                  description={assetType.description}
                  disabled={assetType.disabled}
                  iconName={assetType.icon}
                  onClick={() => {
                    onPick(assetType.id)
                  }}
                />
              ))}
              {getUsedAssetTypes().length === 0 && (
                <div>{t("mobile.general.no_asset_types")}</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="choose-another-building">
        <p className="question">{t("mobile.book.not_okay")}</p>
        <div className="next-button">
          <Button
            variant="mobile-action"
            onClick={() => history.push("/book/asset/building")}
          >
            {t("mobile.book.pick_another_building")}
          </Button>
        </div>
      </div>
    </SafeViewArea>
  )
}

export default AssetTypePicker

const sortDisabledLastByName = (
  a: AssetTypeWithDisabled,
  b: AssetTypeWithDisabled,
): number =>
  Number(a.disabled) - Number(b.disabled) === 0
    ? a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
    : Number(a.disabled) - Number(b.disabled)
