import React, { PropsWithChildren } from "react"

import classNames from "classnames"

type Props = {
  className?: any
}

const View = ({ className, children }: PropsWithChildren<Props>) => {
  const viewClassName = classNames({
    View: true,
    [className]: !!className,
  })
  return <div className={viewClassName}>{children}</div>
}

export default View
