import { ParseKeys } from "i18next"
import { Trans } from "react-i18next"

import { Children } from "../../../../types/sharedTypes"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import Card from "../../../../components/basic/Card"

import "./CalendarProviderCard.sass"

type CalendarProviderCardProps = {
  title: string
  description: ParseKeys
  icon: Children
  onClick: () => void
  kbKey: string
}

const CalendarProviderCard = ({
  title,
  icon,
  onClick,
  kbKey,
}: CalendarProviderCardProps) => {
  const handleOnClick = () => onClick()

  return (
    <div className="CalendarProviderCard" onClick={handleOnClick}>
      <Card className="CalendarProviderCard__card">
        <h3>{title}</h3>
        <div>{icon}</div>
        <div className="description">
          <div>
            <Trans i18nKey="desktop.settings.integrations.calendars.providers.description">
              <RedirectLink to={kbKey}></RedirectLink>
            </Trans>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default CalendarProviderCard
