import {
  IdAndName,
  ISODate,
  RecurringType,
  ScheduleReservation,
} from "../../../types/sharedTypes"
import { PaginatedOptions } from "../../types"
import { Recurring } from "../../types"
import { UserProfile } from "../users/types"

export type DeskReservation = ScheduleReservation & {
  desk: IdAndName
  recurring?: Recurring | null
  timeslot_id: string
  tz: string
  building: IdAndName
  floor: IdAndName
  profile?: UserProfile
}

export type CreateDeskReservation = {
  tz: string
  desk_id?: string
  user_email: string
  department_id?: string
  timeslot_id: string
  recurring?: {
    freq: RecurringType
    until: string
  }
} & Pick<ScheduleReservation, "start" | "end">

export type FailedRecurringReservation = {
  date: string
  _error: string[]
}

export type CreatedRecurringReservation = {
  id: string
  date: string
}

export type RecurringReservationResponse = {
  created: CreatedRecurringReservation[]
  failed: FailedRecurringReservation[]
}

export const isRecurringReservation = (
  response: RecurringReservationResponse | DeskReservation,
): response is RecurringReservationResponse => "created" in response

export type FetchDeskReservationsProps = {
  start?: ISODate
  end?: ISODate
  building_id?: string
  department_id?: string
  floor_id?: string
  user_department_id?: string
  show?: "my"
} & PaginatedOptions
