import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./Intro.sass"

type Props = {
  isConstrained?: boolean
  isCentered?: boolean
  isSubscriptionIntro?: boolean
}

const Intro = ({
  children,
  isConstrained,
  isCentered,
  isSubscriptionIntro,
}: PropsWithChildren<Props>) => {
  const introClassName = classNames({
    Intro: true,
    isConstrained,
    isCentered,
    isSubscriptionIntro: !!isSubscriptionIntro,
  })

  return <div className={introClassName}>{children}</div>
}

export default Intro
