import { Redirect } from "react-router-dom"

import { useIsScheduleAvailable } from "../hooks/useIsScheduleAvailable"
import { INITIAL_REROUTE_PATHS } from "../screens/Manage/constants"
import { Children } from "../types/sharedTypes"

import { useAppSelector } from "../redux/reducers"
import { selectUser } from "../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../redux/user/utils"

/**
 *  Redirects from Schedule to the appropriate page in Settings based on the user's role if they only have Room service available.
 * */
const SolutionsGuard = ({ children }: { children: Children }) => {
  const showSchedule = useIsScheduleAvailable()

  const { entry: user } = useAppSelector(selectUser)

  const isAdmin = isPortalAdmin(user)
  const isManager = !isAdmin && isOfficeManager(user)

  const role = isAdmin ? "ADMIN" : isManager ? "OFFICE_MANAGER" : "USER"

  if (!showSchedule) {
    return <Redirect to={INITIAL_REROUTE_PATHS["ROOM"][role]} />
  }

  return children
}

export default SolutionsGuard
