import React from "react"

import {
  Redirect,
  Switch as RouteSwitch,
  useRouteMatch,
} from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import CompanyDetailsGuard from "../../../guards/CompanyDetailsGuard"
import PaymentTypeGuard from "../../../guards/PaymentTypeGuard"
import { BILLING_PATHS } from "./constants"
import Invoices from "./Invoices"
import Overview from "./Overview"
import PaymentMethod from "./PaymentMethod"
import CompanyDetailsFormPage from "./Payments/CompanyDetailsFormPage"
import PlanSelection from "./SubscriptionFlow/PlanSelection"
import SubscriptionQuantity from "./SubscriptionFlow/SubscriptionQuantity"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Billing = () => {
  let { path } = useRouteMatch()

  const accessPermission = PERMISSIONS.subscriptions.canChangeSubscriptions

  return (
    <RouteSwitch>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.overview.root}
        // accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <Overview />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.overview.companyDetails}
        accessPermission={accessPermission}
        component={CompanyDetailsFormPage}
      />
      <ProtectedRoute
        exact
        path={BILLING_PATHS.overview.paymentMethod}
        accessPermission={accessPermission}
        component={PaymentMethod}
      />
      <ProtectedRoute
        exact
        path={BILLING_PATHS.invoices}
        accessPermission={accessPermission}
        component={Invoices}
      />
      <ProtectedRoute
        exact
        path={BILLING_PATHS.room.root}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <SubscriptionQuantity />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.desk.root}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <SubscriptionQuantity />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.visitor.root}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <SubscriptionQuantity />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.sign.root}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <SubscriptionQuantity />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.room.planSelection}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <PlanSelection />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.desk.planSelection}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <PlanSelection />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.visitor.planSelection}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <PlanSelection />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={BILLING_PATHS.sign.planSelection}
        accessPermission={accessPermission}
      >
        <CompanyDetailsGuard>
          <PaymentTypeGuard>
            <PlanSelection />
          </PaymentTypeGuard>
        </CompanyDetailsGuard>
      </ProtectedRoute>
      <Redirect to={`${path}/overview`} />
    </RouteSwitch>
  )
}

export default Billing
