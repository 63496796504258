import React from "react"

import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type Props = {
  value: string
  onChange: (v: string) => void
}

export default function RoleFilter({ value, onChange }: Props) {
  const { t } = useTranslation()

  const roleOptions = [
    { label: t("mobile.general.all_roles"), value: FilterSpecialValues.ALL },
    { label: t("general.users"), value: "portal_user" },
    { label: t("general.office_managers"), value: "portal_officer_manager" },
    { label: t("general.admins"), value: "portal_admin" },
  ]

  return (
    <Dropdown
      className="role-filter"
      options={roleOptions}
      value={value}
      onChange={onChange}
    />
  )
}
