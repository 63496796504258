import React from "react"

import classNames from "classnames"

import { getAppParams } from "../redux/appParams/selectors"
import { useAppSelector } from "../redux/reducers"

import "./LogoVariant.sass"

type Props = {
  variant: "icon" | "portrait" | "landscape" | "mobile"
}

const LogoVariant = ({ variant }: Props) => {
  const { isAppWhitelabel, appLogo } = useAppSelector(getAppParams)

  const logoClassNames = classNames({
    logo: true,
    "logo-whitelabel": isAppWhitelabel,
  })

  return (
    <div className={logoClassNames}>
      <img src={appLogo[variant]} alt="logo" />
    </div>
  )
}

export default LogoVariant
