import ReactModal from "react-modal"
import { useHistory } from "react-router-dom"

import { contentStyle, overlayStyle } from "./modalStyles"

import { DeskBookingForm, FormType } from "../components/Form/DeskBookingForm"

type Props = {
  open: boolean
  reservationId?: string
  formData?: FormType
  /**
   * A callback that is called every time the modal does some
   * manipulation about data
   */
  onClose?: () => void
}

const DeskBookingModal = ({
  open,
  reservationId,
  formData,
  onClose,
}: Props) => {
  const history = useHistory()

  const handleClose = () => {
    onClose ? onClose() : history.goBack()
  }

  return (
    <ReactModal
      isOpen={open}
      style={{
        content: {
          ...contentStyle,
          width: "100%",
        },
        overlay: overlayStyle,
      }}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <DeskBookingForm
        reservationId={reservationId}
        formData={formData}
        onClose={onClose}
      />
    </ReactModal>
  )
}

export default DeskBookingModal
