import React, { useCallback, useEffect, useState } from "react"

import dayjs from "dayjs"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  DAYS_REG_EXP,
  getDaysOfWeekOptions,
  getScheduleOptions,
  getScheduleRepeatsOptions,
  REPEATS_WEEKLY_SUFFIX,
  SCHEDULE_REPEATS_TYPES,
  SCHEDULE_TYPES,
} from "../constants"
import { useCustomContentContext } from "../CustomContentContext"
import { parseDaysOfWeek } from "../utils"
import SettingsSection from "./SettingsSection"

import { DatePicker } from "../../../../../components/advanced/DatePicker"
import Checkbox from "../../../../../components/basic/Checkbox"
import { RadioGroup } from "../../../../../components/basic/Radio"
import { Select } from "../../../../../components/basic/Select"
import Field from "../../../../../components/Field"

const { REPEATS_WEEKLY } = SCHEDULE_REPEATS_TYPES

const ScheduleSection = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const { schedule, onChangeSchedule } = useCustomContentContext()

  const [scheduleType, setScheduleType] = useState(SCHEDULE_TYPES.ALWAYS_SHOW)

  const scheduleOptions = getScheduleOptions()
  const scheduleRepeatsOptions = getScheduleRepeatsOptions()
  const daysOfWeek = getDaysOfWeekOptions()

  const handleChangeType = useCallback(
    (type: string) => {
      setScheduleType(type)

      if (type === SCHEDULE_TYPES.ALWAYS_SHOW) {
        onChangeSchedule(null)
      }

      if (!schedule && type === SCHEDULE_TYPES.SHOW_AT_SPECIFIC_TIME) {
        const startDateTime = dayjs().startOf("day").toISOString()
        const endDateTime = dayjs().add(1, "day").startOf("day").toISOString()

        onChangeSchedule({
          start: startDateTime,
          end: endDateTime,
          repeat: scheduleRepeatsOptions[0],
        })
      }
    },
    [onChangeSchedule, schedule],
  )

  const repeatValue = schedule?.repeat?.value

  const [selectedDays, setSelectedDays] = useState<string[]>(
    () => parseDaysOfWeek(repeatValue ?? "", DAYS_REG_EXP) ?? [],
  )

  const handleCheckboxChange = useCallback(
    (day: string) => {
      if (!schedule) return

      setSelectedDays((prev) => {
        const newSelectedDays = prev.includes(day)
          ? prev.filter((d) => d !== day)
          : [...prev, day]

        const repeatString = `${REPEATS_WEEKLY}${newSelectedDays.join(",")}`

        const updatedSchedule = {
          ...schedule,
          repeat: { ...schedule.repeat, value: repeatString },
          start: schedule.start || "",
          end: schedule.end || "",
        }

        onChangeSchedule(updatedSchedule)

        return newSelectedDays
      })
    },
    [onChangeSchedule, schedule],
  )

  useEffect(() => {
    if (schedule) {
      setScheduleType(SCHEDULE_TYPES.SHOW_AT_SPECIFIC_TIME)
    }

    if (
      schedule &&
      repeatValue &&
      repeatValue.includes(REPEATS_WEEKLY_SUFFIX)
    ) {
      setSelectedDays(parseDaysOfWeek(repeatValue ?? "", DAYS_REG_EXP))
    }
  }, [repeatValue, schedule])

  return (
    <SettingsSection
      title={t(
        "desktop.settings.rooms.custom_content.forms.general.schedule_label",
      )}
      className="ScheduleSection"
    >
      <RadioGroup
        display="vertical"
        options={scheduleOptions}
        value={scheduleType}
        onChange={handleChangeType}
      />

      {scheduleType === SCHEDULE_TYPES.SHOW_AT_SPECIFIC_TIME && (
        <>
          <div className="Row">
            <Field
              control={control}
              name="schedule.start"
              label={t(
                "desktop.settings.rooms.custom_content.forms.general.from_label",
              )}
              rules={{
                required: t("desktop.onboarding.organization.form.required"),
              }}
              className="field-width-50"
              required
            >
              {(props) => (
                <DatePicker
                  {...props}
                  showMonthDropdown
                  showYearDropdown
                  withTime
                  useUtc
                />
              )}
            </Field>

            <Field
              control={control}
              name="schedule.end"
              label={t(
                "desktop.settings.rooms.custom_content.forms.general.to_label",
              )}
              rules={{
                required: t("desktop.onboarding.organization.form.required"),
              }}
              className="field-width-50"
              required
            >
              {(props) => (
                <DatePicker
                  {...props}
                  showMonthDropdown
                  showYearDropdown
                  minDate={schedule?.start}
                  withTime
                  useUtc
                />
              )}
            </Field>
          </div>

          <div className="Row">
            <Field
              control={control}
              name="schedule.repeat"
              label={t(
                "desktop.settings.rooms.custom_content.forms.general.repeats_label",
              )}
              rules={{
                required: t("desktop.onboarding.organization.form.required"),
              }}
              className="field-width-50"
              required
            >
              {(props) => (
                <Select {...props} options={scheduleRepeatsOptions} />
              )}
            </Field>
          </div>

          {schedule &&
            repeatValue &&
            repeatValue.includes(REPEATS_WEEKLY_SUFFIX) && (
              <div>
                <div className="Label">
                  {t(
                    "desktop.settings.rooms.custom_content.forms.general.repeats_on_label",
                  )}
                </div>
                <div className="Row">
                  {daysOfWeek.map(({ label, value }) => (
                    <Checkbox
                      key={value}
                      label={label}
                      isSecondary
                      className="horizontal-field"
                      value={selectedDays.includes(value)}
                      onChange={() => handleCheckboxChange(value)}
                    />
                  ))}
                </div>
              </div>
            )}
        </>
      )}
    </SettingsSection>
  )
}

export default ScheduleSection
