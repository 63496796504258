import { api } from ".."
import { scimAccessTokenURL, scimSettingsURL } from "../../../api"
import {
  ScimAccessTokenRequest,
  ScimAccessTokenResponse,
  ScimSettings,
  ScimSettingsResponse,
} from "./types"

export const scim = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchScimSettings: builder.query<ScimSettingsResponse, void>({
      query: () => ({
        url: scimSettingsURL(),
      }),
      providesTags: ["Scim"],
    }),
    updateScimSettings: builder.mutation<ScimSettingsResponse, ScimSettings>({
      query: (body) => ({
        url: scimSettingsURL(),
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Scim"],
    }),
    fetchScimAccessToken: builder.query<ScimAccessTokenResponse, void>({
      query: () => ({
        url: scimAccessTokenURL(),
      }),
      providesTags: ["Scim"],
    }),
    regenerateScimAccessToken: builder.mutation<
      ScimAccessTokenResponse,
      ScimAccessTokenRequest
    >({
      query: () => ({
        url: scimAccessTokenURL(),
        method: "POST",
      }),
      invalidatesTags: ["Scim"],
    }),
  }),
})

export const {
  useFetchScimSettingsQuery,
  useUpdateScimSettingsMutation,
  useFetchScimAccessTokenQuery,
  useRegenerateScimAccessTokenMutation,
} = scim
