import React, { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"

import { OptionType } from "../../types/sharedTypes"
import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

import { useFetchDepartmentsQuery } from "../../redux/api/departments"
import { DepartmentResponse } from "../../redux/api/departments/types"

type Props = {
  value: string
  onChange: (v: string) => void
  showAll?: boolean
  showNotAssigned?: boolean
}

export default function DepartmentFilter({
  value,
  onChange,
  showAll = false,
  showNotAssigned = false,
}: Props) {
  const { t } = useTranslation()

  const {
    data: { results: departments = [] } = {},
    isSuccess: areDepartmentsLoaded,
  } = useFetchDepartmentsQuery()

  const initValueRef = useRef<string>(value)

  useEffect(() => {
    if (!areDepartmentsLoaded) {
      return
    }
    // Handle if department deletes or if not selected
    const initValue = initValueRef.current

    if (
      initValue !== FilterSpecialValues.EMPTY &&
      initValue !== FilterSpecialValues.ALL &&
      initValue !== FilterSpecialValues.NOT_ASSIGNED
    ) {
      const hasDepartment =
        departments.find((d: DepartmentResponse) => d.id === initValue) !==
        undefined

      if (!hasDepartment) {
        if (showAll || departments.length < 1) {
          onChange(FilterSpecialValues.ALL)
          initValueRef.current = FilterSpecialValues.ALL
        } else {
          onChange(departments[0].id)
          initValueRef.current = departments[0].id
        }
      }
    } else {
      if (initValue === FilterSpecialValues.EMPTY && departments.length > 0) {
        onChange(departments[0].id)
        initValueRef.current = departments[0].id
      }
    }
  }, [showAll, departments, areDepartmentsLoaded])

  if (departments.length === 0) {
    return null
  }

  const departmentOptions: OptionType[] = departments.map(
    (d: DepartmentResponse) => ({
      value: d.id,
      label: d.name,
    }),
  )
  const count = departments.length

  if (showNotAssigned) {
    departmentOptions.unshift({
      label: t("desktop.shareable.without_department"),
      value: FilterSpecialValues.NOT_ASSIGNED,
    })
  }

  if (showAll) {
    departmentOptions.unshift({
      label: (
        <>
          {t("mobile.general.all_departments")}{" "}
          <span className="count">&middot; {count}</span>
        </>
      ),
      value: FilterSpecialValues.ALL,
    })
  }

  return (
    <Dropdown
      className="department-filter"
      options={departmentOptions}
      value={value}
      onChange={onChange}
    />
  )
}
