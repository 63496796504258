import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { useBookContext } from "../../../../contexts/Mobile/BookContext"
import { useToast } from "../../../../hooks/useToast"
import { useAnalyticsScreenView } from "../../../../providers/Mobile/FirebaseAnalyticsProvider"
import { shortUserTimeFormat } from "../../../../utils"
import { DESK_PATHS } from "./constants"
import NoDeskFound from "./NoDeskFound"

import { useDeskCheckInMutation } from "../../../../redux/api/checkIns"
import { useFetchMyDeskReservationsQuery } from "../../../../redux/api/deskReservations"
import { useFetchDeskQuery } from "../../../../redux/api/desks"

import Button from "../../../../components/advanced/Button"
import Loader from "../../../../components/basic/Loader"
import { IconMapper } from "../../../../components/CorrectIconMapper"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import CalendarSVG from "../../../../assets/images/icons/Calendar.svg"
import ClockSVG from "../../../../assets/images/icons/Clock.svg"
import WorldSVG from "../../../../assets/images/icons/World2.svg"

import "./AdhocDeskCheckin.sass"

const AdhocDeskCheckin = () => {
  useAnalyticsScreenView("Book/Desks/AdhocDeskCheckin")

  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()
  const history = useHistory()
  const [deskCheckIn] = useDeskCheckInMutation()
  const { goToHome } = useBookContext()
  const location = useLocation<{ desk_id: string }>()
  const deskId = location.state?.desk_id || ""
  const date = dayjs()
  const startOfDay = date.startOf("day").toISOString()
  const endOfDay = date.endOf("day").toISOString()

  // Fetch desk data
  const { data: desk, isLoading: isDeskLoading } = useFetchDeskQuery(deskId)

  // Fetch user's reservations
  const { data: myDeskReservations, isLoading: isMyDeskReservationsLoading } =
    useFetchMyDeskReservationsQuery({
      start: startOfDay,
      end: endOfDay,
    })

  // Find the reservation for the current desk and extract start and end times
  const reservation = myDeskReservations?.results?.find(
    (reservation) => reservation.desk.id === deskId,
  )
  const startTime = reservation?.start ? dayjs(reservation.start) : null
  const endTime = reservation?.end ? dayjs(reservation.end) : null

  // Format times and date
  const formattedStartTime = startTime
    ? startTime.format(shortUserTimeFormat())
    : ""
  const formattedEndTime = endTime ? endTime.format(shortUserTimeFormat()) : ""
  const formattedDate = date.format("dddd, MMM D")

  const isLoading = isDeskLoading || isMyDeskReservationsLoading

  const handleClose = () => goToHome()

  const handleCheckIn = async () => {
    try {
      if (!desk) {
        return
      }

      // Check in to desk
      await deskCheckIn({ id: deskId }).unwrap()

      infoToast(t("mobile.desk_check_in.success"))

      // Navigate to home screen
      history.push(DESK_PATHS.home)
    } catch (error) {
      errorToast(t("mobile.desk_check_in.error"))
    }
  }

  return (
    <SafeViewArea className="AdhocDeskCheckin">
      <div className="head">
        <TopNav onClose={handleClose} />
        <div className="icon">
          <div>
            <IconMapper iconType="desk" needsWrap={false} />
          </div>
        </div>
      </div>

      <div className="body">
        <div className="main-data">
          {isLoading && <Loader variant="fullScreen" />}

          {myDeskReservations && !isLoading && (
            <>
              <div className="info">
                <div className="type">{t("mobile.general.desk")}</div>
                <div className="status">{t("mobile.home.reserved")}</div>
              </div>
              <div className="name">{desk?.name}</div>
              <div className="location">
                {desk?.building.name}, {desk?.floor.name}
              </div>

              <div className="details">
                <div className="detail-row">
                  <CalendarSVG />
                  <div>{formattedDate}</div>
                </div>

                <div className="detail-row">
                  <ClockSVG />
                  <div>
                    {formattedStartTime} - {formattedEndTime}
                  </div>
                </div>

                <div className="detail-row">
                  <WorldSVG />
                  <div>{desk?.tz}</div>
                </div>
              </div>

              <div className="next-button">
                <Button variant="mobile-action" onClick={handleCheckIn}>
                  {t("mobile.general.check_in")}
                </Button>
              </div>
            </>
          )}

          {!myDeskReservations && !isLoading && <NoDeskFound />}
        </div>
      </div>
    </SafeViewArea>
  )
}

export default AdhocDeskCheckin
