import { useTranslation } from "react-i18next"

import { generateString } from "../../../utils"

import { SchedulePeopleDataRow } from "../../../redux/people_schedule/types"
import { formatUser } from "../../../redux/user/utils"

import Avatar from "../../../components/advanced/Avatar"
import UserTooltip from "../../../components/UserTooltip"

type Props = {
  peopleRow: SchedulePeopleDataRow
  rowNumber: number
  isCurrentUser: boolean
}

export const PeopleRowHeading = ({
  peopleRow,
  rowNumber,
  isCurrentUser,
}: Props) => {
  const { t } = useTranslation()
  const name = formatUser(peopleRow)

  return (
    <div className="PeopleRowHeading RowHeading">
      <div className="row-number">{rowNumber}</div>
      <span className="people" aria-label={name}>
        <UserTooltip user={peopleRow} uniqueId={generateString(4)}>
          <span className="label">
            <Avatar user={peopleRow} hasDefaultAvatar size="medium" />
            <span className="name">
              {name}
              {isCurrentUser && (
                <>
                  <span className="you">
                    {" "}
                    {t("desktop.settings.people.you")}
                  </span>
                </>
              )}
            </span>
          </span>
        </UserTooltip>
      </span>
    </div>
  )
}
