import queryString from "query-string"

import { fetchExtended } from "../fetch"

const { stringify } = queryString

export const fetchOptions: RequestInit = {
  mode: "cors",
  cache: "no-cache",
  credentials: "omit",
  headers: {
    Accept: "application/json, text/javascript, */*; q=0.01",
    "Accept-Encoding": "gzip, deflate, br",
  },
}

export const signedFetchOptions = (token: string) => ({
  ...fetchOptions,
  headers: {
    ...fetchOptions.headers,
    Authorization: `Bearer ${token}`,
  },
})

export async function get(
  url: string,
  options = {},
  token: string | null = null,
): Promise<Response> {
  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)
  return fetchExtended(url, { method: "GET", ...defaultOptions, ...options })
}

export async function postJSON(
  url: string,
  options = { body: {} },
  token: string | null = null,
): Promise<Response> {
  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)
  const mergedOptions = {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      "Content-type": "application/json",
    },
    ...options,
    body: JSON.stringify(options.body),
  }

  return fetchExtended(url, {
    method: "POST",
    ...mergedOptions,
  })
}

export async function putJSON(
  url: string,
  options = { body: {} },
  token: string | null = null,
): Promise<Response> {
  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)
  const mergedOptions = {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      "Content-type": "application/json",
    },
    ...options,
    body: JSON.stringify(options.body),
  }

  return fetchExtended(url, {
    method: "PUT",
    ...mergedOptions,
  })
}

export async function patchJSON(
  url: string,
  options = { body: {} },
  token: string | null = null,
): Promise<Response> {
  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)
  const mergedOptions = {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      "Content-type": "application/json",
    },
    ...options,
    body: JSON.stringify(options.body),
  }

  return fetchExtended(url, {
    method: "PATCH",
    ...mergedOptions,
  })
}

export async function deleteJSON(
  url: string,
  options = { body: {} },
  token: string | null = null,
): Promise<Response> {
  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)
  const mergedOptions = {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      "Content-type": "application/json",
    },
    ...options,
    body: JSON.stringify(options.body),
  }

  return fetchExtended(url, {
    method: "DELETE",
    ...mergedOptions,
  })
}

export async function postForm(
  url: string,
  options = { body: {} },
  token: string | null = null,
): Promise<Response> {
  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    body: stringify(options.body),
  }

  return fetchExtended(url, {
    method: "POST",
    ...mergedOptions,
    headers: {
      ...mergedOptions.headers,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
}

export async function postData(
  url: string,
  options: any = { body: {} },
  token: string | null = null,
): Promise<Response> {
  const formData = new FormData()

  for (const key in options.body) {
    formData.append(key, options.body[key])
  }

  const defaultOptions = !token ? fetchOptions : signedFetchOptions(token)

  return fetchExtended(url, {
    method: "POST",
    ...defaultOptions,
    ...options,
    body: formData,
  })
}

export const mockDataFetch = async (
  data?: any,
  timeout = 1000,
): Promise<Response> => {
  return new Promise<Response>((resolve) => {
    setTimeout(() => {
      resolve(new Response(JSON.stringify({ ok: true, results: data })))
    }, timeout)
  })
}
