import { useEffect } from "react"

import { isNative } from "../utils"
import { useRefresh } from "./mobile/useRefresh"
import { Network } from "@capacitor/network"

type Props = (connected: boolean) => void

/**
 * This hook will trigger the onConnectivityChange function whenever
 * connection parameters change in the app.
 *
 * At the moment it only works if the app is detected to be running
 * on native devices (Our isNative utility function is called).
 */
export const useNativeConnectivityChange = (onConnectivityChange: Props) => {
  useRefresh(() => {
    if (!isNative()) return

    Network.getStatus().then(({ connected }) => onConnectivityChange(connected))
  })

  useEffect(() => {
    if (!isNative()) return

    Network.getStatus().then(({ connected }) => {
      onConnectivityChange(connected)

      Network.addListener("networkStatusChange", (status) =>
        onConnectivityChange(status.connected),
      )
    })
  }, [onConnectivityChange])
}
