import { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { useToast } from "../../hooks/useToast"
import { Input } from "../basic/Input"
import Field from "../Field"
import ModalDeleteForm from "./ModalDeleteForm"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchAssetsQuery } from "../../redux/api/assets"
import {
  useDestroyAssetTypeMutation,
  useFetchAssetTypesQuery,
} from "../../redux/api/assetTypes"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { AssetType } from "../../redux/asset_types/types"

type Props = {
  assetType: AssetType
}

type FormValues = {
  confirmationName: string
}

const AssetTypeDeleteForm = ({ assetType }: Props) => {
  const { closeAllModals } = useModals()
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()
  const methods = useForm<FormValues>({
    defaultValues: {
      confirmationName: "",
    },
  })
  const {
    control,
    formState: { isSubmitting },
  } = methods

  const { id, name } = assetType

  const { data: { count: assetTypeCount = 0 } = {} } = useFetchAssetTypesQuery()
  const [destroyAssetType] = useDestroyAssetTypeMutation()

  const { data: { results: assets = [] } = {} } = useFetchAssetsQuery(
    {
      active: true,
      asset_type: id,
    },
    {
      skip: !id,
    },
  )

  const onDeleteClick = useCallback(async () => {
    const response = await destroyAssetType(id)

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)
      }
    } else {
      analyticsEvent(SupportedEvents.ASSET_TYPE_DELETE, {
        id,
        name: assetType?.name,
        total: assetTypeCount - 1,
      })

      infoToast(
        t(
          "desktop.settings.assets.asset_type_delete_form.asset_type_deleted_toast",
        ),
      )
      closeAllModals()
    }
  }, [
    destroyAssetType,
    id,
    errorToast,
    assetType?.name,
    assetTypeCount,
    infoToast,
    t,
    closeAllModals,
  ])

  return (
    <FormProvider {...methods}>
      <ModalDeleteForm
        onDelete={onDeleteClick}
        title={t("desktop.settings.assets.asset_type_delete_form.title")}
        hint={
          <Trans
            i18nKey={"desktop.settings.assets.asset_type_delete_form.text"}
            values={{
              assetTypeName: name,
              numberOfAssets: assets.length || 0,
            }}
          />
        }
        helpText={
          <Trans
            i18nKey={
              "desktop.settings.assets.asset_type_delete_form.name_field_help_text"
            }
            values={{
              name,
            }}
          />
        }
      >
        <Field
          control={control}
          name="confirmationName"
          label={t(
            "desktop.settings.assets.asset_type_delete_form.asset_type_name",
          )}
          rules={{
            validate: (v: string) =>
              v === name
                ? undefined
                : t(
                    "desktop.settings.assets.asset_type_delete_form.asset_type_incorrect_name_toast",
                  ),
          }}
        >
          {(props) => (
            <Input
              autoFocus
              maxLength={60}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>
      </ModalDeleteForm>
    </FormProvider>
  )
}

export default AssetTypeDeleteForm
