import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"
import { FilterSpecialValues } from "./types"

type Props = {
  value: string
  onChange: (v: string) => void
}

const BATTERY_UNDER_75 = "75"
const BATTERY_UNDER_50 = "50"
const BATTERY_UNDER_25 = "25"

const DeviceBatteryFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t("desktop.settings.rooms.devices.filters.battery.all"),
      value: FilterSpecialValues.ALL,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.filters.battery.battery_under_25",
      ),
      value: BATTERY_UNDER_25,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.filters.battery.battery_under_50",
      ),
      value: BATTERY_UNDER_50,
    },
    {
      label: t(
        "desktop.settings.rooms.devices.filters.battery.battery_under_75",
      ),
      value: BATTERY_UNDER_75,
    },
  ]

  return (
    <Dropdown
      className="device-type-filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export default DeviceBatteryFilter
