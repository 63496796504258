import { useEffect } from "react"

import throttle from "lodash.throttle"

type UseBottomScrollListener = {
  containerRef: React.RefObject<HTMLElement>
  onBottom: () => void
  offset?: number
  throttleMs?: number
}

export const useBottomScrollListener = ({
  containerRef,
  onBottom,
  offset = 0,
  throttleMs = 150,
}: UseBottomScrollListener) => {
  useEffect(() => {
    if (!containerRef.current) return

    const target = containerRef.current
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = target
      if (scrollTop + clientHeight >= scrollHeight - offset) {
        onBottom()
      }
    }

    const handleThrottledScroll = throttle(handleScroll, throttleMs)

    target.addEventListener("scroll", handleThrottledScroll, {
      passive: true,
    })
    target.addEventListener("wheel", handleThrottledScroll, {
      passive: true,
    })

    return () => {
      target.removeEventListener("scroll", handleThrottledScroll)
      target.removeEventListener("wheel", handleThrottledScroll)
    }
  }, [containerRef, onBottom, offset])
}
