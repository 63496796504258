import { useEffect } from "react"

import { createSelector } from "reselect"

import {
  ExtendedReducerKeys,
  RootState,
  useAppSelector,
} from "../redux/reducers"

type Props = {
  reducerKeys: ExtendedReducerKeys[]
  getSliceData?: () => void
}

/**
 * Will return true once all isLoaded values are set to
 * true.
 */
const areSlicesLoadedSelector = (keys: ExtendedReducerKeys[]) => {
  const selectors = getSelectors(keys)

  return createSelector(selectors, (...slices) => {
    return slices.every((slice) =>
      slice && typeof slice === "object" && "isLoaded" in slice
        ? slice.isLoaded
        : false,
    )
  })
}

const areSlicesLoadingSelector = (keys: ExtendedReducerKeys[]) => {
  const selectors = getSelectors(keys)

  return createSelector(selectors, (...slices) => {
    return slices.some((slice) =>
      slice && "isLoading" in slice ? slice.isLoading : false,
    )
  })
}

export const useLoadReducers = ({ reducerKeys, getSliceData }: Props) => {
  const areSlicesLoaded = useAppSelector(areSlicesLoadedSelector(reducerKeys))
  const areSlicesLoading = useAppSelector(areSlicesLoadingSelector(reducerKeys))

  useEffect(() => {
    getSliceData && getSliceData()
  }, [getSliceData])

  return { areSlicesLoaded, areSlicesLoading }
}

const getSelectors = (keys: ExtendedReducerKeys[]) =>
  keys.flatMap((key) =>
    createSelector(
      [
        (state: RootState) => {
          const selectorKeys = key?.split(".") ?? []

          let value = state
          selectorKeys.forEach((selectorKey) => {
            // @ts-ignore
            value = value[selectorKey]

            if (value === undefined) {
              return {}
            }
          })

          return value
        },
      ],
      (i) => i,
    ),
  )
