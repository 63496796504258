import { api } from "../"
import { deskCheckInsURL } from "../../../api"

type DeskCheckInProps = {
  id: string
  onSpot?: boolean
}

export const checkIns = api.injectEndpoints({
  endpoints: (builder) => ({
    deskCheckIn: builder.mutation<void, DeskCheckInProps>({
      query: ({ id, onSpot: spotOn = true }) => ({
        url: deskCheckInsURL(id, { on_spot: spotOn }),
      }),
      invalidatesTags: [{ type: "DeskReservations", id: "LIST" }],
    }),
  }),
})

export const { useDeskCheckInMutation } = checkIns
