import { Block } from "slate"
import { RenderMarkProps, RenderNodeProps } from "slate-react"

export const renderNode = ({ attributes, children, node }: RenderNodeProps) => {
  if (!Block.isBlock(node)) {
    return null
  }

  const { data } = node

  const { attributeKey, attributeValue, verticalAlignment, theme } = data.toJS()

  const customAttributes = attributeKey
    ? { [attributeKey]: attributeValue }
    : {}

  const commonAttributes = { ...attributes, ...customAttributes }

  const renderParagraph = () => <p {...commonAttributes}>{children}</p>

  const renderBulletedList = () => <ul {...commonAttributes}>{children}</ul>

  const renderHeadingOne = () => <h1 {...commonAttributes}>{children}</h1>

  const renderHeadingTwo = () => <h2 {...commonAttributes}>{children}</h2>

  const renderHeadingThree = () => <h3 {...commonAttributes}>{children}</h3>

  const renderListItem = () => <li {...commonAttributes}>{children}</li>

  const renderNumberedList = () => <ol {...commonAttributes}>{children}</ol>

  const renderBackgroundTheme = () => (
    <div className={`background-theme ${theme}`} {...commonAttributes}>
      <div
        className="vertical-align-container"
        style={{ alignItems: verticalAlignment }}
      >
        <div className="vertical-align-container-inner">{children}</div>
      </div>
    </div>
  )

  const nodeTypes: Record<string, () => JSX.Element | null> = {
    paragraph: renderParagraph,
    "bulleted-list": renderBulletedList,
    "heading-one": renderHeadingOne,
    "heading-two": renderHeadingTwo,
    "heading-three": renderHeadingThree,
    "list-item": renderListItem,
    "numbered-list": renderNumberedList,
    "background-theme": renderBackgroundTheme,
  }

  const renderFunction = nodeTypes[node.type]

  return renderFunction ? renderFunction() : null
}

export const renderMark = ({ children, mark }: RenderMarkProps) => {
  const { type } = mark

  const markComponents: Record<string, () => JSX.Element | null> = {
    bold: () => <strong>{children}</strong>,
    italic: () => <em>{children}</em>,
    underline: () => (
      <span style={{ textDecoration: "underline" }}>{children}</span>
    ),
  }

  const renderFunction = markComponents[type]

  return renderFunction ? renderFunction() : null
}
