import React, { useEffect, useMemo, useState } from "react"

import dayjs from "dayjs"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { SHORT_USER_TIME_FORMAT } from "../../constants"
import { timeZone as defaultTimezone } from "../../dayjs"
import { useStoredFilter } from "../../hooks/useStoredFilter"
import { useToast } from "../../hooks/useToast"
import CSVInviteModal from "../../modals/CSVInviteModal"
import InviteCSVEditModal from "../../modals/Visitors/InviteCSVEditModal"
import { INVITES_FILTERS_STORE_NAME } from "../../screens/Manage/constants"
import { Filter } from "../../screens/Manage/Visitors/InvitesFilters"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import Switch from "../basic/Switch"
import { FilterSpecialValues } from "../Filter/types"
import { useModals } from "@mattjennings/react-modal-stack"

import { InviteRequest } from "../../redux/invite/types"
import {
  importInvitesRemove,
  importInvitesRemoveAll,
  importInvitesSave,
  SaveImportProps,
} from "../../redux/invites/invitesSlice"
import { selectInvites } from "../../redux/invites/selectors"
import { ImportErrors, InviteCSVResponse } from "../../redux/invites/types"
import { useAppSelector } from "../../redux/reducers"
import { selectUser } from "../../redux/user/selectors"
import { formatUser } from "../../redux/user/utils"
import { useActions } from "../../redux/utils"
import { selectVisitorSettings } from "../../redux/visitor_settings/selectors"

import Button from "../../components/advanced/Button"
import Table from "../../components/advanced/Table"
import Card from "../../components/basic/Card"
import { Action, Column } from "../../components/basic/Table"
import { Tooltip } from "../../components/basic/Tooltip"
import { PageActions } from "../../components/Form/PageFormHook"

import CheckSVG from "../../assets/images/icons/Check.svg"
import ErrorSVG from "../../assets/images/icons/Error.svg"

import "./CsvInvite.sass"

const InvalidData = () => {
  const { t } = useTranslation()

  return (
    <span className="InvalidData">
      {t("desktop.manage.visitors.invite.table.invalid")}
    </span>
  )
}

type ErrorsTooltipProp = {
  errors: ImportErrors
}

const ErrorsTooltip = ({ errors }: ErrorsTooltipProp) => {
  const { t } = useTranslation()

  return (
    <div>
      {Object.keys(errors).map((key) => (
        <div key={key}>
          {t(`desktop.manage.visitors.invite.table.${key}` as ParseKeys)}:{" "}
          {errors[key]}
        </div>
      ))}
    </div>
  )
}

const CsvInvite = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModals()
  const { infoToast, errorToast } = useToast()

  const [invitingInProgress, setInvitingInProgress] = useState(false)

  const { entries: visitorSettings } = useAppSelector(selectVisitorSettings)
  const { entry: user } = useAppSelector(selectUser)

  const defaultFilter: Filter = {
    building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
    show: "",
    search: "",
    page: 1,
  }

  const [storedFilterValues] = useStoredFilter({
    filterName: INVITES_FILTERS_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })

  useEffect(() => {
    setNotifyHost(
      visitorSettings?.find(
        (setting) => setting.building_id === storedFilterValues.building_id,
      )?.enable_general_notification ?? false,
    )
    setInviteVisitors(
      visitorSettings?.find(
        (setting) => setting.building_id === storedFilterValues.building_id,
      )?.enable_visitors_notification ?? false,
    )
  }, [visitorSettings, storedFilterValues.building_id])

  const [inviteVisitors, setInviteVisitors] = useState(false)
  const [notifyHost, setNotifyHost] = useState(false)

  const actions = useActions({
    importInvitesSave: (props: SaveImportProps) => importInvitesSave(props),
    importInvitesRemove: (id: string) => importInvitesRemove(id),
    importInvitesRemoveAll: () => importInvitesRemoveAll(),
  })

  const { imports } = useAppSelector(selectInvites)

  const tableColumns = useMemo<Column<InviteCSVResponse>[]>(
    () => [
      {
        field: "full_name",
        label: t("desktop.manage.visitors.invite.table.invite"),
        renderCell: (i) => {
          if (i.errors && i.errors.full_name) {
            return <InvalidData />
          }
          return i.full_name
        },
      },
      {
        field: "email",
        label: t("desktop.manage.visitors.invite.table.email"),
        renderCell: (i) => {
          if (i.errors && i.errors.email) {
            return <InvalidData />
          }
          return i.email
        },
      },
      {
        field: "start",
        label: t("desktop.manage.visitors.invite.table.date"),
        renderCell: (i) => {
          if (i.errors && i.errors.start) {
            return <InvalidData />
          }
          return dayjs(i.start).format("l")
        },
      },
      {
        field: "end",
        label: t("desktop.manage.visitors.invite.table.time"),
        renderCell: (i) => {
          if (i.errors && (i.errors.start || i.errors.end)) {
            return <InvalidData />
          }
          return `${dayjs(i.start).format(SHORT_USER_TIME_FORMAT)} - ${dayjs(i.end).format(SHORT_USER_TIME_FORMAT)}`
        },
      },
      {
        field: "host",
        label: t("desktop.manage.visitors.invite.table.host"),
        renderCell: (i) => {
          if (i.errors && i.errors.host) {
            return <InvalidData />
          }
          return formatUser(i.host)
        },
      },
      {
        field: "additional_info",
        label: t("desktop.manage.visitors.invite.table.additional_info"),
        renderCell: (i) => {
          if (i.errors && i.errors.additional_info) {
            return <InvalidData />
          }
          return (
            <Tooltip
              uniqueId={`${i.id}-info`}
              content={i.additional_info ?? ""}
            >
              <div id={`${i.id}-info`} className="info-text">
                {i.additional_info ?? ""}
              </div>
            </Tooltip>
          )
        },
      },
      {
        field: "errors",
        label: t("desktop.manage.visitors.invite.table.status"),
        renderCell: (i) => {
          if (i.errors) {
            return (
              <Tooltip
                uniqueId={i.id}
                content={<ErrorsTooltip errors={i.errors} />}
              >
                <span id={i.id} className="status-error">
                  <ErrorSVG />
                </span>
              </Tooltip>
            )
          } else {
            return (
              <span className="status-check">
                <CheckSVG />
              </span>
            )
          }
        },
      },
    ],
    [t],
  )

  const rowActions = useMemo<Action<InviteCSVResponse>[]>(
    () => [
      {
        label: t("desktop.manage.visitors.invite.table.actions.edit_invite"),
        onClick: (i) => {
          openModal(InviteCSVEditModal, {
            invite: {
              ...i,
              send_invite_to_visitor: inviteVisitors ?? null,
              send_notification_to_host: notifyHost ?? null,
            },
          })
        },
      },
      {
        label: t("desktop.manage.visitors.invite.table.actions.delete_invite"),
        onClick: (i) => {
          actions.importInvitesRemove(i.id)
        },
      },
    ],
    [t, actions, openModal, inviteVisitors, notifyHost],
  )

  const handleDeleteConfirmation = () => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await handleDelete()
        closeModal()
      },
    })
  }

  const handleDelete = () => {
    actions.importInvitesRemoveAll()
  }

  const handleInvite = async () => {
    const inviteRequest: InviteRequest[] = []
    setInvitingInProgress(true)

    for (const invite of imports) {
      if (invite.errors) {
        errorToast(t("desktop.manage.visitors.invite.csv_invite.import_error"))
        setInvitingInProgress(false)
        return
      }

      inviteRequest.push({
        full_name: invite.full_name,
        company_name: invite.company_name,
        email: invite.email,
        phone: invite.phone,
        start: invite.start,
        end: invite.end,
        tz: defaultTimezone,
        building_id: invite.building.id,
        desk_id: null,
        host_id: invite.host?.email,
        schedule: null,
        guests: invite.guests ?? 0,
        additional_info: invite.additional_info,
        send_invite_to_visitor: inviteVisitors || null,
        send_notification_to_host: notifyHost || null,
      })
    }

    const response = await actions.importInvitesSave({
      invites: inviteRequest,
      send_invite_to_visitor: inviteVisitors,
      send_notification_to_host: notifyHost,
    })

    if (importInvitesSave.fulfilled.match(response)) {
      if (response.payload && response.payload.length > 0) {
        errorToast(t("desktop.manage.visitors.invite.csv_invite.import_error"))
        setInvitingInProgress(false)
      } else {
        infoToast(t("desktop.manage.visitors.invite.csv_invite.import_success"))
        history.push("/manage/visitors/invites")
        return
      }
    }
  }

  return (
    <div className="CsvInvite">
      <Card className="Card">
        {imports.length > 0 ? (
          <div className="section">
            <Table
              showRowNumber
              onRowClick={(i) => {
                openModal(InviteCSVEditModal, {
                  invite: {
                    ...i,
                    send_invite_to_visitor: inviteVisitors ?? null,
                    send_notification_to_host: notifyHost ?? null,
                  },
                })
              }}
              rows={imports}
              columns={tableColumns}
              rowActions={rowActions}
            />
          </div>
        ) : (
          <div className="section">
            <div className="info">
              {t("desktop.manage.visitors.invite.upload_info")}
            </div>
            <Button
              variant="secondary"
              onClick={() => openModal(CSVInviteModal)}
            >
              {t("desktop.manage.visitors.invite.buttons.upload_csv")}
            </Button>
          </div>
        )}
        <div className="section">
          <Switch
            label={t("desktop.manage.visitors.invite.send_email")}
            value={inviteVisitors}
            onChange={setInviteVisitors}
          />
          <Switch
            label={t("desktop.manage.visitors.invite.send_notification")}
            value={notifyHost}
            onChange={setNotifyHost}
          />
        </div>
      </Card>
      <PageActions backUrl="/manage/visitors/invites">
        {imports.length > 0 && (
          <Button
            variant="danger-pop"
            noConfirm
            onClick={handleDeleteConfirmation}
          >
            {t("mobile.general.delete")}
          </Button>
        )}
        <Button onClick={handleInvite} isLoading={invitingInProgress}>
          {t("desktop.manage.visitors.invite.buttons.invite")}
        </Button>
      </PageActions>
    </div>
  )
}

export default CsvInvite
