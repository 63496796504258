import React, { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useToast } from "../../../hooks/useToast"
import Button from "../../advanced/Button"
import { Input } from "../../basic/Input"
import { RichText } from "../../basic/RichText"
import { Switch } from "../../basic/Switch"
import Field from "../../Field"
import { setErrors } from "../formUtils"
import PageForm from "../PageFormHook"

import {
  createDocument,
  updateDocument,
  UpdateDocumentProps,
} from "../../../redux/documents/documentsSlice"
import { selectDocuments } from "../../../redux/documents/selectors"
import {
  DocumentRequest,
  DocumentResponse,
  DocumentStatusType,
  DocumentViewType,
} from "../../../redux/documents/types"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import "./DocumentForm.sass"

type Props = {
  document?: DocumentResponse
  onShowPreview: (d: DocumentViewType) => void
}

type FormValues = {
  title: string
  description: string
  required_signing: boolean
  visitor_must_re_sign: boolean
}

const DocumentForm = ({ document, onShowPreview }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { errorToast, infoToast } = useToast()

  const { entries: documents } = useAppSelector(selectDocuments)

  const methods = useForm<FormValues>({
    defaultValues: {
      title: document?.title ?? "",
      description: document?.description ?? "",
      required_signing: document?.required_signing ?? true,
      visitor_must_re_sign: document?.visitor_must_re_sign ?? true,
    },
  })
  const { setError, control, getValues } = methods

  const actions = useActions({
    createDocument: (document: DocumentRequest) => createDocument(document),
    updateDocument: (document: UpdateDocumentProps) => updateDocument(document),
  })

  const onCreateClick = useCallback(
    async ({
      title,
      description,
      required_signing,
      visitor_must_re_sign,
    }: FormValues) => {
      const response = await actions.createDocument({
        title,
        description,
        required_signing,
        visitor_must_re_sign,
        status:
          documents.length > 0
            ? DocumentStatusType.UNPUBLISHED
            : DocumentStatusType.PUBLISHED,
      })
      if (createDocument.rejected.match(response)) {
        if (response.payload) {
          setErrors(response.payload, setError, errorToast)
        }
      } else {
        infoToast(t("desktop.settings.visitors.documents.form.created_toast"))
        history.push("/settings/visitors/documents")
      }
    },
    [actions, history, documents, errorToast, infoToast, setError, t],
  )

  const onUpdateClick = useCallback(
    async ({
      title,
      description,
      required_signing,
      visitor_must_re_sign,
    }: FormValues) => {
      if (document?.id) {
        const response = await actions.updateDocument({
          id: document.id,
          title,
          description,
          required_signing,
          visitor_must_re_sign,
          status: document.status,
        })
        if (updateDocument.rejected.match(response)) {
          if (response.payload) {
            setErrors(response.payload, setError, errorToast)
          }
        } else {
          infoToast(t("desktop.settings.visitors.documents.form.updated_toast"))
          history.push("/settings/visitors/documents")
        }
      }
    },
    [document, actions, history, errorToast, infoToast, setError, t],
  )

  const handleShowPreview = () => {
    const values = getValues()
    onShowPreview({
      title: values.title,
      description: values.description,
    })
  }

  const updateMode = Boolean(document)

  return (
    <FormProvider {...methods}>
      <PageForm
        className="DocumentForm"
        updateMode={updateMode}
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        backUrl="/settings/visitors/documents"
      >
        <Field
          control={control}
          name="title"
          label={t("desktop.settings.visitors.documents.form.title")}
        >
          {(props) => <Input autoFocus {...props} />}
        </Field>
        <Field
          control={control}
          name="description"
          label={t("desktop.settings.visitors.documents.form.description")}
        >
          {(props) => <RichText allowVideoEmbedding {...props} />}
        </Field>
        <Field control={control} name="required_signing">
          {(props) => (
            <Switch
              {...props}
              label={t(
                "desktop.settings.visitors.documents.form.required_signing",
              )}
            />
          )}
        </Field>
        <Field control={control} name="visitor_must_re_sign">
          {(props) => (
            <Switch
              {...props}
              label={t(
                "desktop.settings.visitors.documents.form.visitor_must_re_sign",
              )}
            />
          )}
        </Field>
        <Button variant="secondary" onClick={handleShowPreview}>
          {t("desktop.settings.visitors.documents.form.preview")}
        </Button>
      </PageForm>
    </FormProvider>
  )
}

export default DocumentForm
