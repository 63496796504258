import React, { useEffect } from "react"

import { Trans, useTranslation } from "react-i18next"

import { isDev, isProd } from "../utils"

import { getAppParams } from "../redux/appParams/selectors"
import { fetchAuthCode } from "../redux/auth/authSlice"
import { useAppSelector } from "../redux/reducers"
import { useActions } from "../redux/utils"

import Button from "../components/advanced/Button"
import Heading from "../components/Heading"
import RouteView from "../components/RouteView"
import Screen from "../components/Screen"

import "./Login.sass"

function Login() {
  const { t } = useTranslation()

  const actions = useActions({
    fetchAuthCode: () => fetchAuthCode(),
  })

  const { appName } = useAppSelector(getAppParams)

  useEffect(() => {
    isProd() && actions.fetchAuthCode()
  }, [actions])

  return (
    <RouteView className="Login">
      <Screen>
        {isProd() && (
          <>
            <Heading>{t("general.signing_in")}</Heading>
            <Trans
              i18nKey="general.redirection.redirecting_with_app_name"
              values={{ appName }}
            >
              Redirecting you to {{ appName }} Portal for authorization.
            </Trans>
          </>
        )}
        {isDev() && (
          <>
            <Heading>{t("general.signed_out")}</Heading>
            <br />
            <Button onClick={actions.fetchAuthCode}>
              {t("general.sign_in")}
            </Button>
          </>
        )}
      </Screen>
    </RouteView>
  )
}

export default Login
