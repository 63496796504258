import { useCallback, useEffect, useMemo } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useScrollToTop } from "../../../hooks/useScrollToLocation"
import { useToast } from "../../../hooks/useToast"
import VisitorEmailPreviewModal from "../../../modals/VisitorEmailPreviewModal"
import { getDirectionsLink, getLabel } from "../../../utils"
import Button from "../../advanced/Button"
import { GooglePlacesSelect } from "../../advanced/GooglePlacesSelect"
import { GooglePlacesSelectType } from "../../advanced/GooglePlacesSelect/types"
import { Checkbox } from "../../basic/Checkbox"
import { RichText } from "../../basic/RichText"
import Switch from "../../basic/Switch"
import Field from "../../Field"
import { setErrors } from "../formUtils"
import PageForm from "../PageFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import { useActions } from "../../../redux/utils"
import {
  VisitorEmailSettingResponse,
  VisitorEmailSettingsRequestParams,
  VisitorEmailType,
} from "../../../redux/visitor_email_settings/types"
import {
  createVisitorEmailSetting,
  CreateVisitorEmailSettingProps,
  destroyVisitorEmailSetting,
  updateVisitorEmailSetting,
  UpdateVisitorEmailSettingProps,
} from "../../../redux/visitor_email_settings/visitorEmailSettingsSlice"

import InfoSVG from "../../../assets/images/icons/InfoOutlined.svg"

import "./EmailSettingsForm.sass"

type Props = {
  buildingId: string
  type: VisitorEmailType
  emailSettings?: VisitorEmailSettingResponse
}

type FormValues = {
  show_details: boolean
  show_location: boolean
  address: GooglePlacesSelectType
  directions_link?: boolean
  additional_info: boolean
  additional_info_text?: string
  easy_check_in?: boolean
  attach_signed_documents?: boolean
}

const EmailSettingsForm = ({ buildingId, type, emailSettings }: Props) => {
  useScrollToTop()

  const history = useHistory()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const { openModal } = useModals()

  const actions = useActions({
    createVisitorEmailSetting: (setting: CreateVisitorEmailSettingProps) =>
      createVisitorEmailSetting(setting),
    updateVisitorEmailSetting: (setting: UpdateVisitorEmailSettingProps) =>
      updateVisitorEmailSetting(setting),
    destroyVisitorEmailSetting: (params: VisitorEmailSettingsRequestParams) =>
      destroyVisitorEmailSetting(params),
  })

  const defaultValues = useMemo(() => {
    return {
      show_details: emailSettings?.show_details ?? false,
      show_location: emailSettings?.show_location ?? false,
      address: {
        address: emailSettings?.address,
      } as GooglePlacesSelectType,
      directions_link: typeof emailSettings?.directions_link === "string",
      additional_info: emailSettings?.additional_info ?? false,
      additional_info_text: emailSettings?.additional_info_text ?? "",
      easy_check_in: emailSettings?.easy_check_in ?? false,
      attach_signed_documents: emailSettings?.attach_signed_documents ?? false,
    }
  }, [emailSettings])

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const { control, watch, setError, reset, getValues } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [reset, emailSettings, defaultValues])

  const handlePreviewEmail = () => {
    const values = getValues()

    const content = {
      ...values,
      directions_link: getDirectionsLink(
        !!values.directions_link,
        values.address,
      ),
      address: values?.address?.address,
    }

    openModal(VisitorEmailPreviewModal, {
      building_id: buildingId,
      type,
      content,
    })
  }

  const onCreateClick = useCallback(
    async (values: FormValues) => {
      const response = await actions.createVisitorEmailSetting({
        ...values,
        address: values.address.address,
        directions_link:
          type === VisitorEmailType.WELCOME
            ? undefined
            : getDirectionsLink(!!values.directions_link, values.address),
        building_id: buildingId,
        type,
      })

      if (createVisitorEmailSetting.rejected.match(response)) {
        if (response.payload) {
          setErrors(response.payload, setError, errorToast)
        }
      } else {
        infoToast(
          t(
            "desktop.settings.visitors.email_settings.email_settings_created_toast",
          ),
        )
        history.push("/settings/visitors/general")
      }
    },
    [actions, buildingId, type, setError, errorToast, infoToast, t, history],
  )

  const onUpdateClick = useCallback(
    async (values: FormValues) => {
      const response = await actions.updateVisitorEmailSetting({
        ...values,
        address: values.address.address,
        directions_link:
          type === VisitorEmailType.WELCOME
            ? undefined
            : getDirectionsLink(
                Boolean(values.directions_link),
                values.address,
              ),
        building_id: buildingId,
        type,
      })

      if (updateVisitorEmailSetting.rejected.match(response)) {
        if (response.payload) {
          setErrors(response.payload, setError, errorToast)
        }
      } else {
        infoToast(
          t(
            "desktop.settings.visitors.email_settings.email_settings_updated_toast",
          ),
        )
        history.push("/settings/visitors/general")
      }
    },
    [actions, buildingId, type, setError, errorToast, infoToast, t, history],
  )

  const onDeleteClick = useCallback(async () => {
    const response = await actions.destroyVisitorEmailSetting({
      building_id: buildingId,
      type,
    })

    if (destroyVisitorEmailSetting.fulfilled.match(response)) {
      infoToast(
        t(
          "desktop.settings.visitors.email_settings.email_settings_deleted_toast",
        ),
      )
      history.push("/settings/visitors/general")
    } else {
      errorToast(response.error.message)
    }
  }, [actions, history, buildingId, type, errorToast, infoToast, t])

  const showLocation = watch("show_location")
  const additionalInfo = watch("additional_info")

  return (
    <div className="EmailSettingsForm">
      <FormProvider {...methods}>
        <PageForm
          updateMode={emailSettings?.id !== undefined}
          onCreate={onCreateClick}
          onUpdate={onUpdateClick}
          onDelete={onDeleteClick}
          backUrl="/settings/visitors/general"
        >
          <Field control={control} name="show_details">
            {(props) => (
              <Switch
                {...props}
                label={t(
                  "desktop.settings.visitors.email_settings.show_visit_details_and_host_name",
                )}
              />
            )}
          </Field>

          <hr />

          <Field control={control} name="show_location">
            {(props) => (
              <Switch
                {...props}
                label={t(
                  "desktop.settings.visitors.email_settings.show_location",
                )}
              />
            )}
          </Field>
          <Field
            control={control}
            name="address"
            label={t("desktop.settings.visitors.email_settings.address")}
          >
            {(props) => (
              <GooglePlacesSelect
                {...props}
                maxLength={30}
                disabled={!showLocation}
              />
            )}
          </Field>
          {type !== VisitorEmailType.WELCOME && (
            <Field control={control} name="directions_link">
              {(props) => (
                <Checkbox
                  {...props}
                  label={t(
                    "desktop.settings.visitors.email_settings.get_directions_link",
                  )}
                  disabled={!showLocation}
                  isSecondary
                />
              )}
            </Field>
          )}
          <hr />

          {type === VisitorEmailType.INVITATION && (
            <>
              <Field control={control} name="easy_check_in">
                {(props) => (
                  <>
                    <Switch
                      disabled={!emailSettings?.is_easy_check_in_available}
                      {...props}
                      label={t(
                        "desktop.settings.visitors.email_settings.easy_check_in",
                      )}
                    />
                    <div className="Info-subtext">
                      {emailSettings?.is_easy_check_in_available ? (
                        <>
                          <InfoSVG />
                          <span>
                            <Trans
                              i18nKey="desktop.settings.visitors.email_settings.easy_check_in_info_text"
                              components={{
                                a: (
                                  <a
                                    href={getLabel(
                                      "links.howToConfigureDevicesURL",
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {t(
                                      "desktop.settings.visitors.email_settings.knowledge_base_link_text",
                                    )}
                                  </a>
                                ),
                              }}
                              values={{
                                knowledge_base_link_text: t(
                                  "desktop.settings.visitors.email_settings.knowledge_base_link_text",
                                ),
                              }}
                            ></Trans>
                          </span>
                        </>
                      ) : (
                        <span>
                          <Trans i18nKey="desktop.settings.visitors.email_settings.easy_check_in_not_available">
                            <a href="/settings/visitors/devices"> </a>
                          </Trans>
                        </span>
                      )}
                    </div>
                  </>
                )}
              </Field>
              {/* <div className="subtext">
								<InfoSVG /> Placeholder text
							</div> */}
            </>
          )}
          {type === VisitorEmailType.WELCOME && (
            <Field control={control} name="attach_signed_documents">
              {(props) => (
                <Switch
                  {...props}
                  label={t(
                    "desktop.settings.visitors.email_settings.attach_signed_document",
                  )}
                />
              )}
            </Field>
          )}

          <hr />

          <Field control={control} name="additional_info">
            {(props) => (
              <Switch
                {...props}
                label={t(
                  "desktop.settings.visitors.email_settings.additional_info",
                )}
              />
            )}
          </Field>
          <Field control={control} name="additional_info_text">
            {(props) => <RichText {...props} disabled={!additionalInfo} />}
          </Field>
          <Button variant="secondary" onClick={handlePreviewEmail}>
            {t("desktop.settings.visitors.email_settings.preview")}
          </Button>
        </PageForm>
      </FormProvider>
    </div>
  )
}
export default EmailSettingsForm
