import { ThunkApiConfig } from "RootType"

import { assetsScheduleGetURL, get } from "../../api"
import { timeZone } from "../../dayjs"
import { ISODate, ObjectToUnion } from "../../types/sharedTypes"
import {
  checkInReservation,
  getErrorMessage,
  paginationInitialState,
  setFetchErrorState,
  setFetchSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import {
  AssetScheduleState,
  PaginatedOptions,
  PaginatedResponse,
} from "../types"
import { AssetScheduleEntry } from "./types"
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit"

import { FilterSpecialValues } from "../../components/Filter/types"

export const ASSET_SCHEDULE_STATUSES = {
  all: "all",
  reserved: "reserved",
  available: "available",
} as const

export type AssetScheduleStatus = ObjectToUnion<typeof ASSET_SCHEDULE_STATUSES>

export type FetchAssetScheduleProps = {
  asset_type?: string
  start: ISODate
  end: ISODate
  building_id?: string
  department_id?: string
  search?: string
  show?: AssetScheduleStatus
  limit?: number
} & PaginatedOptions

export const fetchAssetsSchedule = createAsyncThunk<
  PaginatedResponse<AssetScheduleEntry>,
  FetchAssetScheduleProps | undefined,
  ThunkApiConfig
>("assets/fetchAssetsSchedule", async (params, { getState }) => {
  const {
    auth: { access_token },
  } = getState()
  const { building_id, department_id, ...remainingParams } = params ?? {}

  const isAllDepartmentsFilter = department_id === FilterSpecialValues.ALL
  const isNoneDepartmentsFilter =
    department_id === FilterSpecialValues.NOT_ASSIGNED
  const isAllBuildingFilter = building_id === FilterSpecialValues.ALL

  const updatedParams = {
    ...remainingParams,
    tz: timeZone,
    building_id: isAllBuildingFilter ? undefined : building_id,
    department_id: isAllDepartmentsFilter
      ? undefined
      : isNoneDepartmentsFilter
        ? ""
        : department_id,
  }

  const response: Response = await get(
    assetsScheduleGetURL(updatedParams),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

const initialState: AssetScheduleState = {
  schedule: [],
  ...sliceInitialState,
  ...paginationInitialState,
}

/**
 * Asset Schedule slice
 */
const assetScheduleSlice: Slice<AssetScheduleState> = createSlice({
  name: "asset-schedule",
  initialState,
  reducers: {
    assetCheckIn(state, { payload }: PayloadAction<string>) {
      checkInReservation(state, payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssetsSchedule.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAssetsSchedule.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchAssetsSchedule.fulfilled, (state, action) => {
      const { payload } = action
      setFetchSuccessState(state)
      state.schedule = payload.results
      state.count = payload.count
      state.offset = action.meta.arg?.offset ?? 0
    })
  },
})

export const assetScheduleReducer = assetScheduleSlice.reducer

export const { assetCheckIn } = assetScheduleSlice.actions
