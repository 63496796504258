import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { BuildingResponse } from "../redux/buildings/types"

import BuildingDeleteForm from "../components/Form/BuildingDeleteForm"

type Props = {
  open: boolean
  building: BuildingResponse
}

export default function BuildingDeleteModal({ open, building }: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <BuildingDeleteForm building={building} />
    </ReactModal>
  )
}
