import React from "react"

import { Redirect, Switch as RouteSwitch } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import Branding from "./Branding"
import CompanyDetails from "./CompanyDetails"
import { ORGANIZATION_PATHS } from "./constants"
import DeleteCompany from "./DeleteCompany"
import DeleteCompanySurvey from "./DeleteCompanySurvey"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Organization = () => {
  const changeCompanyPermission = PERMISSIONS.companies.canChangeCompany
  const deleteCompanyPermission = PERMISSIONS.companies.canDeleteCompany

  return (
    <RouteSwitch>
      <ProtectedRoute
        exact
        path={ORGANIZATION_PATHS.branding}
        accessPermission={changeCompanyPermission}
        component={Branding}
      />
      <ProtectedRoute
        exact
        path={ORGANIZATION_PATHS.company_details}
        accessPermission={changeCompanyPermission}
        component={CompanyDetails}
      />
      <ProtectedRoute
        exact
        path={ORGANIZATION_PATHS.company_details_delete}
        accessPermission={deleteCompanyPermission}
        component={DeleteCompany}
      />
      <ProtectedRoute
        exact
        path={ORGANIZATION_PATHS.company_details_survey}
        component={DeleteCompanySurvey}
      />
      <ProtectedRoute
        exact
        path={ORGANIZATION_PATHS.root}
        accessPermission={changeCompanyPermission}
        render={() => <Redirect to={ORGANIZATION_PATHS.company_details} />}
      />
      <Redirect to={ORGANIZATION_PATHS.company_details} />
    </RouteSwitch>
  )
}

export default Organization
