import { MouseEvent } from "react"

import classNames from "classnames"

import AssetIcon, { AssetIconNames } from "../../../../components/AssetIcon"

import "./PickerItem.sass"

type PickerItemProps = {
  name: string
  description?: string
  disabled?: boolean
  iconName?: AssetIconNames
  onClick?: (e: MouseEvent) => void
}

const PickerItem = ({
  name,
  iconName,
  description,
  disabled,
  onClick,
}: PickerItemProps) => {
  const itemClasses = classNames({
    "mobile-picker-item": true,
    disabled,
  })

  return (
    <div
      className={itemClasses}
      onClick={(e: MouseEvent) => {
        if (!disabled && onClick) {
          onClick(e)
        }
      }}
    >
      <div className="mobile-picker-item-info">
        <div className="mobile-picker-item-info-name">{name}</div>
        {description && (
          <div className="mobile-picker-item-info-address">{description}</div>
        )}
      </div>
      {iconName && <AssetIcon name={iconName} />}
    </div>
  )
}

export default PickerItem
