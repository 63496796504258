import { api } from "../.."
import { subscriptionsEstimatesURL } from "../../../../api"
import { Estimate, EstimateRequest } from "./types"

export const estimate = api.injectEndpoints({
  endpoints: (builder) => ({
    calculateEstimate: builder.query<Estimate, EstimateRequest>({
      query: (body) => {
        const quantity =
          body.quantity !== undefined
            ? body.quantity > 0
              ? body.quantity
              : 1
            : undefined
        return {
          url: subscriptionsEstimatesURL(),
          method: "POST",
          body: { ...body, quantity },
        }
      },
      providesTags: ["Estimates"],
    }),
  }),
})

export const { useLazyCalculateEstimateQuery } = estimate
