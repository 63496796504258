import { api } from "../.."
import { devicesURL } from "../../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../../constants"
import { DevicesResponse } from "../../../devices/types"
import { PaginatedOptions } from "../../../types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
  stats: false,
}

export const visitorDevices = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchVisitorDevices: builder.query<
      DevicesResponse,
      PaginatedOptions | void
    >({
      query: (options = {}) => devicesURL({ ...FETCH_DEFAULTS, ...options }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "VisitorDevices" as const,
                id,
              })),
              { type: "VisitorDevices", id: "LIST" },
            ]
          : [{ type: "VisitorDevices", id: "LIST" }],
    }),
  }),
})

export const { useFetchVisitorDevicesQuery } = visitorDevices
