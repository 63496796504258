import { t } from "i18next"

import { OptionType, RecurringType } from "../../types/sharedTypes"

import timezones from "../../assets/Data/timezones.json"

/**
 * We're good guys so we're mapping some cities
 * if customers specifically request them.
 *
 * Example: https://futurevisions.atlassian.net/browse/JOAN-5394
 */
export const timezoneMapper = (timezone: string) => {
  switch (timezone) {
    case "Europe/Kiev":
      return "Europe/Kyiv"
    default:
      return timezone
  }
}

/**
 * This is calculated on page load and then simply reused.
 */
export const TIMEZONE_OPTIONS = timezones.map((timezone: string) => {
  return {
    value: timezone,
    label: timezoneMapper(timezone),
  }
})

export const getReservationLengthOptions = () => [
  { value: 7, label: t("general.reservation_length_options.1_week") },
  { value: 14, label: t("general.reservation_length_options.2_weeks") },
  { value: 21, label: t("general.reservation_length_options.3_weeks") },
  { value: 30, label: t("general.reservation_length_options.30_days") },
  { value: 60, label: t("general.reservation_length_options.60_days") },
  { value: 90, label: t("general.reservation_length_options.90_days") },
]

export const DISABLED_CHECKIN_PERIOD = 300

export const getCheckinPeriodOptions = () => [
  {
    value: DISABLED_CHECKIN_PERIOD,
    label: t("general.disabled_checkin_period_options.off"),
  },
  {
    value: 900,
    label: t("general.disabled_checkin_period_options.15_minutes"),
  },
  {
    value: 1800,
    label: t("general.disabled_checkin_period_options.30_minutes"),
  },
  {
    value: 3600,
    label: t("general.disabled_checkin_period_options.1_hour"),
  },

  {
    value: 7200,
    label: t("general.disabled_checkin_period_options.2_hours"),
  },
  {
    value: 10800,
    label: t("general.disabled_checkin_period_options.3_hours"),
  },
]

export const getHideReservationWindowLengthOptions = () => [
  {
    value: 0,
    label: t("general.hide_reservation_window_length_options.off"),
  },

  {
    value: 1,
    label: t("general.hide_reservation_window_length_options.1_day"),
  },
  {
    value: 7,
    label: t("general.hide_reservation_window_length_options.7_days"),
  },
  {
    value: 14,
    label: t("general.hide_reservation_window_length_options.14_days"),
  },
  {
    value: 30,
    label: t("general.hide_reservation_window_length_options.30_days"),
  },
  {
    value: 90,
    label: t("general.hide_reservation_window_length_options.90_days"),
  },
]

export const getScheduleOptions = (
  day: string,
  showWeekends: boolean = false,
): OptionType<string | null>[] => {
  const options = [
    { label: t("general.repeat.once"), value: null },
    ...(showWeekends
      ? [
          {
            label: t("general.repeat.every_day"),
            value: RecurringType.EVERY_DAY,
          },
        ]
      : []),
    {
      label: t("general.repeat.every_weekday"),
      value: RecurringType.EVERY_WEEKDAY,
    },
    {
      label: t("general.repeat.every_day_of_week", { day }),
      value: RecurringType.EVERY_DAY_OF_WEEK,
    },
  ]
  return options
}

export const createScheduleOption = (value?: string, day?: string) => {
  switch (value) {
    case RecurringType.EVERY_DAY:
      return {
        label: t("general.repeat.every_day"),
        value: RecurringType.EVERY_DAY,
      }
    case RecurringType.EVERY_WEEKDAY:
      return {
        label: t("general.repeat.every_weekday"),
        value: RecurringType.EVERY_WEEKDAY,
      }
    case RecurringType.EVERY_DAY_OF_WEEK:
      return {
        label: t("general.repeat.every_day_of_week", { day }),
        value: RecurringType.EVERY_DAY_OF_WEEK,
      }
    default:
      return { label: t("general.repeat.once"), value: null }
  }
}
