import React, { useCallback, useContext, useMemo } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { visitorRegistrationHostsURL } from "../../api"
import { FlowContext } from "../../providers/Tablet/RegistrationFlowProvider"
import { isBlank, isEmail, isNative } from "../../utils"

import { appError } from "../../redux/app/appSlice"
import { useAppSelector } from "../../redux/reducers"
import { selectTablet } from "../../redux/tablet/selectors"
import { SignInFieldType } from "../../redux/tablet_settings/types"
import { formatUser } from "../../redux/user/utils"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import {
  HostResponse,
  RegistrationUpdateRequest,
} from "../../redux/visitor_registration/types"
import { updateRegistration } from "../../redux/visitor_registration/visitorRegistrationSlice"

import AsyncSelect from "../../components/advanced/AsyncSelect"
import Button from "../../components/advanced/Button"
import { Input } from "../../components/basic/Input"
import Field from "../../components/Field"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorFooter from "../../components/Visitors/VisitorFooter"
import VisitorForm from "../../components/Visitors/VisitorForm"
import VisitorHeader from "../../components/Visitors/VisitorHeader"
import VisitorView from "../../components/Visitors/VisitorView"

type FormValues = {
  [id: string]: string
}

const Fields = () => {
  const { t } = useTranslation()

  const { nextStep, previousStep } = useContext(FlowContext)

  const { buildingId, id: tabletId } = useAppSelector(selectTablet)
  const { data, registration, isSubmitting } = useAppSelector(
    selectVisitorRegistration,
  )

  const actions = useActions({
    appError: (message: string) => appError(message),
    updateRegistration: (
      buildingId: string,
      tabletId: string,
      id: string,
      body: RegistrationUpdateRequest,
    ) => updateRegistration({ buildingId, tabletId, id, ...body }),
  })

  const fields = useMemo(() => (data ? [...data.signin_fields] : []), [data])

  const defaultValues = useMemo(() => {
    return fields
      .sort((a, b) => a.order - b.order)
      .reduce((obj, field) => {
        const exFieldVal = registration?.fields.find(
          (f) => f.id === field.id,
        )?.value
        return {
          ...obj,
          [field.id!]: exFieldVal,
        }
      }, {})
  }, [fields, registration?.fields])

  const exHost = useMemo(() => {
    const hostField = fields.find((f) => f.type === SignInFieldType.HOST)
    if (hostField) {
      return registration?.fields.find((f) => f.id === hostField.id)
    }
  }, [fields, registration?.fields])

  const methods = useForm<FormValues>({
    defaultValues,
  })
  const { control } = methods

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (buildingId && tabletId && registration) {
        const fields = Object.keys(values).map((id) => ({
          id,
          value:
            typeof values[id] === "string" ||
            values[id] === null ||
            values[id] === undefined
              ? values[id]
              : (values[id] as any).id,
        }))

        const response = await actions.updateRegistration(
          buildingId,
          tabletId,
          registration?.id,
          {
            fields,
          },
        )

        if (updateRegistration.fulfilled.match(response)) {
          nextStep(response.payload.id)
        } else {
          actions.appError(response.error.message ?? t("tablet.general_error"))
        }
      }
    },
    [t, actions, nextStep, buildingId, tabletId, registration],
  )

  const handleGoBack = () => previousStep()

  return (
    <VisitorView>
      <FormProvider {...methods}>
        <VisitorForm onSubmit={onSubmit}>
          <VisitorHeader
            showNavigation={!isNative()}
            title={t("tablet.visitors.screens.fields.title")}
          />
          <VisitorContent>
            <div className="fields">
              {fields
                .filter((f) => f.type !== SignInFieldType.NAME)
                .map((field) => (
                  <Field
                    key={field.id}
                    control={control}
                    name={field.id!}
                    required={field.required}
                    label={
                      field.type === SignInFieldType.CUSTOM
                        ? field.custom_field_name
                        : t(
                            `desktop.settings.visitors.device_settings.tablet.sign_in_fields.${field.type.toLocaleLowerCase()}.title` as ParseKeys,
                          )
                    }
                    rules={{
                      validate: (value) => {
                        if (
                          field.type === SignInFieldType.EMAIL &&
                          !isBlank(value) &&
                          !isEmail(value)
                        ) {
                          return t(
                            "desktop.settings.visitors.device_settings.tablet.sign_in_fields.email.invalid",
                          )
                        }
                        if (field.required && isBlank(value)) {
                          return t(
                            `desktop.settings.visitors.device_settings.tablet.sign_in_fields.${field.type.toLocaleLowerCase()}.required` as ParseKeys,
                          )
                        }
                      },
                    }}
                  >
                    {(props) =>
                      field.type === SignInFieldType.HOST ? (
                        <AsyncSelect
                          {...props}
                          urlGenerator={(fetchOptions) =>
                            visitorRegistrationHostsURL(
                              buildingId!,
                              tabletId!,
                              fetchOptions,
                            )
                          }
                          authenticate={false}
                          characterDelay={3}
                          nothingFoundMessage={t(
                            "tablet.visitors.screens.fields.no_host_found",
                          )}
                          getOptionLabel={(host: unknown) =>
                            host ? formatUser(host as HostResponse) : ""
                          }
                          getOptionValue={(host: unknown) =>
                            (host as HostResponse)?.id ?? ""
                          }
                          disabled={
                            field.type === SignInFieldType.HOST &&
                            exHost !== undefined &&
                            exHost.value !== undefined &&
                            exHost.value !== null &&
                            typeof exHost.value !== "string"
                          }
                        />
                      ) : (
                        <Input {...props} />
                      )
                    }
                  </Field>
                ))}
            </div>
          </VisitorContent>
          <VisitorFooter>
            <div>
              <Button isSubmit variant="submit" isLoading={isSubmitting}>
                {t("tablet.visitors.continue")}
              </Button>
            </div>
            {isNative() && (
              <div>
                <Button
                  variant="link"
                  onClick={handleGoBack}
                  isDisabled={isSubmitting}
                >
                  {t("general.redirection.go_back")}
                </Button>
              </div>
            )}
          </VisitorFooter>
        </VisitorForm>
      </FormProvider>
    </VisitorView>
  )
}

export default Fields
