import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  OFFICE_HOURS_OPTIONS,
  SETTINGS_GROUPS,
  SettingsGroups,
} from "../constants"
import FieldWithCheckbox from "../FieldWithCheckbox"
import { EffectiveSettingsLocation } from "../types"
import SettingsGroup from "./index"

import { Select } from "../../../../../components/basic/Select"
import Field from "../../../../../components/Field"
import { Label } from "../../../../../components/Field/Label"

type Props = {
  checked: boolean
  disabled: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
  isSwitchHidden?: boolean
  isDeviceSpecificEdit?: boolean
}

const OfficeHoursGroup = ({
  checked,
  disabled,
  onToggleSettingsGroup,
  effectiveSettingsLocation,
  onLocationClick,
  isSwitchHidden,
  isDeviceSpecificEdit,
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const isGroupTooltipVisible = isDeviceSpecificEdit
  const groupTooltipText = isDeviceSpecificEdit
    ? t("desktop.settings.rooms.device_settings.form.per_device_unavailable")
    : undefined

  const OFFICE_HOURS_FROM_OPTIONS = [
    {
      value: null,
      label: t(
        "desktop.settings.rooms.device_settings.form.office_hours.start",
      ),
    },
    ...OFFICE_HOURS_OPTIONS,
  ]

  const OFFICE_HOURS_UNTIL_OPTIONS = [
    {
      value: null,
      label: t("desktop.settings.rooms.device_settings.form.office_hours.end"),
    },
    ...OFFICE_HOURS_OPTIONS,
  ]

  return (
    <SettingsGroup
      label={t(
        "desktop.settings.rooms.device_settings.form.office_hours.title",
      )}
      checked={checked}
      onChange={(checked) =>
        onToggleSettingsGroup(SETTINGS_GROUPS.OFFICE_HOURS_GROUP, checked)
      }
      disabled={disabled}
      className="office-hours"
      effectiveSettingsLocation={effectiveSettingsLocation}
      onLocationClick={onLocationClick}
      isSwitchHidden={isSwitchHidden}
      id={SETTINGS_GROUPS.OFFICE_HOURS_GROUP}
      showTooltip={isGroupTooltipVisible}
      tooltipText={groupTooltipText}
    >
      <div className="FieldWrapper Subtext">
        {t("desktop.settings.rooms.device_settings.form.office_hours.intro")}
      </div>
      <div className="FieldWrapper">
        <Label>
          {t(
            "desktop.settings.rooms.device_settings.form.office_hours.subtitle",
          )}
        </Label>

        <div className="Row">
          <FieldWithCheckbox
            name="office_hours.sleep_day_mon"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.mon_label",
            )}
            disabled={disabled}
            isSecondary
          />

          <FieldWithCheckbox
            name="office_hours.sleep_day_tue"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.tue_label",
            )}
            disabled={disabled}
            isSecondary
          />

          <FieldWithCheckbox
            name="office_hours.sleep_day_wed"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.wed_label",
            )}
            disabled={disabled}
            isSecondary
          />

          <FieldWithCheckbox
            name="office_hours.sleep_day_thu"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.thu_label",
            )}
            disabled={disabled}
            isSecondary
          />

          <FieldWithCheckbox
            name="office_hours.sleep_day_fri"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.fri_label",
            )}
            disabled={disabled}
            isSecondary
          />

          <FieldWithCheckbox
            name="office_hours.sleep_day_sat"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.sat_label",
            )}
            disabled={disabled}
            isSecondary
          />

          <FieldWithCheckbox
            name="office_hours.sleep_day_sun"
            className="horizontal-field"
            label={t(
              "desktop.settings.rooms.device_settings.form.office_hours.sun_label",
            )}
            disabled={disabled}
            isSecondary
          />
        </div>
      </div>

      <Field
        control={control}
        name="office_hours.start_hour"
        label={t(
          "desktop.settings.rooms.device_settings.form.office_hours.from_label",
        )}
        className="field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={OFFICE_HOURS_FROM_OPTIONS}
            disabled={disabled}
          />
        )}
      </Field>

      <Field
        control={control}
        name="office_hours.stop_hour"
        label={t(
          "desktop.settings.rooms.device_settings.form.office_hours.until_label",
        )}
        className="field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={OFFICE_HOURS_UNTIL_OPTIONS}
            disabled={disabled}
          />
        )}
      </Field>
    </SettingsGroup>
  )
}

export default OfficeHoursGroup
