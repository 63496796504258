import cn from "classnames"

import { FETCH_FOR_COUNT, FETCH_UNREAD } from "../../../constants"

import { useFetchNotificationsQuery } from "../../../redux/api/notifications"

import BellSVG from "../../../assets/images/icons/Bell.svg"
import BellRingSVG from "../../../assets/images/icons/BellRing.svg"

import "./style.sass"

const NOTIFICATIONS_POLLING_INTERVAL_MS = 10000

type Props = {
  action: () => void
}

const NotificationBell = ({ action }: Props) => {
  const { data: { count: notificationCount = 0 } = {} } =
    useFetchNotificationsQuery(
      { ...FETCH_FOR_COUNT, ...FETCH_UNREAD },
      {
        pollingInterval: NOTIFICATIONS_POLLING_INTERVAL_MS,
        refetchOnMountOrArgChange: true,
      },
    )

  const hasNotifications = notificationCount > 0

  const formattedNotificationCount =
    notificationCount <= 99 ? notificationCount : "99+"

  const handleClick = () => {
    action()
  }

  return (
    <div
      className={cn("NotificationBell", {
        HasNotifications: hasNotifications,
      })}
      onClick={handleClick}
    >
      {hasNotifications ? (
        <>
          <BellRingSVG />
          <div className="NotificationBellCount">
            {formattedNotificationCount}
          </div>
        </>
      ) : (
        <BellSVG />
      )}
    </div>
  )
}

export default NotificationBell
