import { Children } from "../../../../types/sharedTypes"

import { VISITOR_EVENTS } from "../../../../redux/api/notifications/types"

import BadgeOutlineSVG from "../../../../assets/images/icons/BadgeOutline.svg"

/**
 * Once we start supporting more types, we include them here and also filter them in the NotificationList component (not implemented yet)
 */
export const SUPPORTED_TYPES = [
  VISITOR_EVENTS.INVITE_CREATED_HOST,
  VISITOR_EVENTS.INVITE_UPDATED_HOST,
  VISITOR_EVENTS.INVITE_CHECKED_IN_HOST,
  VISITOR_EVENTS.INVITE_CANCELLED_HOST,
]

export const EVENT_ICON: {
  [key: string]: Children
} = {
  [VISITOR_EVENTS.INVITE_CREATED_HOST]: <BadgeOutlineSVG />,
  [VISITOR_EVENTS.INVITE_CHECKED_IN_HOST]: <BadgeOutlineSVG />,
  [VISITOR_EVENTS.INVITE_UPDATED_HOST]: <BadgeOutlineSVG />,
  [VISITOR_EVENTS.INVITE_CANCELLED_HOST]: <BadgeOutlineSVG />,
}
