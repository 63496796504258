import { Settings as CompanySettings } from "../../settings/types"

export const TIME_FORMAT_OPTIONS = [
  { value: "1", label: "24-h" },
  { value: "2", label: "AM/PM" },
]

export interface Settings {
  settings: CompanySettings
  settings_effective: CompanySettings
}

export interface CompanyDetails {
  name: string | null
  country: string | null
  industry: string | null
  employees: string | null
  address: string | null
  meeting_room_count: string | null
  daily_visitors: string | null
  tools: string[] | null
  longitude: number | null
  latitude: number | null
  settings: CompanySettings
  settings_effective: CompanySettings
  phone: string | null
}

export interface OfficeHours {
  start_hour: string | null
  stop_hour: string | null
  sleep_day_mon: boolean
  sleep_day_tue: boolean
  sleep_day_wed: boolean
  sleep_day_thu: boolean
  sleep_day_fri: boolean
  sleep_day_sat: boolean
  sleep_day_sun: boolean
}

interface Timeslot {
  id: string
  name: string
  type: string
  active: boolean
  is_default: boolean
  from: string
  to: string
}

interface Address {
  street: string
  number: number
  postal_code: string
  city: string
  addition: null | string
}

interface Seat {
  id: string
  name: string
  type: string
  coord_x: number
  coord_y: number
  departments: any[]
  active: boolean
  room_id: null
  timeslots: any[]
}

interface Floor {
  id: string
  name: string
  floor_id: string
  floor: string
  visible: boolean
  image: string
  width: number
  height: number
  seats: Seat[]
  capacity_limit: null
  seat_limit: number
  rooms: any[]
}

interface Location {
  id: string
  name: string
  visible: boolean
  rules: string
  proximity_radius: number
  seat_limit: number
  capacity_limit: null
  address: Address
  parking_count: number
  maps: Floor[]
}

export interface Company {
  id: string
  name: string
  timeslots: Timeslot[]
  locations: Location[]
  force_geofence: boolean
  check_in_enabled: boolean
  mandatory_check_in: boolean
  reservation_window_length: number
  force_timeslot_use: boolean
  send_notifications_at_change: boolean
  checkin_period: number
  lunch_enabled: boolean
  visitors_enabled: boolean
  department_rules_enabled: boolean
}

export interface CompanyRequest {
  name?: string | null
  country?: string | null
  industry?: string | null
  employees?: string | null
  address?: string | null
  meeting_room_count?: string | null
  daily_visitors?: string | null
  tools?: string[] | null
  longitude?: number | null
  latitude?: number | null
  settings?: Partial<CompanySettings>
  phone?: string | null
}
