import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { useToast } from "../../../../hooks/useToast"
import { OptionType } from "../../../../types/sharedTypes"

import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"
import { updateSettings } from "../../../../redux/settings/settingsSlice"
import { SettingsRequest } from "../../../../redux/settings/types"
import { selectUserGroups } from "../../../../redux/user/selectors"
import { isNormalUser } from "../../../../redux/user/utils"
import { useActions } from "../../../../redux/utils"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import { Checkbox } from "../../../../components/basic/Checkbox"
import { Select } from "../../../../components/basic/Select"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import {
  DISABLED_CHECKIN_PERIOD,
  getCheckinPeriodOptions,
  getHideReservationWindowLengthOptions,
  getReservationLengthOptions,
  TIMEZONE_OPTIONS,
} from "../../../../components/Form/options"
import PageForm from "../../../../components/Form/PageFormHook"

import "./DeskSettingsForm.sass"

const FORM_MAPPING = {
  "settings.timezone": "timezone",
  "settings.desk_check_in_enabled": "desk_check_in_enabled",
  "settings.desk_checkin_period": "desk_checkin_period",
  "settings.desk_reservation_window_length": "desk_reservation_window_length",
  "settings.desk_send_notifications_at_change":
    "desk_send_notifications_at_change",
  "settings.desk_department_rules_enabled": "desk_department_rules_enabled",
  "settings.desk_one_reservation_per_user": "desk_one_reservation_per_user",
  "settings.desk_check_in_location": "desk_check_in_location",
  "settings.privacy_mode_enabled": "privacy_mode_disabled",
} as const

type FormValues = {
  timezone: OptionType<string>
  desk_check_in_enabled: boolean
  desk_checkin_period: OptionType<number>
  desk_hide_reservations: boolean
  desk_hide_reservations_window_length: OptionType<number>
  desk_mandatory_check_in: boolean
  desk_reservation_window_length: OptionType<number>
  desk_send_notifications_at_change: boolean
  desk_department_rules_enabled: boolean
  desk_one_reservation_per_user: boolean
  desk_check_in_location: boolean
  privacy_mode_disabled: boolean
}

const DeskSettingsForm = () => {
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()

  const groups = useAppSelector(selectUserGroups)
  const { entry } = useAppSelector(selectSettingsEffective)
  const {
    timezone,
    desk_check_in_enabled: check_in_enabled,
    desk_checkin_period: checkin_period,
    desk_hide_reservations: hide_reservations,
    desk_hide_reservations_window_length: hide_reservations_window_length,
    desk_reservation_window_length: reservation_window_length,
    desk_send_notifications_at_change: send_notifications_at_change,
    desk_department_rules_enabled: department_rules_enabled,
    desk_one_reservation_per_user: one_reservation_per_user,
    desk_check_in_location,
    privacy_mode_enabled,
  } = entry ?? {}

  const actions = useActions({
    updateSettings: (payload: SettingsRequest) => updateSettings(payload),
  })

  const hideReservationWindowLengthOptions =
    getHideReservationWindowLengthOptions()
  const reservationLengthOptions = getReservationLengthOptions()
  const checkinPeriodOptions = getCheckinPeriodOptions()

  const methods = useForm<FormValues>({
    defaultValues: {
      timezone: { value: timezone ?? "", label: timezone ?? "" },
      desk_check_in_enabled: check_in_enabled ?? false,
      desk_checkin_period:
        checkinPeriodOptions.find((o) => o.value === checkin_period) ??
        checkinPeriodOptions[0],
      desk_hide_reservations: hide_reservations ?? false,
      desk_hide_reservations_window_length:
        hideReservationWindowLengthOptions.find(
          (o) => o.value === hide_reservations_window_length,
        ) ?? hideReservationWindowLengthOptions[0],
      desk_mandatory_check_in:
        checkin_period === DISABLED_CHECKIN_PERIOD ? false : true,
      desk_reservation_window_length:
        reservationLengthOptions.find(
          (o) => o.value === reservation_window_length,
        ) ?? reservationLengthOptions[0],
      desk_send_notifications_at_change: send_notifications_at_change ?? false,
      desk_department_rules_enabled: department_rules_enabled ?? false,
      desk_one_reservation_per_user: one_reservation_per_user ?? false,
      desk_check_in_location: desk_check_in_location === "on_spot",
      privacy_mode_disabled: !privacy_mode_enabled,
    },
  })

  const {
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const onSaveClick = async ({
    timezone,
    desk_check_in_enabled,
    desk_checkin_period,
    desk_hide_reservations_window_length,
    desk_reservation_window_length,
    desk_send_notifications_at_change,
    desk_department_rules_enabled,
    desk_one_reservation_per_user,
    desk_check_in_location,
    privacy_mode_disabled,
  }: FormValues) => {
    const response = await actions.updateSettings({
      settings: {
        timezone: timezone.value,
        desk_check_in_enabled,
        desk_checkin_period: desk_checkin_period.value,
        desk_hide_reservations:
          desk_hide_reservations_window_length.value !== 0,
        desk_hide_reservations_window_length:
          desk_hide_reservations_window_length.value !== 0
            ? desk_hide_reservations_window_length.value
            : null,
        desk_mandatory_check_in:
          desk_checkin_period.value === DISABLED_CHECKIN_PERIOD ? false : true,
        desk_reservation_window_length: desk_reservation_window_length.value,
        desk_send_notifications_at_change,
        desk_department_rules_enabled,
        desk_one_reservation_per_user,
        desk_check_in_location: desk_check_in_location ? "on_spot" : "any",
        privacy_mode_enabled: !privacy_mode_disabled,
      },
    })

    if (updateSettings.rejected.match(response)) {
      if (response.payload) {
        setErrors(response.payload, setError, errorToast, FORM_MAPPING)
      }
    } else {
      infoToast(
        t("desktop.settings.booking_policies.form.desk_setting_updated_toast"),
      )
    }
  }

  const canEdit = !isNormalUser({ groups })
  const isDisabled = !canEdit || isSubmitting

  return (
    <FormProvider {...methods}>
      <PageForm<FormValues>
        className="DeskSettingsForm"
        updateMode
        onUpdate={onSaveClick}
        disabled={!canEdit}
      >
        <Field
          control={control}
          name="timezone"
          label={t("desktop.settings.booking_policies.form.timezone")}
          className="horizontal-field"
        >
          {(props) => (
            <Select
              {...props}
              options={TIMEZONE_OPTIONS}
              disabled={isDisabled}
              getOptionValue={(t) => `${t.value}`}
            />
          )}
        </Field>
        <Field
          control={control}
          name="desk_reservation_window_length"
          label={t(
            "desktop.settings.booking_policies.form.allow_booking_for_up_to",
          )}
          className="horizontal-field"
        >
          {(props) => (
            <Select
              {...props}
              options={reservationLengthOptions}
              disabled={isDisabled}
              getOptionValue={(t) => `${t.value}`}
            />
          )}
        </Field>
        <Field control={control} name="desk_check_in_enabled">
          {(props) => (
            <Checkbox
              {...props}
              disabled={isDisabled}
              label={t(
                "desktop.settings.booking_policies.form.enable_checking",
              )}
              description={t(
                "desktop.settings.booking_policies.form.enable_checking_description",
              )}
              isSecondary
            />
          )}
        </Field>
        <Field
          control={control}
          name="desk_checkin_period"
          label={t(
            "desktop.settings.booking_policies.form.release_desk_if_no_checkin",
          )}
          className="horizontal-field"
        >
          {(props) => (
            <Select
              {...props}
              options={checkinPeriodOptions}
              disabled={isDisabled}
              getOptionValue={(t) => `${t.value}`}
            />
          )}
        </Field>
        <Field
          control={control}
          name="desk_hide_reservations_window_length"
          label={t("desktop.settings.booking_policies.form.hide_reservations")}
          className="horizontal-field"
        >
          {(props) => (
            <Select
              {...props}
              options={hideReservationWindowLengthOptions}
              disabled={isDisabled}
              getOptionValue={(t) => `${t.value}`}
            />
          )}
        </Field>
        <Field control={control} name="desk_send_notifications_at_change">
          {(props) => (
            <Checkbox
              {...props}
              disabled={isDisabled}
              label={t(
                "desktop.settings.booking_policies.form.send_notification",
              )}
              description={t(
                "desktop.settings.booking_policies.form.send_notification_description",
              )}
              isSecondary
            />
          )}
        </Field>
        <Field control={control} name="desk_department_rules_enabled">
          {(props) => (
            <Checkbox
              {...props}
              disabled={isDisabled}
              label={t(
                "desktop.settings.booking_policies.form.enable_departmental_booking_rules",
              )}
              description={
                <Trans i18nKey="desktop.settings.booking_policies.form.enable_departmental_booking_rules_description">
                  <RedirectLink to="departments">placeholder</RedirectLink>
                </Trans>
              }
              isSecondary
            />
          )}
        </Field>
        <Field control={control} name="desk_one_reservation_per_user">
          {(props) => (
            <Checkbox
              {...props}
              disabled={isDisabled}
              label={t(
                "desktop.settings.booking_policies.form.limit_concurrent_reservations",
              )}
              description={t(
                "desktop.settings.booking_policies.form.limit_concurrent_reservations_description",
              )}
              isSecondary
            />
          )}
        </Field>

        <Field control={control} name="desk_check_in_location">
          {(props) => (
            <Checkbox
              {...props}
              disabled={isDisabled}
              label={t(
                "desktop.settings.booking_policies.form.local_check_in_only",
              )}
              description={
                <Trans
                  i18nKey="desktop.settings.booking_policies.form.local_check_in_only_description"
                  components={{
                    redirect: <RedirectLink to="qr-code-check-in" />,
                  }}
                />
              }
              isSecondary
            />
          )}
        </Field>

        <Field control={control} name="privacy_mode_disabled">
          {(props) => (
            <Checkbox
              {...props}
              disabled={isDisabled}
              label={t(
                "desktop.settings.booking_policies.form.privacy_mode_disabled",
              )}
              description={t(
                "desktop.settings.booking_policies.form.privacy_mode_disabled_description",
              )}
              isSecondary
            />
          )}
        </Field>
      </PageForm>
    </FormProvider>
  )
}

export default DeskSettingsForm
