import { ThunkApiConfig } from "RootType"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import {
  assetReservationCheckin,
  assetReservationCheckout,
  assetReservationsExportURL,
  assetReservationURL,
  deleteJSON,
  get,
  postJSON,
  putJSON,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import { timeZone } from "../../dayjs"
import { assetCheckIn } from "../asset_schedule/assetScheduleSlice"
import {
  getErrorMessage,
  getErrorObject,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { DeleteType } from "../reservations/types"
import { SliceState } from "../types"
import {
  AssetReservation,
  CreateAssetReservation,
  isRecurringAssetReservation,
  RecurringAssetReservationResponse,
} from "./types"
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export type AssetReservationHash = {
  [id: string]: AssetReservation
}

export const createAssetReservation = createAsyncThunk<
  AssetReservation | RecurringAssetReservationResponse,
  CreateAssetReservation,
  ThunkApiConfig<ResponseError>
>(
  "asset-schedule/createAssetReservation",
  async (body, { getState, rejectWithValue }) => {
    const {
      auth: { access_token },
      user: { entry: user },
    } = getState()

    const response: Response = await postJSON(
      assetReservationURL({}),
      { body },
      access_token,
    )

    if (response.ok) {
      const reservation = await response.json()

      if (body.user_email === user.email) {
        analyticsEvent(SupportedEvents.ASSET_BOOKED, {
          id: body.asset_id,
          name: reservation?.asset?.name ?? "",
        })
      } else {
        analyticsEvent(SupportedEvents.ASSET_BOOKED_FOR_OTHERS, {
          id: body.asset_id,
          name: reservation?.asset?.name ?? "",
        })
      }

      return reservation
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

export const updateAssetReservation = createAsyncThunk<
  AssetReservation,
  { reservationId: string; payload: Partial<CreateAssetReservation> },
  ThunkApiConfig<ResponseError>
>(
  "asset-schedule/updateAssetReservation",
  async ({ reservationId, payload }, { getState, rejectWithValue }) => {
    const {
      auth: { access_token },
    } = getState()

    const response: Response = await putJSON(
      assetReservationURL({ id: reservationId }),
      { body: payload },
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

export const fetchAssetReservation = createAsyncThunk<
  AssetReservation,
  string,
  ThunkApiConfig
>(
  "asset-schedule/fetchAssetReservation",
  async (assetReservationId, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response: Response = await get(
      assetReservationURL({ id: assetReservationId }),
      {},
      access_token,
    )

    if (response.ok) {
      return await response.json()
    }

    throw new Error(await getErrorMessage(response))
  },
)

export const deleteAssetReservation = createAsyncThunk<
  void,
  { reservationId: string; type?: DeleteType },
  ThunkApiConfig
>(
  "asset-schedule/deleteAssetReservation",
  async ({ reservationId, type }, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response: Response = await deleteJSON(
      assetReservationURL({ id: reservationId, type }),
      { body: {} },
      access_token,
    )

    if (response.ok) {
      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

export const checkinAssetReservation = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  "asset-schedule/checkinAssetReservation",
  async (assetReservationId, { getState, dispatch }) => {
    const {
      auth: { access_token },
    } = getState()

    const response: Response = await postJSON(
      assetReservationCheckin(assetReservationId),
      { body: {} },
      access_token,
    )

    if (response.ok) {
      dispatch(assetCheckIn(assetReservationId))

      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

export const checkoutAssetReservation = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  "asset-schedule/checkoutAssetReservation",
  async (assetReservationId, { getState, dispatch }) => {
    const {
      auth: { access_token },
    } = getState()

    const response: Response = await postJSON(
      assetReservationCheckout(assetReservationId),
      { body: {} },
      access_token,
    )

    if (response.ok) {
      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

export type AssetReservationsExportProps = {
  start: string
  end: string
  assetTypeId?: string
  departmentId?: string
}

export const fetchAssetReservationsExport = createAsyncThunk<
  string,
  AssetReservationsExportProps,
  ThunkApiConfig
>(
  "asset-schedule/fetchAssetReservationsExport",
  async (
    { start, end, assetTypeId: asset_type, departmentId: department },
    { getState },
  ) => {
    const {
      auth: { access_token },
    } = getState()

    const response: Response = await get(
      assetReservationsExportURL({
        start: start,
        end: end,
        asset_type,
        department,
        tz: timeZone,
      }),
      {},
      access_token,
    )

    if (response.ok) {
      const csv = await response.text()
      return csv
    }

    throw new Error(await getErrorMessage(response))
  },
)

/**
 *  Slice
 */
export interface AssetReservationState extends SliceState {
  reservations: AssetReservationHash
}

const initialState: AssetReservationState = {
  reservations: {},
  ...sliceInitialState,
}

const assetReservationSlice: Slice<AssetReservationState> = createSlice({
  name: "asset-schedule",
  initialState,
  reducers: {
    clearAssetReservationErrorState: (state) => {
      state.error = null
      state.submitError = null
    },
    clearAssetReservationState: (state) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssetReservation.pending, (state) => {
      state.error = null
      state.isLoading = true
    })
    builder.addCase(fetchAssetReservation.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchAssetReservation.fulfilled, (state, action) => {
      const { payload } = action
      setFetchSuccessState(state)
      state.reservations[payload.id] = payload
    })

    builder.addCase(createAssetReservation.pending, (state, _action) => {
      state.submitError = null
      state.isSubmitting = true
    })
    builder.addCase(createAssetReservation.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(createAssetReservation.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      if (!isRecurringAssetReservation(payload)) {
        state.reservations[payload.id] = payload
      }
    })

    builder.addCase(updateAssetReservation.pending, (state, _action) => {
      state.isSubmitting = true
      state.submitError = null
    })
    builder.addCase(updateAssetReservation.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(updateAssetReservation.fulfilled, (state, action) => {
      const { payload } = action
      setSubmitSuccessState(state)
      state.reservations[payload.id] = payload
    })

    builder.addCase(deleteAssetReservation.pending, (state, _action) => {
      state.isSubmitting = true
      state.submitError = null
    })
    builder.addCase(deleteAssetReservation.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(deleteAssetReservation.fulfilled, (state, action) => {
      setSubmitSuccessState(state)
    })

    builder.addCase(checkinAssetReservation.pending, (state, _action) => {
      state.isSubmitting = true
      state.submitError = null
    })
    builder.addCase(checkinAssetReservation.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(checkinAssetReservation.fulfilled, (state, action) => {
      setSubmitSuccessState(state)
    })
  },
})

export const assetReservationReducer = assetReservationSlice.reducer
export const { clearAssetReservationErrorState, clearAssetReservationState } =
  assetReservationSlice.actions
