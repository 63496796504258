import React from "react"

import { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"

import Button from "../../../components/advanced/Button"
import Datestring from "../../../components/Datestring"
import Heading from "../../../components/Heading"
import { WeekNav } from "../../../components/Manage/CalNav"
import CSVReportDownload from "../../../components/Manage/CSVReportDownload"
import WeekendToggle from "../../../components/Manage/WeekendToggle"

type Props = {
  fromDate: Dayjs
  toDate: Dayjs
  weekEnd: Dayjs
  showWeekends: boolean
  showExportButton: boolean
  toggleWeekends: () => void
  onNewReservation: () => void
}
const Header = ({
  fromDate,
  toDate,
  weekEnd,
  showWeekends,
  toggleWeekends,
  onNewReservation,
  showExportButton,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Heading>
      <WeekNav />
      <span className="date-range">
        <Datestring date={fromDate} isInline isLong /> -{" "}
        <Datestring date={weekEnd} isInline isLong />
      </span>
      <WeekendToggle showWeekends={showWeekends} onToggle={toggleWeekends} />
      <div className="spacer" />
      {showExportButton && <CSVReportDownload start={fromDate} end={toDate} />}

      <Button className="NewReservation" onClick={onNewReservation} isSmall>
        {t("desktop.manage.desk_booking.reservation_button")}
      </Button>
    </Heading>
  )
}

export default Header
