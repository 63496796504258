import React from "react"

import classNames from "classnames"

import "./CategoryRow.sass"

type CategoryRowProps = {
  name: string
}

export const CategoryRow: React.FC<
  React.PropsWithChildren<CategoryRowProps>
> = ({ name, children }) => {
  const categoryRowName = classNames({
    CategoryRow: true,
  })

  return (
    <div className={categoryRowName}>
      <div className="label-row">
        <div className="label">{name}</div>
      </div>
      <div className="content-row">
        <div className="content">{children}</div>
      </div>
    </div>
  )
}
