import classNames from "classnames"

import { Children, ObjectToUnion } from "../../../types/sharedTypes"

import "./style.sass"

export const STATUSES = {
  VALID: "valid",
  INVALID: "invalid",
  WARNING: "warning",
} as const

type StatusProps = {
  variant?: "small" | "big"
  status?: ObjectToUnion<typeof STATUSES>
  children: Children
  className?: string
}
const Status = ({
  variant = "small",
  status = "valid",
  children,
  className,
}: StatusProps) => {
  const statusClass = classNames("Status", variant, status, className)

  return <span className={statusClass}>{children}</span>
}

export default Status
