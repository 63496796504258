import { selectFeatureFlags } from "../redux/feature_flags/selectors"
import { useAppSelector } from "../redux/reducers"

export const useCheckForFeatureFlag = (flagToFind?: string) => {
  const { entry: featureFlags } = useAppSelector(selectFeatureFlags)

  // If flag is not specified, return as if it was enabled.
  // This is purely for convenience, so that we do not have to
  // do falsy checks when calling the function.
  if (!flagToFind) {
    return true
  }

  return !!featureFlags.find((featureFlag) => flagToFind === featureFlag.name)
}
