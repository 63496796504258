import { useEffect, useMemo, useRef } from "react"

import debounce from "lodash.debounce"

type SomeFunction = (...args: any[]) => void

/**
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */

export const useDebounce = <Func extends SomeFunction>(
  callback: Func,
  delay: number = 1000,
) => {
  const ref = useRef<Func | null>(null)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, delay)
  }, [])

  return debouncedCallback
}
