import { ThunkApiConfig } from "RootType"

import { readLocalState, writeLocal } from "./utils"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export const storeConfiguration = createAsyncThunk<
  void,
  Record<string, string>,
  ThunkApiConfig
>("visitorLogin/storeConfiguration", async (params) => {
  let errors = 0

  Object.keys(params).forEach((key) => {
    const result = writeLocal(key, params[key])
    if (result === null) {
      errors += 1
    }
  })

  if (errors === 0) {
    return
  } else {
    throw new Error("Store configuration error")
  }
})

/**
 *  Slice
 */
export interface VisitorLoginState {
  requireHealthScreen: boolean
  companyName: string
  disableRequestMeeting: boolean
}

const initialState: VisitorLoginState = readLocalState()

const visitorLoginSlice = createSlice({
  name: "visitorLogin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(storeConfiguration.fulfilled, () => {
      return readLocalState()
    })
  },
})

export const visitorLoginReducer = visitorLoginSlice.reducer
