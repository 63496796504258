import { ThunkApiConfig } from "RootType"

import { get, peopleScheduleGetURL } from "../../api"
import { timeZone } from "../../dayjs"
import { ISODate } from "../../types/sharedTypes"
import {
  checkInReservation,
  getErrorMessage,
  paginationInitialState,
  setFetchErrorState,
  setFetchSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import {
  PaginatedOptions,
  PaginatedResponseWithAggregationAndFacets,
  PeopleScheduleState,
} from "../types"
import {
  PeopleScheduleEntry,
  PeopleScheduleFacetQuery,
  PeopleScheduleFacets,
} from "./types"
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit"

import { FilterSpecialValues } from "../../components/Filter/types"

export type PeopleScheduleStatus = "reserved" | "available" | "all"

export type FetchPeopleScheduleProps = {
  start: ISODate
  end: ISODate
  show?: string
  department_id?: string
  search?: string
  facets?: PeopleScheduleFacetQuery[]
} & PaginatedOptions

export const fetchPeopleSchedule = createAsyncThunk<
  PaginatedResponseWithAggregationAndFacets<
    PeopleScheduleEntry,
    PeopleScheduleFacets
  >,
  FetchPeopleScheduleProps | undefined,
  ThunkApiConfig
>("peoples/fetchPeoplesSchedule", async (params, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const isAllDepartmentsFilter =
    params?.department_id === FilterSpecialValues.ALL

  const isNoneDepartmentsFilter =
    params?.department_id === FilterSpecialValues.NOT_ASSIGNED
  const facets = params?.facets?.join(",")

  const response: Response = await get(
    peopleScheduleGetURL({
      ...params,
      ...(params?.show && { show: params?.show.toLowerCase() }),
      facets,
      department_id: isAllDepartmentsFilter
        ? undefined
        : isNoneDepartmentsFilter
          ? ""
          : params?.department_id,
      tz: timeZone,
    }),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

const initialState: PeopleScheduleState = {
  schedule: [],
  aggregation: {},
  ...sliceInitialState,
  ...paginationInitialState,
  facets: {},
}

const peopleScheduleSlice: Slice<PeopleScheduleState> = createSlice({
  name: "people-schedule",
  initialState,
  reducers: {
    peopleCheckIn(state, { payload }: PayloadAction<string>) {
      checkInReservation(state, payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPeopleSchedule.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPeopleSchedule.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(
      fetchPeopleSchedule.fulfilled,
      (state, { payload, meta }) => {
        setFetchSuccessState(state)
        state.schedule = payload.results
        state.count = payload.count
        state.offset = meta.arg?.offset ?? 0

        state.aggregation = payload.aggr?.reduce(
          (obj, aggr) => ({ ...obj, ...aggr }),
          {},
        )
        state.facets = payload.facets
      },
    )
  },
})

export const peopleScheduleReducer = peopleScheduleSlice.reducer

export const { peopleCheckIn } = peopleScheduleSlice.actions
