import React from "react"

import { Redirect, Switch } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import SettingsBuildings from "./Buildings"
import { BUILDINGS_PATHS } from "./constants"
import EditBuildingFloors from "./EditBuildingFloors"
import EditBuildings from "./EditBuildings"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Buildings = () => {
  const editBuildingPermission = PERMISSIONS.buildings.canChangeBuilding
  const editFloorPermission = PERMISSIONS.floorPlans.canChangeFloor

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={BUILDINGS_PATHS.overview}
        accessPermission={editBuildingPermission}
        component={SettingsBuildings}
      />
      <ProtectedRoute
        exact
        path={BUILDINGS_PATHS.add}
        accessPermission={editBuildingPermission}
        component={EditBuildings}
      />
      <ProtectedRoute
        exact
        path={BUILDINGS_PATHS.edit}
        accessPermission={editBuildingPermission}
        component={EditBuildings}
      />
      <ProtectedRoute
        exact
        path={BUILDINGS_PATHS.add_floor}
        accessPermission={editFloorPermission}
        component={EditBuildingFloors}
      />

      <ProtectedRoute
        exact
        path={BUILDINGS_PATHS.edit_floor}
        accessPermission={editFloorPermission}
        component={EditBuildingFloors}
      />
      <Redirect to={BUILDINGS_PATHS.overview} />
    </Switch>
  )
}

export default Buildings
