import React from "react"

import classNames from "classnames"
import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { useId } from "../../../../../hooks/useId"
import { Children } from "../../../../../types/sharedTypes"
import { EffectiveSettingsLocation } from "../types"

import Button from "../../../../../components/advanced/Button"
import Switch from "../../../../../components/basic/Switch"
import { Tooltip } from "../../../../../components/basic/Tooltip"

import "./styles.sass"

type Props = {
  label: string
  checked: boolean
  onChange: (v: boolean) => void
  disabled?: boolean
  className?: string
  children: Children
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
  isSwitchHidden?: boolean
  id: string
  showTooltip?: boolean
  tooltipText?: string
}
const SettingsGroup = ({
  label,
  checked,
  onChange,
  disabled,
  className,
  children,
  effectiveSettingsLocation,
  onLocationClick,
  isSwitchHidden = false,
  id,
  showTooltip,
  tooltipText,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="SettingsGroup FieldWrapper" id={id}>
        {isSwitchHidden ? (
          <div className="SettingsGroup__title">{label}</div>
        ) : (
          <Switch
            label={label}
            value={checked}
            onChange={onChange}
            disabled={disabled}
            showTooltip={showTooltip}
            tooltipText={tooltipText}
          />
        )}
        {!checked && effectiveSettingsLocation && (
          <Button
            variant="link"
            onClick={() => onLocationClick?.(effectiveSettingsLocation)}
          >
            {t(
              `desktop.settings.rooms.devices.form.settings_statuses.${effectiveSettingsLocation === "building" ? "using_building_settings" : "using_global_settings"}` as ParseKeys,
            )}
          </Button>
        )}
      </div>

      {checked && (
        <div className={classNames("group-settings", className)}>
          <div className="Row">{children}</div>
        </div>
      )}
    </>
  )
}

export default SettingsGroup

type LabelWithToolTipProps = {
  group: string
  name: string
}

/**
 * Renders a label with a tooltip for a specific settings group and name.
 *
 * @param group - The settings group for translations.
 * @param name - The name of the translation without label.
 * @returns The rendered label with tooltip.
 */
export const LabelWithToolTip = ({ group, name }: LabelWithToolTipProps) => {
  const { t } = useTranslation()
  const id = useId()
  return (
    <Tooltip
      uniqueId={id}
      content={
        <div>
          <div className="tooltip-title">
            {t(
              `desktop.settings.rooms.device_settings.form.${group}.${name}_label` as ParseKeys,
            )}
          </div>
          {t(
            `desktop.settings.rooms.device_settings.form.${group}.${name}_tooltip` as ParseKeys,
          )}
        </div>
      }
      className="device-settings-tooltip"
    >
      {t(
        `desktop.settings.rooms.device_settings.form.${group}.${name}_label` as ParseKeys,
      )}
    </Tooltip>
  )
}
