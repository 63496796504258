import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import BillingSection from "../BillingSection"
import InvoiceTable from "./InvoiceTable"

import { Invoice } from "../../../../redux/api/billing/invoices/types"

import "./InvoiceList.sass"

type Props = {
  invoices: Invoice[]
}

const InvoiceList = ({ invoices = [] }: Props) => {
  const { t } = useTranslation()

  return (
    <BillingSection
      className="InvoiceList"
      title={t("desktop.settings.billing.invoices.title")}
    >
      <InvoiceTable invoices={invoices} />
      <Link to="invoices">
        {t("desktop.settings.billing.invoices.show_all_invoices")}
      </Link>
    </BillingSection>
  )
}

export default InvoiceList
