import { ThunkApiConfig } from "RootType"

import { featureFlagsURL, get } from "../../api"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import { FeatureFlagsResponse } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */

type Response = {
  featureFlags: FeatureFlagsResponse[]
  isWithoutAccessToken: boolean
}
export const fetchFeatureFlags = createAsyncThunk<
  Response,
  void,
  ThunkApiConfig
>("featureFlags/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(featureFlagsURL(), {}, access_token)

  if (response.ok) {
    const featureFlags = await response.json()
    /**
     * we can fetch the feature flags without access token which fetch only small set of flags
     * the isWithoutAccessToken was added so that we can detect that
     * and re-fetch feature flags after user is authenticated to get all flags
     */
    return { featureFlags, isWithoutAccessToken: access_token === null }
  }

  throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface FeatureFlagsState extends SliceState {
  entry: FeatureFlagsResponse[]
  isWithoutAccessToken: boolean
}

const initialState: FeatureFlagsState = {
  entry: [],
  isWithoutAccessToken: true,
  ...sliceInitialState,
}

const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlags.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchFeatureFlags.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchFeatureFlags.fulfilled, (state, { payload }) => {
      state.entry = payload.featureFlags
      state.isWithoutAccessToken = payload.isWithoutAccessToken
      setFetchSuccessState(state)
      return state
    })
  },
})

export const featureFlagsReducer = featureFlagsSlice.reducer
