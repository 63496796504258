import { api } from ".."
import { assetsGetURL, assetsURL } from "../../../api"
import { PaginatedOptions } from "../../types"
import {
  AssetCreateRequest,
  AssetFetchRequest,
  AssetRequestWithId,
  AssetResponse,
  AssetsResponse,
} from "./types"

export const assets = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchAssets: builder.query<
      AssetsResponse & { offset: number },
      (PaginatedOptions & AssetFetchRequest) | void
    >({
      query: (options) => ({
        url: assetsGetURL({ ...options }),
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Assets" as const,
                id,
              })),
              { type: "Assets", id: "LIST" },
            ]
          : [{ type: "Assets", id: "LIST" }],
      transformResponse: (response: AssetsResponse, _, arg) => {
        return {
          ...response,
          offset: arg?.offset ?? 0,
        }
      },
    }),
    fetchAsset: builder.query<AssetResponse, string>({
      query: (id) => ({
        url: assetsURL(id),
      }),
      providesTags: (_result, _error, id) => [{ type: "Assets", id }],
    }),
    createAsset: builder.mutation<AssetResponse, AssetCreateRequest>({
      query: (body) => ({
        url: assetsURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Assets", id: "LIST" }],
    }),
    updateAsset: builder.mutation<AssetResponse, AssetRequestWithId>({
      query: ({ id, ...body }) => ({
        url: assetsURL(id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Assets", id: "LIST" },
        { type: "Assets", id },
      ],
    }),
    destroyAsset: builder.mutation<void, string>({
      query: (id) => ({
        url: assetsURL(id),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "Assets", id: "LIST" },
        { type: "Assets", id },
      ],
    }),
  }),
})

export const {
  useFetchAssetsQuery,
  useLazyFetchAssetsQuery,
  useFetchAssetQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useDestroyAssetMutation,
} = assets
