import { useTranslation } from "react-i18next"

import { useFetchCompanyQuery } from "../../../redux/api/company"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import CompanyDetailsForm from "../../../components/Form/CompanyDetailsForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./CompanyDetails.sass"

const CompanyDetails = () => {
  const { t } = useTranslation()

  const { data: company, isLoading } = useFetchCompanyQuery()

  const companyNotFound = !company

  return (
    <View className="CompanyDetails">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.organization.title"),
          t("desktop.settings.organization.company_details.title"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.organization.company_details.intro")}
      </Intro>

      <Space size={0.75} />

      {isLoading ? (
        <Loader className="loader" />
      ) : companyNotFound ? (
        <>{t("desktop.settings.organization.company_details.not_found")}</>
      ) : (
        <CompanyDetailsForm company={company} />
      )}
    </View>
  )
}

export default CompanyDetails
