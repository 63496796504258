import { IdAndName } from "../../../../types/sharedTypes"

import { AssetScheduleEntry } from "../../../../redux/asset_schedule/types"
import { AssetType } from "../../../../redux/asset_types/types"
import { Asset } from "../../../../redux/assets/types"
import { BuildingResponse } from "../../../../redux/buildings/types"

export type BuildingWithDefaultAndDisabled = BuildingResponse & {
  disabled: boolean
  default: boolean
}

export const getSortedDisabledAndDefaultBuilding = (
  assets: Asset[],
  buildings: BuildingResponse[],
  assetTypes: AssetType[],
  schedule?: AssetScheduleEntry[],
  defaultUserBuilding?: IdAndName | null,
) => {
  const filteredAssets = schedule
    ? assets.filter((a) => !schedule.find((s) => s.id === a.id))
    : assets
  const filteredAssetTypes = assetTypes.filter((at) =>
    filteredAssets.find((a) => a.asset_type.id === at.id),
  )

  return buildings
    .map((b) => ({
      ...b,
      disabled: !filteredAssetTypes.find((at) => at.buildings[0]?.id === b.id),
      default: !!filteredAssetTypes.find(
        () => defaultUserBuilding?.id === b.id,
      ),
    }))
    .sort(sortByDefaultAndDisabledAndName)
}

export const sortByDefaultAndDisabledAndName = (
  a: BuildingWithDefaultAndDisabled,
  b: BuildingWithDefaultAndDisabled,
) => {
  if (a.disabled && !b.disabled) {
    return 1
  } else if (!a.disabled && b.disabled) {
    return -1
  } else if (a.default && !b.default) {
    return -1
  } else if (!a.default && b.default) {
    return 1
  }
  return a.name.localeCompare(b.name)
}
