import {
  ToastContainer as ReactToastContainer,
  toast,
  ToastContainerProps,
  ToastOptions,
} from "react-toastify"

import "./Toast.sass"

export const ToastContainer = (props: ToastContainerProps) => (
  <ReactToastContainer {...props} closeOnClick={true} draggable={true} />
)
export { toast }
export type { ToastOptions }
