import React, { useCallback, useEffect, useState } from "react"

import classNames from "classnames"
import cn from "classnames"

import { get } from "../../../../../../api"

import { useAppSelector } from "../../../../../../redux/reducers"
import { getAuth } from "../../../../../../redux/selectors"

import PencilSVG from "../../../../../../assets/images/icons/Pencil.svg"
import RadioCheckSVG from "../../../../../../assets/images/icons/RadioCheck.svg"
import RadioUncheckedSVG from "../../../../../../assets/images/icons/RadioUnchecked.svg"
import TrashSVG from "../../../../../../assets/images/icons/Trash.svg"

import "./ImagePreview.sass"

type Props = {
  src: string
  isSelected: boolean
  onRemove: () => void
  onClick: () => void
  onReplace: () => void
}

const ImagePreview = ({
  src,
  isSelected,
  onRemove,
  onClick,
  onReplace,
}: Props) => {
  const { access_token } = useAppSelector(getAuth)

  const [buttonsProps, setButtonsProps] = useState({
    isReplaceButtonDark: false,
    isRemoveButtonDark: false,
  })

  const processImage = useCallback((blob: Blob) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const base64data = reader.result as string
      const img = new Image()

      img.src = base64data
      img.onload = () => {
        const removeButtonBrightness = calculateBrightness(img, "RemoveImage")
        const replaceButtonBrightness = calculateBrightness(img, "ReplaceImage")

        setButtonsProps({
          isReplaceButtonDark: replaceButtonBrightness < 160,
          isRemoveButtonDark: removeButtonBrightness < 160,
        })
      }
    }

    reader.readAsDataURL(blob)
  }, [])

  const calculateBrightness = (img: HTMLImageElement, buttonClass: string) => {
    const canvas = document.createElement("canvas")

    canvas.width = img.width
    canvas.height = img.height

    const ctx = canvas.getContext("2d")

    if (!ctx) {
      return 0
    }

    ctx.drawImage(img, 0, 0, img.width, img.height)

    const button = document.querySelector(`.${buttonClass}`)

    if (!button) {
      console.error(`Button with class ${buttonClass} not found`)
      return 0
    }

    const rect = button.getBoundingClientRect()

    const x = rect.left - img.offsetLeft
    const y = rect.top - img.offsetTop

    const width = rect.width
    const height = rect.height

    const startX = Math.max(0, x)
    const startY = Math.max(0, y)
    const endX = Math.max(img.width, x + width)
    const endY = Math.max(img.height, y + height)

    const imageData = ctx.getImageData(
      startX,
      startY,
      endX - startX,
      endY - startY,
    )

    const data = imageData.data

    let colorSum = 0
    let pixelCount = 0

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i]
      const g = data[i + 1]
      const b = data[i + 2]
      const avg = (r + g + b) / 3

      colorSum += avg
      pixelCount++
    }

    return colorSum / pixelCount
  }

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response: Response = await get(src, {}, access_token)
        const blob = await response.blob()

        processImage(blob)
      } catch (error) {
        console.error("Error fetching image:", error)
      }
    }

    fetchImage()
  }, [access_token, processImage, src])

  return (
    <div className={classNames("ImagePreview", { SelectedImage: isSelected })}>
      <img src={src} alt="Preview" onClick={onClick} />
      <div className="Actions">
        <button
          className={cn("RemoveImage", {
            Dark: buttonsProps.isRemoveButtonDark,
          })}
          onClick={onRemove}
          type="button"
        >
          <TrashSVG />
        </button>

        <button
          className={cn("ReplaceImage", {
            Dark: buttonsProps.isReplaceButtonDark,
          })}
          onClick={onReplace}
          type="button"
        >
          <PencilSVG />
        </button>
      </div>
      <div className={cn("ImageStatus")}>
        {isSelected ? <RadioCheckSVG /> : <RadioUncheckedSVG />}
      </div>
    </div>
  )
}

export default ImagePreview
