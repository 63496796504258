import { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"
import {
  ActionMeta,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  OnChangeValue,
  Theme,
} from "react-select"
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate"

import Select from "react-select/dist/declarations/src/Select"

import "./style.sass"

type Props<
  TOption extends unknown = unknown,
  TIsMulti extends boolean = false,
> = {
  placeholder?: string
  characterDelay?: number
  characterDelayMessage?: string
  nothingFoundMessage?: string | JSX.Element
  getOptionLabel: GetOptionLabel<TOption>
  getOptionValue: GetOptionValue<TOption>
  className?: string
  id?: string
  isMulti?: TIsMulti
  value?: OnChangeValue<TOption, TIsMulti>
  onChange?: (
    newValue: OnChangeValue<TOption, TIsMulti> | null,
    actionMeta?: ActionMeta<TOption>,
  ) => void
  disabled?: boolean
  clearable?: boolean
  cacheOptions?: boolean
  loadOptions: LoadOptions<TOption, GroupBase<TOption>, unknown>
  hasError?: boolean
  key?: string
  isPaginated?: boolean
}

const AsyncSelectInternal = <TOption, TIsMulti extends boolean>(
  {
    placeholder,
    characterDelay,
    characterDelayMessage,
    nothingFoundMessage,
    getOptionLabel,
    getOptionValue,
    className: extraClassName,
    onChange,
    value,
    isMulti,
    id,
    disabled = false,
    clearable,
    loadOptions,
    hasError,
    key,
    isPaginated,
  }: Props<TOption, TIsMulti>,
  ref: React.ForwardedRef<Select<TOption, TIsMulti>>,
) => {
  const className = classNames("AsyncSelect", extraClassName, {
    disabled,
    error: hasError,
  })

  return (
    <AsyncPaginate
      debounceTimeout={500}
      placeholder={placeholder}
      className={className}
      classNamePrefix="async-select-input"
      isMulti={isMulti}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={({ inputValue }) => {
        if (characterDelay && inputValue.trim().length < characterDelay) {
          return characterDelayMessage
        }
        if (!inputValue.trim().length) {
          return null
        }
        return nothingFoundMessage
      }}
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      defaultOptions
      theme={selectTheme}
      id={id}
      closeMenuOnSelect
      blurInputOnSelect
      menuPortalTarget={document.body}
      menuPosition="absolute"
      isClearable={clearable}
      isDisabled={disabled}
      key={key}
    />
  )
}

const selectTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: "#F6F6F6",
    primary50: "#E8E8E8",
    primary: "#4205DD",
  },
})

/**
 * In most cases user should use the advance AsyncSelect form the /src/components/advance/AsyncSelect
 * use this one only when the end point returns the data in different way than paginated data returned form Joan portal end-points
 */

export default forwardRef(AsyncSelectInternal) as <
  TOption,
  TIsMulti extends boolean = false,
>(
  props: Props<TOption, TIsMulti> & {
    ref?: ForwardedRef<Select<TOption, TIsMulti>>
  },
) => ReturnType<typeof AsyncSelectInternal> // The forwardRef does not know how to pass types from component
