import {
  ChangeEventHandler,
  forwardRef,
  HTMLAttributes,
  ReactChild,
} from "react"

import classNames from "classnames"

import { useId } from "../../../hooks/useId"
import { Tooltip } from "../Tooltip"

import "./style.sass"

export type SwitchProps = {
  value?: boolean
  onChange?: (v: boolean) => void
  label?: ReactChild
  disabled?: boolean
  altInput?: string
  id?: string
  subLabel?: ReactChild
  hasError?: boolean
  rightAlign?: boolean
  showTooltip?: boolean
  tooltipText?: string
} & Omit<HTMLAttributes<HTMLInputElement>, "onChange">

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      label,
      disabled,
      altInput = "switch",
      className,
      id,
      value,
      subLabel,
      hasError,
      onChange,
      rightAlign,
      showTooltip,
      tooltipText,
      ...args
    },
    ref,
  ) => {
    const componentId = useId(id)

    const labelClasses = classNames("Label", "SwitchLabel", {
      disabled,
      error: hasError,
    })
    const toggleClasses = classNames(className, "Switch", {
      disabled,
      rightAlign,
    })

    const innerOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange && onChange(e.target.checked)
    }

    const sanitizedValue = value ?? false

    const leftAlignedSwitch = !rightAlign ? (
      <Tooltip
        className="device-settings-tooltip"
        uniqueId={`tooltip-${componentId}`}
        content={showTooltip && tooltipText}
      >
        <label className="switcher">
          <input
            ref={ref}
            aria-label={altInput}
            type="checkbox"
            disabled={disabled}
            checked={sanitizedValue}
            id={componentId}
            onChange={innerOnChange}
            {...args}
          />
          <div className="switcher-bg"></div>
          <div className="slider" />
        </label>
      </Tooltip>
    ) : null

    const rightAlignedSwitch = rightAlign ? (
      <Tooltip
        uniqueId={`tooltip-${id}`}
        className="device-settings-tooltip"
        content={showTooltip && tooltipText}
      >
        <label className="switcher">
          <input
            ref={ref}
            aria-label={altInput}
            type="checkbox"
            disabled={disabled}
            checked={sanitizedValue}
            id={componentId}
            onChange={innerOnChange}
            {...args}
          />
          <div className="switcher-bg"></div>
          <div className="slider" />
        </label>
      </Tooltip>
    ) : null

    return (
      <div className={toggleClasses}>
        {leftAlignedSwitch}
        {label && (
          <label className={labelClasses} htmlFor={componentId}>
            {label}
            {subLabel && <span className="SubText">{subLabel}</span>}
          </label>
        )}
        {rightAlignedSwitch}
      </div>
    )
  },
)

export default Switch
