import { useCallback } from "react"

import queryString from "query-string"

import { analyticsEvent, SupportedEvents } from "../analytics"
import { HOME_PATHS } from "../constants"
import { useNavigation } from "./useNavigation"
import { useToast } from "./useToast"
import { isRejected } from "@reduxjs/toolkit"

import { useFetchCompanyQuery } from "../redux/api/company"
import { useCheckinDeskReservationMutation } from "../redux/api/deskReservations"
import { DeskReservation } from "../redux/api/deskReservations/types"
import {
  isEmployeeScreeningNotApplicable,
  useLazyFetchEmployeeScreeningQuery,
} from "../redux/api/screenings"

type deskCheckinParams = {
  reservation: DeskReservation
  onCheckinCallback?: () => void
  onRedirectionCallback?: () => void
}

export const useDeskCheckIn = () => {
  const [checkinDeskReservation] = useCheckinDeskReservationMutation()
  const [fetchScreening] = useLazyFetchEmployeeScreeningQuery()
  const { data: { settings } = {} } = useFetchCompanyQuery()

  const { push } = useNavigation()
  const { errorToast } = useToast()

  return useCallback(
    async ({
      reservation,
      onCheckinCallback,
      onRedirectionCallback,
    }: deskCheckinParams) => {
      const screening = await fetchScreening(reservation.building.id)

      if (
        isEmployeeScreeningNotApplicable(screening) ||
        screening?.data?.passed === true
      ) {
        if (settings?.desk_check_in_location === "on_spot") {
          push(HOME_PATHS.scanQrCode)

          return
        }

        const response = await checkinDeskReservation(reservation.id)

        if (isRejected(response)) {
          errorToast(response.error.message)
        }

        analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKIN, {
          id: reservation.id,
          seat_id: reservation.desk.id,
        })

        onCheckinCallback?.()

        return
      }

      if (!isRejected(screening)) {
        push(
          `/screening/employee/${
            reservation.building.id
          }?${queryString.stringify({
            id: reservation.id,
          })}`,
        )
        onRedirectionCallback?.()
      }
    },
    [
      checkinDeskReservation,
      errorToast,
      fetchScreening,
      push,
      settings?.desk_check_in_location,
    ],
  )
}
