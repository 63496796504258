import { forwardRef, HTMLProps, useCallback } from "react"

import classNames from "classnames"
import {
  buildCountryData,
  CountryIso2,
  defaultCountries,
  getCountry,
  ParsedCountry,
  PhoneInput,
} from "react-international-phone"

import { PhoneInputRefType } from "react-international-phone/dist/components/PhoneInput/PhoneInput"
import "react-international-phone/style.css"

import LockSVG from "../../../assets/images/icons/Lock.svg"

import "./style.sass"

type CountryMetadataProps = {
  country: ParsedCountry
  inputValue: string
}

type CommonProps = {
  value?: string
  className?: string
  onChange?: (phone: string, meta: CountryMetadataProps) => void
  hasError?: boolean
  variant?: "desktop" | "mobile"
  defaultCountry?: CountryIso2
} & (
  | { includedCountries?: CountryIso2[]; excludedCountries?: never }
  | { excludedCountries?: CountryIso2[]; includedCountries?: never }
)

type ConditionalProps = HTMLProps<HTMLInputElement>

export type InputProps = CommonProps & Omit<ConditionalProps, "onChange">

/**
 * Input component for phone numbers including dropdown with country codes
 * @param {CountryIso2} defaultCountry - Iso2 country code - eg. "si"
 * @param {CountryIso2[]} excludedCountries - Array with Iso2 country codes to exclude them from dropdown - eg. ["si", "en", "ger"]
 * @param {CountryIso2[]} includedCountries -Array with Iso2 country codes to include them in the dropdown- eg. ["si", "en", "ger"]
 */
export const InputPhone = forwardRef<PhoneInputRefType, InputProps>(
  (
    {
      className,
      onChange,
      hasError,
      value,
      variant = "desktop",
      excludedCountries,
      includedCountries,
      defaultCountry = includedCountries?.[0],
      ...props
    },
    ref,
  ) => {
    const cn = classNames([
      {
        disabled: !!props.disabled,
        error: hasError,
      },
      "InputPhone",
      variant,
      className,
    ])

    const innerOnChange = useCallback(
      (phone: string, countryMetadata: CountryMetadataProps) => {
        const phoneNumber =
          phone === `+${countryMetadata.country.dialCode}` ? "" : phone

        onChange && onChange(phoneNumber, countryMetadata)
      },
      [onChange],
    )

    let countries = defaultCountries

    if (includedCountries) {
      countries = includedCountries.map((c) => {
        const country = getCountry({
          field: "iso2",
          value: c,
          countries: defaultCountries,
        })
        return buildCountryData(country!)
      })
    }

    if (excludedCountries) {
      countries = defaultCountries.filter(
        (c) => !excludedCountries.includes(c[1]),
      )
    }

    const sanitizedValue = value ?? undefined

    return (
      <div className={cn}>
        <PhoneInput
          onChange={innerOnChange}
          value={sanitizedValue}
          ref={ref}
          defaultCountry={defaultCountry}
          countries={countries}
        />

        {props.disabled && <LockSVG className="lock" />}
      </div>
    )
  },
)
