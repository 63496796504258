import { Redirect } from "react-router-dom"

import { useNavigation } from "../hooks/useNavigation"
import { BILLING_PATHS } from "../screens/Settings/Billing/constants"
import { Children } from "../types/sharedTypes"

import { useFetchPaymentsQuery } from "../redux/api/billing/payments"

import Loader from "../components/basic/Loader"

const CompanyDetailsGuard = ({ children }: { children: Children }) => {
  const { getNextFromCurrentUrl, createLocationString } = useNavigation()
  const { data: payments, isFetching } = useFetchPaymentsQuery()

  if (isFetching) {
    return <Loader variant="fullScreen" />
  }

  if (!payments?.billing_details) {
    const next = getNextFromCurrentUrl()
    const redirectPath = createLocationString(
      BILLING_PATHS.overview.companyDetails,
      { next },
    )
    return <Redirect to={redirectPath} />
  }

  return children
}

export default CompanyDetailsGuard
