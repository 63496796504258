import React from "react"

import { Redirect, Switch } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import Amenities from "./Amenities"
import EditAmenities from "./Amenities/EditAmenities"
import { DESKS_PATHS } from "./constants"
import DesksOverview from "./DesksOverview"
import General from "./General"
import DeskLayout from "./Layout"
import TimeSlots from "./TimeSlots"
import EditTimeSlots from "./TimeSlots/EditTimeSlots"

import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isPortalAdmin } from "../../../redux/user/utils"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Desks = () => {
  const editDeskCompanySettingsPermission =
    PERMISSIONS.desks.canChangeDeskCompanySettings
  const editDeskPermission = PERMISSIONS.desks.canChangeDesk
  const editDeskTimeSlotPermission = PERMISSIONS.desks.canChangeDeskTimeslot
  const editAmenityPermission = PERMISSIONS.amenities.canChangeAmenity

  const { entry: currentUser } = useAppSelector(selectUser)
  const isAdmin = isPortalAdmin(currentUser)

  return (
    <Switch>
      {isAdmin && (
        <ProtectedRoute
          exact
          path={DESKS_PATHS.overview}
          accessPermission={editDeskCompanySettingsPermission}
          component={DesksOverview}
        />
      )}
      <ProtectedRoute
        exact
        path={DESKS_PATHS.general}
        accessPermission={editDeskCompanySettingsPermission}
        component={General}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.layout}
        accessPermission={editDeskPermission}
        component={DeskLayout}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.timeSlots.default}
        accessPermission={editDeskTimeSlotPermission}
        component={TimeSlots}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.timeSlots.add}
        accessPermission={editDeskTimeSlotPermission}
        component={EditTimeSlots}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.timeSlots.edit}
        accessPermission={editDeskTimeSlotPermission}
        component={EditTimeSlots}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.amenities.default}
        accessPermission={editAmenityPermission}
        component={Amenities}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.amenities.add}
        accessPermission={editAmenityPermission}
        component={EditAmenities}
      />
      <ProtectedRoute
        exact
        path={DESKS_PATHS.amenities.edit}
        accessPermission={editAmenityPermission}
        component={EditAmenities}
      />
      <Redirect to={isAdmin ? DESKS_PATHS.overview : DESKS_PATHS.general} />
    </Switch>
  )
}

export default Desks
