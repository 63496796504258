import React from "react"

import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { OVERVIEW_PATH } from "../../Overview/constants"

import Card from "../../../../components/basic/Card"
import WarningCard from "../../../../components/basic/WarningCard"

import ListSVG from "../../../../assets/images/icons/List_alt.svg"

import "./NoPlansInfo.sass"

const NoPlansInfo = () => {
  const { t } = useTranslation()

  return (
    <div className="NoPlansInfo">
      <Card>
        <div className="NoPlansInfo__center">
          <ListSVG />
          <div>
            {t("desktop.settings.billing.no_plans_info.no_subscription_plans")}
          </div>
        </div>
      </Card>
      <WarningCard isCentered>
        <div>
          <Trans i18nKey="desktop.settings.billing.no_plans_info.no_plans_info">
            <Link to={OVERVIEW_PATH} />
          </Trans>
        </div>
      </WarningCard>
    </div>
  )
}

export default NoPlansInfo
