import React from "react"

import SubscriptionOverviewCard from "../SubscriptionOverviewCard"

import { ACTIVE_LICENSE_STATUSES } from "../../../redux/api/billing/constants"
import { useFetchSubscriptionsQuery } from "../../../redux/api/billing/subscriptions"
import { Subscription } from "../../../redux/api/billing/subscriptions/types"
import { PlanGroup } from "../../../redux/api/billing/types"

const ACTIVE_AND_FUTURE_LICENSE_STATUSES = [
  ...ACTIVE_LICENSE_STATUSES,
  "future",
] as const

type RoomsSubscriptionsProps = {
  planGroup: PlanGroup
}

const SubscriptionOverview = ({ planGroup }: RoomsSubscriptionsProps) => {
  const { data: { results: subscriptions = [] } = {} } =
    useFetchSubscriptionsQuery()

  const roomSubscriptions = subscriptions
    .filter((s: Subscription) => planGroup === s.planGroup)
    .filter((s: Subscription) =>
      ACTIVE_AND_FUTURE_LICENSE_STATUSES.includes(s.status),
    )

  if (!roomSubscriptions.length) {
    return
  }

  return roomSubscriptions.map((s: Subscription) => (
    <SubscriptionOverviewCard
      key={s.subscription_id}
      type={s.plan_type}
      unit={s.unit}
      status={s.status}
      planGroup={s.planGroup}
    />
  ))
}

export default SubscriptionOverview
