import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import PairDeviceForm from "../components/Form/Visitors/PairDeviceForm"

type Props = {
  open: boolean
}

export default function PairDeviceModal({ open }: Props) {
  const { closeModal } = useModals()

  const onRequestClose = () => {
    closeModal()
  }

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <PairDeviceForm />
    </ReactModal>
  )
}
