export const AVAILABLE_FACETS = ["building", "floor", "type"] as const

export const DEVICE_TYPES = {
  joan_6: "JOAN_6",
  joan_6_pro: "JOAN_6_PRO",
  joan_6_re: "JOAN_6_RE",
  joan_9: "JOAN_9",
  joan_13: "JOAN_13",
  joan_32: "JOAN_32",
  joan_on_display: "JOAN_ON_DISPLAY",
  joan_on_tablets: "JOAN_ON_TABLETS",
} as const
