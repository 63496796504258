import React, { useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { useBookContext } from "../../../contexts/Mobile/BookContext"
import { userTimeFormat } from "../../../utils"
import SuggestAssetType from "./Assets/SuggestAssetType"

import { clearBookAsset } from "../../../redux/book_asset/bookAssetSlice"
import { useActions } from "../../../redux/utils"

import Button from "../../../components/advanced/Button"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import CheckCircleSVG from "../../../assets/images/icons/CheckCircle.svg"
import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./Done.sass"

type LocationState = {
  checked_in: boolean
  desk: {
    id: number
    name: string
  }
  end: string
  start: string
  tz: string
  user: {
    first_name: string
    last_name: string
    email: string
  }
  id: number
  timeslot_id: number
}

type Props = {
  isAsset?: boolean
}

const Done = ({ isAsset = false }: Props) => {
  const { goToHome } = useBookContext()
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useLocation<LocationState>()
  const actions = useActions({
    clearBookAsset: () => clearBookAsset(null),
  })

  const navigateHome = () => {
    actions.clearBookAsset()
    goToHome()
  }

  /**
   * Prevent users from going to previous stages after a successful reservation.
   * The user has the opportunity to return to previous stages in 3 ways:
   * 1. When the user can back to the reservation `book/{type}/summary` stage from the <Done /> component.
   * 2. When the user can back to the reservation `book/{type}/done` stage from <Book /> component.
   * (Redirect restrictions for second way can be found in <Book /> component)
   * 3. Follow link to one of the previous registration stages. There are currently no restrictions on this logic.
   *
   * Part of the code below is responsible for the first way and restricts the visit to the previous stage <Summary />
   * using redirect to the reservation screen.
   */
  useEffect(
    () => history.listen((_, action) => action === "POP" && goToHome()),
    [goToHome, history],
  )

  if (state) {
    const date = dayjs(state.start)
    const isToday = dayjs().isSame(date, "day")
    const isTomorrow = dayjs().add(1, "day").isSame(date, "day")

    const day = isToday
      ? t("mobile.general.today")
      : isTomorrow
        ? t("mobile.general.tomorrow")
        : date.format("dddd DD MMM")

    return (
      <SafeViewArea className="DoneNew">
        <TopNav backArrow={false} onClose={navigateHome} />
        <div className="upper">
          <div className="wrapper">
            <div className="icon">
              <div>
                <CheckCircleSVG />
              </div>
            </div>
            <div>
              <div className="title">{t("mobile.book.done_title")}</div>
              <div className="description">
                <div>{t("mobile.book.booked_desk")}</div>
                <span>
                  <strong>{state.desk.name}</strong>
                </span>
                <span>, {t("mobile.book.for")}&nbsp;</span>
                <span>
                  <strong>{day}</strong>
                </span>
                <span>, {t("mobile.book.from")}&nbsp;</span>
                <span>
                  <strong>
                    {dayjs(state.start).format(userTimeFormat())}
                    &nbsp;-&nbsp;
                    {dayjs(state.end).format(userTimeFormat())}
                  </strong>
                  .
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="info">{t("mobile.book.done_info")}</div>
        </div>
        <div className="action">
          <SuggestAssetType wasAsset={isAsset} />
          <Button variant="mobile-action" onClick={navigateHome}>
            {t("mobile.general.done")}
          </Button>
        </div>
      </SafeViewArea>
    )
  }

  return (
    <SafeViewArea className="Done">
      <div className="head">
        <TopNav
          backArrow={false}
          rightIcon={<CrossSVG onClick={navigateHome} />}
        />
        <div className="icon">
          <div>
            <CheckCircleSVG />
          </div>
        </div>
      </div>
      <div className="body">
        <div className="details">
          <div className="title">{t("mobile.book.done_title")}</div>
          <div className="info">{t("mobile.book.done_info")}</div>
        </div>
      </div>
      <div className="action">
        <SuggestAssetType wasAsset={isAsset} />
        <Button variant="mobile-action" onClick={navigateHome}>
          {t("mobile.general.done")}
        </Button>
      </div>
    </SafeViewArea>
  )
}

export default Done
