import { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { useToast } from "../../hooks/useToast"
import { BUILDINGS_PATHS } from "../../screens/Settings/Buildings/constants"
import { countFloorsInBuildings } from "../../utils"
import { Input } from "../basic/Input"
import Field from "../Field"
import ModalDeleteForm from "./ModalDeleteForm"
import { useModals } from "@mattjennings/react-modal-stack"

import { useLazyFetchBuildingsQuery } from "../../redux/api/buildings"
import { useDestroyFloorMutation } from "../../redux/api/floors"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { FloorResponse } from "../../redux/floors/types"

import "./FloorDeleteForm.sass"

type Props = {
  floor: FloorResponse
  building_id?: string
}

type FormValues = {
  confirmationName: string
}

const FloorDeleteForm = ({ floor, building_id }: Props) => {
  const { closeAllModals } = useModals()
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()
  const history = useHistory()

  const methods = useForm<FormValues>({
    defaultValues: {
      confirmationName: "",
    },
  })
  const {
    control,
    formState: { isSubmitting },
  } = methods

  const [fetchBuildings] = useLazyFetchBuildingsQuery()
  const [destroyFloor] = useDestroyFloorMutation()

  const { id, name, desks_count } = floor

  const onDeleteClick = useCallback(async () => {
    const response = await destroyFloor(id)

    if (isRejected(response)) {
      const { error } = response

      if (isApiResponseError(error)) {
        errorToast(error.message)
      }
    } else {
      fetchBuildings({
        stats: true,
      })
        .unwrap()
        .then((buildings) =>
          analyticsEvent(SupportedEvents.FLOOR_DELETE, {
            id,
            total: countFloorsInBuildings(buildings),
          }),
        )

      infoToast(
        t("desktop.settings.floor_plans.delete_form.floor_deleted_toast"),
      )
      closeAllModals()
      if (building_id)
        history.push(BUILDINGS_PATHS.edit.replace(":building_id", building_id))
    }
  }, [
    building_id,
    history,
    destroyFloor,
    id,
    errorToast,
    fetchBuildings,
    infoToast,
    t,
    closeAllModals,
  ])

  return (
    <FormProvider {...methods}>
      <ModalDeleteForm
        onDelete={onDeleteClick}
        title={t("desktop.settings.floor_plans.delete_form.title")}
        hint={
          <Trans
            i18nKey={"desktop.settings.floor_plans.delete_form.hint"}
            values={{
              name: name,
              desksCount: desks_count ?? 0,
            }}
          />
        }
        helpText={
          <Trans
            i18nKey={"desktop.settings.floor_plans.delete_form.help_text"}
            values={{
              name,
            }}
          />
        }
      >
        <Field
          control={control}
          name="confirmationName"
          label={t("desktop.settings.floor_plans.delete_form.name")}
          rules={{
            validate: (v: string) =>
              v === name
                ? undefined
                : t(
                    "desktop.settings.floor_plans.delete_form.incorrect_name_error",
                  ),
          }}
        >
          {(props) => <Input autoFocus disabled={isSubmitting} {...props} />}
        </Field>
      </ModalDeleteForm>
    </FormProvider>
  )
}

export default FloorDeleteForm
