import React, { ReactNode } from "react"

import SafeViewArea from "./SafeViewArea"

import "./MobileWrapper.sass"

type MobileWrapperProps = {
  children: ReactNode
}

const MobileWrapper = ({ children }: MobileWrapperProps) => {
  return (
    <div className="mobile-wrapper">
      <SafeViewArea className="mobile-background">{children}</SafeViewArea>
    </div>
  )
}

export default MobileWrapper
