import { useMemo } from "react"

/**
 * Generates a random id, not safe for cryptographic use
 */
export function generateId(): string {
  return `ID_${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(
    16,
  )}`
}

/**
 * Hook that will fallback to a randomly {@link generateId} if nullable
 * @param id Id to use
 */
export function useId(id?: string): string {
  return useMemo(() => id ?? generateId(), [id])
}
