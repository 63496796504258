import React from "react"

import { useTranslation } from "react-i18next"

import { calculatePercentAmount } from "../utils"

import { DepartmentResponse } from "../redux/api/departments/types"
import { getNumberOfDesksInDepartment } from "../redux/api/departments/utils"
import { BuildingResponse } from "../redux/buildings/types"
import { MapResponse } from "../redux/company/types"
import { FloorResponse } from "../redux/floors/types"

import InfoSVG from "../assets/images/icons/Info.svg"

import "./OccupancyInfo.sass"

type Props =
  | {
      type: "Building"
      building: BuildingResponse
    }
  | {
      type: "Floor"
      floor: Partial<FloorResponse & MapResponse>
    }
  | {
      type: "Department"
      department: DepartmentResponse
    }

export default function OccupancyInfo(props: Props) {
  const { t } = useTranslation()

  let capacityLimit = 0
  let activeDeskNo = 0
  let totalDeskNo = 0

  switch (props.type) {
    case "Building":
      capacityLimit = props.building.capacity_limit || 0
      totalDeskNo = props.building.desks_count || 0
      activeDeskNo = calculatePercentAmount(totalDeskNo, capacityLimit)
      break
    case "Floor":
      capacityLimit = props.floor.capacity_limit || 0
      if (props.floor.desks_count !== undefined) {
        totalDeskNo = props.floor.desks_count || 0
      } else {
        totalDeskNo = props.floor.seats ? props.floor.seats.length : 0
      }
      activeDeskNo = calculatePercentAmount(totalDeskNo, capacityLimit)
      break
    case "Department":
      capacityLimit = props.department.capacity_limit || 0
      totalDeskNo = getNumberOfDesksInDepartment(props.department)
      activeDeskNo = calculatePercentAmount(totalDeskNo, capacityLimit)
      break
  }

  return (
    <div className="OccupancyInfo">
      <InfoSVG className="InfoIcon" />
      <div>
        {props.type} {t("general.occupancy_set_at")}&nbsp;
        <strong>{capacityLimit}&nbsp;%</strong>.&nbsp;
        {`(${activeDeskNo} / ${totalDeskNo} desks)`}
      </div>
    </div>
  )
}
