import { PaginatedResponse } from "../types"

export enum NewVisitsCreatedByOption {
  ALL_EMPLOYEES = "ALL_EMPLOYEES",
  OFFICE_MANAGER_AND_ADMIN = "OFFICE_MANAGER_AND_ADMIN",
}

type Document = {
  id: string
  title: string
}

export type VisitorSettingResponse = {
  id: string
  company_id: string
  building_id: string
  enabled: boolean
  create_visits_by: NewVisitsCreatedByOption
  document_signing_enabled: boolean
  document?: Document
  send_signed_email: boolean
  document_send_to: string
  automatic_visitor_registration: boolean
  excluded_calendar_domain: string
  enable_general_notification: boolean
  send_app_notification: boolean
  send_sms_notification: boolean
  send_email_limit_notification: boolean
  send_slack_notification: boolean
  send_arrival_email_notification: boolean
  enable_visitors_notification: boolean
  invitation_email_id?: string
  send_invitation_email: boolean
  welcome_email_id?: string
  send_welcome_email: boolean
  enable_maintenance_notification: boolean
  maintenance_email?: string
}

export type VisitorSettingRequest = {
  id?: string
  document?: Document
  building_id: string
  enabled: boolean
  create_visits_by: NewVisitsCreatedByOption
  document_signing_enabled: boolean
  document_id?: string
  send_signed_email: boolean
  document_send_to: string
  automatic_visitor_registration: boolean
  excluded_calendar_domain: string
  enable_general_notification: boolean
  send_app_notification: boolean
  send_sms_notification: boolean
  send_email_limit_notification: boolean
  send_slack_notification: boolean
  send_arrival_email_notification: boolean
  enable_visitors_notification: boolean
  invitation_email_id?: string
  send_invitation_email: boolean
  welcome_email_id?: string
  send_welcome_email: boolean
  enable_maintenance_notification: boolean
  maintenance_email?: string
}

export type VisitorSettingUserResponse = {
  enable_general_notification: boolean
  send_app_notification: boolean
  send_sms_notification: boolean
  send_arrival_email_notification: boolean
  send_slack_notification: boolean
  health_screening_notification: boolean
  document_signing_enabled: boolean
}

export type VisitorSettingsResponse = PaginatedResponse<VisitorSettingResponse>
