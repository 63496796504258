import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useBookContext } from "../../../contexts/Mobile/BookContext"
import { internalTimeFormat, userTimeFormat } from "../../../utils"

import Button from "../../../components/advanced/Button"
import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Time.sass"

const Time = () => {
  const {
    date,
    timeslot,
    editing,
    onDateTimeConfirm,
    onToggleDateTimePicker,
    goToHome,
    isBookRoom,
  } = useBookContext()

  const { t } = useTranslation()
  const history = useHistory()

  const today = dayjs()
  const isToday = today.isSame(date, "day")
  const isTomorrow = today.add(1, "day").isSame(date, "day")
  const formattedDay = dayjs(date).format("dddd DD MMM")

  const day = isToday
    ? t("mobile.general.today")
    : isTomorrow
      ? t("mobile.general.tomorrow")
      : formattedDay

  const handleClose = () => goToHome()

  return (
    <SafeViewArea className="Time">
      <TopNav
        backArrow={true}
        onGoBack={history.goBack}
        onClose={handleClose}
      />
      <div className="body">
        <div className="info">
          {isBookRoom
            ? t("mobile.book.like_to_book_room")
            : t("mobile.book.like_to_book_desk")}
          <br />
          {t("mobile.book.for")}
          <br />
          <span onClick={onToggleDateTimePicker}>{day}</span>
          <br />
          {t("mobile.book.from")}
          <br />
          <span onClick={onToggleDateTimePicker}>
            {dayjs(
              timeslot ? timeslot.from : "9:00",
              internalTimeFormat(),
            ).format(userTimeFormat())}
            &nbsp;-&nbsp;
            {dayjs(
              timeslot ? timeslot.to : "17:00",
              internalTimeFormat(),
            ).format(userTimeFormat())}
          </span>
        </div>
        <div className="instructions">{t("mobile.book.tap_value")}</div>
        <CategoryRow name={t("mobile.general.next_step")}>
          <div className="next-button">
            <Button variant="mobile-action" onClick={onDateTimeConfirm}>
              {editing
                ? t("mobile.book.overview")
                : isBookRoom
                  ? t("mobile.book.pick_room")
                  : t("mobile.book.pick_desk")}
            </Button>
          </div>
        </CategoryRow>
      </div>
    </SafeViewArea>
  )
}

export default Time
