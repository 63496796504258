import { Dayjs, UnitType } from "dayjs"

export const roundTimeToInterval = (
  time: Dayjs,
  units: UnitType,
  interval: number,
) => {
  if (time.minute() === 0 || time.minute() === interval) return time

  return time.add(interval - (time.get(units) % interval), units)
}
