import { ThunkApiConfig } from "RootType"

import {
  get,
  postJSON,
  printerSettingsURL,
  printerSettingURL,
  putJSON,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
  getErrorMessage,
  getErrorObject,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  PrinterSettingRequest,
  PrinterSettingResponse,
  PrinterSettingsResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchPrinterSettings = createAsyncThunk<
  PrinterSettingsResponse,
  void,
  ThunkApiConfig
>("printerSettings/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(printerSettingsURL(), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchPrinterSetting = createAsyncThunk<
  PrinterSettingResponse,
  string,
  ThunkApiConfig
>("printerSetting/fetch", async (id, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(printerSettingURL(id), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const createPrinterSettings = createAsyncThunk<
  PrinterSettingResponse,
  PrinterSettingRequest,
  ThunkApiConfig<ResponseError>
>("printerSettings/create", async (body, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    printerSettingsURL(),
    {
      body,
    },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  return rejectWithValue(await getErrorObject(response))
})

export const updatePrinterSettings = createAsyncThunk<
  PrinterSettingResponse,
  PrinterSettingRequest,
  ThunkApiConfig<ResponseError>
>("printerSettings/update", async (body, { getState, rejectWithValue }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await putJSON(
    printerSettingURL(body.building_id),
    {
      body,
    },
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  return rejectWithValue(await getErrorObject(response))
})

export interface PrinterSettingState extends SliceState {
  entries: PrinterSettingResponse[]
}

const initialState: PrinterSettingState = {
  entries: [],
  ...sliceInitialState,
}

const printerSettingsSlice = createSlice({
  name: "printerSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrinterSettings.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entries = payload.results
    })
    builder.addCase(fetchPrinterSetting.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      const remain = state.entries.filter((e) => e.id !== payload.id)
      state.entries = [...remain, payload]
    })
    builder.addCase(createPrinterSettings.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      state.entries = [...state.entries, payload]
    })
    builder.addCase(updatePrinterSettings.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      const remain = state.entries.filter((e) => e.id !== payload.id)
      state.entries = [...remain, payload]
    })
    builder.addMatcher(isAnyOf(fetchPrinterSettings.pending), (state) => {
      state.isLoading = true
    })
    builder.addMatcher(
      isAnyOf(createPrinterSettings.pending, updatePrinterSettings.pending),
      (state) => {
        state.isSubmitting = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        fetchPrinterSettings.rejected,
        fetchPrinterSetting.rejected,
        createPrinterSettings.rejected,
        updatePrinterSettings.rejected,
      ),
      (state, action) => {
        setFetchErrorState(state, action)
      },
    )
  },
})

export const printerSettingsReducer = printerSettingsSlice.reducer
