import React from "react"

import { InternalTime } from "../../types/sharedTypes"

import { TimeslotResponse } from "../../redux/timeslots/types"
import { formatSlotTime } from "../../redux/timeslots/utils"

import "./QuickSlots.sass"

type Props = {
  slots: TimeslotResponse[]
  onChange: (from: InternalTime, to: InternalTime) => void
}

export default function QuickSlots({ slots, onChange }: Props) {
  function handleClick(slot: TimeslotResponse) {
    onChange && onChange(slot.from as InternalTime, slot.to as InternalTime)
  }

  return (
    <span className="QuickSlots">
      {slots.map((s, i) => (
        <span key={`qs-${i}`}>
          {i !== 0 && <span className="separator"> / </span>}
          <span className="slot" onClick={() => handleClick(s)}>
            {formatSlotTime(s.from)} - {formatSlotTime(s.to)}
          </span>
        </span>
      ))}
    </span>
  )
}
