import React from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { INTEGRATIONS_PATHS } from "../constants"

import { useConnectCalendarMutation } from "../../../../redux/api/calendars"
import { CalendarType } from "../../../../redux/api/calendars/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { Input } from "../../../../components/basic/Input"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./Exchange.sass"

type FormValues = {
  password: string
  password_repeat: string
  user: string
  url: string
}

const Exchange = () => {
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const [connectCalendar] = useConnectCalendarMutation()
  const { push } = useNavigation()

  const methods = useForm<FormValues>({
    defaultValues: {
      password: "",
      password_repeat: "",
      user: "",
      url: "",
    },
    mode: "onBlur",
  })

  const { setError, control } = methods

  const onCreate = async (values: FormValues) => {
    const response = await connectCalendar({
      type: CalendarType.EXCHANGE,
      next: `${window.location.origin}${INTEGRATIONS_PATHS.calendars}?scan=true`,
      ...values,
    })

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        setErrors(error.formError, setError, errorToast)
        return
      }
      return
    }

    infoToast(t("desktop.settings.integrations.calendars.exchange.success"))
    push(INTEGRATIONS_PATHS.calendars)
  }

  return (
    <View className="Exchange">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.calendars.title"),
          t("desktop.settings.integrations.calendars.exchange.title"),
        ]}
      />
      <Intro isConstrained>
        <Trans
          i18nKey={"desktop.settings.integrations.calendars.exchange.intro"}
        />
      </Intro>

      <Space size={0.75} />
      <FormProvider {...methods}>
        <PageForm
          backUrl={INTEGRATIONS_PATHS.calendars}
          onCreate={onCreate}
          updateMode={false}
          className="Exchange__form"
        >
          <div className="Exchange__form__two-columns">
            <Field
              required
              control={control}
              name="url"
              label={t(
                "desktop.settings.integrations.calendars.form.exchange_url",
              )}
            >
              {(props) => <Input {...props} />}
            </Field>
            <Field
              required
              control={control}
              name="user"
              label={t(
                "desktop.settings.integrations.calendars.form.user_name",
              )}
            >
              {(props) => <Input {...props} />}
            </Field>
          </div>
          <div className="Exchange__form__two-columns">
            <Field
              required
              control={control}
              name="password"
              label={t("desktop.settings.integrations.calendars.form.password")}
            >
              {(props) => <Input {...props} type="password" />}
            </Field>
            <Field
              required
              control={control}
              name="password_repeat"
              label={t(
                "desktop.settings.integrations.calendars.form.password_confirm",
              )}
              rules={{
                validate: (value: string, formValues: FormValues) =>
                  value === formValues.password
                    ? undefined
                    : t(
                        "desktop.settings.integrations.calendars.form.password_mismatch",
                      ),
              }}
            >
              {(props) => <Input {...props} type="password" />}
            </Field>
          </div>
        </PageForm>
      </FormProvider>
    </View>
  )
}

export default Exchange
