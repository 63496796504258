import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import { BUILDINGS_PATHS } from "./constants"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchFloorQuery } from "../../../redux/api/floors"
import { FloorResponse } from "../../../redux/api/floors/types"

import Loader from "../../../components/basic/Loader"
import Breadcrumbs from "../../../components/Breadcrumbs"
import BuildingFloorForm from "../../../components/Form/BuildingFloorForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./EditBuildingFloors.sass"

type ParamsType = {
  building_id: string
  floor_id: string
}

const DEFAULT_FLOOR = {
  name: "",
} as Partial<FloorResponse>

const EditBuildingFloors = () => {
  const { t } = useTranslation()

  const { building_id, floor_id } = useParams<ParamsType>()

  const {
    data: floor = DEFAULT_FLOOR,
    isLoading,
    error,
  } = useFetchFloorQuery(floor_id ? { id: floor_id } : skipToken)

  const buildingFloorNotFound =
    (!!floor_id && !floor.id) ||
    (error && "status" in error && error.status === 404)

  return (
    <View className="EditBuildingFloors">
      {!isLoading && (
        <>
          <Breadcrumbs
            urls={[
              BUILDINGS_PATHS.root,
              BUILDINGS_PATHS.edit.replace(":building_id", building_id),
            ]}
            depth={3}
            values={[
              t("desktop.settings.buildings.title"),
              t("desktop.settings.buildings.edit_building_breadcrumb"),
              floor?.id
                ? t("desktop.settings.buildings.edit_floor_breadcrumb")
                : t("desktop.settings.buildings.add_floor_breadcrumb"),
            ]}
          />

          <Intro isConstrained>
            {floor?.id
              ? t("desktop.settings.buildings.edit_floor_title")
              : t("desktop.settings.buildings.add_floor_title")}
          </Intro>

          <Space size={0.75} />
        </>
      )}

      {isLoading ? (
        <Loader />
      ) : buildingFloorNotFound ? (
        <p>{t("desktop.settings.buildings.floor_not_found")}</p>
      ) : (
        <BuildingFloorForm floor={floor} buildingId={building_id} />
      )}
    </View>
  )
}

export default EditBuildingFloors
