import { useCallback, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { visitorSettingUserURL } from "../../../api"
import { useToast } from "../../../hooks/useToast"
import { AsyncCheckbox } from "../../advanced/AsyncCheckbox"
import { AsyncSwitch } from "../../advanced/AsyncToggle"
import Card from "../../basic/Card"
import Loader from "../../basic/Loader"
import { UncontrolledField } from "../../Field"

import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"
import { selectVisitorSettingsUser } from "../../../redux/visitor_settings/selectors"
import { VisitorSettingUserResponse } from "../../../redux/visitor_settings/types"
import { fetchVisitorUserSetting } from "../../../redux/visitor_settings/visitorSettingsSlice"

import "./VisitorSettingsUserForm.sass"

const VisitorSettingsUserForm = () => {
  const { t } = useTranslation()
  const { infoToast } = useToast()

  const actions = useActions({
    fetchVisitorUserSetting: () => fetchVisitorUserSetting(),
  })

  const { entry: visitorSettingsUser, isLoaded } = useAppSelector(
    selectVisitorSettingsUser,
  )

  const [visitorSettingUser, setVisitorSetting] =
    useState<VisitorSettingUserResponse>()

  const {
    enable_general_notification,
    send_app_notification,
    health_screening_notification,
    send_arrival_email_notification,
    send_slack_notification,
    send_sms_notification,
    document_signing_enabled,
  } = visitorSettingsUser ?? {}

  useEffect(() => {
    if (isLoaded) {
      setVisitorSetting({
        enable_general_notification: enable_general_notification ?? false,
        send_app_notification: send_app_notification ?? false,
        send_sms_notification: send_sms_notification ?? false,
        send_arrival_email_notification:
          send_arrival_email_notification ?? false,
        send_slack_notification: send_slack_notification ?? false,
        health_screening_notification: health_screening_notification ?? false,
        document_signing_enabled: document_signing_enabled ?? false,
      })
    }
  }, [
    isLoaded,
    enable_general_notification,
    send_app_notification,
    health_screening_notification,
    send_arrival_email_notification,
    send_slack_notification,
    send_sms_notification,
    document_signing_enabled,
  ])

  const refresh = useCallback(
    (value: boolean) => {
      if (value) {
        infoToast(
          t(
            "desktop.settings.visitors.visitor_settings.settings_enabled_toast",
          ),
        )
      } else {
        infoToast(
          t(
            "desktop.settings.visitors.visitor_settings.settings_disabled_toast",
          ),
        )
      }

      actions.fetchVisitorUserSetting()
    },
    [actions, infoToast, t],
  )

  useEffect(() => {
    actions.fetchVisitorUserSetting()
  }, [actions])

  return (
    <div className="VisitorSettingsUserForm">
      <Card>
        {!isLoaded ? (
          <Loader />
        ) : (
          <div className="fields">
            <UncontrolledField>
              <AsyncSwitch
                urlGenerator={() => visitorSettingUserURL()}
                bodyGenerator={(value) => ({
                  ...visitorSettingUser,
                  enable_general_notification: value,
                })}
                refresh={refresh}
                value={visitorSettingUser?.enable_general_notification ?? false}
                label={t(
                  "desktop.settings.visitors.visitor_settings.enable_general_notifications",
                )}
                updateMode={true}
              />
            </UncontrolledField>
            {/* <UncontrolledField className="field-width-50">
							<AsyncCheckbox
								urlGenerator={() => visitorSettingUserURL()}
								bodyGenerator={(value) => ({
									...visitorSettingUser,
									send_app_notification: value,
								})}
								refresh={refresh}
								value={visitorSettingUser?.send_app_notification ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.visitor_arrival_joan_app_push_notifications",
								)}
								updateMode={true}
								disabled={!!!enable_general_notification}
								isSecondary
							/>
						</UncontrolledField> */}
            {/* 						<UncontrolledField className="field-width-50">
							<AsyncCheckbox
								urlGenerator={() => visitorSettingUserURL()}
								bodyGenerator={(value) => ({
									...visitorSettingUser,
									send_slack_notification: value,
								})}
								refresh={refresh}
								value={visitorSettingUser?.send_slack_notification ?? false}
								label={t(
									"desktop.settings.visitors.visitor_settings.link_to_joan_push_notifications",
								)}
								updateMode={true}
								disabled={!!!enable_general_notification}
								isSecondary
							/>
						</UncontrolledField> */}
            {/* <div className="StoreIcons">
						<a href="https://google.com" target="_blank" rel="noreferrer">
							<AppStoreSVG />
						</a>
						<a href="https://google.com" target="_blank" rel="noreferrer">
							<GooglePlaySVG />
						</a>
					</div> */}
            {/* <UncontrolledField className="field-width-50">
							<AsyncCheckbox
								urlGenerator={() => visitorSettingUserURL()}
								bodyGenerator={(value) => ({
									...visitorSettingUser,
									health_screening_notification: value,
								})}
								refresh={refresh}
								value={
									visitorSettingUser?.health_screening_notification ?? false
								}
								label="Visitor health screening notifications"
								updateMode={true}
								disabled={!!!enable_general_notification}
								isSecondary
							/>
						</UncontrolledField> */}
            <div className="SettingsGroup">
              <div className="Row">
                <div className="FieldWrapper">
                  <p className="Subtext mw-50">
                    {t(
                      "desktop.settings.visitors.visitor_settings.select_your_preferred_notification_methods",
                    )}
                  </p>
                </div>
              </div>
              <div className="Row">
                <UncontrolledField className="field-width-50">
                  <AsyncCheckbox
                    urlGenerator={() => visitorSettingUserURL()}
                    bodyGenerator={(value) => ({
                      ...visitorSettingUser,
                      send_arrival_email_notification: value,
                    })}
                    refresh={refresh}
                    value={
                      visitorSettingUser?.send_arrival_email_notification ??
                      false
                    }
                    label={t(
                      "desktop.settings.visitors.visitor_settings.visitor_arrival_email_notifications",
                    )}
                    updateMode={true}
                    disabled={!enable_general_notification}
                    isSecondary
                  />
                </UncontrolledField>
                <UncontrolledField className="field-width-50">
                  <AsyncCheckbox
                    urlGenerator={() => visitorSettingUserURL()}
                    bodyGenerator={(value) => ({
                      ...visitorSettingUser,
                      document_signing_enabled: value,
                    })}
                    refresh={refresh}
                    value={
                      visitorSettingUser?.document_signing_enabled ?? false
                    }
                    label="Visitor document signing notifications"
                    updateMode={true}
                    disabled={!enable_general_notification}
                    isSecondary
                  />
                </UncontrolledField>
              </div>
              <div className="Row">
                <UncontrolledField className="field-width-50">
                  <AsyncCheckbox
                    urlGenerator={() => visitorSettingUserURL()}
                    bodyGenerator={(value) => ({
                      ...visitorSettingUser,
                      send_sms_notification: value,
                    })}
                    refresh={refresh}
                    value={visitorSettingUser?.send_sms_notification ?? false}
                    label={t(
                      "desktop.settings.visitors.visitor_settings.visitor_arrival_sms_notification",
                    )}
                    description={t(
                      "desktop.settings.visitors.visitor_settings.visitor_arrival_sms_notification_details",
                    )}
                    updateMode={true}
                    disabled={!enable_general_notification}
                    isSecondary
                  />
                </UncontrolledField>
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}
export default VisitorSettingsUserForm
