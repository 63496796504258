import React from "react"

import { Trans, useTranslation } from "react-i18next"

import { useToast } from "../../../hooks/useToast"
import { UploadFile } from "../../basic/UploadFile"
import Space from "../../Space"
import { useModals } from "@mattjennings/react-modal-stack"

import { importInvites } from "../../../redux/invites/invitesSlice"
import { selectInvites } from "../../../redux/invites/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./InviteCSVForm.sass"

const InviteCSVForm = () => {
  const { t } = useTranslation()
  const { closeModal } = useModals()
  const { infoToast, errorToast } = useToast()

  const { isSubmitting: areFilesLoading } = useAppSelector(selectInvites)

  const actions = useActions({
    importInvites: (file: File) => importInvites(file),
  })

  async function handleChange(files: FileList | null) {
    const file = files && files[0]

    if (file) {
      const response = await actions.importInvites(file)

      if (importInvites.rejected.match(response)) {
        errorToast(response.error?.message)
      }

      if (importInvites.fulfilled.match(response)) {
        infoToast(t("desktop.manage.visitors.invite.csv_invite.import_success"))
        closeModal()
      }
    }
  }

  return (
    <div className="InviteCSVForm ModalForm">
      <div className="title">
        <h1>{t("desktop.manage.visitors.invite.csv_invite.title")}</h1>
      </div>
      <div className="close" onClick={() => closeModal()}>
        <CrossSVG />
      </div>
      <div>
        <div>
          <Trans components={{ code: <code /> }}>
            {"desktop.manage.visitors.invite.csv_invite.instructions"}
          </Trans>
        </div>
      </div>
      <Space size={0.75} />
      <div>
        {t("desktop.manage.visitors.invite.csv_invite.file_example")}
        <Space size={0.25} />
        <div>
          <code>
            Jane Doe,jane@example.com,2023-07-10T09:00:00Z,2023-07-10T17:00:00Z,
            Headquarters,allan@example.com,Aditional info,Visionect,040111222
          </code>
        </div>
        <div>
          <code>
            John Doe,john@example.com,2023-07-10T09:00:00Z,2023-07-10T17:00:00Z,
            Headquarters,allan@example.com
          </code>
        </div>
        <div>
          <code>
            Sue Roe,sue@example.com,2023-07-10T09:00:00Z,2023-07-10T17:00:00Z,
            Headquarters,allan@example.com,,,
          </code>
        </div>
      </div>
      <Space size={0.75} />
      <div className="actions">
        {!areFilesLoading ? (
          <UploadFile onChange={handleChange} accept="text/csv">
            {t("desktop.manage.visitors.invite.csv_invite.choose_file")}
          </UploadFile>
        ) : (
          <span>
            {t("desktop.manage.visitors.invite.csv_invite.uploading")}
          </span>
        )}
      </div>
    </div>
  )
}

export default InviteCSVForm
