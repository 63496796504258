import { useState } from "react"

import classNames from "classnames"

import { useId } from "../../../hooks/useId"
import { Children } from "../../../types/sharedTypes"
import { Collapse } from "../Collapse"

import ChevronSVG from "../../../assets/images/icons/Chevron.svg"

import "./style.sass"

interface AccordionProps {
  header: Children
  headerClosed?: Children
  children: Children
  className?: string
  itemKey?: string
  defaultOpen?: boolean
}

const Accordion = ({
  header,
  headerClosed,
  children,
  className,
  itemKey,
  defaultOpen = false,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const key = useId(itemKey)

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev)
  }

  const cn = classNames("Accordion", className)
  const cnHeader = classNames("Accordion__header", { isOpened: isOpen })
  const headerContent = isOpen ? header : headerClosed ? headerClosed : header

  return (
    <div className={cn}>
      <button type="button" className={cnHeader} onClick={toggleAccordion}>
        <span className="Accordion__header__content">{headerContent}</span>
        <ChevronSVG className="Accordion__header__chevron" />
      </button>
      <Collapse
        isExtended={isOpen}
        itemKey={key}
        className="Accordion__content"
      >
        {children}
      </Collapse>
    </div>
  )
}

export default Accordion
