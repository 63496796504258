import { api } from ".."
import { customContentListURL, customContentURL } from "../../../api"
import {
  CustomContentFetchOptions,
  CustomContentRequest,
  CustomContentResponse,
  CustomContentsResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const customContent = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchCustomContentList: builder.query<
      CustomContentsResponse,
      CustomContentFetchOptions | void
    >({
      query: ({ devices, ...options } = {}) => {
        const params = {
          ...(devices && devices !== FilterSpecialValues.ALL && { devices }),
          ...options,
        }

        return {
          url: customContentListURL({ ...params }),
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "CustomContent" as const,
                id,
              })),
              { type: "CustomContent", id: "LIST" },
            ]
          : [{ type: "CustomContent", id: "LIST" }],
    }),

    fetchCustomContent: builder.query<CustomContentResponse, string>({
      query: (id) => ({
        url: customContentURL(id),
      }),
      keepUnusedDataFor: 0,
      providesTags: (_result, _error, id) => [{ type: "CustomContent", id }],
    }),

    createCustomContent: builder.mutation<
      CustomContentResponse,
      CustomContentRequest
    >({
      query: (body) => ({
        url: customContentListURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: ["CustomContent"],
    }),

    updateCustomContent: builder.mutation<
      CustomContentResponse,
      CustomContentRequest
    >({
      query: ({ id, ...body }) => ({
        url: customContentURL(id?.toString()),
        method: "PUT",
        body,
      }),
      invalidatesTags: ["CustomContent"],
    }),

    destroyCustomContent: builder.mutation<void, number[]>({
      query: (ids) => {
        return {
          url: customContentURL(ids.join(",")),
          method: "DELETE",
        }
      },
      invalidatesTags: ["CustomContent"],
    }),
  }),
})

export const {
  useFetchCustomContentListQuery,
  useFetchCustomContentQuery,
  useCreateCustomContentMutation,
  useUpdateCustomContentMutation,
  useDestroyCustomContentMutation,
} = customContent
