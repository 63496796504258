import React from "react"

import { useTranslation } from "react-i18next"

import { useBookContext } from "../../../contexts/Mobile/BookContext"

import Button from "../../../components/advanced/Button"
import { CategoryRow } from "../../../components/Mobile/CategoryRow"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import "./Title.sass"

const Title = () => {
  const { title, onChangeTitle, goToHome, goToScreen } = useBookContext()

  const { t } = useTranslation()

  const handleClose = () => goToHome()

  const handleNext = () => goToScreen("summary")

  return (
    <SafeViewArea className="Title">
      <TopNav backArrow={true} onClose={handleClose} />
      <div className="body">
        <div className="info">{t("mobile.book.title_info")}</div>
        <input
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeTitle(e.target.value)
          }}
          placeholder={t("mobile.book.title")}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleNext()
            }
          }}
        />
        <CategoryRow name={t("mobile.general.next_step")}>
          <div className="next-button">
            <Button variant="mobile-action" onClick={handleNext}>
              {t("mobile.book.overview")}
            </Button>
          </div>
        </CategoryRow>
      </div>
    </SafeViewArea>
  )
}

export default Title
