import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useCurrencyFormatter } from "../../../hooks/useCurrencyFormatter"
import {
  getSubscriptionIcon,
  toFriendlyName,
  toFriendlyTitle,
} from "../../../screens/Settings/Billing/utils"
import Button from "../../advanced/Button"
import Card from "../../basic/Card"

import {
  ACTIVE_LICENSE_STATUSES,
  SUBSCRIPTION_STATUSES,
} from "../../../redux/api/billing/constants"
import { Subscription } from "../../../redux/api/billing/subscriptions/types"
import { PlanType } from "../../../redux/api/billing/types"

import "./styles.sass"

export type SubscriptionCardProps = {
  id?: string
  subscriptions: Subscription[]
  onModifyPlanClick?: (subscription: Subscription) => void
  onSelectPlanClick?: (subscription: Subscription) => void
}

/**
 * SubscriptionCard
 */
const SubscriptionCard = ({
  id,
  subscriptions,
  onModifyPlanClick,
  onSelectPlanClick,
}: SubscriptionCardProps) => {
  const { plan_type } = subscriptions[0] ?? {}

  return (
    <Card
      className="SubscriptionCard"
      header={<CardHeader planType={plan_type} id={id} />}
    >
      <div className="SubscriptionCard__grid">
        {subscriptions.map((subscription) => {
          return (
            <SubscriptionDetails
              key={subscription.id}
              subscription={subscription}
              onModifyPlanClick={onModifyPlanClick}
              onSelectPlanClick={onSelectPlanClick}
            />
          )
        })}
      </div>
      <div className="plan-icon">
        {getSubscriptionIcon(plan_type)({ className: "plan-icon" })}
      </div>
    </Card>
  )
}

/**
 * CardHeader
 */
const CardHeader = ({ planType, id }: { planType: PlanType; id?: string }) => (
  <div className="subscription-header" id={id}>
    {toFriendlyTitle(planType)}
  </div>
)

type SubscriptionDetailsProps = {
  subscription: Subscription
  onModifyPlanClick?: (subscription: Subscription) => void
  onSelectPlanClick?: (subscription: Subscription) => void
}

/**
 * SubscriptionDetails
 */
const SubscriptionDetails = ({
  subscription,
  onModifyPlanClick,
  onSelectPlanClick,
}: SubscriptionDetailsProps) => {
  const {
    unit,
    amount,
    plan_variation,
    next_billing_date,
    trial_end,
    status,
    prepaid_code,
    plan_type,
  } = subscription
  const { t } = useTranslation()
  const currency = useCurrencyFormatter(plan_variation.currency)

  const isActive = ACTIVE_LICENSE_STATUSES.includes(status)
  const isFuture = status === SUBSCRIPTION_STATUSES.FUTURE
  const isTrial = status === SUBSCRIPTION_STATUSES.IN_TRIAL

  const handleOnClick = () => {
    isTrial
      ? onSelectPlanClick?.(subscription)
      : onModifyPlanClick?.(subscription)
  }

  const getDates = () => {
    switch (status) {
      case SUBSCRIPTION_STATUSES.IN_TRIAL:
        return (
          <div className="small-details">
            {t("desktop.components.subscription_card.trial_ends")}:{" "}
            <strong>{dayjs(trial_end).format("L")}</strong>
          </div>
        )

      case SUBSCRIPTION_STATUSES.ACTIVE:
        return (
          <div className="small-details">
            {t("desktop.components.subscription_card.next_billing_date")}:{" "}
            <strong>{dayjs(next_billing_date).format("L")}</strong>
          </div>
        )

      case SUBSCRIPTION_STATUSES.NON_RENEWING: {
        const startDate = prepaid_code?.activated_at
          ? dayjs(prepaid_code?.activated_at)
          : dayjs(next_billing_date).subtract(
              1,
              plan_variation.period_unit === "month" ? "month" : "year",
            )

        return (
          <div className="small-details">
            {t("desktop.components.subscription_card.validity")}:{" "}
            <strong>
              {startDate.format("L")} - {dayjs(next_billing_date).format("L")}
            </strong>
          </div>
        )
      }

      case SUBSCRIPTION_STATUSES.FUTURE: {
        const startDate = dayjs(prepaid_code?.activated_at)
        const endDate = startDate.add(
          1,
          plan_variation.period_unit === "month" ? "month" : "year",
        )

        return (
          <div className="small-details">
            {t("desktop.components.subscription_card.validity")}:{" "}
            <strong>
              {startDate.format("L")} - {endDate.format("L")}
            </strong>
          </div>
        )
      }
    }
  }

  const getType = () => {
    switch (status) {
      case SUBSCRIPTION_STATUSES.IN_TRIAL:
        return t("desktop.components.subscription_card.types.trial")
      case SUBSCRIPTION_STATUSES.ACTIVE:
        return t("desktop.components.subscription_card.types.auto_renewing")
      case SUBSCRIPTION_STATUSES.NON_RENEWING:
        return t("desktop.components.subscription_card.types.non_renewing")
      case SUBSCRIPTION_STATUSES.FUTURE:
        return t("desktop.components.subscription_card.types.future")
    }
  }

  const getPlanInfo = () => {
    if (isActive) {
      return isTrial
        ? t("desktop.components.subscription_card.free_trial")
        : t("desktop.components.subscription_card.subscription")
    }

    if (isFuture) {
      return t("desktop.components.subscription_card.inactive")
    }

    return t("desktop.components.subscription_card.no_plan_selected")
  }

  return (
    <div className="subscription-details">
      <div className="plan-info">
        <div>
          <div className="plan-info-title">
            {isActive || isFuture
              ? toFriendlyName(plan_type)
              : t("desktop.components.subscription_card.inactive")}
          </div>
          <div className="plan-info-desc">{getPlanInfo()}</div>
        </div>
      </div>
      <div className="licenses">
        {t("desktop.components.subscription_card.licenses", {
          count: unit[0].quantity,
          unit: unit[0].type,
        })}
      </div>
      <div className="active">
        {t("desktop.components.subscription_card.active_units", {
          count: unit[0].quantity_used,
          unit: unit[0].type,
        })}
      </div>
      {getDates()}
      <div className="small-details">
        {t("desktop.components.subscription_card.type")}:{" "}
        <strong>{getType()}</strong>
      </div>
      <div className="small-details">
        {t("desktop.components.subscription_card.cost")}:{" "}
        <strong>
          {currency.format(amount ?? 0)} / {plan_variation.period_unit}
        </strong>
      </div>
      {prepaid_code && (
        <div className="small-details">
          {t("desktop.components.subscription_card.prepaid_code")}:{" "}
          <strong>{prepaid_code.code}</strong>
        </div>
      )}
      {(status === SUBSCRIPTION_STATUSES.ACTIVE || isTrial) && (
        <Button
          variant="secondary"
          onClick={handleOnClick}
          className="subscription-details-modify"
        >
          {t(
            isTrial
              ? "desktop.components.subscription_card.actions.select_subscription_plan"
              : "desktop.components.subscription_card.actions.modify_plan",
          )}
        </Button>
      )}
    </div>
  )
}

export default SubscriptionCard
