import React from "react"

import classNames from "classnames"

import { Children } from "../../../types/sharedTypes"

import "./style.sass"

export type WarningCardProps = {
  children: Children
  className?: string
  isCentered?: boolean
}
const WarningCard = ({
  children,
  className,
  isCentered = false,
}: WarningCardProps) => {
  const cn = classNames("WarningCard", className, { centered: isCentered })

  return <div className={cn}>{children}</div>
}

export default WarningCard
