import React, { PropsWithChildren } from "react"

import { AnimatePresence, motion } from "framer-motion"
import { Switch, useLocation } from "react-router-dom"

export const AnimatedSwitch = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  return (
    <AnimatePresence mode="popLayout">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Switch location={location}>{children}</Switch>
      </motion.div>
    </AnimatePresence>
  )
}
