import React, { useEffect, useRef } from "react"

import { deviceURL, postJSON } from "../../../api"
import { Capacitor, PluginListenerHandle } from "@capacitor/core"
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications"

import { useAppSelector } from "../../../redux/reducers"

export const PushNotificationsContext = React.createContext({})

const PushNotificationsProvider: React.FC = () => {
  const pushRef1 = useRef<PluginListenerHandle>()
  const pushRef2 = useRef<PluginListenerHandle>()
  const pushRef3 = useRef<PluginListenerHandle>()
  const pushRef4 = useRef<PluginListenerHandle>()

  const access_token = useAppSelector((state) => state.auth.access_token)
  const {
    entry: { push_enabled },
  } = useAppSelector((state) => state.user)

  const isPushNotificationsAvailable =
    Capacitor.isPluginAvailable("PushNotifications")

  useEffect(() => {
    if (!push_enabled || !access_token || !isPushNotificationsAvailable) return

    const initializePushNotifications = async () => {
      pushRef1.current = await PushNotifications.addListener(
        "registration",
        (token: Token) => {
          console.log("Push registration success, token: " + token.value)
          const params = {
            token: token.value,
            platform: Capacitor.getPlatform(),
          }

          postJSON(deviceURL(), { body: params }, access_token).then(() => {
            console.log("Token registered with our backend.")
          })
        },
      )

      pushRef2.current = await PushNotifications.addListener(
        "registrationError",
        (error: any) => {
          alert("Error on registration: " + JSON.stringify(error))
        },
      )

      // Show us the notification payload if the app is open on our device
      pushRef3.current = await PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          console.log("Push received: " + JSON.stringify(notification))
        },
      )

      // Method called when tapping on a notification
      pushRef4.current = await PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          console.log("Push action performed: " + JSON.stringify(notification))
        },
      )

      let permStatus = await PushNotifications.checkPermissions()

      if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== "granted") {
        console.log("User denied permissions.")
        return
      }

      await PushNotifications.register()
    }

    initializePushNotifications()

    return () => {
      pushRef1.current?.remove()
      pushRef2.current?.remove()
      pushRef3.current?.remove()
      pushRef4.current?.remove()
    }
  }, [isPushNotificationsAvailable, access_token, push_enabled])

  return (
    <PushNotificationsContext.Provider
      value={{}}
    ></PushNotificationsContext.Provider>
  )
}

export default PushNotificationsProvider
