import React from "react"

import { useTranslation } from "react-i18next"

import { useToast } from "../../../../hooks/useToast"
import { openBrowserWindow } from "../../../../utils"
import ChatBotCard from "./ChatBotsCard"
import { CHAT_BOTS } from "./constants"

import {
  useFetchIntegrationsQuery,
  useRegisterIntegrationMutation,
} from "../../../../redux/api/integrations"
import { IntegrationTypes } from "../../../../redux/api/integrations/types"
import { isRejected } from "../../../../redux/api/types"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./styles.sass"

const ChatBots = () => {
  const { t } = useTranslation()
  const { errorToast } = useToast()

  const { data: { results: chatBots = [] } = {}, isLoading } =
    useFetchIntegrationsQuery()
  const [registerIntegration, { isLoading: isSubmitting }] =
    useRegisterIntegrationMutation()

  const handleOnChatBotClick = async (type: IntegrationTypes) => {
    const response = await registerIntegration(type)
    if (isRejected(response)) {
      errorToast(response.error.message)
      return
    }

    openBrowserWindow(response.data.url, type)
  }

  return (
    <View className="ChatBot">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.chat_bots.title"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.integrations.chat_bots.intro")}
      </Intro>

      <Space size={0.75} />

      {isLoading && <Loader className="loader" />}

      {!isLoading && (
        <div className="ChatBot__list">
          {chatBots.map(({ type, connected }) => (
            <ChatBotCard
              key={type}
              type={type}
              title={t(CHAT_BOTS[type]?.title)}
              description={t(CHAT_BOTS[type]?.intro)}
              icon={CHAT_BOTS[type]?.icon}
              onClick={handleOnChatBotClick}
              isConnected={connected}
              disabled={isSubmitting}
            />
          ))}
        </div>
      )}
    </View>
  )
}

export default ChatBots
