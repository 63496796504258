import React from "react"

import ReactModal from "react-modal"

import ImportUsersErrorTable, {
  ImportUsersErrorTableProps,
} from "../screens/Settings/UserDirectory/ImportUsersErrorTable"
import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

type Props = ImportUsersErrorTableProps & {
  open: boolean
}

export default function ImportUsersErrorsModal({ open, errors }: Props) {
  const { closeAllModals } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeAllModals()}
      ariaHideApp={false}
    >
      <ImportUsersErrorTable errors={errors} />
    </ReactModal>
  )
}
