import React, { ReactNode } from "react"

import { useTranslation } from "react-i18next"

import { useCustomContentContext } from "../CustomContentContext"

import Breadcrumbs from "../../../../../components/Breadcrumbs"
import Intro from "../../../../../components/Intro"
import Space from "../../../../../components/Space"
import View from "../../../../../components/View"

import "./styles.sass"

type Props = {
  children: ReactNode
}

const CustomContentType = ({ children }: Props) => {
  const { t } = useTranslation()

  const { isUpdateMode } = useCustomContentContext()

  const customContentFormTitle = isUpdateMode
    ? t("desktop.settings.rooms.custom_content.forms.general.edit_title")
    : t("desktop.settings.rooms.custom_content.forms.general.add_title")

  const customContentTypeFormTitle = isUpdateMode
    ? t("desktop.settings.rooms.custom_content.forms.content_type.edit_title")
    : t("desktop.settings.rooms.custom_content.forms.content_type.add_title")

  return (
    <View className="SettingsRoomsCustomContentTypes">
      <Breadcrumbs
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.custom_content.title"),
          customContentFormTitle,
          customContentTypeFormTitle,
        ]}
      />

      <Intro>
        <div className="description">
          {t("desktop.settings.rooms.custom_content.forms.content_type.intro")}
        </div>
      </Intro>

      <Space size={0.75} />

      {children}
    </View>
  )
}

export default CustomContentType
