export enum DocumentBulkAction {
  PUBLISH = "PUBLISH",
  UNPUBLISH = "UNPUBLISH",
  DELETE = "DELETE",
}

export enum DocumentStatusType {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export type DocumentResponse = {
  id: string
  title: string
  description: string
  required_signing: boolean
  visitor_must_re_sign: boolean
  status: DocumentStatusType
  created_at: string
  modified_at: string
}

export type DocumentRequest = {
  title: string
  description: string
  required_signing: boolean
  visitor_must_re_sign: boolean
  status?: DocumentStatusType
}

export type DocumentViewType = Pick<DocumentResponse, "title" | "description">

export type DocumentBulkActionRequest = {
  document_ids: string[]
  action: DocumentBulkAction
}

export type DocumentError = {
  detail: string
}

export type DocumentsResponse = {
  offset: number
  count: number
  search: string
  results: DocumentResponse[]
}

export type DocumentConfigAction = {
  type: string
  payload: Array<DocumentResponse> | DocumentResponse | DocumentError | string
}
