import React from "react"

import { useTranslation } from "react-i18next"
import { NavLink, useHistory } from "react-router-dom"

import { getLabel } from "../../../utils"

import { DepartmentResponse } from "../../../redux/api/departments/types"
import { useFetchScimSettingsQuery } from "../../../redux/api/scim"
import { useAppSelector } from "../../../redux/reducers"
import { formatUser } from "../../../redux/user/utils"

import Avatar from "../../../components/advanced/Avatar"
import { BottomNav } from "../../../components/Mobile/BottomNav"
import SafeViewArea from "../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../components/Mobile/TopNav"

import ChatSVG from "../../../assets/images/icons/Chat.svg"
import LogoutSVG from "../../../assets/images/icons/Logout.svg"
import PencilSVG from "../../../assets/images/icons/Pencil.svg"
import PhoneSVG from "../../../assets/images/icons/Phone.svg"
import SettingsSVG from "../../../assets/images/icons/Settings.svg"

import "./Info.sass"

const LinkBox: React.FC = () => {
  const { t } = useTranslation()

  const version = import.meta.env.VITE_APP_VERSION

  const onFeedBackClick = () => {
    window.location.href =
      `mailto:${getLabel("links.supportEmail")}?subject=App Feedback&body=App Version ` +
      version
  }

  return (
    <div className="LinkBox">
      <NavLink to="/profile/settings">
        <SettingsSVG />
        <div>{t("mobile.profile.settings")}</div>
      </NavLink>
      <NavLink to="#" onClick={onFeedBackClick}>
        <ChatSVG />
        <div>{t("mobile.profile.feedback")}</div>
      </NavLink>
      <NavLink to="/profile/about">
        <PhoneSVG />
        <div>{t("mobile.profile.about")}</div>
      </NavLink>
    </div>
  )
}

const Info: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { entry } = useAppSelector((state) => state.user)

  const { data: scimSettings } = useFetchScimSettingsQuery()
  const { enabled: isSCIMEnabled } = scimSettings ?? {}

  const isEditingEnabled = !isSCIMEnabled

  const logOut = () => {
    history.push("/auth/logout")
  }

  return (
    <SafeViewArea className="Info">
      <TopNav backArrow={false} rightIcon={<LogoutSVG onClick={logOut} />} />
      <div className="head">
        <Avatar user={entry} size="large" hasDefaultAvatar />
        <h2>{formatUser(entry)}</h2>
      </div>
      <div className="body">
        <div className="fields">
          <div className="field">
            <div className="data">
              <div className="label">{t("mobile.profile.name")}</div>
              <div className="value">{formatUser(entry)}</div>
            </div>
            {isEditingEnabled && (
              <div className="edit">
                <NavLink to="/profile/edit">
                  <PencilSVG />
                </NavLink>
              </div>
            )}
          </div>
          <div className="field">
            <div className="data">
              <div className="label">{t("mobile.profile.email")}</div>
              <div className="value">{entry.email}</div>
            </div>
          </div>
          {entry.departments && entry.departments.length > 0 && (
            <div className="field">
              <div className="data">
                <div className="label">
                  {t("mobile.profile.my_departments")}
                </div>
                {entry.departments.map((dep: DepartmentResponse, i: number) => (
                  <span key={dep.id} className="value">
                    {dep.name}
                    {entry.departments.length !== i + 1 && ", "}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        <LinkBox />
      </div>
      <BottomNav />
    </SafeViewArea>
  )
}

export default Info
