import dayjs, { Dayjs } from "dayjs"

import { toInternalTime } from "../../../utils"

import { useFetchTimeslotsQuery } from "../../../redux/api/timeslots"
import {
  DeskScheduleReservation,
  ScheduleDeskDataRow,
} from "../../../redux/desk_schedule/types"
import { useAppSelector } from "../../../redux/reducers"
import { TimeslotResponse } from "../../../redux/timeslots/types"
import { getAvailableSlots } from "../../../redux/timeslots/utils"
import { selectUser } from "../../../redux/user/selectors"
import {
  formatUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../../redux/user/utils"

import ReservationData from "../../../components/Manage/ReservationData"
import { ScheduleCheckinLabel } from "../../../components/Manage/ScheduleCheckingLabel"

type DeskCellProps = {
  day: Dayjs
  header: ScheduleDeskDataRow
  schedule: DeskScheduleReservation[]
  renderVacancy?: (d: {
    hasAvailableSlots: boolean
    day: Dayjs
    disabled?: boolean
  }) => JSX.Element
  onClick: (r: DeskScheduleReservation) => void
  rowIndex: string
  disabled?: boolean
}

export default function DeskScheduleCell({
  day,
  header,
  schedule,
  onClick,
  renderVacancy,
  rowIndex,
  disabled,
}: DeskCellProps) {
  const { data: timeslots = [] } = useFetchTimeslotsQuery()
  const { entry: user } = useAppSelector(selectUser)
  const isAdminOrManager = isOfficeManager(user) || isPortalAdmin(user)
  const slots = timeslots.filter((slot: TimeslotResponse) => !!slot.active)

  const bookedSlots = schedule.map((r) => ({
    from: toInternalTime(dayjs(r.start)),
    to: toInternalTime(dayjs(r.end)),
  }))

  const availableSlots = getAvailableSlots(slots, bookedSlots)

  return (
    <>
      {schedule.map((entry, k) => (
        <ReservationData
          key={`entry-${rowIndex}-${k}`}
          onClick={() => onClick(entry)}
          showOwnership={false}
          idString={entry.user.email}
          iconType="ellipse"
          isPartial={entry.start ? availableSlots.length > 0 : false}
          label={formatUser(entry.user)}
          user={entry.user}
          isVisit={!!entry.visit_id}
          disabled={
            !isAdminOrManager &&
            entry.user.email !== user.email &&
            !!entry.visit_id
          }
        >
          <ScheduleCheckinLabel day={day} reservation={entry} />
        </ReservationData>
      ))}

      {availableSlots.length > 0 &&
        !!renderVacancy &&
        renderVacancy({
          hasAvailableSlots: availableSlots.length > 0,
          day,
          disabled,
        })}
    </>
  )
}
