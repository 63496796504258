import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { SETTINGS_GROUPS, SettingsGroups } from "../constants"
import { EffectiveSettingsLocation } from "../types"
import SettingsGroup from "./index"

import { ImageSelector } from "../../../../../components/basic/ImageSelector"
import Field from "../../../../../components/Field"

type Props = {
  checked: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  disabled: boolean
  setLogo: (value: File | null) => void
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
  isDeviceSpecificEdit?: boolean
  isUnavailableWithCurrentPlan?: boolean
}

const CustomLogoGroup = ({
  checked,
  onToggleSettingsGroup,
  setLogo,
  disabled,
  effectiveSettingsLocation,
  onLocationClick,
  isDeviceSpecificEdit,
  isUnavailableWithCurrentPlan,
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const isGroupTooltipVisible =
    isDeviceSpecificEdit || isUnavailableWithCurrentPlan

  const groupTooltipText = isUnavailableWithCurrentPlan
    ? t("desktop.settings.rooms.device_settings.form.subscription_unavailable")
    : isDeviceSpecificEdit
      ? t("desktop.settings.rooms.device_settings.form.per_device_unavailable")
      : undefined

  return (
    <SettingsGroup
      label={t("desktop.settings.rooms.device_settings.form.custom_logo.title")}
      checked={checked}
      onChange={(checked) =>
        onToggleSettingsGroup(SETTINGS_GROUPS.CUSTOM_LOGO_GROUP, checked)
      }
      disabled={disabled}
      className="custom-logo"
      effectiveSettingsLocation={effectiveSettingsLocation}
      onLocationClick={onLocationClick}
      id={SETTINGS_GROUPS.CUSTOM_LOGO_GROUP}
      showTooltip={isGroupTooltipVisible}
      tooltipText={groupTooltipText}
    >
      <div className="FieldWrapper Subtext">
        {t("desktop.settings.rooms.device_settings.form.custom_logo.intro")}
      </div>
      <div className="field-description">
        <div>
          <Field control={control} name="device_logo">
            {({ value, ...props }) => (
              <ImageSelector
                {...props}
                label={t("desktop.settings.organization.branding.upload_logo")}
                onChange={setLogo}
                image={value}
                accept="image/png,image/jpeg,image/jpg"
              />
            )}
          </Field>
        </div>
        <div className="info">
          <div>{t("desktop.settings.organization.branding.logo_size")}</div>
          <div>{t("desktop.settings.organization.branding.logo_info")}</div>
        </div>
      </div>
    </SettingsGroup>
  )
}

export default CustomLogoGroup
