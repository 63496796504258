import { useAppSelector } from "../redux/reducers"
import { selectUserPermissions } from "../redux/user/selectors"

export const useCheckForPermission = (permission?: string | string[]) => {
  const permissions: string[] = useAppSelector(selectUserPermissions)

  // If permission is not specified, return as if permission is granted.
  // This is purely for convenience, so that we do not have to  do falsy checks when calling the function.
  if (!permission) {
    return true
  }

  // Check if permission is a string or array and handle accordingly.
  if (Array.isArray(permission)) {
    return permission.every((p) => permissions.includes(p))
  }

  return permissions.includes(permission)
}
