import React from "react"

import ReactModal from "react-modal"

import { RangeFromTo } from "../types/sharedTypes"
import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import ExportForm from "../components/Form/ExportForm"

type Props = {
  open: boolean
  range: RangeFromTo
}

export default function ExportModal({ open, range }: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <ExportForm range={range} />
    </ReactModal>
  )
}
