import React from "react"

import { Trans } from "react-i18next"

import VisitorOverview from "../Overview/VisitorOverview"

import { PLAN_GROUPS } from "../../../redux/api/billing/constants"

import SolutionOverview from "../../../components/advanced/SolutionOverview"

const VisitorsOverviewPage = () => {
  return (
    <SolutionOverview
      planGroup={PLAN_GROUPS.VISITOR}
      intro={
        <Trans i18nKey="desktop.settings.visitors.visitor_settings.subtitle" />
      }
    >
      <VisitorOverview isDefaultExtended />
    </SolutionOverview>
  )
}

export default VisitorsOverviewPage
