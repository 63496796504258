import React, { PropsWithChildren } from "react"

import classNames from "classnames"

import "./VisitorContent.sass"

type Props = {
  className?: string
}

const VisitorContent = ({ className, children }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(["VisitorContent", className])}>{children}</div>
  )
}

export default VisitorContent
