import React, { useEffect } from "react"

import { Trans } from "react-i18next"

import RoomsOverview from "../../Overview/RoomsOverview"

import { PLAN_GROUPS } from "../../../../redux/api/billing/constants"
import { fetchExperiments } from "../../../../redux/experiments/experimentsSlice"
import { useActions } from "../../../../redux/utils"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import SolutionOverview from "../../../../components/advanced/SolutionOverview"

const RoomsOverviewPage = () => {
  const actions = useActions({
    fetchExperiments: () => fetchExperiments(),
  })

  useEffect(() => {
    actions.fetchExperiments()
  }, [actions])

  return (
    <SolutionOverview
      planGroup={PLAN_GROUPS.ROOM}
      intro={
        <Trans i18nKey="desktop.settings.rooms.overview._intro">
          <RedirectLink to="room-management">Room Management</RedirectLink>
        </Trans>
      }
    >
      <RoomsOverview isDefaultExtended />
    </SolutionOverview>
  )
}

export default RoomsOverviewPage
