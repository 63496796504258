import { api } from "../"
import { desksURL, deskURL, qrCodesURL } from "../../../api"
import {
  DeskLabelRequest,
  DeskRequest,
  DeskRequestWithId,
  DeskResponse,
  DesksResponse,
  FetchDeskProps,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

const FETCH_DEFAULTS = {
  limit: 20,
  offset: 0,
}

export const desks = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchDesks: builder.query<DesksResponse, FetchDeskProps>({
      query: ({
        floor,
        building,
        search,
        department_id,
        amenity_id,
        limit,
      }) => {
        const isAllAmenitiesFilter = amenity_id?.[0]
          ? amenity_id[0] === FilterSpecialValues.ALL
          : true

        const params = {
          ...(floor &&
            floor !== FilterSpecialValues.ALL && { floor_id: floor }),
          ...(building &&
            building !== FilterSpecialValues.ALL && { building_id: building }),
          ...(department_id &&
            department_id !== FilterSpecialValues.ALL && {
              department_id:
                department_id === FilterSpecialValues.NOT_ASSIGNED
                  ? ""
                  : department_id,
            }),
          amenity_id:
            isAllAmenitiesFilter || typeof amenity_id === "string"
              ? undefined
              : amenity_id?.join(","),
          name: search,
          limit,
        }
        return {
          url: desksURL({ ...FETCH_DEFAULTS, ...params }),
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Desks" as const,
                id,
              })),
              { type: "Desks", id: "LIST" },
            ]
          : [{ type: "Desks", id: "LIST" }],
    }),

    fetchDesk: builder.query<DeskResponse, string>({
      query: (id) => ({
        url: deskURL(id),
      }),
      providesTags: (_result, _error, arg) => [{ type: "Desks", id: arg }],
    }),

    createDesk: builder.mutation<DeskResponse, DeskRequest>({
      query: (body) => ({
        url: desksURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "Desks", id: "LIST" },
        {
          type: "Departments",
          id: "LIST",
        },
      ],
    }),

    updateDesk: builder.mutation<DeskResponse, DeskRequestWithId>({
      query: ({ id, ...body }) => ({
        url: deskURL(id),
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Desks", id: "LIST" },
        { type: "Desks", id },
        {
          type: "Departments",
          id: "LIST",
        },
      ],
    }),

    destroyDesk: builder.mutation<void, string>({
      query: (id) => ({
        url: deskURL(id),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "Desks", id: "LIST" },
        { type: "Desks", id },
        {
          type: "Departments",
          id: "LIST",
        },
      ],
    }),
    generateDeskLabels: builder.mutation<Blob, DeskLabelRequest>({
      query: (body) => ({
        url: qrCodesURL(),
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
})

export const {
  useFetchDesksQuery,
  useLazyFetchDesksQuery,
  useFetchDeskQuery,
  useLazyFetchDeskQuery,
  useCreateDeskMutation,
  useUpdateDeskMutation,
  useDestroyDeskMutation,
  useGenerateDeskLabelsMutation,
} = desks
