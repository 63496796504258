import { ParseKeys } from "i18next"
import { useTranslation } from "react-i18next"

import { OptionType } from "../types/sharedTypes"

export const useTranslateOptions = <Value = string>(
  options: Omit<OptionType<Value>, "tooltip">[],
) => {
  const { t } = useTranslation()

  return options.map((option) => ({
    ...option,
    label:
      typeof option.label === "string"
        ? t(option.label as ParseKeys)
        : option.label,
  }))
}
