import { api } from ".."
import { integrationsURL } from "../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import { Integrations, IntegrationTypes, RegisterIntegration } from "./types"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
}

type FetchIntegrationsProps = {
  limit?: number
  offset?: number
}
export const integrations = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchIntegrations: builder.query<
      Integrations,
      FetchIntegrationsProps | void
    >({
      query: (props) => ({
        url: integrationsURL({ ...FETCH_DEFAULTS, ...props }),
      }),
      providesTags: ["Integrations"],
    }),

    registerIntegration: builder.mutation<
      RegisterIntegration,
      IntegrationTypes
    >({
      query: (type) => ({
        url: integrationsURL(),
        body: { type },
        method: "POST",
      }),
      invalidatesTags: ["Integrations"],
    }),
  }),
})

export const { useFetchIntegrationsQuery, useRegisterIntegrationMutation } =
  integrations
