import React from "react"

import { useTranslation } from "react-i18next"

import PasswordForm from "./PasswordForm"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const Password = () => {
  const { t } = useTranslation()

  return (
    <View className="Password">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.profile.general.title"),
          t("desktop.settings.profile.password.title"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.profile.password.intro")}
      </Intro>

      <Space size={0.75} />

      <PasswordForm />
    </View>
  )
}

export default Password
