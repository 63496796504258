import { useEffect } from "react"

import { useTranslation } from "react-i18next"

import "./Headway.sass"

const Headway = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const ref = document.getElementsByTagName("meta")[0]
    const script = document.createElement("script")
    const w: any = window

    if (!ref.parentNode) return
    if (w.Headway) return
    if (!import.meta.env.VITE_APP_HEADWAY_ACC) return

    w.HW_config = {
      selector: "#headway-changelog",
      trigger: "#headway-changelog",
      account: import.meta.env.VITE_APP_HEADWAY_ACC,
    }

    script.async = true
    script.src = "https://cdn.headwayapp.co/widget.js"
    ref.parentNode.insertBefore(script, ref)
  }, [])

  return (
    <span id="headway-changelog">
      {t("general.navigation_links.changelog")}
    </span>
  )
}

export default Headway
