import React, { SyntheticEvent, useMemo, useState } from "react"

import { useTranslation } from "react-i18next"

import Button from "../advanced/Button"
import { PinField } from "../Field/PinField"

import Card from "../../components/basic/Card"
import Space from "../../components/Space"

import "./VisitorLoginForm.sass"

type Props = {
  disableRequestMeeting: boolean
  onRequestAdHoc: () => void
  onSubmit: (pin: string) => void
  isLoading: boolean
}

const VisitorLoginForm = ({
  disableRequestMeeting,
  onRequestAdHoc,
  onSubmit,
  isLoading,
}: Props) => {
  const { t } = useTranslation()

  const [appointmentPin, setAppointmentPin] = useState("")

  const isValid = useMemo(() => appointmentPin.length === 6, [appointmentPin])

  function handleSubmit(e: SyntheticEvent) {
    e.stopPropagation()
    e.preventDefault()

    onSubmit && onSubmit(appointmentPin)
  }

  return (
    <form className="VisitorLoginForm" onSubmitCapture={handleSubmit}>
      <Card className="visitor-login">
        <div className="fields">
          <PinField
            label="Appointment PIN"
            value={appointmentPin}
            onChange={setAppointmentPin}
            canEdit={true}
            maxLength={6}
            minLength={6}
          />
        </div>
      </Card>

      <Space size={0.75} />

      <div className={`actions`}>
        <Button
          onClick={handleSubmit}
          isSubmit
          variant="submit"
          className="submit"
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          {t("mobile.auth.log_in")}
        </Button>

        {!disableRequestMeeting && (
          <Button variant="secondary" onClick={onRequestAdHoc}>
            {t(
              "desktop.settings.visitors.devices.form.visitor_request_appointment",
            )}
          </Button>
        )}
      </div>
    </form>
  )
}

export default VisitorLoginForm
