import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { ReservationType } from "../redux/reservations/types"

import DeleteReservationForm from "../components/Form/DeleteReservationForm"

type Props = {
  open: boolean
  reservationId: string
  reservationType: ReservationType
  /**
   * A callback that is called every time the modal does some
   * manipulation about data
   */
  dataUpdate?: () => void
}

export default function DeleteReservationModal({
  open,
  reservationId,
  reservationType,
  dataUpdate,
}: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <DeleteReservationForm
        reservationId={reservationId}
        reservationType={reservationType}
        dataUpdate={dataUpdate}
      />
    </ReactModal>
  )
}
