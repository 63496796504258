import { ThunkApiConfig } from "RootType"

import { get, scimSettingsURL } from "../../api"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import { SCIMSettings } from "./types"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

/**
 *  Thunks
 */
export const fetchSCIM = createAsyncThunk<SCIMSettings, void, ThunkApiConfig>(
  "scim/fetch",
  async (_, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await get(scimSettingsURL(), {}, access_token)

    if (response.ok) {
      return await response.json()
    }

    throw new Error(await getErrorMessage(response))
  },
)

/**
 *  Slice
 */
export type SCIMState = SliceState & {
  entry: SCIMSettings | null
}

const initialState: SCIMState = {
  entry: null,
  ...sliceInitialState,
}

const scimSlice = createSlice({
  name: "scim",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSCIM.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSCIM.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchSCIM.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })
  },
})

export const scimReducer = scimSlice.reducer
