import React from "react"

import { useFormContext } from "react-hook-form"

import { Checkbox, CheckboxProps } from "../../../../components/basic/Checkbox"
import Field from "../../../../components/Field"

const FieldWithCheckbox = ({
  label,
  name,
  className,
  disabled,
  exclusions,
  ...checkboxProps
}: CheckboxProps & { name: string; exclusions?: string[] }) => {
  const { control } = useFormContext()

  return (
    <Field
      control={control}
      name={name}
      className={className}
      exclusions={exclusions}
    >
      {(props) => (
        <Checkbox
          {...checkboxProps}
          {...props}
          label={label}
          disabled={disabled}
        />
      )}
    </Field>
  )
}

export default FieldWithCheckbox
