import { PlanGroup } from "../../../redux/api/billing/types"

const BILLING_ROOT_PATH = "/settings/billing"
const BILLING_OVERVIEW_ROOT_PATH = `${BILLING_ROOT_PATH}/overview`
const BILLING_ROOM_ROOT_PATH = `${BILLING_ROOT_PATH}/room`
const BILLING_DESK_ROOT_PATH = `${BILLING_ROOT_PATH}/desk`
const BILLING_VISITOR_ROOT_PATH = `${BILLING_ROOT_PATH}/visitor`
const BILLING_SIGN_ROOT_PATH = `${BILLING_ROOT_PATH}/sign`

export const BILLING_PATHS = {
  root: BILLING_ROOT_PATH,
  overview: {
    root: BILLING_OVERVIEW_ROOT_PATH,
    companyDetails: `${BILLING_OVERVIEW_ROOT_PATH}/company-details`,
    paymentMethod: `${BILLING_OVERVIEW_ROOT_PATH}/payment-method`,
  },
  invoices: `${BILLING_ROOT_PATH}/invoices`,
  room: {
    root: BILLING_ROOM_ROOT_PATH,
    planSelection: `${BILLING_ROOM_ROOT_PATH}/plan-selection`,
  },
  desk: {
    root: BILLING_DESK_ROOT_PATH,
    planSelection: `${BILLING_DESK_ROOT_PATH}/plan-selection`,
  },
  visitor: {
    root: BILLING_VISITOR_ROOT_PATH,
    planSelection: `${BILLING_VISITOR_ROOT_PATH}/plan-selection`,
  },
  sign: {
    root: BILLING_SIGN_ROOT_PATH,
    planSelection: `${BILLING_SIGN_ROOT_PATH}/plan-selection`,
  },
}

export const getPlanGroupPath = (planGroup: PlanGroup) => {
  switch (planGroup) {
    case "desk":
      return BILLING_PATHS.desk
    case "room":
      return BILLING_PATHS.room
    case "sign":
      return BILLING_PATHS.sign
    case "visitor":
      return BILLING_PATHS.visitor
    default:
      return BILLING_PATHS.room
  }
}
