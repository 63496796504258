import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { VISITOR_BASE_URL } from "./constants"
import { Capacitor } from "@capacitor/core"

import { useAppSelector } from "../../redux/reducers"
import { useActions } from "../../redux/utils"
import { selectVisitorRegistration } from "../../redux/visitor_registration/selectors"
import { resetRegistration } from "../../redux/visitor_registration/visitorRegistrationSlice"

import PrintBadge from "../../components/Visitors/PrintBadge"
import VisitorContent from "../../components/Visitors/VisitorContent"
import VisitorView from "../../components/Visitors/VisitorView"

const Print = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { registration } = useAppSelector(selectVisitorRegistration)

  const actions = useActions({
    resetRegistration: () => resetRegistration(),
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      actions.resetRegistration()
      history.push(`${VISITOR_BASE_URL}/start`)
    }, 10 * 1000)

    return () => clearTimeout(timer)
  }, [actions, history])

  return (
    <VisitorView>
      <VisitorContent>
        <div className="title">
          {t("tablet.visitors.screens.print.printing")}
        </div>
        {registration && Capacitor.isNativePlatform() && <PrintBadge />}
      </VisitorContent>
    </VisitorView>
  )
}

export default Print
