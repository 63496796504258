import React, { useCallback, useEffect, useMemo, useState } from "react"

import cn from "classnames"
import dayjs from "dayjs"
import { Trans, useTranslation } from "react-i18next"

import { useNavigation } from "../../../../../hooks/useNavigation"
import { useToast } from "../../../../../hooks/useToast"
import { CUSTOM_CONTENT_TYPES, ROTATION_INTERVALS } from "../constants"
import { useCustomContentContext } from "../CustomContentContext"
import { isGalleryContent } from "../utils"
import ImageGallery from "./Gallery/ImageGallery"
import ImageGalleryActions from "./Gallery/ImageGalleryActions"
import { parseInterval } from "./Gallery/utils"

import {
  ImageFile,
  RotationInterval,
  RotationIntervalUnit,
} from "../../../../../redux/api/customContent/types"
import { uploadCustomContentFile } from "../../../../../redux/files/filesSlice"
import { removeDotsAndSpaces } from "../../../../../redux/files/utils"
import { useActions } from "../../../../../redux/utils"

import { UploadFile } from "../../../../../components/basic/UploadFile"
import PageForm from "../../../../../components/Form/PageFormHook"
import NoDataFound from "../../../../../components/NoDataFound"

import "./EditGalleryForm.sass"

const NoFilesView = ({
  onUploadFiles,
}: {
  onUploadFiles: (files: FileList | null) => Promise<void>
}) => {
  const { t } = useTranslation()

  return (
    <NoDataFound warning className="NoFilesView">
      <div className="NoFilesViewDescription">
        {t(
          "desktop.settings.rooms.custom_content.forms.gallery.no_images_view.description",
        )}
      </div>
      <div className="NoFilesViewViewButton">
        <Trans i18nKey="desktop.settings.rooms.custom_content.forms.gallery.no_images_view.upload_image_button">
          <UploadFile
            onChange={onUploadFiles}
            multiple
            buttonProps={{
              variant: "link",
            }}
          >
            Upload images
          </UploadFile>
          now.
        </Trans>
      </div>
    </NoDataFound>
  )
}

const PREFIX_INTERVAL = "PT"
const INTERVAL_DURATION = "5"

const EditGalleryForm = () => {
  const { t } = useTranslation()
  const { errorToast } = useToast()

  const { push } = useNavigation()

  const {
    paths,
    content,
    selectedSection,
    onUpdateSectionContent,
    isUpdateMode,
  } = useCustomContentContext()

  const actions = useActions({
    uploadFile: (image: File) => uploadCustomContentFile(image),
  })

  const [images, setImages] = useState<ImageFile[]>([])

  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([])

  const [interval, setInterval] = useState<RotationInterval>({
    prefix: PREFIX_INTERVAL,
    duration: INTERVAL_DURATION,
    unit: ROTATION_INTERVALS.minutes,
  })

  const isImagesExist = images.length > 0

  const sectionContent = useMemo(
    () => content.sections[selectedSection],
    [content.sections, selectedSection],
  )

  const handleRemoveFile = (id: string) => {
    setImages(images.filter((image) => image.id !== id))
    setSelectedImageIds((prev) => prev.filter((imageId) => imageId !== id))
  }

  const handleReplaceUploadFile = async (id: string, newFile: File) => {
    const pictureWithFixedName = new File(
      [newFile],
      removeDotsAndSpaces(newFile.name),
    )

    const file = await actions.uploadFile(pictureWithFixedName)

    if (uploadCustomContentFile.fulfilled.match(file)) {
      setImages(
        images.map((image) =>
          image.id === id
            ? {
                ...image,
                id: file.payload.id,
                file: file.payload,
                src: file.payload.url,
              }
            : image,
        ),
      )
      setSelectedImageIds((prev) => [
        ...prev.filter((imageId) => imageId !== id),
        file.payload.id,
      ])
    } else {
      errorToast(file.error.message)
      return
    }
  }

  const handleClickFile = (id: string) => {
    setSelectedImageIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((imageId) => imageId !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  const handleReplaceFile = (id: string) => {
    const newFileInput = document.createElement("input")

    newFileInput.type = "file"

    newFileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement

      if (target.files && target.files[0]) {
        handleReplaceUploadFile(id, target.files[0])
      }
    }

    newFileInput.click()
  }

  const handleSelectAllFiles = () => {
    setSelectedImageIds(images.map((image) => image.id))
  }

  const handleDeselectAllFiles = () => {
    setSelectedImageIds([])
  }

  const handleUploadFiles = async (files: FileList | null) => {
    if (files) {
      const newImages: ImageFile[] = []
      const selectedIds: string[] = []

      await Promise.all(
        Array.from(files).map(async (file, index) => {
          const pictureWithFixedName = new File(
            [file],
            removeDotsAndSpaces(file.name),
          )

          try {
            const fileResponse = await actions.uploadFile(pictureWithFixedName)

            if (uploadCustomContentFile.fulfilled.match(fileResponse)) {
              const uid = "rc-upload-" + +dayjs() + "-" + ++index

              newImages.push({
                id: fileResponse.payload.id,
                src: fileResponse.payload.url,
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: file.lastModified,
                uid,
                lastModifiedDate: dayjs(file.lastModified).toISOString(),
                percent: 0,
                originFileObj: {
                  uid,
                },
                state: "ready",
              })
              selectedIds.push(fileResponse.payload.id)
            } else {
              errorToast(fileResponse.error.message)
            }
          } catch (error) {
            console.error("Error uploading files:", error)
          }
        }),
      )

      setImages((prev) => [...prev, ...newImages])
      setSelectedImageIds((prev) => [...prev, ...selectedIds])
    }
  }

  const onUpdateClick = useCallback(async () => {
    onUpdateSectionContent(selectedSection, {
      type: CUSTOM_CONTENT_TYPES.gallery,
      content: {
        images,
        interval: Object.values(interval).join(""),
        selected: selectedImageIds,
      },
    })

    push(paths.root)
  }, [
    images,
    interval,
    onUpdateSectionContent,
    paths.root,
    push,
    selectedImageIds,
    selectedSection,
  ])

  const handleChangeInterval = (key: string, value: string | number) => {
    setInterval((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (!sectionContent?.content) return

    const { content } = sectionContent

    if (isGalleryContent(content)) {
      setImages(content.images ?? [])

      setSelectedImageIds(content.selected ?? [])

      const { interval } = content
      const [, duration, unit] = parseInterval(interval ?? "")

      if (
        Object.values(ROTATION_INTERVALS).includes(unit as RotationIntervalUnit)
      ) {
        setInterval((prev) => ({
          ...prev,
          duration,
          unit: unit as RotationIntervalUnit,
        }))
      } else {
        console.error(`Unexpected unit value received: ${unit}`)
      }
    }
  }, [sectionContent])

  return (
    <div className={cn("EditGalleryForm", { NoImages: !isImagesExist })}>
      <PageForm
        updateMode
        onUpdate={onUpdateClick}
        backUrl={isUpdateMode ? paths.root : paths.contentTypes}
        footer={
          !isImagesExist && <NoFilesView onUploadFiles={handleUploadFiles} />
        }
      >
        <div>
          <div className="Label">
            {t(
              "desktop.settings.rooms.custom_content.forms.gallery.add_image_gallery_label",
            )}
          </div>

          <div className="SubText">
            {t(
              "desktop.settings.rooms.custom_content.forms.gallery.add_image_gallery_description",
            )}
          </div>
        </div>

        <div className="ImageGallery">
          {isImagesExist && (
            <ImageGallery
              images={images}
              selectedImageIds={selectedImageIds}
              onClickFile={handleClickFile}
              onReplaceFile={handleReplaceFile}
              onRemoveFile={handleRemoveFile}
            />
          )}
        </div>
      </PageForm>

      <ImageGalleryActions
        images={images}
        selectedImageIds={selectedImageIds}
        interval={interval}
        onUploadFiles={handleUploadFiles}
        onSelectAllFiles={handleSelectAllFiles}
        onDeselectAllFiles={handleDeselectAllFiles}
        onChangeInterval={handleChangeInterval}
      />
    </div>
  )
}

export default EditGalleryForm
