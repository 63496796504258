import React from "react"

import ImagePreview from "./ImagePreview"

import { ImageFile } from "../../../../../../redux/api/customContent/types"

import "./ImageGallery.sass"

type Props = {
  images: ImageFile[]
  selectedImageIds: string[]
  onClickFile: (id: string) => void
  onReplaceFile: (id: string) => void
  onRemoveFile: (id: string) => void
}

const ImageGallery = ({
  images,
  selectedImageIds,
  onClickFile,
  onReplaceFile,
  onRemoveFile,
}: Props) => {
  return (
    <div className="ImageGallery">
      {images.map((image) => (
        <ImagePreview
          key={image.id}
          src={image.src}
          isSelected={selectedImageIds.includes(image.id)}
          onRemove={() => onRemoveFile(image.id)}
          onClick={() => onClickFile(image.id)}
          onReplace={() => onReplaceFile(image.id)}
        />
      ))}
    </div>
  )
}

export default ImageGallery
