import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import { FETCH_FOR_COUNT, FETCH_UNREAD } from "../../constants"
import { useLocalStorage } from "../../hooks/useLocalStorage"

import { useFetchNotificationsQuery } from "../../redux/api/notifications"

import NotificationsTutorialOverlaySVG from "../../assets/images/icons/NotificationsTutorialOverlay.svg"

import "./NotificationsTutorialOverlay.sass"

const NotificationsTutorialOverlay = () => {
  const { t } = useTranslation()

  const [showOverlay, setShowOverlay] = useState(false)
  const [remainingSeconds, setRemainingSeconds] = useState(7)

  const {
    value: hasSeenNotificationSwipeTutorial,
    onChange: setHasSeenNotificationSwipeTutorial,
  } = useLocalStorage("has-seen-notification-swipe-tutorial", "false")

  const { data: { count: notificationCount = 0 } = {} } =
    useFetchNotificationsQuery({ ...FETCH_FOR_COUNT, ...FETCH_UNREAD })

  const handleDismiss = () => {
    setShowOverlay(false)
    setHasSeenNotificationSwipeTutorial("true")
  }

  useEffect(() => {
    if (notificationCount > 0 && hasSeenNotificationSwipeTutorial === "false") {
      setShowOverlay(true)

      const timer = setInterval(() => {
        setRemainingSeconds((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            setShowOverlay(false)
            setHasSeenNotificationSwipeTutorial("true")
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [
    hasSeenNotificationSwipeTutorial,
    setHasSeenNotificationSwipeTutorial,
    notificationCount,
  ])

  if (!showOverlay) return null

  return (
    <div
      className="NotificationsTutorialOverlay"
      onClick={handleDismiss}
      onTouchStart={handleDismiss}
    >
      <div className="content">
        <NotificationsTutorialOverlaySVG />
        <p>
          {t(
            "desktop.settings.profile.notifications.notifications_swipe_tutorial_title",
          )}
        </p>

        <p className="countdown">
          {t(
            "desktop.settings.profile.notifications.notifications_swipe_tutorial_description",
          )}
          <span>
            {t(
              "desktop.settings.profile.notifications.notifications_swipe_tutorial_autoclosing_timer",
              {
                count: remainingSeconds,
              },
            )}
          </span>
        </p>
      </div>
    </div>
  )
}

export default NotificationsTutorialOverlay
