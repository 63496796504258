import { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"
import { isEmail, ReactMultiEmail } from "react-multi-email"

import { InputProps } from "../../basic/Input"

import RemoveSVG from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

type Props = {
  value?: string[]
  onChange?: (newValues: string[]) => void
  label?: string
  disabled?: boolean
  clearable?: boolean
  hasError?: boolean
  className?: string
  id?: string
  placeholder?: string
  validateEmail?: boolean
} & Omit<InputProps, "value" | "onChange">

const InputMultiInternal = (
  {
    placeholder,
    className,
    onChange,
    value,
    disabled = false,
    hasError,
    validateEmail = false,
  }: Props,
  ref: React.ForwardedRef<ReactMultiEmail>,
) => {
  const innerOnChange = (values: string[]) => {
    if (validateEmail) {
      const validEmails = values.filter((email) => isEmail(email))
      onChange && onChange(validEmails)
    } else {
      onChange && onChange(values)
    }
  }

  return (
    <ReactMultiEmail
      ref={ref}
      className={classNames("InputMulti", className, {
        disabled,
        error: hasError,
      })}
      placeholder={placeholder}
      emails={value}
      getLabel={(value, index, removeValue) => {
        return (
          <div data-tag key={index} className="InputValue">
            <div data-tag-item>{value}</div>
            <span
              className="Remove"
              data-tag-handle
              onClick={() => removeValue(index)}
            >
              <RemoveSVG />
            </span>
          </div>
        )
      }}
      onChange={innerOnChange}
      validateEmail={() => true} // Validation is handled in innerOnChange
    />
  )
}

export const InputMulti = forwardRef(InputMultiInternal) as (
  props: Props & {
    ref?: ForwardedRef<ReactMultiEmail>
  },
) => ReturnType<typeof InputMultiInternal>
