import React, { ReactNode } from "react"

import classNames from "classnames"

import "./SettingsSection.sass"

type Props = {
  title: string
  children: ReactNode
  className?: string
  action?: ReactNode
}

const SettingsSection = ({ title, children, className, action }: Props) => {
  return (
    <div
      className={classNames("SettingsSection", className, {
        HasAction: !!action,
      })}
    >
      <div className="SettingsSectionContent">
        <div className="SettingsSectionHead">{title}</div>
        <div className="SettingsSectionBody">{children}</div>
      </div>
      {action && <div className="SettingsSectionActions">{action}</div>}
    </div>
  )
}

export default SettingsSection
