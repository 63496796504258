import { useTranslation } from "react-i18next"

import {
  ConfirmationModal as BasicConfirmationModal,
  ConfirmationModalProps,
} from "../../basic/ConfirmationModal"
import { useModals } from "@mattjennings/react-modal-stack"

import { selectIsMobile } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"

import "./style.sass"

export const ConfirmationModal = ({
  open,
  title,
  hint,
  onConfirm,
  className,
  hideActions,
}: Omit<ConfirmationModalProps, "onCancel">) => {
  const { closeModal } = useModals()
  const { t } = useTranslation()
  const isMobile = useAppSelector(selectIsMobile)

  title = title || t("general.confirm_action")
  hint = hint || t("general.confirm_action_hint")
  const cancelText = t("mobile.general.cancel")
  const confirmText = t("mobile.general.confirm")

  const onCancel = () => {
    closeModal()
  }

  return (
    <BasicConfirmationModal
      open={open}
      title={title}
      hint={hint}
      cancelText={cancelText}
      confirmText={confirmText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isMobile={isMobile}
      className={className}
      hideActions={hideActions}
    />
  )
}
