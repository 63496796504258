import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { FormProvider, useForm } from "react-hook-form"

import { ISODate, OptionType } from "../../../../types/sharedTypes"
import {
  CUSTOM_CONTENT_PATHS,
  CUSTOM_CONTENT_STATUS,
  LAYOUT_GRID_TYPES,
} from "./constants"

import { Content, Section } from "../../../../redux/api/customContent/types"

export type Schedule = {
  start: ISODate
  end: ISODate
  repeat: OptionType<string>
}

export type FormValues = {
  id: number | null
  name: string | null
  type: number
  content: Content
  status: number
  devices: string[]
  schedule: Schedule | null
}

type CustomContentContextProps = {
  id: number | null
  content: Content
  onChangeContent: (v: Content) => void
  status: number
  onChangeStatus: (v: number) => void
  devices: string[]
  onChangeDevices: (v: string[]) => void
  schedule: Schedule | null
  onChangeSchedule: (v: Schedule | null) => void
  formValues: FormValues
  isUpdateMode: boolean
  paths: Record<string, string>
  selectedSection: number
  setSelectedSection: (v: number) => void
  onUpdateSectionContent: (index: number, section: Section) => void
  onDeleteSectionContent: (index: number) => void
}

const CustomContentContext = createContext<CustomContentContextProps | null>(
  null,
)

export const useCustomContentContext = () =>
  useContext(CustomContentContext) as CustomContentContextProps

const DEFAULT_VALUES = {
  id: null,
  name: null,
  type: 2,
  content: {
    type: LAYOUT_GRID_TYPES.full,
    sections: [{ type: null, content: null }],
  },
  status: CUSTOM_CONTENT_STATUS.enabled,
  devices: [],
  schedule: null,
}

export const CustomContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  })

  const { setValue, watch } = methods

  const formValues = watch()

  const { id, type, content, status, devices, schedule } = formValues

  const handleChangeDevices = useCallback(
    (deviceIds: string[]) =>
      setValue("devices", deviceIds, { shouldDirty: true }),
    [setValue],
  )

  const handleChangeContent = useCallback(
    (content: Content) => setValue("content", content, { shouldDirty: true }),
    [setValue],
  )

  const handleUpdateSectionContent = useCallback(
    (index: number, newSection: Section) => {
      if (content) {
        const updatedSections = [...content.sections]

        updatedSections[index] = newSection

        handleChangeContent({ ...content, sections: updatedSections })
      }
    },
    [content, handleChangeContent],
  )

  const handleDeleteSectionContent = useCallback(
    (index: number) => {
      if (content) {
        const sections = [...content.sections]

        sections[index] = {
          type: null,
          content: null,
        }

        handleChangeContent({ ...content, sections })
      }
    },
    [content, handleChangeContent],
  )

  const handleChangeStatus = useCallback(
    (status: number) => setValue("status", status, { shouldDirty: true }),
    [setValue],
  )

  const handleChangeSchedule = useCallback(
    (schedule: Schedule | null) =>
      setValue("schedule", schedule, { shouldDirty: true }),
    [setValue],
  )

  const [selectedSection, setSelectedSection] = useState<number>(0)

  const isUpdateMode = Boolean(id)

  const paths = useMemo(() => {
    if (!id) {
      return CUSTOM_CONTENT_PATHS.add
    }

    const contentId = id.toString()

    return {
      root: CUSTOM_CONTENT_PATHS.edit.root(contentId),
      contentTypes: CUSTOM_CONTENT_PATHS.edit.contentTypes(contentId),
      text: CUSTOM_CONTENT_PATHS.edit.text(contentId),
      gallery: CUSTOM_CONTENT_PATHS.edit.gallery(contentId),
      webpage: CUSTOM_CONTENT_PATHS.edit.webpage(contentId),
      roomStatus: CUSTOM_CONTENT_PATHS.edit.roomStatus(contentId),
      devices: CUSTOM_CONTENT_PATHS.edit.devices(contentId),
    }
  }, [id])

  const values = useMemo(
    () => ({
      id,
      type,
      content,
      onChangeContent: handleChangeContent,
      status,
      onChangeStatus: handleChangeStatus,
      devices,
      onChangeDevices: handleChangeDevices,
      schedule,
      onChangeSchedule: handleChangeSchedule,
      formValues,
      isUpdateMode,
      paths,
      selectedSection,
      setSelectedSection,
      onUpdateSectionContent: handleUpdateSectionContent,
      onDeleteSectionContent: handleDeleteSectionContent,
    }),
    [
      id,
      type,
      content,
      handleChangeContent,
      status,
      handleChangeStatus,
      devices,
      handleChangeDevices,
      schedule,
      handleChangeSchedule,
      formValues,
      isUpdateMode,
      paths,
      selectedSection,
      handleUpdateSectionContent,
      handleDeleteSectionContent,
    ],
  )

  return (
    <CustomContentContext.Provider value={values}>
      <FormProvider {...methods}>{children}</FormProvider>
    </CustomContentContext.Provider>
  )
}
