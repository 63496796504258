import React from "react"

import { useTranslation } from "react-i18next"

import "./CurrentSubscriptionDetails.sass"

type CurrentSubscriptionDetailsProps = {
  period: string
  unit: string
  planLabel: string
  nextBillingDate: string
  purchased: number
  used: number
  cost: string
}
const CurrentSubscriptionDetails = ({
  period,
  unit,
  planLabel,
  nextBillingDate,
  used,
  purchased,
  cost,
}: CurrentSubscriptionDetailsProps) => {
  const { t } = useTranslation()

  return (
    <div className="CurrentSubscriptionDetails">
      <div className="CurrentSubscriptionDetails__row">
        {t(
          "desktop.settings.billing.subscription_quantity.current_subscription",
        )}
        : {planLabel}
      </div>
      <div className="CurrentSubscriptionDetails__row">
        {t("desktop.settings.billing.subscription_quantity.next_renewal_date")}:{" "}
        {nextBillingDate}
      </div>
      <div className="CurrentSubscriptionDetails__row bold">
        {t(
          "desktop.settings.billing.subscription_quantity.no_of_unit_licenses_used",
          { unit, used, purchased },
        )}
      </div>
      <div className="CurrentSubscriptionDetails__row bold">
        <div>
          {t("desktop.settings.billing.subscription_quantity.cost_per_period", {
            period,
          })}
        </div>
        <div>{cost}</div>
      </div>
    </div>
  )
}

export default CurrentSubscriptionDetails
