import { ThunkApiConfig } from "RootType"

import { logBreadcrumb } from "../../analytics"
import {
  get,
  postJSON,
  printerURL,
  tabletAuthenticateURL,
  tabletURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  PrinterResponse,
  RegisterTabletRequest,
  RegisterTabletResponse,
  TabletConnectionType,
  TabletResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"
import { captureMessage } from "@sentry/capacitor"

export const registerTablet = createAsyncThunk<
  RegisterTabletResponse,
  RegisterTabletRequest,
  ThunkApiConfig<ResponseError>
>("tablet/register", async (body) => {
  const response = await postJSON(tabletAuthenticateURL(), {
    body,
  })

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export interface TabletParams {
  id: string
  buildingId: string
  touchlessPin: string | null
  inviteId: string | null
}

export const setTabletParams = createAsyncThunk<
  TabletParams,
  TabletParams,
  ThunkApiConfig
>("tablet/setParams", async (body) => {
  return body
})

export const fetchTablet = createAsyncThunk<
  TabletResponse,
  string,
  ThunkApiConfig
>("tablet/fetch", async (id, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(tabletURL(id), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchPrinter = createAsyncThunk<
  PrinterResponse,
  string,
  ThunkApiConfig
>("printer/fetch", async (id, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(printerURL(id), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})
export interface TabletState extends SliceState {
  id: string | null
  pin: string | null
  buildingId: string | null
  touchlessPin: string | null
  inviteId: string | null
  entry?: TabletResponse
  printer?: PrinterResponse
}

const initialState: TabletState = {
  id: localStorage.getItem("tablet_id"),
  pin: localStorage.getItem("tablet_pin"),
  buildingId: localStorage.getItem("tablet_building_id"),
  touchlessPin: null,
  inviteId: null,
  entry: undefined,
  printer: undefined,
  ...sliceInitialState,
}

const tabletSlice = createSlice({
  name: "tablet",
  initialState,
  reducers: {
    clearPrinter: (state) => {
      state.printer = undefined
    },
    clearTablet: (state) => {
      state.id = null
      state.pin = null
      state.buildingId = null
      state.touchlessPin = null
      state.inviteId = null
      state.entry = undefined
      state.printer = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerTablet.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      if (payload.connection_status === TabletConnectionType.PAIRED) {
        state.id = payload.id
        state.pin = payload.pin

        logBreadcrumb(
          `Tablet paired: ${payload.id} ${payload.pin} and set to local storage`,
        )

        if (localStorage) {
          localStorage.setItem("tablet_id", payload.id)
          localStorage.setItem("tablet_pin", payload.pin)
        }

        captureMessage("Tablet paired")
      }
    })
    builder.addCase(setTabletParams.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.id = payload.id
      state.buildingId = payload.buildingId
      state.touchlessPin = payload.touchlessPin
      state.inviteId = payload.inviteId
    })
    builder.addCase(fetchTablet.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
      if (payload.building_id) {
        state.buildingId = payload.building_id

        if (localStorage) {
          localStorage.setItem("tablet_building_id", payload.building_id)
        }
      }
    })
    builder.addCase(fetchPrinter.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.printer = payload
    })
    builder.addCase(registerTablet.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(registerTablet.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addMatcher(
      isAnyOf(fetchTablet.pending, fetchPrinter.pending),
      (state) => {
        state.isLoading = true
      },
    )
    builder.addMatcher(
      isAnyOf(fetchTablet.rejected, fetchPrinter.rejected),
      (state, action) => {
        setFetchErrorState(state, action)
      },
    )
  },
})

export const tabletReducer = tabletSlice.reducer
export const { clearPrinter, clearTablet } = tabletSlice.actions
