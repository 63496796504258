import { useState } from "react"

import { useTranslation } from "react-i18next"

import { CreateApiKeyResponse } from "../../../../redux/api/apiKeys/types"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import ApiKeyForm from "../../../../components/Form/ApiKeyForm"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const NewApiKey = () => {
  const { t } = useTranslation()

  const [apiKeyResponse, setApiKeyResponse] = useState<CreateApiKeyResponse>()

  return (
    <View className="NewApiKey">
      {apiKeyResponse ? (
        <Breadcrumbs
          depth={2}
          values={[
            t("desktop.settings.integrations.title"),
            t("desktop.settings.integrations.api.copy.title"),
          ]}
        />
      ) : (
        <Breadcrumbs
          depth={2}
          values={[
            t("desktop.settings.integrations.title"),
            t("desktop.settings.integrations.api.new.title"),
          ]}
        />
      )}

      <Intro isConstrained>
        {apiKeyResponse
          ? t("desktop.settings.integrations.api.copy.intro")
          : t("desktop.settings.integrations.api.new.intro")}
      </Intro>

      <Space size={0.75} />

      <ApiKeyForm
        apiKeyResponse={apiKeyResponse}
        setApiKeyResponse={setApiKeyResponse}
      />
    </View>
  )
}

export default NewApiKey
