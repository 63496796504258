import "../ContentGridSection.sass"

import {
  SectionContent,
  WebpageContent,
} from "../../../../../redux/api/customContent/types"

import PencilSVG from "../../../../../assets/images/icons/Pencil.svg"
import TrashSVG from "../../../../../assets/images/icons/Trash.svg"

import "./WebpageContentPreview.sass"

type WebpageContentPreviewProps = {
  index: number
  sectionContent: SectionContent
  onDeleteSectionContent: (index: number) => void
  onUpdateSectionContent: () => void
}

const WebpageContentPreview = ({
  index,
  sectionContent,
  onDeleteSectionContent,
  onUpdateSectionContent,
}: WebpageContentPreviewProps) => {
  const { url } = sectionContent as WebpageContent

  return (
    <div
      className="ContentGridSection WebpageContentPreview"
      onClick={onUpdateSectionContent}
    >
      <div className="ContentSectionActions">
        <button type="button" className="UpdateSectionContent">
          <PencilSVG />
        </button>
        <button
          type="button"
          className="DeleteSectionContent"
          onClick={(e) => {
            e.stopPropagation()
            onDeleteSectionContent(index)
          }}
        >
          <TrashSVG />
        </button>
      </div>
      <iframe
        onClick={onUpdateSectionContent}
        className="PreviewWindow"
        src={url}
        scrolling="no"
        title="Webpage content"
      />
    </div>
  )
}

export default WebpageContentPreview
