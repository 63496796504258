import { ThunkApiConfig } from "RootType"

import {
  createShareableURL,
  deleteJSON,
  get,
  postJSON,
  shareableAccessLogURL,
  shareableDeviceURL,
  shareableURL,
} from "../../api"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  CreateShareableRequest,
  Shareable,
  ShareableAccessLogResponse,
  ShareableAccessLogs,
  ShareableResponse,
} from "./types"
import { getDesksByDepartment, sortDesks } from "./utils"
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit"

/**
 * Thunks
 */
export const createShareable = createAsyncThunk<
  void,
  CreateShareableRequest,
  ThunkApiConfig
>("shareable/createShareable", async ({ type, ...body }, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await postJSON(
    createShareableURL(type),
    { body },
    access_token,
  )

  if (response.ok) {
    return
  }

  throw new Error(await getErrorMessage(response))
})

export const destroyShareable = createAsyncThunk<void, string, ThunkApiConfig>(
  "shareable/destroyShareable",
  async (id, { getState }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await deleteJSON(
      shareableURL(id),
      { body: {} },
      access_token,
    )

    if (response.ok) {
      return
    }

    throw new Error(await getErrorMessage(response))
  },
)

export const fetchShareableAccessLog = createAsyncThunk<
  ShareableAccessLogResponse,
  string,
  ThunkApiConfig
>("shareable/fetchAccessLog", async (shareableId, { getState }) => {
  const { access_token } = getState().auth
  const response = await get(
    shareableAccessLogURL(shareableId),
    {},
    access_token,
  )

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchShareable = createAsyncThunk<
  ShareableResponse,
  string,
  ThunkApiConfig
>("shareable/fetch", async (shareableId, { dispatch }) => {
  const response = await fetch(shareableDeviceURL(shareableId))

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export interface SharableState extends SliceState {
  entry: Shareable | null
  accessLogs: ShareableAccessLogs
}

const initialState: SharableState = {
  entry: null,
  accessLogs: {},
  ...sliceInitialState,
}

/**
 * Slice
 */
const shareableSlice: Slice<SharableState> = createSlice({
  name: "shareable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createShareable.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(createShareable.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(createShareable.fulfilled, (state) => {
      setSubmitSuccessState(state)
    })

    builder.addCase(destroyShareable.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(destroyShareable.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(destroyShareable.fulfilled, (state) => {
      setSubmitSuccessState(state)
    })

    builder.addCase(fetchShareableAccessLog.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchShareableAccessLog.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchShareableAccessLog.fulfilled, (state, action) => {
      const shareableId = action.meta.arg
      setFetchSuccessState(state)
      state.accessLogs[shareableId] = action.payload.results
    })

    builder.addCase(fetchShareable.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchShareable.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchShareable.fulfilled, (state, action) => {
      const { payload } = action
      const sortedDesks = sortDesks(payload.desks)
      const desksByDepartment = getDesksByDepartment(sortedDesks)
      setFetchSuccessState(state)
      state.entry = {
        ...payload,
        desks: sortedDesks,
        desksByDepartment,
      }
    })
  },
})

export const shareableReducer = shareableSlice.reducer

// export const {} = shareableSlice.actions
