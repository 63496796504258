import { api } from ".."
import { solutionsURL } from "../../../api"
import { Solution, Solutions, SolutionTypes } from "./types"

export const solutions = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSolutions: builder.query<Solutions, void>({
      query: () => solutionsURL(),
      providesTags: ["Solutions"],
    }),

    activateSolution: builder.mutation<Solution, SolutionTypes>({
      query: (type: SolutionTypes) => ({
        url: solutionsURL({ type }),
        method: "POST",
      }),
      invalidatesTags: ["Solutions", { type: "Subscriptions", id: "LIST" }],
    }),

    deactivateSolution: builder.mutation<void, SolutionTypes>({
      query: (type: SolutionTypes) => ({
        url: solutionsURL({ type }),
        method: "DELETE",
      }),
      invalidatesTags: ["Solutions", { type: "Subscriptions", id: "LIST" }],
    }),
  }),
})

export const {
  useFetchSolutionsQuery,
  useLazyFetchSolutionsQuery,
  useActivateSolutionMutation,
  useDeactivateSolutionMutation,
} = solutions
