import { ScheduleAssetDataRow } from "../../redux/asset_schedule/types"

type Props = {
  assetRow: ScheduleAssetDataRow
  rowNumber: number
}

export const AssetScheduleHeading = ({ assetRow, rowNumber }: Props) => (
  <div className="AssetHeading Asset RowHeading">
    <div className="row-number">{rowNumber}</div>{" "}
    <span className="asset" title={assetRow.name}>
      {assetRow.name}
    </span>
  </div>
)
