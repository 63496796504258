import dayjs, { Dayjs } from "dayjs"
import querystring from "query-string"

import {
  IS_WHITE_LABEL,
  LabelPaths,
  LABELS,
  WHITE_LABEL_LABELS,
} from "./constants"
import { InternalTime, OptionType } from "./types/sharedTypes"
import { Browser } from "@capacitor/browser"
import { Capacitor, PluginListenerHandle } from "@capacitor/core"

import { DepartmentResponse } from "./redux/api/departments/types"
import { BuildingsResponse } from "./redux/buildings/types"

import { GooglePlacesSelectType } from "./components/advanced/GooglePlacesSelect/types"

export const BLANK_STR_REGEX = /^\s*$/
export const EMAIL_REGEX = /^\S+@\S+\.\S+$/
export const COLOR_HEX_REGEX = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/
export const IP_ADDRESS_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
export const URL_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/

const FORCE_MODE_LOCAL_KEY = "force_device_render"
const { parse, stringify } = querystring

export const isDev = () => import.meta.env.DEV

export const isProd = () => import.meta.env.PROD

/* eslint-disable no-fallthrough */
export const pluralize = (number: number) => {
  switch (number % 10) {
    case 1:
      if (number !== 11) {
        return `${number}st`
      } else {
        return `${number}th`
      }
    case 2:
      if (number !== 12) {
        return `${number}nd`
      } else {
        return `${number}th`
      }
    case 3:
      if (number !== 13) {
        return `${number}rd`
      }
      return `${number}th`
    default:
      return `${number}th`
  }
}

export function colorFromString(str: string) {
  if (str === null) {
    str = "default"
  }
  const seed = xmur3(str)
  const rand = sfc32(seed(), seed(), seed(), seed())
  const angle = Math.ceil(rand() * 360)
  return `hsl(${angle}, 50%, 66%)`
}

export function sfc32(a: number, b: number, c: number, d: number) {
  return function () {
    a >>>= 0
    b >>>= 0
    c >>>= 0
    d >>>= 0
    let t = (a + b) | 0
    a = b ^ (b >>> 9)
    b = (c + (c << 3)) | 0
    c = (c << 21) | (c >>> 11)
    d = (d + 1) | 0
    t = (t + d) | 0
    c = (c + t) | 0
    return (t >>> 0) / 4294967296
  }
}

export function xmur3(str: string) {
  let h = 1779033703 ^ str.length
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353)
    h = (h << 13) | (h >>> 19)
  }
  return function () {
    h = Math.imul(h ^ (h >>> 16), 2246822507)
    h = Math.imul(h ^ (h >>> 13), 3266489909)
    return (h ^= h >>> 16) >>> 0
  }
}

const date = new Date()

export const is12Hour = () => {
  const localeTimeString = date.toLocaleTimeString(dayjs.locale(), {
    hour: "numeric",
    minute: "numeric",
  })
  return /AM|PM/.test(localeTimeString)
}

export const userTimeFormat = () => (is12Hour() ? "hh:mm a" : "HH:mm")
export const shortUserTimeFormat = () => (is12Hour() ? "h:mm a" : "H:mm")

export const internalTimeFormat = () => "HH:mm"

export const roundToClosest30Minutes = (date: Dayjs) => {
  const minutes = date.minute()
  if (minutes < 15) {
    return date.startOf("hour")
  } else if (minutes < 45) {
    return date.startOf("hour").add(30, "minute")
  } else {
    return date.startOf("hour").add(1, "hour")
  }
}

export const toInternalTime = (date: Dayjs | string): InternalTime =>
  (typeof date === "string" ? dayjs(date) : date).format(
    internalTimeFormat(),
  ) as InternalTime

export const geocodeByPlaceId = (placeId: string) => {
  const { maps } = (window as any).google

  const geocoder: google.maps.Geocoder = new maps.Geocoder()
  const { OK } = maps.GeocoderStatus

  return new Promise<google.maps.GeocoderResult[] | null>((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        return reject(status)
      }
      return resolve(results)
    })
  })
}

export const isBlank = (str?: string) => {
  return !str || BLANK_STR_REGEX.test(str)
}

export const isEmail = (str: string) => {
  return EMAIL_REGEX.test(str)
}

const isNumber = function isNumber(value: any) {
  return typeof value === "number" && isFinite(value)
}

export const isInternalTime = (value: unknown): value is InternalTime => {
  if (typeof value !== "string") {
    return false
  }
  if (!value.includes(":")) {
    return false
  }
  const [hours, minutes] = value.split(":")
  if (!isNumber(Number(hours)) || !isNumber(Number(minutes))) {
    return false
  }
  return true
}

export const convertInternalTimeToUserTime = (time: InternalTime) => {
  const [hours, minutes] = time.split(":")
  const dayTime = dayjs()
    .set("hour", Number(hours))
    .set("minute", Number(minutes))
  return dayTime.format(userTimeFormat())
}

/**
 * Compering two internal times
 * @param a {InternalTime} - internal time
 * @param b {InternalTime} - internal time
 * @returns true when first time si before second time
 */
export const isBefore = (a: InternalTime, b: InternalTime) => {
  const [aHours, aMinutes] = a.split(":")
  const [bHours, bMinutes] = b.split(":")

  if (aHours < bHours) {
    return true
  }
  if (bHours < aHours) {
    return false
  }
  return aMinutes <= bMinutes
}

export const getLaterInternalTime = (a: InternalTime, b: InternalTime) =>
  isBefore(a, b) ? b : a

export const setTimeToDayjs = (date: Dayjs, time: InternalTime): Dayjs => {
  const [hours, minutes] = time.split(":")
  return date.hour(Number(hours)).minute(Number(minutes)).startOf("minute")
}

export const calculatePercentAmount = (totalAmount: number, percent: number) =>
  Math.floor((totalAmount * percent) / 100)

type HasId = {
  id: string
}

export const equalArraysById = (a: HasId[], b: HasId[]) => {
  if (a.length !== b.length) {
    return false
  } else if (a.every((obj, index) => obj.id === b[index].id)) {
    return true
  } else {
    return false
  }
}

type HasName = {
  name: string
}

export function nameComparator(a: HasName, b: HasName) {
  if (!a || !b || !a.name || !b.name) return 0

  return a.name.localeCompare(b.name, undefined, { numeric: true })
}

export const countDesksInBuildings = (buildings: BuildingsResponse) => {
  let numOfDesks = 0

  buildings.results.forEach((building) => {
    numOfDesks += building.desks_count || 0
  })

  return numOfDesks
}

export const countFloorsInBuildings = (buildings: BuildingsResponse) => {
  let numOfFloors = 0

  buildings.results.forEach((building) => {
    numOfFloors += building.floors_count || 0
  })

  return numOfFloors
}

export const parseQueryWithDefault = <T>(
  queryString: string,
  defaultValues: T,
): T => {
  const params = parse(queryString)
  const result: { [x: string]: unknown } = {}

  if (!params) {
    return defaultValues
  }

  Object.keys(params).forEach((key) => {
    const value = params[key]

    if (value === undefined || value === null) return

    result[key] = value
  })

  return { ...defaultValues, ...result }
}

export const getDepartmentString = (departments?: DepartmentResponse[]) =>
  departments
    ? departments.map((d: DepartmentResponse) => d.name).join(", ")
    : ""

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export const generateString = (length: number) => {
  let result = ""
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const getDirectionsLink = (
  directionsLink: boolean,
  address?: GooglePlacesSelectType,
) => {
  if (directionsLink && address?.address) {
    return `${
      import.meta.env.VITE_APP_GOOGLE_MAP_DIRECTION_URL
    }${encodeURIComponent(address.address)}`
  }
}

const testUserAgent = (expr: RegExp) => expr.test(window.navigator.userAgent)

const isIpad = () => {
  // iOS 12 and below
  if (testUserAgent(/iPad/i)) {
    return true
  }

  // iOS 13+
  if (testUserAgent(/Macintosh/i)) {
    return true
  }

  return false
}

const isAndroidTablet = () =>
  testUserAgent(/android|sink/i) && !testUserAgent(/mobile/i)

export const isTablet = () => {
  const width = window.innerWidth
  const height = window.innerHeight
  const smallest = Math.min(width, height)
  const largest = Math.max(width, height)

  if (!isBlank(import.meta.env.VITE_APP_FORCE_DEVICE_RENDER)) {
    return import.meta.env.VITE_APP_FORCE_DEVICE_RENDER === "TABLET"
  }

  if (localStorage.getItem(FORCE_MODE_LOCAL_KEY) !== null) {
    return localStorage.getItem(FORCE_MODE_LOCAL_KEY) === "tablet"
  }

  return (
    Capacitor.isNativePlatform() &&
    (isIpad() ||
      isAndroidTablet() ||
      (smallest > 460 && smallest < 820 && largest > 780 && largest < 1400))
  )
}

export const isMobile = (params?: { ignoreWidth: boolean }) => {
  if (!isBlank(import.meta.env.VITE_APP_FORCE_DEVICE_RENDER)) {
    return import.meta.env.VITE_APP_FORCE_DEVICE_RENDER === "MOBILE"
  }

  if (localStorage.getItem(FORCE_MODE_LOCAL_KEY) !== null) {
    return localStorage.getItem(FORCE_MODE_LOCAL_KEY) === "mobile"
  }

  if (params?.ignoreWidth) return Capacitor.isNativePlatform()

  return Capacitor.isNativePlatform() || window.innerWidth < 1024
}

export const forceMode = (mode?: "tablet" | "mobile") => {
  if (mode) {
    localStorage.setItem(FORCE_MODE_LOCAL_KEY, mode)
  } else {
    localStorage.removeItem(FORCE_MODE_LOCAL_KEY)
  }
}

/**
 * This function should be used in case we need native
 * business logic and not native functionality.
 *
 * CAREFUL:
 *
 * The isNative() function will return true while
 * Capacitor.isNativePlatform() could return false in
 * certain situations, like if we force the tablet
 * render with the env variable REACT_APP_FORCE_DEVICE_RENDER.
 *
 * For example, if you just need to make sure the app
 * is being ran on a native platform because you need to
 * access the camera for example, you still need to perform
 * the check with Capacitor.isNativePlatform().
 */
export const isNative = () => isTablet() || isMobile({ ignoreWidth: true })

/**
 * The function update the url without causing a navigation reload?
 * @param pathname
 * @param filters
 */
export const updateHistory = <Filters extends Record<string, any>>(
  pathname: string,
  filters: Filters,
  hash?: string,
) => {
  window.history.replaceState(
    null,
    document.title,
    `${pathname}?${stringify(filters)}${hash ? hash : ""}`,
  )
}

export const prettifyUrlValue = (location: string) => {
  const URL_REGEX = /[	\-_:]+/g // eslint-disable-line

  return (
    location.charAt(0).toUpperCase() + location.slice(1).replace(URL_REGEX, " ")
  )
}

export const openInAppBrowser = async (url: string) => {
  await Browser.open({ url })
}

/**
 * Opens a new browser window with the specified URL.
 *
 * @param url - The URL to open in the new window.
 * @param target - The target attribute of the window (optional, defaults to "_blank").
 */
export const openBrowserWindow = async (
  url: string,
  target: string = "_blank",
) => {
  window.open(url, target)
}

/**
 * @param hexColor Hex value format: #ffffff or ffffff
 * @param decimal lighten or darken decimal value, example 0.5 to lighten by 50% or 1.5 to darken by 50%.
 */
export const shadeColor = (hexColor: string, decimal: number): string => {
  const base = hexColor.startsWith("#") ? 1 : 0

  let r = parseInt(hexColor.substring(base, 3), 16)
  let g = parseInt(hexColor.substring(base + 2, 5), 16)
  let b = parseInt(hexColor.substring(base + 4, 7), 16)

  r = Math.round(r / decimal)
  g = Math.round(g / decimal)
  b = Math.round(b / decimal)

  r = r < 255 ? r : 255
  g = g < 255 ? g : 255
  b = b < 255 ? b : 255

  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`
}

export const recordToOptions = <T>(obj: Record<string, T>) =>
  Object.entries(obj).map(([key, label]) => ({
    value: key,
    label: label,
  }))

export const getOption = (options: OptionType[], value?: string | null) =>
  value ? options.find((t) => t.value === value) : undefined

/**
 * Converts the values of an object into options with both value and label properties.
 *
 * @param obj - The object whose values will be converted into options.
 * @returns An array of options with value and label properties.
 */
export const objectValuesToOptions = <T>(obj: Record<string, T>) =>
  Object.values(obj).map((label) => ({
    value: label,
    label,
  }))

/**
 * Converts an object to an array of options.
 * Each option in the resulting array contains a value and a label.
 *
 * @param obj - The object to convert to options.
 * @returns An array of options.
 */
export const objectToOptions = (
  obj: Record<string, { value: string; label: string }>,
) =>
  Object.values(obj).map((o) => ({
    value: o.value,
    label: o.label,
  }))

/**
 * Retrieves the label associated with the given key base on WHITE LABEL flag.
 *
 * @param key - The key to retrieve the label for.
 * @returns The label associated with the key, or the key itself if no label is found.
 */
export const getLabel = (key: LabelPaths): string => {
  const labels = IS_WHITE_LABEL ? WHITE_LABEL_LABELS : LABELS
  try {
    return getValueByPath(labels, key)
  } catch (e) {
    return key
  }
}

/**
 * Retrieves a value from an object using a dot-separated path.
 *
 * @param obj - The object to retrieve the value from.
 * @param path - The dot-separated path to the value.
 * @returns The value at the specified path, or undefined if not found.
 */
const getValueByPath = (obj: Record<string, any>, path: string): any => {
  const keys = path.split(".")
  const key = keys.shift()

  if (key && Object.prototype.hasOwnProperty.call(obj, key)) {
    const value = obj[key]

    if (keys.length === 0) {
      return value
    } else if (typeof value === "object") {
      return getValueByPath(value, keys.join("."))
    }
  }

  throw new Error(`Invalid path: ${path}`)
}

/**
 * Creates an array of numbers from 0 to the specified length.
 *
 * @param length - The length of the array to create.
 * @returns An array of numbers from 0 to length-1.
 */
export const createArray = (length: number) =>
  Array.from({ length }, (_, i) => i)

/**
 * Randomizes the order of elements in an array.
 *
 * @param array - The array to be randomized.
 * @returns A new array with the elements randomly shuffled.
 * @template ArrayType - The type of elements in the array.
 */
export const randomizeArray = <ArrayType>(array: ArrayType[]) =>
  [...array].sort(() => Math.random() - 0.5)

/**
 * Reads a value from the local storage based on the provided key.
 *
 * @param key - The key used to retrieve the value from the local storage.
 * @returns The value associated with the provided key, or null if the local storage is not available.
 */
export const readLocal = (key: string): string | null => {
  if (!window.localStorage) return null

  return window.localStorage.getItem(key)
}

/**
 * Writes a value to the local storage with the specified key.
 * @param key - The key to use for storing the value.
 * @param value - The value to be stored.
 */
export const writeLocal = (key: string, value: string): void => {
  if (!window.localStorage) return

  window.localStorage.setItem(key, value)
}

export const removePluginListeners = async (
  handle: Promise<PluginListenerHandle>,
) => {
  try {
    return await handle.then((handle) => handle.remove())
  } catch (e) {
    console.log("Something went wrong when removing a listener.")
  }
}

/**
 * The NFC library we use does not always return proper
 * strings, they may have weird characters at start that
 * String() or trim() can't pick up and remove. It
 * also strips away https.
 */
export const sanitizeUrl = (url: string) => {
  const regex = /^[a-zA-Z]/
  let loopPrevention = 0
  let result = url

  while (loopPrevention < 100 && !regex.test(result)) {
    result = result.substring(1)
    loopPrevention++
  }

  if (!result.startsWith("https://")) {
    result = `https://${result}`
  }

  return result
}
