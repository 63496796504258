import { ReactNode } from "react"

import classNames from "classnames"

import { selectIsPortraitOrientation } from "../../redux/app/selectors"
import { useAppSelector } from "../../redux/reducers"

import "./SafeViewArea.sass"

type SafeViewAreaProps = {
  children: ReactNode
  className?: string
}
const SafeViewArea = ({ children, className }: SafeViewAreaProps) => {
  const isPortrait = useAppSelector(selectIsPortraitOrientation)

  const safeViewAreaClass = classNames("safe-view-area", className, {
    landscape: !isPortrait,
  })
  return <div className={safeViewAreaClass}>{children}</div>
}

export default SafeViewArea
