import React from "react"

import ReactModal from "react-modal"

import { contentStyle, overlayStyle } from "./modalStyles"
import { useModals } from "@mattjennings/react-modal-stack"

import { FloorResponse } from "../redux/floors/types"

import FloorDeleteForm from "../components/Form/FloorDeleteForm"

type Props = {
  open: boolean
  floor: FloorResponse
  building_id?: string
}

export default function FloorDeleteModal({ open, floor, building_id }: Props) {
  const { closeModal } = useModals()

  return (
    <ReactModal
      isOpen={open}
      style={{ content: contentStyle, overlay: overlayStyle }}
      onRequestClose={() => closeModal()}
      ariaHideApp={false}
    >
      <FloorDeleteForm floor={floor} building_id={building_id} />
    </ReactModal>
  )
}
