import { ISODate } from "../../../types/sharedTypes"
import { SettingsResponse } from "../../settings/types"
import { PaginatedResponse } from "../../types"
import { BuildingResponse } from "../buildings/types"
import { FloorResponse } from "../floors/types"

export type RoomResponse = {
  key: string
  name: string
  capacity: number
  building: BuildingResponse | null
  floor: FloorResponse | null
  amenities: number[]
} & SettingsResponse

export type RoomsResponse = PaginatedResponse<RoomResponse>

export type RoomsFetchRequest = {
  building?: string
  floor?: string
  search?: string
  limit?: number
}

export type RoomUpdateRequest = {
  name?: string
  capacity: string
  building?: string | null
  floor?: string | null
  amenities?: number[]
}

export type RoomRequestWithKey = RoomUpdateRequest & {
  key: string
}

export type CheckScanRoomsResponse = {
  in_progress: boolean
  started_at: ISODate | null
}

export enum RoomBulkAction {
  DELETE = "DELETE",
  EDIT = "EDIT",
}
