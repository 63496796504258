import React, { PropsWithChildren, useEffect, useRef, useState } from "react"

import Lottie, { LottieRefCurrentProps } from "lottie-react"
import { useLocation } from "react-router-dom"

import { useLoadReducers } from "../hooks/useLoadReducers"

import JoanLoader from "../assets/images/icons/JoanLoader.json"

import "./LoadingProvider.sass"

function MobileLoadingProvider({ children }: PropsWithChildren<unknown>) {
  const { pathname } = useLocation()

  const isAuthPath = pathname.indexOf("/auth/") > -1

  const { areSlicesLoaded } = useLoadReducers({
    reducerKeys: [
      "settings",
      "featureFlags",
      "consent",
      "experiments",
      "user_experiments",
      "experiments",
      "user",
    ],
  })

  const [isAnimationComplete, setIsAnimationComplete] = useState(false)
  const [shouldLoop, setShouldLoop] = useState(false)
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(2)
    }
  }, [lottieRef, shouldLoop])

  useEffect(() => {
    if (isAnimationComplete && !areSlicesLoaded) {
      setShouldLoop(true)
    }
  }, [isAnimationComplete, areSlicesLoaded])

  const handleAnimationComplete = () => {
    if (!isAnimationComplete) {
      setIsAnimationComplete(true)
    }
  }

  return (!isAnimationComplete || !areSlicesLoaded) && !isAuthPath ? (
    <div className="joan-loader-container">
      <Lottie
        lottieRef={lottieRef}
        animationData={JoanLoader}
        loop={shouldLoop}
        onComplete={handleAnimationComplete}
        className="joan-mobile-loader-animation"
      />
    </div>
  ) : (
    <>{children}</>
  )
}

export default MobileLoadingProvider
