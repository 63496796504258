import React, { PropsWithChildren } from "react"

import { ErrorHandlerProvider } from "../Tablet/ErrorHandlerProvider"
import FirebaseAnalyticsProvider from "./FirebaseAnalyticsProvider"
import { NativeProviders } from "./Native/NativeProviders"
import { Capacitor } from "@capacitor/core"

export const MobileProviders = ({ children }: PropsWithChildren<unknown>) => {
  return Capacitor.isNativePlatform() ? (
    <ErrorHandlerProvider>
      <NativeProviders>
        <FirebaseAnalyticsProvider>{children}</FirebaseAnalyticsProvider>
      </NativeProviders>
    </ErrorHandlerProvider>
  ) : (
    <ErrorHandlerProvider>
      <FirebaseAnalyticsProvider>{children}</FirebaseAnalyticsProvider>
    </ErrorHandlerProvider>
  )
}
