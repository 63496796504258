import React, { useCallback, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { RepeatPickerTypes } from "../../../../types/sharedTypes"
import Time from "./Time"

import { RootState, useAppSelector } from "../../../../redux/reducers"
import { TimeslotResponse } from "../../../../redux/timeslots/types"

import { DateTimePicker as DateAndTimePicker } from "../../../../components/Mobile/DateTimePicker"
import { Drawer } from "../../../../components/Mobile/Drawer"
import { repeatPickerTypes } from "../../../../components/Mobile/RepeatPicker"

type DateTimeProps = {
  onConfirm: () => void
  editing: boolean
  handleClose: () => void
  date: Dayjs
  timeslot: Partial<TimeslotResponse> | null
  preferredTime: string
  onPick: (
    date: Dayjs | null,
    timeslot: Partial<TimeslotResponse> | null,
    repeat: RepeatPickerTypes | null,
    repeatUntil: Dayjs | null,
  ) => void
  onCancelClick: () => void
}

const DateTimePicker = ({
  onConfirm,
  editing,
  handleClose,
  date,
  timeslot,
  preferredTime,
  onPick,
  onCancelClick,
}: DateTimeProps) => {
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(true)

  const onPickHandler = useCallback(
    (
      date: Dayjs | null,
      slot: Partial<TimeslotResponse> | null,
      repeat: RepeatPickerTypes | null,
      repeatUntil: Dayjs | null,
    ) => {
      onPick(date, slot, repeat, repeatUntil)
      setIsDateTimePickerVisible(false)
    },
    [onPick],
  )

  const onCancelHandler = useCallback(() => {
    setIsDateTimePickerVisible(false)
    onCancelClick()
  }, [onCancelClick])

  const { recurring } = useAppSelector(
    (state: RootState) => state.bookAsset.bookAsset,
  )

  return (
    <div>
      <Time
        onPicker={() => setIsDateTimePickerVisible(true)}
        onConfirm={onConfirm}
        editing={editing}
        handleClose={handleClose}
      />
      <Drawer open={isDateTimePickerVisible}>
        <DateAndTimePicker
          onlyInput
          date={date}
          timeslot={timeslot}
          preferredTime={preferredTime}
          onPick={onPickHandler}
          onCancelClick={onCancelHandler}
          repeat={recurring?.freq ?? repeatPickerTypes.ONCE}
          repeatUntil={recurring?.until ? dayjs(recurring?.until) : null}
        />
      </Drawer>
    </div>
  )
}

export default DateTimePicker
