import React from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { License } from "../redux/users/types"

import "./LicenseIndicator.sass"

const LICENSE_NAME = "Desk & asset"

type LicenseIndicatorProps = {
  license?: License | null
}

const LicenseIndicator = ({ license }: LicenseIndicatorProps) => {
  const { t } = useTranslation()

  if (license === undefined) {
    return null
  }

  const expiresAt = license?.expires_at ? dayjs(license.expires_at) : null

  if (license === null || (expiresAt && dayjs().isAfter(expiresAt))) {
    const expired = expiresAt
      ? ` / ${t("desktop.components.subscription_card.expired")} ${expiresAt.format("ddd, ll")}`
      : ""

    return (
      <div className="license-indicator">
        <div className="indicator inactive" />
        <div>{`${LICENSE_NAME} ${t("desktop.components.subscription_card.subscription_inactive")}${expired}`}</div>
      </div>
    )
  }

  const expires = expiresAt
    ? ` / ${t("desktop.components.subscription_card.expires")} ${expiresAt.format("ddd, ll")}`
    : ""

  return (
    <div className="license-indicator">
      <div className="indicator" />
      <div>{`${LICENSE_NAME} ${t("desktop.components.subscription_card.subscription_active")}${expires}`}</div>
    </div>
  )
}

export default LicenseIndicator
