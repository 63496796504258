type Props = {
  offset: number
  totalNumberOfItems: number
  entriesPerPage: number
  maxLinks: number
  maxTrailingLinks: number
}

export type LinkElement = {
  label: string | number
  disabled: boolean
  active: boolean
}

export const useBackendPagination = ({
  offset,
  totalNumberOfItems,
  entriesPerPage,
}: Props) => {
  const from = offset + 1
  let to = offset + entriesPerPage
  let hasNext = true
  let hasPrevious = false

  const currentPage =
    offset >= totalNumberOfItems ? -1 : Math.floor(offset / entriesPerPage) + 1

  const pageCount = Math.ceil(totalNumberOfItems / entriesPerPage)

  if (totalNumberOfItems - offset <= entriesPerPage) {
    to = offset + (totalNumberOfItems - offset)
    hasNext = false
  }

  if (offset >= entriesPerPage) {
    hasPrevious = true
  }

  const formatLink = (link: number | string): LinkElement => {
    return {
      label: link,
      active: link === currentPage,
      disabled: typeof link === "string",
    }
  }

  const generateLinks = () => {
    const links: LinkElement[] = []

    const addEllipsis = () => {
      if (links[links.length - 1]?.label !== "...") {
        links.push(formatLink("..."))
      }
    }

    const addPageLinks = (start: number, end: number) => {
      for (let i = start; i <= end; i++) {
        links.push(formatLink(i))
      }
    }

    // Always show the first page
    links.push(formatLink(1))

    if (currentPage > 3) {
      addEllipsis()
    }

    // Determine the range of pages to show around the current page
    const startPage = Math.max(currentPage - 1, 2)
    const endPage = Math.min(currentPage + 1, pageCount - 1)

    // Add the range of page links around the current page
    addPageLinks(startPage, endPage)

    if (endPage < pageCount - 1) {
      addEllipsis()
    }

    // Always show the last page
    if (pageCount > 1) {
      links.push(formatLink(pageCount))
    }

    return links
  }

  const calcRowNumber = (index: number) => offset + index + 1

  return {
    from,
    to,
    hasNext,
    hasPrevious,
    paginationLinks: generateLinks(),
    calcRowNumber,
  }
}
