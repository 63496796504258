import React, { useEffect, useMemo } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { INTEGRATIONS_PATHS } from "../constants"

import {
  isICalendar,
  useConnectCalendarMutation,
  useFetchCalendarsQuery,
} from "../../../../redux/api/calendars"
import { CalendarType } from "../../../../redux/api/calendars/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import { Input } from "../../../../components/basic/Input"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./ICalendar.sass"

type FormValues = {
  password: string
  password_repeat: string
  user: string
}

const ICalendar = () => {
  const { t } = useTranslation()
  const { push } = useNavigation()
  const { errorToast, infoToast } = useToast()

  const [connectCalendar] = useConnectCalendarMutation()
  const { data: { results: calendars = [] } = {}, isLoading } =
    useFetchCalendarsQuery()

  const onCreate = async ({ password, password_repeat, user }: FormValues) => {
    const response = await connectCalendar({
      type: CalendarType.ICALENDAR,
      next: `${window.location.origin}${INTEGRATIONS_PATHS.calendars}`,
      ...(password ? { password } : {}),
      ...(password_repeat ? { password_repeat } : {}),
      ...(user ? { user } : {}),
    })

    if (isRejected(response)) {
      if (isApiResponseError(response.error)) {
        setErrors(response.error.formError, setError, errorToast)

        return
      }

      errorToast(response.error.message)

      return
    }

    infoToast(t("desktop.settings.integrations.calendars.i_calendar.success"))
    push(INTEGRATIONS_PATHS.calendars)
  }

  const defaultValues = useMemo(
    () => ({
      user: calendars.find((calendar) => isICalendar(calendar))?.user || "",
      password: "",
      password_repeat: "",
    }),
    [calendars],
  )

  const methods = useForm<FormValues>({
    defaultValues,
    mode: "onBlur",
  })

  const { control, reset, setError } = methods

  useEffect(() => {
    if (!isLoading) {
      reset(defaultValues)
    }
  }, [isLoading, defaultValues, reset])

  return (
    <View className="ICalendar">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.calendars.title"),
          t("desktop.settings.integrations.calendars.i_calendar.title"),
        ]}
      />
      <Intro isConstrained>
        <Trans
          i18nKey={"desktop.settings.integrations.calendars.i_calendar.intro"}
        />
      </Intro>

      <Space size={0.75} />
      <FormProvider {...methods}>
        <PageForm
          backUrl={INTEGRATIONS_PATHS.calendars}
          onCreate={onCreate}
          updateMode={false}
          className="ICalendar__form"
          disabled={isLoading}
        >
          <Field
            control={control}
            name="user"
            label={t("desktop.settings.integrations.calendars.form.user_name")}
          >
            {(props) => <Input {...props} />}
          </Field>
          <div className="ICalendar__form__password">
            <Field
              control={control}
              name="password"
              label={t("desktop.settings.integrations.calendars.form.password")}
            >
              {(props) => <Input {...props} type="password" />}
            </Field>
            <Field
              control={control}
              name="password_repeat"
              label={t(
                "desktop.settings.integrations.calendars.form.password_confirm",
              )}
              rules={{
                validate: (value: string, formValues: FormValues) =>
                  value === formValues.password
                    ? undefined
                    : t(
                        "desktop.settings.integrations.calendars.form.password_mismatch",
                      ),
              }}
            >
              {(props) => <Input {...props} type="password" />}
            </Field>
          </div>
        </PageForm>
      </FormProvider>
    </View>
  )
}

export default ICalendar
