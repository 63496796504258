export const readLocal = (key: string, defaultValue: string | boolean) => {
  if (!window.localStorage) {
    return defaultValue
  }

  const value = window.localStorage.getItem(`joan_visitor_login_${key}`)

  if (typeof value === "undefined") {
    return defaultValue
  } else if (value === "true") {
    return true
  } else if (value === "false") {
    return false
  } else {
    return value
  }
}

export const readLocalState = () => ({
  requireHealthScreen: readLocal("requireHealthScreen", true) as boolean,
  companyName: readLocal("companyName", "") as string,
  disableRequestMeeting: readLocal("disableRequestMeeting", false) as boolean,
})

export const writeLocal = (key: string, value: string) => {
  if (!window.localStorage) {
    return null
  }
}
