import React, { ForwardedRef, forwardRef } from "react"

import classNames from "classnames"

import { OptionType } from "../../../types/sharedTypes"
import { Checkbox } from "../Checkbox"

import "./style.sass"

export type MultiCheckboxProps<Value = string> = {
  className?: string
  options: OptionType<Value>[]
  onChange: (selectedValues?: Value[]) => void
  value?: Value[]
  disabled?: boolean
}

const MultiCheckbox = forwardRef(
  <Value extends unknown>(
    {
      options,
      onChange,
      className,
      value = [],
      disabled = false,
    }: MultiCheckboxProps<Value>,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => {
    const handleCheckboxChange = (
      option: OptionType<Value>,
      isSelected: boolean,
    ) => {
      const updatedOptions = isSelected
        ? [...value, option.value]
        : value.filter((selectedOption) => selectedOption !== option.value)

      onChange(updatedOptions)
    }

    const cn = classNames("MultiCheckbox", className)

    return (
      <div className={cn} ref={ref}>
        {options.map((option, index) => (
          <Checkbox
            key={index.toString()}
            value={value.includes(option.value)}
            onChange={(value) => handleCheckboxChange(option, value)}
            label={option.label}
            isSecondary
            disabled={disabled}
          />
        ))}
      </div>
    )
  },
)

export default MultiCheckbox
