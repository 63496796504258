import dayjs from "dayjs"
import { ThunkApiConfig } from "RootType"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { checkInURL, checkOutURL, postJSON } from "../../api"
import { DEFAULT_LANGUAGE } from "../../i18n"
import { isMobile, isTablet } from "../../utils"
import { readLocal, writeLocal } from "../../utils"
import { DeskRequestWithId } from "../api/desks/types"
import { getErrorMessage } from "../reduxUtils"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import weekday from "dayjs/plugin/weekday"

dayjs.extend(weekday)

const today = dayjs().toISOString()
const currentDate = dayjs().startOf("day").toISOString()
const fromDate = dayjs().locale("en").weekday(1).startOf("day").toISOString()
const toDate = dayjs().locale("en").weekday(7).endOf("day").toISOString()

/**
 *  Thunks
 */
export const checkIn = createAsyncThunk<void, string, ThunkApiConfig>(
  "app/checkIn",
  async (locationId, { getState }) => {
    const { access_token } = getState().auth

    const payload = { type: "manual" }

    const response = await postJSON(
      checkInURL(locationId),
      { body: payload },
      access_token,
    )

    if (response.ok) {
      const json = await response.json()

      analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKIN)

      return json
    }
    throw new Error(await getErrorMessage(response))
  },
)

export const checkOut = createAsyncThunk<
  { success: boolean },
  void,
  ThunkApiConfig
>("app/checkOut", async (_: void, { getState }) => {
  const { access_token } = getState().auth

  const payload = { type: "manual" }

  const response = await postJSON(
    checkOutURL(),
    { body: payload },
    access_token,
  )

  if (response.ok) {
    analyticsEvent(SupportedEvents.DESK_RESERVATION_CHECKOUT)

    return { success: true }
  }
  throw new Error(await getErrorMessage(response))
})

/**
 *  Slice
 */
export interface OnboardingState {
  steps: ("user" | "organization" | "building")[]
  currentStep: number
  url?: string
}
export interface AppState {
  isMobile: boolean
  isTablet: boolean
  company: string
  today: string
  currentDate: string
  fromDate: string
  toDate: string
  showWeekends: boolean
  reposition_desk: DeskRequestWithId | null
  isCheckinDismissed: boolean
  isCheckinLoading: boolean
  isCheckoutSubmitting: boolean
  lang: string
  error: string | null
  needsOnboarding: boolean
  onboarding?: OnboardingState
  isPortraitOrientation: boolean
}

const initialState: AppState = {
  isMobile: isMobile(),
  isTablet: isTablet(),
  company: "",
  today,
  currentDate,
  fromDate,
  toDate,
  showWeekends: readLocal("showWeekends") === "true" ? true : false,
  reposition_desk: null,
  isCheckinDismissed: false,
  isCheckinLoading: false,
  isCheckoutSubmitting: false,
  lang: DEFAULT_LANGUAGE,
  error: null,
  needsOnboarding: false,
  isPortraitOrientation: true,
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    },
    changeWeek: (
      state,
      { payload }: PayloadAction<{ start: string; end: string }>,
    ) => {
      state.toDate = payload.end
      state.fromDate = payload.start
    },
    changeDay: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload
    },
    toggleWeekends: (state) => {
      writeLocal("showWeekends", !state.showWeekends ? "true" : "false")

      state.showWeekends = !state.showWeekends
    },

    setRepositionDesk: (
      state,
      { payload }: PayloadAction<DeskRequestWithId | null>,
    ) => {
      state.reposition_desk = payload
    },
    clearRepositionDesk: (state) => {
      state.reposition_desk = null
    },
    dismissCheckin: (state) => {
      state.isCheckinDismissed = true
    },
    setCompany: (state, { payload }: PayloadAction<string>) => {
      if (!state.company) {
        state.company = payload
      }
    },
    appError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setOnboardingDone: (state, _: PayloadAction<void>) => {
      state.needsOnboarding = false
    },
    setOnboarding: (state, { payload }: PayloadAction<OnboardingState>) => {
      state.needsOnboarding = true
      if (!state.onboarding) {
        state.onboarding = payload
        return
      }
      // don't set the url as it is already set
      const { url, ...onboardingRest } = payload
      state.onboarding = { ...state.onboarding, ...onboardingRest }
    },
    setOnboardingStep: (state, { payload }: PayloadAction<number>) => {
      if (!state.onboarding) {
        return
      }

      state.onboarding.currentStep = payload
    },
    setOnboardingUrl: (state, { payload }: PayloadAction<string>) => {
      if (!state.onboarding) {
        return
      }

      state.onboarding.url = payload
    },
    setIsPortraitOrientation: (state, { payload }: PayloadAction<boolean>) => {
      state.isPortraitOrientation = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIn.pending, (state) => {
      state.isCheckinLoading = true
    })
    builder.addCase(checkIn.rejected, (state) => {
      state.isCheckinLoading = false
    })
    builder.addCase(checkIn.fulfilled, (state) => {
      state.isCheckinLoading = false
    })
    builder.addCase(checkOut.pending, (state) => {
      state.isCheckoutSubmitting = true
    })
    builder.addCase(checkOut.rejected, (state) => {
      state.isCheckoutSubmitting = false
    })
    builder.addCase(checkOut.fulfilled, (state) => {
      state.isCheckoutSubmitting = false
    })
  },
})

// export const app = appSlice.reducer

export const appReducer = appSlice.reducer
export const {
  changeLanguage,
  changeWeek,
  changeDay,
  toggleWeekends,
  setRepositionDesk,
  clearRepositionDesk,
  dismissCheckin,
  setCompany,
  appError,
  setOnboardingDone,
  setOnboarding,
  setOnboardingStep,
  setOnboardingUrl,
  setIsPortraitOrientation,
} = appSlice.actions
