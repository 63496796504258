import { api, invalidateOn } from ".."
import {
  meEmailPortalURL,
  mePasswordPortalURL,
  mePortalURL,
  meURL,
} from "../../../api"
import { setCompany } from "../../app/appSlice"
import { setUser } from "../../user/userSlice"
import { UserRequest } from "../users/types"
import {
  MeResponse,
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
} from "./types"

export const me = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchMe: builder.query<MeResponse, void>({
      query: () => ({
        url: meURL(),
      }),
      providesTags: ["Me"],
      // update user state so we don't make two calls to the API
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          const result = await queryFulfilled
          dispatch(setUser(result.data))
          if (result.data.companies[0].id) {
            dispatch(setCompany(result.data.companies[0].id))
          }
        } catch (e) {
          console.error(e)
        }
      },
    }),

    updateMe: builder.mutation<MeResponse, Partial<UserRequest>>({
      query: (payload) => ({
        url: mePortalURL(),
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: invalidateOn({ success: ["Me"] }),
    }),

    updateEmail: builder.mutation<UpdateEmailResponse, UpdateEmailRequest>({
      query: ({ newEmail, confirmation }) => ({
        url: meEmailPortalURL(),
        method: "PUT",
        body: {
          new: newEmail,
          confirmation,
        },
      }),
      invalidatesTags: invalidateOn({ success: ["Me"] }),
      transformResponse: (response: UpdateEmailResponse, _, arg) => {
        return {
          ...response,
          newEmail: response.new,
        }
      },
    }),

    updatePassword: builder.mutation<
      UpdatePasswordResponse,
      UpdatePasswordRequest
    >({
      query: ({ newPassword, confirmation }) => ({
        url: mePasswordPortalURL(),
        method: "PUT",
        body: {
          new: newPassword,
          confirmation,
        },
      }),
      invalidatesTags: invalidateOn({ success: ["Me"] }),
    }),
  }),
})

export const {
  useFetchMeQuery,
  useLazyFetchMeQuery,
  useUpdateMeMutation,
  useUpdateEmailMutation,
  useUpdatePasswordMutation,
} = me
