import { isArray } from "lodash"
import { FieldValues, Path, UseFormSetError } from "react-hook-form"

import { ResponseError } from "../../api/apiUtils"

const OCCUPANCY_ERROR_KEYS = [
  "department_capacity",
  "building_capacity",
  "floor_capacity",
]

/**
 * Sets errors in a form.
 * @template TFieldValues
 * @param {ResponseError} errors - The error object.
 * @param {UseFormSetError<TFieldValues>} setError - The setError function from react-hook-form.
 * @param {(error: string) => void} generalErrorHandler - The general error handler function. In most cases the errorToast from internal useToast hook
 * @param {Record<string, Path<TFieldValues>>} [mapErrorObject] - Optional object to map error object to a different field.
 */

export const setErrors = <TFieldValues extends FieldValues>(
  errors: ResponseError | undefined,
  setError: UseFormSetError<TFieldValues>,
  generalErrorHandler: (error: string) => void,
  mapErrorObject?: Record<
    string,
    Path<TFieldValues> | readonly Path<TFieldValues>[] | string[]
  >,
): void => {
  if (!errors) {
    return
  }

  if (typeof errors === "string") {
    generalErrorHandler(errors)

    return
  }
  if (
    typeof errors === "object" &&
    "_error" in errors &&
    typeof errors._error === "string"
  ) {
    generalErrorHandler(errors._error)

    return
  }

  Object.entries(errors).forEach(([name, message]) => {
    if (typeof message === "string") {
      const namePoint = getName(name, mapErrorObject)

      if (OCCUPANCY_ERROR_KEYS.includes(name)) {
        generalErrorHandler(message)
      } else if (isArray(namePoint)) {
        namePoint.forEach((np) => setError(np, { message, type: "server" }))
      } else {
        setError(namePoint as Path<TFieldValues>, { message, type: "server" })
      }
    }
  })
  return
}

const getName = <TFieldValues extends FieldValues>(
  name: string,
  mapErrorObject?: Record<
    string,
    Path<TFieldValues> | readonly Path<TFieldValues>[] | readonly string[]
  >,
) => (mapErrorObject?.[name] ? mapErrorObject[name] : name)
