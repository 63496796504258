import { Trans, useTranslation } from "react-i18next"

import DeskSettingsForm from "./DeskSettingsForm"

import { useAppSelector } from "../../../../redux/reducers"
import { selectSettingsEffective } from "../../../../redux/settings/selectors"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const General = () => {
  const { t } = useTranslation()

  const { isLoaded } = useAppSelector(selectSettingsEffective)

  return (
    <View className="DeskSettingsGeneral">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.desks.title"),
          t("desktop.settings.desks.general.title"),
        ]}
      />

      <Intro isConstrained>
        <Trans i18nKey="desktop.settings.desks.general.settings_intro">
          <RedirectLink to="desk-booking-instructions">link</RedirectLink>
        </Trans>
      </Intro>

      <Space size={0.75} />

      {isLoaded && <DeskSettingsForm />}
    </View>
  )
}

export default General
