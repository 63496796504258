import { forwardRef, useEffect } from "react"

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { userTimeFormat } from "../../../utils"
import { BasicDatePicker, BasicDatePickerProps } from "../../basic/DatePicker"
import { loadLocaleData } from "./utils"

export type DatePickerProps = BasicDatePickerProps

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  ({ withTime, ...props }: DatePickerProps, ref) => {
    const { t } = useTranslation()
    const locale = dayjs.locale()

    const timeCaption = t(
      "desktop.settings.rooms.custom_content.forms.general.time_label",
    )

    const timeFormat = userTimeFormat()
    const dateFormat = withTime ? `MMMM d, yyyy ${timeFormat}` : "MMMM d, yyyy"

    useEffect(() => {
      loadLocaleData(locale)
    }, [locale])

    return (
      <BasicDatePicker
        ref={ref}
        {...props}
        withTime={withTime}
        locale={locale}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        timeCaption={timeCaption}
      />
    )
  },
)
