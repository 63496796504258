import { ValueJSON } from "slate"

import {
  ROTATION_INTERVALS,
  SUPPORTED_TYPES,
} from "../../../screens/Settings/Rooms/CustomContent/constants"
import { ISODate } from "../../../types/sharedTypes"
import { PaginatedResponse } from "../../types"

export type RotationIntervalUnit =
  (typeof ROTATION_INTERVALS)[keyof typeof ROTATION_INTERVALS]

export type RotationInterval = {
  prefix: string
  duration: string
  unit: RotationIntervalUnit
}

export type Schedule = {
  start: ISODate
  end: ISODate
  repeat: string | null
}

export type Content = {
  type: LayoutGridType
  sections: Section[]
}

export type SectionContentType = 0 | 1 | 3 | 4

export enum SectionContentTypeEnum {
  Gallery = 0,
  Text = 1,
  Webpage = 3,
  RoomStatus = 4,
}

export type GalleryContent = {
  images: ImageFile[]
  interval: string
  selected: string[]
}

export type TextContent = {
  text: ValueJSON
}

export type WebpageContent = {
  url: string
}

export type RoomStatusContent = {
  rooms: unknown
}

export type SectionContent =
  | GalleryContent
  | TextContent
  | WebpageContent
  | RoomStatusContent

export type Section = {
  type: SectionContentType | null
  content: SectionContent | null
}

export type ImageFile = {
  uid: string
  lastModified: number
  lastModifiedDate: string
  name: string
  size: number
  type: string
  percent: number
  originFileObj: {
    uid: string
  }
  id: string
  state: string
  src: string
}

export type LayoutGridType = (typeof SUPPORTED_TYPES)[number]

export type CustomContentRequest = {
  id: number | null
  name: string | null
  type: number
  content: Content
  status: number
  devices: string[]
  schedule: Schedule | null
}

export type CustomContentResponse = {
  id: number
  name: string | null
  type: number
  content: Content
  status: number
  devices: string[]
  schedule: Schedule | null
}

export type CustomContentFetchOptions = {
  limit?: number
  offset?: number
  status?: string
  ids?: string
  devices?: string
}

export type CustomContentsResponse = PaginatedResponse<CustomContentResponse>
