import { api } from ".."
import { demoURL } from "../../../api"
import { DemoType } from "./types"

export const demo = api.injectEndpoints({
  endpoints: (builder) => ({
    createDemoData: builder.mutation<void, DemoType | void>({
      query: (demoType) => ({
        url: demoURL({ type: demoType ?? "desk" }),
        method: "POST",
      }),
      invalidatesTags: ["Assets", "AssetTypes", "Buildings", "Desks", "Floors"],
    }),
  }),
})

export const { useCreateDemoDataMutation } = demo
