import React from "react"

import { Redirect, Switch } from "react-router-dom"

import { PERMISSIONS } from "../../../constants"
import { VISITORS_PATHS } from "./constants"
import DeviceDetail from "./DeviceDetail"
import Devices from "./Devices"
import DeviceSettings from "./DeviceSettings"
import DocumentEdit from "./DocumentEdit"
import Documents from "./Documents"
import EmailSettings from "./EmailSettings"
import VisitorSettings from "./VisitorSettings"
import VisitorSettingsUser from "./VisitorSettingsUser"
import VisitorsOverviewPage from "./VisitorsOverviewPage"

import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isNormalUser, isPortalAdmin } from "../../../redux/user/utils"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Visitors = () => {
  const { entry: currentUser } = useAppSelector(selectUser)

  const isUser = isNormalUser(currentUser)
  const isAdmin = isPortalAdmin(currentUser)

  const changeVisitorDocumentPermission =
    PERMISSIONS.documents.canChangeVisitorDocument

  return (
    <Switch>
      {isAdmin && (
        <ProtectedRoute
          exact
          path={VISITORS_PATHS.overview}
          component={VisitorsOverviewPage}
        />
      )}
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.documents}
        accessPermission={changeVisitorDocumentPermission}
        component={Documents}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.documentsEdit}
        accessPermission={changeVisitorDocumentPermission}
        component={DocumentEdit}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.documentsAdd}
        accessPermission={changeVisitorDocumentPermission}
        component={DocumentEdit}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.devices}
        // TODO: Add proper access permissions
        component={Devices}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.deviceDetail}
        // TODO: Add proper access permissions
        component={DeviceDetail}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.deviceSettings}
        component={DeviceSettings}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.general}
        // TODO: Remove /settings/visitors in the future (currently used for redirects)
        render={() => (isUser ? <VisitorSettingsUser /> : <VisitorSettings />)}
      />
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.emailSettings}
        component={EmailSettings}
      />

      <Redirect to={VISITORS_PATHS.general} />
    </Switch>
  )
}

export default Visitors
