import React, { useCallback, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import { AssetScheduleStatus } from "../../../redux/asset_schedule/assetScheduleSlice"

import { AssetStatusFilter } from "../../../components/Filter/AssetStatusFilter"
import AssetTypesFilter from "../../../components/Filter/AssetTypesFilter"
import BuildingFilter from "../../../components/Filter/BuildingFilter"
import DepartmentFilter from "../../../components/Filter/DepartmentFilter"
import Filters from "../../../components/Filter/Filters"
import FilterSpace from "../../../components/Filter/FilterSpace"
import SearchFilter from "../../../components/Filter/SearchFilter"

export type Filter = {
  building_id: string
  department_id?: string
  asset_type: string
  show?: AssetScheduleStatus
  search: string
  page?: number
}
type FilterKeys = keyof Filter

type Props = {
  onChange: (filter: Filter) => Promise<void>
  defaultValues: Filter
}

const AssetFilters = ({ onChange, defaultValues }: Props) => {
  const filtersRef = useRef<Filter>(defaultValues)
  const [filters, setFilters] = useState<Filter>(defaultValues)

  const { t } = useTranslation()

  const handleOnChange = useCallback(
    (filterName: FilterKeys) => (value: string | string[]) => {
      filtersRef.current = {
        ...filtersRef.current,
        [filterName]: value,
      }
      onChange(filtersRef.current as Filter)
      setFilters(filtersRef.current)
    },
    [onChange],
  )

  return (
    <Filters>
      <BuildingFilter
        value={filters.building_id}
        onChange={handleOnChange("building_id")}
        showAll={true}
      />

      <DepartmentFilter
        value={filters.department_id ?? ""}
        onChange={handleOnChange("department_id")}
        showAll={true}
        showNotAssigned={true}
      />

      <AssetTypesFilter
        value={filters.asset_type}
        onChange={handleOnChange("asset_type")}
        showAll={true}
        buildingId={filters.building_id}
      />

      <AssetStatusFilter
        value={filters.show ?? "all"}
        onChange={handleOnChange("show")}
      />

      <FilterSpace />
      <SearchFilter
        value={filters.search ?? ""}
        onChange={handleOnChange("search")}
        placeholder={t("desktop.manage.asset_booking.filter_assets")}
      />
    </Filters>
  )
}

export default AssetFilters
