import { Redirect, Switch as RouteSwitch } from "react-router-dom"

import { FEATURE_FLAGS, PERMISSIONS } from "../../../constants"
import ApiKeys from "./ApiKeys"
import NewApiKey from "./ApiKeys/NewApiKey"
import Calendars from "./Calendars"
import Exchange from "./Calendars/Exchange"
import ICalendar from "./Calendars/ICalendar"
import ChatBots from "./ChatBots"
import { INTEGRATIONS_PATHS } from "./constants"
import PresenceIntegration from "./Presence"
import ScimIntegration from "./Scim"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Integrations = () => {
  const changeUserPermission = PERMISSIONS.users.canChangeUser

  return (
    <RouteSwitch>
      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.scim}
        component={ScimIntegration}
        accessPermission={changeUserPermission}
      />
      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.apiKeys}
        component={ApiKeys}
        /*
				TODO:
				accessPermission=""
				*/
      />
      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.newApiKey}
        component={NewApiKey}
        /*
				TODO:
				accessPermission=""
				*/
      />
      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.calendars}
        component={Calendars}
        /*
				TODO:
				accessPermission=""
				*/
      />
      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.exchange}
        component={Exchange}
        /*
				TODO:
				accessPermission=""
				*/
      />
      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.icalendar}
        component={ICalendar}
        /*
				TODO:
				accessPermission=""
				*/
      />

      <ProtectedRoute
        exact
        path={INTEGRATIONS_PATHS.presence}
        component={PresenceIntegration}
      />
      <ProtectedRoute path={INTEGRATIONS_PATHS.chatBots} component={ChatBots} />
      <Redirect to={INTEGRATIONS_PATHS.calendars} />
    </RouteSwitch>
  )
}

export default Integrations
