import { Trans, useTranslation } from "react-i18next"

import Card from "../../../components/basic/Card"
import Breadcrumbs from "../../../components/Breadcrumbs"
import DeleteCompanyForm from "../../../components/Form/DeleteCompanyForm"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./DeleteCompany.sass"

const DeleteCompany = () => {
  const { t } = useTranslation()

  return (
    <View className="DeleteCompany">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.settings.organization.title"),
          t("desktop.settings.organization.company_details.title"),
          t(
            "desktop.settings.organization.company_details.delete_company.title",
          ),
        ]}
      />

      <Intro isConstrained>
        <Trans i18nKey="desktop.settings.organization.company_details.delete_company.intro" />
      </Intro>

      <Space size={0.75} />

      <Card className="PermanentActionWarning">
        <div>
          <Trans i18nKey="desktop.settings.organization.company_details.delete_company.warning" />
        </div>
      </Card>
      <DeleteCompanyForm />
    </View>
  )
}

export default DeleteCompany
