type EventListeners = { [eventName: string]: Function[] }

/**
 * The Dispatcher class is responsible for managing event listeners and dispatching events.
 */
export class Dispatcher {
  private static instance: Dispatcher
  private eventListeners: EventListeners = {}

  /**
   * Returns the singleton instance of the Dispatcher class.
   * @returns The Dispatcher instance.
   */
  static getInstance(): Dispatcher {
    if (!Dispatcher.instance) {
      Dispatcher.instance = new Dispatcher()
    }
    return Dispatcher.instance
  }

  /**
   * Adds an event listener for the specified event.
   * @param eventName - The name of the event.
   * @param callback - The callback function to be executed when the event is dispatched.
   */
  on(eventName: string, callback: Function) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = []
    }
    this.eventListeners[eventName].push(callback)
  }

  /**
   * Removes an event listener for the specified event.
   * @param eventName - The name of the event.
   * @param callback - The callback function to be removed.
   */
  off(eventName: string, callback: Function) {
    const listeners = this.eventListeners[eventName]
    if (listeners) {
      this.eventListeners[eventName] = listeners.filter(
        (listener) => listener !== callback,
      )
    }
  }

  /**
   * Dispatches the specified event with the provided arguments.
   * @param eventName - The name of the event to be dispatched.
   * @param args - The arguments to be passed to the event listeners.
   */
  dispatch(eventName: string, ...args: any[]) {
    const listeners = this.eventListeners[eventName]
    if (listeners) {
      listeners.forEach((listener) => listener(...args))
    }
  }
}
