import React from "react"

import dayjs from "dayjs"

import { pluralize } from "../utils"

const shortFormats = {
  day: "ddd",
  month: "MMM",
  date: "D",
  year: "YYYY",
}

const longFormats = {
  day: "dddd",
  month: "MMMM",
  date: "D",
  year: "YYYY",
}

type Props = {
  className?: any
  date?: dayjs.ConfigType
  isInline?: boolean
  isLong?: boolean
  showYear?: boolean
  isPluralShown?: boolean
}

const Datestring = ({
  className,
  date,
  isInline,
  isLong,
  showYear,
  isPluralShown = true,
  ...props
}: Props) => {
  const fmt = isLong ? longFormats : shortFormats

  const locale = dayjs.locale()
  const dayjsDate = dayjs(date)
  const month = dayjsDate.format(fmt.month)
  const day = dayjsDate.format(fmt.day)
  const dateNum = dayjsDate.format(fmt.date)
  const year = dayjsDate.format(fmt.year)

  const delim = isInline ? ", " : " "

  const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  const formattedDate = () => {
    if (locale.startsWith("en")) {
      return `${day}${delim}${month} ${isPluralShown ? pluralize(parseInt(dateNum)) : dateNum}`
    } else {
      const dayNum = dayjsDate.format("DD") // Two-digit date number for non-English locales
      return `${capitalize(day)}${delim}${dayNum} ${month}`
    }
  }

  return (
    <span className={className} {...props}>
      {formattedDate()}
      {showYear && `${delim}${year}`}
    </span>
  )
}

export default Datestring
