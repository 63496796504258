import React, { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import i18n from "../../../i18n"
import { userTimeFormat } from "../../../utils"
import Datestring from "../../Datestring"

import "./style.sass"

const Clock = () => {
  const [date, setDate] = useState<Dayjs>(dayjs())
  const [timeFormat, setTimeFormat] = useState(userTimeFormat())

  // Update time format dynamically when language changes
  useEffect(() => {
    const updateTimeFormat = () => {
      setTimeFormat(userTimeFormat())
    }

    i18n.on("languageChanged", updateTimeFormat)

    return () => {
      i18n.off("languageChanged", updateTimeFormat)
    }
  }, [])

  // Update date every second to show current time
  useEffect(() => {
    const interval = setInterval(() => {
      setDate(dayjs())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="Clock">
      <div>
        <Datestring date={date} isInline />
      </div>
      <div>{date.format(timeFormat)}</div>
    </div>
  )
}

export default Clock
