import { useRefresh } from "./useRefresh"
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate"

/**
 * Hook to refetch query results.
 *
 * Accepts an array of RTK query refetch functions,
 * and calls each one on refresh to refetch the queries.
 */

export const useRefetch = (
  reFetchers: (() => QueryActionCreatorResult<any>)[],
): void => {
  useRefresh(() => {
    reFetchers?.forEach((refetch) => refetch())
  })
}
