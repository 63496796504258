import { ThunkApiConfig } from "RootType"

import {
  get,
  patchJSON,
  postJSON,
  visitorSettingsURL,
  visitorSettingURL,
  visitorSettingUserURL,
} from "../../api"
import { ResponseError } from "../../api/apiUtils"
import { api } from "../api"
import {
  getErrorMessage,
  getErrorObject,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  VisitorSettingRequest,
  VisitorSettingResponse,
  VisitorSettingsResponse,
  VisitorSettingUserResponse,
} from "./types"
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit"

export const fetchVisitorSettings = createAsyncThunk<
  VisitorSettingsResponse,
  void,
  ThunkApiConfig
>("visitorSettings/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(visitorSettingsURL(), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchVisitorSetting = createAsyncThunk<
  VisitorSettingResponse,
  string,
  ThunkApiConfig
>("visitorSetting/fetch", async (id, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(visitorSettingURL(id), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const fetchVisitorUserSetting = createAsyncThunk<
  VisitorSettingUserResponse,
  void,
  ThunkApiConfig
>("visitorSetting/fetch", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(visitorSettingUserURL(), {}, access_token)

  if (response.ok) {
    return await response.json()
  }

  throw new Error(await getErrorMessage(response))
})

export const createVisitorSetting = createAsyncThunk<
  VisitorSettingResponse,
  VisitorSettingRequest,
  ThunkApiConfig<ResponseError>
>(
  "visitorSettings/create",
  async (body, { getState, rejectWithValue, dispatch }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await postJSON(
      visitorSettingsURL(),
      {
        body,
      },
      access_token,
    )

    if (response.ok) {
      dispatch(
        api.util.invalidateTags([{ type: "VisitorSettings", id: "LIST" }]),
      )

      return await response.json()
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

export const updateVisitorSetting = createAsyncThunk<
  VisitorSettingResponse,
  VisitorSettingRequest,
  ThunkApiConfig<ResponseError>
>(
  "visitorSettings/update",
  async (body, { getState, rejectWithValue, dispatch }) => {
    const {
      auth: { access_token },
    } = getState()

    const response = await patchJSON(
      visitorSettingURL(body.building_id),
      {
        body,
      },
      access_token,
    )

    if (response.ok) {
      dispatch(
        api.util.invalidateTags([{ type: "VisitorSettings", id: "LIST" }]),
      )

      return await response.json()
    }

    return rejectWithValue(await getErrorObject(response))
  },
)

export interface VisitorSettingsState extends SliceState {
  entries: VisitorSettingResponse[]
}
export interface VisitorSettingUserState extends SliceState {
  entry: VisitorSettingUserResponse
}

const initialState: VisitorSettingsState = {
  entries: [],
  ...sliceInitialState,
}

const initialStateUser: VisitorSettingUserState = {
  entry: {
    document_signing_enabled: false,
    enable_general_notification: false,
    health_screening_notification: false,
    send_app_notification: false,
    send_arrival_email_notification: false,
    send_slack_notification: false,
    send_sms_notification: false,
  },
  ...sliceInitialState,
}

const visitorSettingsSlice = createSlice({
  name: "visitorSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVisitorSettings.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entries = payload.results
    })
    builder.addCase(fetchVisitorSetting.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      const remain = state.entries.filter((e) => e.id !== payload.id)
      state.entries = [...remain, payload]
    })
    builder.addCase(createVisitorSetting.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      state.entries = [...state.entries, payload]
    })
    builder.addCase(updateVisitorSetting.fulfilled, (state, { payload }) => {
      setSubmitSuccessState(state)
      const remain = state.entries.filter((e) => e.id !== payload.id)
      state.entries = [...remain, payload]
    })
    builder.addCase(fetchVisitorSettings.pending, (state) => {
      state.isLoading = true
    })
    builder.addMatcher(
      isAnyOf(createVisitorSetting.pending, updateVisitorSetting.pending),
      (state) => {
        state.isSubmitting = true
      },
    )
    builder.addMatcher(
      isAnyOf(
        fetchVisitorSettings.rejected,
        fetchVisitorSetting.rejected,
        createVisitorSetting.rejected,
        updateVisitorSetting.rejected,
      ),
      (state, action) => {
        setFetchErrorState(state, action)
      },
    )
  },
})

const visitorSettingsUserSlice = createSlice({
  name: "visitorSettingsUser",
  initialState: initialStateUser,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVisitorUserSetting.fulfilled, (state, { payload }) => {
      setFetchSuccessState(state)
      state.entry = payload
    })
    builder.addCase(fetchVisitorUserSetting.pending, (state) => {
      state.isLoading = true
    })
    builder.addMatcher(
      isAnyOf(fetchVisitorUserSetting.rejected),
      (state, action) => {
        setFetchErrorState(state, action)
      },
    )
  },
})

export const visitorSettingsReducer = visitorSettingsSlice.reducer
export const visitorSettingsUserReducer = visitorSettingsUserSlice.reducer
