import { PaginatedResponse } from "../types"

export enum SignInFieldType {
  NAME = "NAME",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  HOST = "HOST",
  CUSTOM = "CUSTOM",
}

export enum BrandingOption {
  NONE = "NONE",
  LOGO = "LOGO",
  COMPANY_NAME = "COMPANY_NAME",
}

export enum CheckInOption {
  NONE = "NONE",
  DATE_AND_TIME = "DATE_AND_TIME",
}

export type SignInField = {
  id?: string
  type: SignInFieldType
  custom_field_name?: string
}

export type PrinterSettingResponse = {
  id: string
  building_id: string
  show_check_in: CheckInOption
  company_id: string
  enable_badge_printing: boolean
  branding: BrandingOption
  message?: string
  signin_fields: SignInField[]
}

export type PrinterSettingRequest = {
  id?: string
  building_id: string
  show_check_in: CheckInOption
  enable_badge_printing: boolean
  branding: BrandingOption
  message?: string
  signin_fields: SignInField[]
}

export type PrinterSettingsResponse = PaginatedResponse<PrinterSettingResponse>
