import React from "react"

import "./FromToTimePicker.sass"

type FromToTimePickerProps = {
  from: string
  to: string
  onFromChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onToChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const FromToTimePicker = ({
  from,
  to,
  onFromChange,
  onToChange,
}: FromToTimePickerProps) => {
  return (
    <div className="FromToTimePicker">
      <div className="from">
        <input
          type="time"
          pattern="[0-9]{2}:[0-9]{2}"
          value={from}
          onChange={onFromChange}
        />
      </div>
      <div className="icon">-</div>
      <div className="to">
        <input
          type="time"
          pattern="[0-9]{2}:[0-9]{2}"
          value={to}
          onChange={onToChange}
        />
      </div>
    </div>
  )
}
