import { ThunkApiConfig } from "RootType"

import { deleteJSON, get, ssoProvidersURL, ssoProviderURL } from "../../api"
import {
  getErrorMessage,
  setFetchErrorState,
  setFetchSuccessState,
  setSubmitErrorState,
  setSubmitSuccessState,
  sliceInitialState,
} from "../reduxUtils"
import { SliceState } from "../types"
import {
  SsoProviderResponse,
  SsoProvidersResponse,
  SsoProviderType,
} from "./types"
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit"

export const fetchSsoProviders = createAsyncThunk<
  SsoProvidersResponse,
  undefined,
  ThunkApiConfig
>("ssoProviders/fetchSsoProviders", async (_, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await get(ssoProvidersURL(), {}, access_token)

  if (response.ok) {
    return (await response.json()) as SsoProvidersResponse
  }
  throw new Error(await getErrorMessage(response))
})

export const removeSsoProvider = createAsyncThunk<
  SsoProviderType,
  SsoProviderType,
  ThunkApiConfig
>("ssoProviders/removeSsoProvider", async (provider, { getState }) => {
  const {
    auth: { access_token },
  } = getState()

  const response = await deleteJSON(
    ssoProviderURL(provider),
    { body: {} },
    access_token,
  )

  if (response.ok) {
    return provider
  }
  throw new Error(await getErrorMessage(response))
})

export interface SsoProvidersState extends SliceState {
  ssoProviders: Array<SsoProviderResponse>
}

const initialState: SsoProvidersState = {
  ssoProviders: [],
  ...sliceInitialState,
}

const ssoProvidersSlice: Slice<SsoProvidersState> = createSlice({
  name: "ssoProviders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSsoProviders.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchSsoProviders.rejected, (state, action) => {
      setFetchErrorState(state, action)
    })
    builder.addCase(fetchSsoProviders.fulfilled, (state, action) => {
      const { payload } = action
      setFetchSuccessState(state)
      state.ssoProviders = [...payload.results]
    })

    builder.addCase(removeSsoProvider.pending, (state) => {
      state.isSubmitting = true
    })
    builder.addCase(removeSsoProvider.rejected, (state, action) => {
      setSubmitErrorState(state, action)
    })
    builder.addCase(removeSsoProvider.fulfilled, (state, action) => {
      const { payload } = action
      setSubmitSuccessState(state)
      state.ssoProviders = [
        ...state.ssoProviders.map((ssoProvider) => {
          if (ssoProvider.provider === payload) {
            return {
              ...ssoProvider,
              linked: false,
            }
          }
          return ssoProvider
        }),
      ]
    })
  },
})

export const ssoProvidersReducer = ssoProvidersSlice.reducer
