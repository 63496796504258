import React from "react"

import { Trans } from "react-i18next"

import { FETCH_FOR_COUNT } from "../../../../constants"

import { useFetchRoomsDevicesQuery } from "../../../../redux/api/devices"

import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import NoDataFound from "../../../../components/NoDataFound"

const OnboardingTableInfo = () => {
  const {
    data: { count: deviceCount = 0 } = {},
    isLoading: areDevicesLoading,
  } = useFetchRoomsDevicesQuery(FETCH_FOR_COUNT)

  if (areDevicesLoading) {
    return null
  }

  if (deviceCount === 0) {
    return (
      <NoDataFound warning>
        <Trans
          i18nKey="desktop.settings.rooms.devices.no_devices.description"
          components={{
            a: (
              <RedirectLink to="how-to-add-a-device-to-my-joan">
                this knowledge base article
              </RedirectLink>
            ),
          }}
        />
      </NoDataFound>
    )
  }

  return null
}

export default OnboardingTableInfo
