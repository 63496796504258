import { useEffect } from "react"

const GTM_ID = import.meta.env.VITE_APP_GTM_ID
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

const TagManager = () => {
  useEffect(() => {
    if (!GTM_ID || isIE11 === true) {
      return
    }

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })

    const ref = document.getElementsByTagName("meta")[0]

    const script = document.createElement("script")

    script.async = true
    script.src = "https://www.googletagmanager.com/gtm.js?id=" + GTM_ID

    ref.parentNode.insertBefore(script, ref)
  }, [])

  return null
}

export default TagManager
