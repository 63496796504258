import { useTranslation } from "react-i18next"

import Dropdown from "../basic/Dropdown"

type Props = {
  value: string
  onChange: (v: string) => void
}

const InvitesFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const invitesOptions = [
    {
      label: t("desktop.manage.visitors.filters.all_invites"),
      value: "",
    },
    {
      label: t("desktop.manage.visitors.filters.my_invites"),
      value: "my",
    },
    {
      label: t("desktop.manage.visitors.filters.entry_approved"),
      value: "approved",
    },
    {
      label: t("desktop.manage.visitors.filters.entry_denied"),
      value: "denied",
    },
    {
      label: t("desktop.manage.visitors.filters.no_show"),
      value: "no-show",
    },
  ]

  return (
    <Dropdown
      className="invites-filter"
      options={invitesOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export default InvitesFilter
