import React, {
  FC,
  Fragment,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"

import { SHORT_USER_TIME_FORMAT } from "../../constants"
import { useQuery } from "../../hooks/useQuery"
import { useStartEndIndexInView } from "../../hooks/useStartEndIndexInView"
import DepartmentListItem from "./DepartmentListItem"
import DeskListItem from "./DeskListItem"
import PlaceDesk from "./PlaceDesk"
import ShareableFloorPlanError from "./ShareableFloorPlanError"
import ShareableHeader from "./ShareableHeader"

import { useAppSelector } from "../../redux/reducers"
import { selectShareable } from "../../redux/shareable/selectors"
import { fetchShareable } from "../../redux/shareable/shareableSlice"
import { Desk } from "../../redux/shareable/types"
import { WITHOUT_DEPARTMENT } from "../../redux/shareable/utils"
import { useActions } from "../../redux/utils"

import Loader from "../../components/basic/Loader"
import SimpleMap from "../../components/SimpleMap"
import YouAreHere from "../../components/YouAreHere"

import "./ShareableFloorPlan.sass"

type DeskWithIndex = Desk & {
  index?: number
}

const CHANGE_DEPARTMENT_INTERVAL = 15000
const FETCH_SHAREABLE_INTERVAL = 60000
const STALE_CHECK_INTERVAL = 3000
const STALE_TIME_IN_SECONDS = 120

const ShareableFloorPlan: FC = () => {
  const { shareableId } = useParams<{ shareableId: string }>()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const hideYouAreHereMarker = queryParams.get("show_location") === "false"

  const { t } = useTranslation()

  const [selectedDepartment, setSelectedDepartment] = useState<string>("")
  const [isStale, setIsStale] = useState<boolean>(false)
  const [selectedRef, setSelectedRef] = useState<HTMLDivElement | null>(null)
  const [selectedDesks, setSelectedDesks] = useState<DeskWithIndex[]>([])

  const query = useQuery()

  const index = useRef<number>(0)
  const lastUpdate = useRef<Dayjs>(dayjs())
  const curDepRef = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const deskItemsRef: MutableRefObject<(HTMLDivElement | null)[]> = useRef([])

  const actions = useActions({
    fetchShareable: (shareableId: string) => fetchShareable(shareableId),
  })

  const {
    entry: shareable,
    isLoading,
    isLoaded,
    error,
  } = useAppSelector(selectShareable)

  const displayIndexes = useStartEndIndexInView({
    elements: deskItemsRef.current,
    container: sidebarRef,
    deps: [selectedRef],
  })

  const { desksByDepartment } = shareable ?? {}
  const isError: boolean = Boolean(error)

  useEffect(() => {
    actions.fetchShareable(shareableId)
    const interval = setInterval(() => {
      actions.fetchShareable(shareableId)
    }, FETCH_SHAREABLE_INTERVAL)
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [actions, shareableId])

  useEffect(() => {
    if (!isLoading) {
      lastUpdate.current = dayjs()
      setIsStale(false)
    }
  }, [shareable, isLoading])

  useEffect(() => {
    const int = setInterval(() => {
      if (dayjs().diff(lastUpdate.current, "second") > STALE_TIME_IN_SECONDS) {
        setIsStale(true)
      }
    }, STALE_CHECK_INTERVAL)
    return () => {
      if (int) {
        clearInterval(int)
      }
    }
  }, [])

  useEffect(() => {
    if (desksByDepartment) {
      const departmentNames: string[] = Object.keys(desksByDepartment)
      if (departmentNames[index.current]) {
        setSelectedDepartment(departmentNames[index.current])
      }
    }

    let changeDepartmentInterval: NodeJS.Timeout | null = null
    if (index.current === 0 && desksByDepartment) {
      const departmentNames: string[] = Object.keys(desksByDepartment)
      setSelectedDepartment(departmentNames[index.current])
    }
    if (desksByDepartment) {
      changeDepartmentInterval = setInterval(() => {
        if (
          displayIndexes.end > 0 &&
          desksByDepartment[selectedDepartment].length > displayIndexes.end
        ) {
          if (deskItemsRef?.current[displayIndexes.end]) {
            deskItemsRef.current[displayIndexes.end]!.scrollIntoView({
              block: "start",
            })
            setSelectedRef(deskItemsRef.current[displayIndexes.end])
          }
        } else {
          const departmentNames: string[] = Object.keys(desksByDepartment)
          if (departmentNames.length > 1) {
            index.current = (index.current + 1) % departmentNames.length
            setSelectedDepartment(departmentNames[index.current])
            curDepRef.current?.scrollIntoView({
              block: "start",
            })
            setSelectedRef(curDepRef.current)
          } else {
            curDepRef.current?.scrollIntoView({
              block: "start",
            })
            setSelectedRef(curDepRef.current)
          }
        }
      }, CHANGE_DEPARTMENT_INTERVAL)
    }

    return () => {
      if (changeDepartmentInterval) {
        clearInterval(changeDepartmentInterval)
      }
    }
  }, [
    selectedDepartment,
    desksByDepartment,
    displayIndexes.end,
    displayIndexes.start,
  ])

  useEffect(() => {
    if (desksByDepartment) {
      setSelectedDesks(
        desksByDepartment[selectedDepartment]?.map((desk, i) => {
          if (
            i >= displayIndexes.start &&
            (displayIndexes.end === -1 || i <= displayIndexes.end)
          ) {
            return { ...desk, index: i + 1 }
          }
          return desk
        }) ?? [],
      )
    }
  }, [
    selectedDepartment,
    desksByDepartment,
    displayIndexes.end,
    displayIndexes.start,
  ])

  const shareableFloorPlanClasses = classNames({
    ShareableFloorPlan: true,
    isCentered: isLoading || isError,
  })

  const getDeskList = useMemo(() => {
    if (desksByDepartment) {
      let els: (HTMLDivElement | null)[] = []
      return Object.keys(desksByDepartment).flatMap((dep) => {
        return (
          <Fragment key={dep}>
            <DepartmentListItem
              name={
                dep === WITHOUT_DEPARTMENT
                  ? t("desktop.shareable.without_department")
                  : dep
              }
              ref={dep === selectedDepartment ? curDepRef : null}
            />
            {desksByDepartment[dep].map((d, i) => {
              const desks = (
                <DeskListItem
                  index={dep === selectedDepartment ? i + 1 : undefined}
                  ref={(element) => {
                    if (dep === selectedDepartment) {
                      els.push(element)
                    }
                  }}
                  key={`desk-list-${dep}-${d.id}`}
                  name={d.name}
                  reserved={d.reserved}
                  reservations={d.reservations}
                  amenities={d.amenities}
                />
              )
              deskItemsRef.current = els
              return desks
            })}
          </Fragment>
        )
      })
    }
  }, [desksByDepartment, selectedDepartment, t])

  const height = shareable?.image.height ?? 1
  const width = shareable?.image.width ?? 1

  const placeDesks = useMemo(
    () =>
      desksByDepartment && shareable
        ? Object.keys(desksByDepartment).flatMap((dep) => {
            if (dep === selectedDepartment) {
              return selectedDesks.map((d) => (
                <PlaceDesk
                  key={`place-${dep}-${d.id}`}
                  desk={d}
                  mapHeight={height}
                  mapWidth={width}
                  index={d.index}
                />
              ))
            }
            return desksByDepartment[dep].map((d, i) => (
              <PlaceDesk
                key={`place-${dep}-${d.id}`}
                desk={d}
                mapHeight={height}
                mapWidth={width}
              />
            ))
          })
        : null,
    [
      desksByDepartment,
      shareable,
      selectedDepartment,
      selectedDesks,
      height,
      width,
    ],
  )

  const noOfBookedDesks: number =
    shareable?.desks.filter((d) => d.reserved).length ?? 0

  const hereX: number = Number(
    query.get("coord_x") ?? shareable?.payload.here.coord_x,
  )
  const hereY: number = Number(
    query.get("coord_y") ?? shareable?.payload.here.coord_y,
  )

  return (
    <div className={shareableFloorPlanClasses}>
      {isLoading && !isLoaded ? (
        <Loader />
      ) : isError ? (
        <ShareableFloorPlanError />
      ) : (
        shareable && (
          <div className="wrapper">
            <ShareableHeader
              buildingName={shareable.building.name}
              floorName={shareable.floor.name}
              noOfBookedDesks={noOfBookedDesks}
              logoURL={shareable.logo?.url}
            />
            <div className="content">
              <div className="sidebar" ref={sidebarRef}>
                {isStale && (
                  <div className="stale">
                    {t("desktop.shareable.stale_timetable")}{" "}
                    {dayjs(lastUpdate.current).format(SHORT_USER_TIME_FORMAT)}
                  </div>
                )}
                <div className="desk-list">{getDeskList}</div>
              </div>
              <div className="floor-map">
                <SimpleMap
                  image={shareable.image.url}
                  mapHeight={shareable.image.height}
                  mapWidth={shareable.image.width}
                >
                  {!hideYouAreHereMarker && (
                    <YouAreHere
                      x={hereX}
                      y={hereY}
                      mapHeight={shareable.image.height}
                      mapWidth={shareable.image.width}
                    />
                  )}
                  {placeDesks}
                </SimpleMap>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  )
}
export default ShareableFloorPlan
