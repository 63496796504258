import { useMemo } from "react"

import i18n from "../i18n"

import { CURRENCIES } from "../redux/api/billing/constants"
import { Currency } from "../redux/api/billing/types"

export const useCurrencyFormatter = (
  currency: Currency = CURRENCIES.EUR,
  signDisplay: "auto" | "never" | "always" = "auto",
  language: string = i18n.language,
) => {
  return useMemo(
    () =>
      new Intl.NumberFormat(language, {
        style: "currency",
        currency: currency ?? CURRENCIES.EUR,
        signDisplay,
      }),
    [currency, language, signDisplay],
  )
}
