import React from "react"

import { useTranslation } from "react-i18next"
import { MultiValue } from "react-select"

import { customContentListURL } from "../../../../../api"
import { CUSTOM_CONTENT_STATUS } from "../../CustomContent/constants"
import { SETTINGS_GROUPS, SettingsGroups } from "../constants"
import SettingsGroup from "./index"

import { CustomContentResponse } from "../../../../../redux/api/customContent/types"

import AsyncSelect from "../../../../../components/advanced/AsyncSelect"
import { UncontrolledField } from "../../../../../components/Field"

type Props = {
  checked: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  selectedContent: CustomContentResponse[]
  setSelectedContent: (content: CustomContentResponse[]) => void
}

const CustomContentGroup = ({
  checked,
  onToggleSettingsGroup,
  selectedContent,
  setSelectedContent,
}: Props) => {
  const { t } = useTranslation()

  // converts the MultiValue<CustomContentResponse> to CustomContentResponse[]
  const handleOnChange = (value: MultiValue<CustomContentResponse> | null) =>
    setSelectedContent([...(value ?? [])])

  return (
    <SettingsGroup
      label={t(
        "desktop.settings.rooms.device_settings.form.custom_content.title",
      )}
      checked={checked}
      onChange={(checked) =>
        onToggleSettingsGroup(SETTINGS_GROUPS.CUSTOM_CONTENT_GROUP, checked)
      }
      id={SETTINGS_GROUPS.CUSTOM_CONTENT_GROUP}
    >
      <div className="FieldWrapper Subtext">
        {t("desktop.settings.rooms.device_settings.form.custom_content.intro")}
      </div>
      <div style={{ minWidth: "calc(50% - 1rem)" }}>
        <UncontrolledField
          label={t(
            "desktop.settings.rooms.device_settings.form.custom_content.label",
          )}
        >
          <AsyncSelect
            value={selectedContent ?? []}
            onChange={handleOnChange}
            isMulti
            urlGenerator={(fetchOptions) =>
              customContentListURL({
                ...fetchOptions,
                status: CUSTOM_CONTENT_STATUS.enabled,
              })
            }
            getOptionLabel={(content) =>
              content.name ?? `${t("general.untitled")} (#${content.id})`
            }
            getOptionValue={(content) => String(content.id)}
            isPaginated
          />
        </UncontrolledField>
      </div>
    </SettingsGroup>
  )
}

export default CustomContentGroup
