import { useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useNavigation } from "../../hooks/useNavigation"
import { useToast } from "../../hooks/useToast"
import { INTEGRATIONS_PATHS } from "../../screens/Settings/Integrations/constants"
import Button from "../advanced/Button"
import CopyToClipboard from "../advanced/CopyToClipboard"
import Card from "../basic/Card"
import { Input } from "../basic/Input"
import Field from "../Field"
import { setErrors } from "./formUtils"
import PageForm from "./PageFormHook"

import { useCreateApiKeyMutation } from "../../redux/api/apiKeys"
import { CreateApiKeyResponse } from "../../redux/api/apiKeys/types"
import { isApiResponseError, isRejected } from "../../redux/api/types"

import "./ApiKeyForm.sass"

type FormValues = {
  name: string
}

type ApiKeyFormProps = {
  setApiKeyResponse: (response: CreateApiKeyResponse) => void
  apiKeyResponse: CreateApiKeyResponse | undefined
}

const ApiKeyForm = ({ apiKeyResponse, setApiKeyResponse }: ApiKeyFormProps) => {
  const { t } = useTranslation()
  const { infoToast, errorToast } = useToast()
  const { push } = useNavigation()

  const methods = useForm<FormValues>({
    defaultValues: {
      name: "",
    },
  })

  const {
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const [createApiKey] = useCreateApiKeyMutation()

  const onCreateClick = useCallback(
    async ({ name }: FormValues) => {
      const response = await createApiKey({
        name,
      })

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
          return
        }
      } else {
        infoToast(
          t("desktop.settings.integrations.api.new.form.api_key_created"),
        )
        setApiKeyResponse(response.data)
      }
    },
    [createApiKey, setError, errorToast, infoToast, t, setApiKeyResponse],
  )

  return (
    <FormProvider {...methods}>
      {apiKeyResponse ? (
        <div className="ApiResponse">
          <Card className="ApiKeyCard">
            <div className="ApiResponseRow">
              <label>
                {t("desktop.settings.integrations.api.new.form.name")}
              </label>
              <Input
                autoFocus
                disabled
                placeholder={t(
                  "desktop.settings.integrations.api.new.form.name_placeholder",
                )}
                value={apiKeyResponse.name}
              />
            </div>

            <div className="ApiResponseRow">
              <label>
                {t("desktop.settings.integrations.api.new.form.api_key")}
              </label>
              <CopyToClipboard
                successText={t(
                  "desktop.settings.integrations.api.new.form.copy_success",
                )}
                text={apiKeyResponse.client_id}
              />
            </div>

            <div className="ApiResponseRow">
              <label>
                {t("desktop.settings.integrations.api.new.form.api_secret")}
              </label>
              <CopyToClipboard
                successText={t(
                  "desktop.settings.integrations.api.new.form.copy_success",
                )}
                text={apiKeyResponse.client_secret}
              />
            </div>
          </Card>
          <Button
            className="ApiResponseButton"
            onClick={() => push(INTEGRATIONS_PATHS.apiKeys)}
          >
            {t("desktop.settings.integrations.api.new.form.api_key_ok")}
          </Button>
        </div>
      ) : (
        <PageForm
          submitButtonText={t(
            "desktop.settings.integrations.api.new.form.create",
          )}
          backUrl={INTEGRATIONS_PATHS.root}
          className="ApiKeyForm"
          updateMode={false}
          onCreate={onCreateClick}
        >
          <Field
            control={control}
            name="name"
            label={t("desktop.settings.integrations.api.new.form.name")}
          >
            {(props) => (
              <Input
                autoFocus
                disabled={isSubmitting}
                placeholder={t(
                  "desktop.settings.integrations.api.new.form.name_placeholder",
                )}
                {...props}
              />
            )}
          </Field>
        </PageForm>
      )}
    </FormProvider>
  )
}

export default ApiKeyForm
