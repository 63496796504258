import React, { useRef, useState } from "react"

import cn from "classnames"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { cssTransition } from "react-toastify"

import { FETCH_FOR_COUNT, FETCH_UNREAD } from "../../../constants"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"
import { useOnEscape } from "../../../hooks/useOnEscape"
import { useToast } from "../../../hooks/useToast"
import { ACCOUNT_PATHS } from "../../../screens/Settings/UserProfile/constants"
import { generateString } from "../../../utils"
import { Tooltip } from "../../Tooltip"
import NotificationBell from "../NotificationBell"
import NotificationList from "../NotificationList"

import {
  useFetchNotificationsQuery,
  useMarkNotificationsAsReadAndDiscardMutation,
} from "../../../redux/api/notifications"
import { isRejected } from "../../../redux/api/types"

import CheckAllSVG from "../../../assets/images/icons/CheckAll.svg"
import OpenInNewSVG from "../../../assets/images/icons/OpenInNew.svg"

import "./style.sass"

const NOTIFICATIONS_CONTAINER_HEIGHT = "44.375rem"
export const TOAST_POPUP_CONTAINER_ID = "notification-popup-toast-container"

const NotificationMenu = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { errorToast, infoToast } = useToast()

  const menuRef = useRef<HTMLDivElement>(null)

  const [listId, setListId] = useState<string | null>(null)

  const handleListIdGenerated = (id: string) => {
    setListId(id)
  }

  const { data: { count: notificationCount = 0 } = {} } =
    useFetchNotificationsQuery({ ...FETCH_FOR_COUNT, ...FETCH_UNREAD })

  const [markNotificationsAsReadAndDiscard, { isLoading }] =
    useMarkNotificationsAsReadAndDiscardMutation()

  const [isOpen, setOpen] = useState(false)

  const handleToggleMenu = () => setOpen((prev) => !prev)

  const handleCloseMenu = () => setOpen(false)

  const handleMarkAllAsReadClick = async () => {
    if (!listId) {
      return
    }

    const response = await markNotificationsAsReadAndDiscard({
      ids: [],
      listId,
    })

    if (isRejected(response)) {
      errorToast(response.error.message)

      return
    }

    infoToast(
      t(
        "desktop.settings.profile.notifications.toasts.mark_all_as_read_success",
      ),
      {
        hideProgressBar: true,
        containerId: TOAST_POPUP_CONTAINER_ID,
      },
    )
  }

  const handleShowAllNotificationsClick = () => {
    history.push(ACCOUNT_PATHS.notifications.root)
    handleCloseMenu()
  }

  useOnEscape(handleCloseMenu)

  useOnClickOutside([menuRef], handleCloseMenu)

  const isMarkAllAsReadButtonDisabled = isLoading || notificationCount === 0

  return (
    <div className="notification-menu" ref={menuRef}>
      <NotificationBell action={handleToggleMenu} />

      {isOpen && (
        <div className="notification-menu__dropdown">
          <div className="notification-menu__header">
            <div className="notification-menu__title">
              {t("desktop.settings.profile.notifications.notifications")}
            </div>
            <div className="notification-menu__actions">
              <Tooltip
                uniqueId={generateString(4)}
                content={t(
                  "desktop.settings.profile.notifications.actions.mark_all_as_read",
                )}
              >
                <CheckAllSVG
                  className={cn("notification-menu__actions-mark-all-as-read", {
                    "notification-menu__actions-mark-all-as-read--disabled":
                      isMarkAllAsReadButtonDisabled,
                  })}
                  onClick={handleMarkAllAsReadClick}
                />
              </Tooltip>

              <Tooltip
                uniqueId={generateString(4)}
                content={t(
                  "desktop.settings.profile.notifications.actions.show_all_notifications",
                )}
              >
                <OpenInNewSVG
                  className="notification-menu__actions-show-all-notifications"
                  onClick={handleShowAllNotificationsClick}
                />
              </Tooltip>
            </div>
          </div>
          <div className="notification-menu__content">
            <NotificationList
              variant="popup"
              onlyShowUnread
              containerHeight={NOTIFICATIONS_CONTAINER_HEIGHT}
              onListIdGenerated={handleListIdGenerated}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default NotificationMenu
