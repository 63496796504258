import React from "react"

import classNames from "classnames"

import "./style.sass"

export type CardProps = {
  children: React.ReactNode
  header?: React.ReactNode
  actions?: React.ReactNode
  className?: string
  isFloorPlan?: boolean
}

const Card = ({
  header,
  children,
  actions,
  className,
  isFloorPlan,
}: CardProps) => {
  const cn = classNames({
    Card: !isFloorPlan,
    FloorPlanCard: isFloorPlan,
    [className!]: className,
  })

  return (
    <div className={cn}>
      {isFloorPlan ? (
        children
      ) : (
        <>
          {header && <div className="card-header">{header}</div>}
          <div className="card-content">
            <div className="card-content-body">{children}</div>
            {actions && <div className="card-actions">{actions}</div>}
          </div>
        </>
      )}
    </div>
  )
}

export default Card
