import React, { useState } from "react"

import classNames from "classnames"

import CheckCircleSVG from "../../assets/images/icons/CheckCircle.svg"
import RadioUncheckedSVG from "../../assets/images/icons/RadioUnchecked.svg"

import "./Checker.sass"

type CheckerProps = {
  checked: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
}

export const Checker: React.FC<CheckerProps> = ({
  checked,
  disabled = false,
  onChange,
}) => {
  const [value, setValue] = useState(checked)

  const actionButtonClassName = classNames({
    Checker: true,
    checked,
    disabled,
  })

  const handleClick = () => {
    if (!disabled) {
      if (onChange) {
        onChange(!value)
      }
      setValue(!value)
    }
  }

  return (
    <div className={actionButtonClassName} onClick={handleClick}>
      {checked ? <CheckCircleSVG /> : <RadioUncheckedSVG />}
    </div>
  )
}
