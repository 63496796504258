import React from "react"

import { Trans, useTranslation } from "react-i18next"

import { useCustomContentContext } from "../CustomContentContext"
import SettingsSection from "./SettingsSection"

import Button from "../../../../../components/advanced/Button"

const DevicesSection = () => {
  const { t } = useTranslation()
  const { paths, devices } = useCustomContentContext()

  return (
    <SettingsSection
      title={t(
        "desktop.settings.rooms.custom_content.forms.general.show_on_devices_label",
      )}
      action={
        <Button variant="secondary" to={paths.devices}>
          {t("general.change")}
        </Button>
      }
      className="DevicesSection"
    >
      <Trans
        i18nKey={"desktop.settings.rooms.custom_content.show_on_devices"}
        values={{
          number: `<strong>${devices.length}</strong>`,
        }}
      />
    </SettingsSection>
  )
}

export default DevicesSection
