import React, { ChangeEvent, ForwardedRef, forwardRef } from "react"

import { Field } from "./Field"
import { Label } from "./Label"

import "./PinField.sass"

type Props = {
  value: string
  onChange: (pin: string) => void
  label?: string
  helpText?: string
  canEdit?: boolean
  maxLength?: number
  minLength?: number
}

/**
 * @deprecated
 */
export const PinField = forwardRef(
  (
    { value, onChange, label, helpText, maxLength, minLength, canEdit }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (maxLength) {
        onChange(e.target.value.substr(0, maxLength).toUpperCase())
      } else {
        onChange(e.target.value.toUpperCase())
      }
    }

    return (
      <Field className="PinField">
        <Label>{label || "Pin"}</Label>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          ref={ref}
          maxLength={maxLength}
          minLength={minLength}
          disabled={!canEdit}
        />
        {helpText && <p>{helpText}</p>}
      </Field>
    )
  },
)
