import React, { forwardRef } from "react"

import "./DepartmentListItem.sass"

type DepartmentListItemProps = {
  name: string
}

const DepartmentListItem = forwardRef<HTMLDivElement, DepartmentListItemProps>(
  ({ name }, ref) => (
    <div className="department-name" ref={ref}>
      {name}
    </div>
  ),
)

export default DepartmentListItem
